import {
    put, call, all, takeLatest,
} from 'redux-saga/effects';
import { notification } from 'antd';
import {
    signInFailure, signInSuccess, signUpSuccess, signUpFailure
} from '../redux/actions/authActions';
import { SIGNIN, SIGNOUT, SIGNUP } from '../redux/types';
import { setTokens, userSignIn, userSignOut, userSignUp, authenticateCurrentUserIngroup } from '../services/auth';
import { parseJwt } from '../utils/utility';

function* signIn({ credentials }) {
    try {
        const res = yield userSignIn(credentials.username?.trim(), credentials.password?.trim());
        yield authenticateCurrentUserIngroup()
        notification.destroy();
        if (res && !res.challengeName) {
            localStorage.setItem('user', JSON.stringify(res.attributes))
            notification.success({ message: 'Login Successful.' });
            yield setTokens(res);
        }
        yield put(signInSuccess({ res }));

    } catch (error) {
        yield put(signInFailure());
        notification.destroy();
        if (error.message === 'Network error') {
            notification.error({
                message: error.message
            });
        } else if (error.message === "User is not confirmed.") {
            notification.warn({
                message: error.message,
                description: "Please verify your email & try again."
            });
        } else {
            notification.error({
                message: 'Incorrect email address or password',
            });
        }
    }
}

export const getCurrentUser = () => {
    const token = localStorage.getItem('ap_idToken') ?? '';
    if (token) {
        const userPayload = parseJwt(token);
        if (userPayload.exp < new Date().getTime() / 1000) {
            return false;
        }
        return true;
    }
    return false;
};


function* signOut() {
    yield userSignOut();
    // notification.destroy();
}

function* signUp({ credentials }) {
    try {
        var attributes = {}
        attributes.firstName = credentials.firstName
        attributes.lastName = credentials.lastName
        const res = yield userSignUp(credentials.username?.trim(), credentials.password?.trim(), attributes);

        notification.destroy();
        if (res) {
            notification.destroy();
            notification.success({
                duration: 5,
                message: 'Successfully Created PIH Account.',
                description: 'Please Verify Your mail & Login'

            });
            yield put(signUpSuccess({ res }));
        }

    } catch (error) {
        yield put(signUpFailure());
        notification.destroy();

        if (error.message === 'Network error') {
            notification.error({
                message: error.message
            });
        } else if (error.message === 'An account with the given email already exists.') {
            notification.error({
                message: error.message,
            });
            notification.warn({
                message: "Please try signing up using different email.",
            });
        } else {
            notification.error({
                message: 'Incorrect email address or password',
            });
        }
    }
}

function* authWatcher() {
    yield takeLatest(SIGNIN, signIn);
    yield takeLatest(SIGNOUT, signOut);
    yield takeLatest(SIGNUP, signUp);
}

function* authSagas() {
    yield all([
        call(authWatcher),
    ]);
}

export default authSagas;
