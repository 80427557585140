import {GENERATELICENSE,FETCH_LICENSE_LIST,FETCH_LICENSE_LIST_SUCECESS,FETCH_LICENSE_LIST_FAILURE,
GENERATELICENSE_SUCCESS,GENERATELICENSE_FAILURE,SHARELICENSEINFO,SHARELICENSEINFO_SUCCESS,SHARELICENSEINFO_FAILURE
,SHARE_FETCH_LICENSE_LIST,SHARE_FETCH_LICENSE_LIST_SUCECESS,SHARE_FETCH_LICENSE_LIST_FAILURE, FETCH_GROUP_LICENSE_LIST, FETCH_GROUP_LICENSE_LIST_SUCECESS, FETCH_GROUP_LICENSE_LIST_FAILURE,
FETCH_UNIQUE_ID,FETCH_UNIQUE_ID_SUCECESS,FETCH_UNIQUE_ID_FAILURE} from './types';

const generateLicenseaction = (payload) => ({
    type: GENERATELICENSE, ...payload,
  });
  const generatelicenseSuccess = (payload) => ({
    type: GENERATELICENSE_SUCCESS,
    ...payload
  });
  const generateLicenseFailure = () => ({
    type: GENERATELICENSE_FAILURE
  });
  const fetchLicenseList = (payload) => ({
    type: FETCH_LICENSE_LIST,
    ...payload
  });
  const fetchLicenseListSuccess = (payload) => ({
    type: FETCH_LICENSE_LIST_SUCECESS,
    ...payload
  });
  const fetchLicenseListFailure = () => ({
    type: FETCH_LICENSE_LIST_FAILURE
  });

  const shareLicenseInfo = (payload) => ({
    type: SHARELICENSEINFO, ...payload,
  });
  const shareLicenseInfoSuccess = (payload) => ({
    type: SHARELICENSEINFO_SUCCESS,
    ...payload
  });
  const shareLicenseInfoFailure = () => ({
    type: SHARELICENSEINFO_FAILURE
  });
  const sharefetchLicenseList = (payload) => ({
    type: SHARE_FETCH_LICENSE_LIST,
    ...payload
  });
  const sharefetchLicenseListSuccess = (payload) => ({
    type: SHARE_FETCH_LICENSE_LIST_SUCECESS,
    ...payload
  });
  const sharefetchLicenseListFailure = () => ({
    type: SHARE_FETCH_LICENSE_LIST_FAILURE
  });
  const fetchGroupLicenseList = (payload) => ({
    type: FETCH_GROUP_LICENSE_LIST,
    ...payload
  });
  const fetchGroupLicenseListSuccess = (payload) => ({
    type: FETCH_GROUP_LICENSE_LIST_SUCECESS,
    ...payload
  });
  const fetchGroupLicenseListFailure = () => ({
    type: FETCH_GROUP_LICENSE_LIST_FAILURE
  });

  const fetchUniqueId = (payload) => ({
    type: FETCH_UNIQUE_ID,
    ...payload
  });
  const fetchUniqueIdSuccess = (payload) => ({
    type: FETCH_UNIQUE_ID_SUCECESS,
    ...payload
  });
  const fetchUniqueIdFailure = () => ({
    type: FETCH_UNIQUE_ID_FAILURE
  });

  export {
    generateLicenseaction,
    fetchLicenseList,fetchLicenseListSuccess,fetchLicenseListFailure,
    fetchGroupLicenseList,fetchGroupLicenseListSuccess,fetchGroupLicenseListFailure,
    generatelicenseSuccess,generateLicenseFailure,shareLicenseInfo,shareLicenseInfoSuccess,shareLicenseInfoFailure,
    sharefetchLicenseList,
    sharefetchLicenseListSuccess,
    sharefetchLicenseListFailure,
    fetchUniqueId,fetchUniqueIdSuccess,fetchUniqueIdFailure
  };