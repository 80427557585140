import { useState } from 'react';
import { Form, Input, Button, Select, Row, Col, Card } from 'antd';

const { Option } = Select;

const PricingForm =  (props) =>{
  // State to store form values
  const [formData, setFormData] = useState({});

  // Handler for changing form input values
  const handleValueChange = (changedValues, allValues) => {
    setFormData(allValues);
  };

  // Form submission handler
  const onFinish = (values) => {
    console.log('Form Data:', values);
    // Process the formData here
  };

  return (
    <Form
      layout="vertical"
     // onFinish={onFinish}
      //onValuesChange={handleValueChange}
    >
      {/* Project Inputs Section */}
      <Card title="Project Inputss" className="equipment-card">
        <Row gutter={16}>
          <Col span={4}>
            <Form.Item label="No of Welding Systems" name="QTY">
              <Input type="number" />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item label="Contract Type" name="mode" rules={[{ required: true, message: 'Please select contract type' }]}>
              <Select placeholder="Select Contract">
                <Option value="Rent">Rent</Option>
                <Option value="Sale">Sale</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item label="Project Duration (months)" name="duration">
              <Input type="number" />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item label="License Tier" name="tier" rules={[{ required: true, message: 'Please select license tier' }]}>
              <Select placeholder="Select License Tier">
                <Option value="Basic">Basic</Option>
                <Option value="Standard">Standard</Option>
                <Option value="Premium">Premium</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item label="License Duration" name="LicDuration" rules={[{ required: true, message: 'Please select license duration' }]}>
              <Select placeholder="Select License Duration">
                <Option value="1 Year">1 Year</Option>
                <Option value="2 Year">2 Year</Option>
                <Option value="3 Year">3 Year</Option>
                <Option value="5 Year">5 Year</Option>
                <Option value="Perpetual">Perpetual</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Card>

      {/* Hardware Section */}
      <Row gutter={16}>
      <Col span={8}>
      <Card title="Hardware" className="Prcing-card2">
        <Row gutter={8}>
          <Col span={6}>
            <Form.Item label="Unit Cost" name="HWCost">
              <Input type="number" defaultValue={3000} />
            </Form.Item>
            <Form.Item label="Utilization" name="utilization">
              <Input type="number" min="0" max="100" step="0.01" defaultValue={60} />
            </Form.Item>
            <Form.Item label="Profit Margin" name="margin">
              <Input type="number" min="0" max="100" step="0.01" defaultValue={60} />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="Selling Price" name="Sellingprice">
              <Input type="number" readOnly />
            </Form.Item>
            <Form.Item label="Rental Rate" name="rate">
              <Input type="number" min="0" max="100" step="0.01" defaultValue={10} />
            </Form.Item>
            <Form.Item label="Monthly Rental" name="HWMonthlyrental">
              <Input type="number" readOnly />
            </Form.Item>
          </Col>
        </Row>
      </Card>
      </Col>
      <Col span={8}>
      {/* Application Section */}
      <Card title="Applications" className="Prcing-card2">
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item label="Pay Back Period (months)" name="Payback">
              <Input type="number" defaultValue={12} />
            </Form.Item>
            <Form.Item label="Development Cost" name="devcost">
              <Input type="number" defaultValue={300000} readOnly />
            </Form.Item>
            <Form.Item label="Profit Margin" name="sw_margin">
              <Input type="number" min="0" max="100" step="0.01" defaultValue={60} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Units per Year" name="ProjectCount">
              <Input type="number" defaultValue={40} />
            </Form.Item>
            <Form.Item label="Recovery Per unit" name="recoveryperproject">
              <Input type="number" readOnly />
            </Form.Item>
            <Form.Item label="Pricing Per unit" name="swpricing">
              <Input type="number" readOnly />
            </Form.Item>
          </Col>
        </Row>
      </Card>
      </Col>
      <Col span={8}>
      {/* License Weightage */}
      <Card title="License Weightage" className="Prcing-card2">
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item label="Basic" name="basictier">
              <Input type="number" defaultValue={0.5} readOnly />
            </Form.Item>
            <Form.Item label="Standard" name="standardtier">
              <Input type="number" defaultValue={0.75} readOnly />
            </Form.Item>
            <Form.Item label="Premium" name="Premiumtier">
              <Input type="number" defaultValue={1} readOnly />
            </Form.Item>
          </Col>
        </Row>
      </Card>
      </Col>
      <Col span={8}>
      {/* Other Costs Section */}
      <Card title="Other Costs" className="Prcing-card2">
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item label="Internet Fee" name="InternetFees">
              <Input type="number" defaultValue={50} />
            </Form.Item>
            <Form.Item label="AWS" name="cloudfee">
              <Input type="number" defaultValue={50} />
            </Form.Item>
            <Form.Item label="Maintenance & Support" name="Maintenance">
              <Input type="number" defaultValue={100} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Premium Charges" name="Premiumcharges">
              <Input type="number" defaultValue={100} />
            </Form.Item>
            <Form.Item label="One Time Training Cost" name="Training">
              <Input type="number" defaultValue={1500} />
            </Form.Item>
            <Form.Item label="One Time Premium" name="Premiumonetime">
              <Input type="number" defaultValue={500} />
            </Form.Item>
          </Col>
        </Row>
      </Card>
      </Col>
      </Row>

      {/* Submit Button */}
      <Form.Item>
        <Button type="primary" htmlType="submit">
          Generate Pricing
        </Button>
      </Form.Item>
    </Form>
  );
};

export default PricingForm;
