/* eslint-disable no-param-reassign */
/* eslint-disable react/no-unescaped-entities */
import { ArrowLeftOutlined, LineChartOutlined, InfoCircleOutlined, DatabaseOutlined, ProjectOutlined } from '@ant-design/icons';
import {
  Button,
  Col,
  Form,
  Row,
  Tabs,
  Tooltip,
  Typography,
  Card, Table, Collapse, Input
} from 'antd';
import { get } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import history from '../../services/history.js';

const { Title, Text } = Typography;
const { TabPane } = Tabs;
const { Panel } = Collapse;

const WeldingWireProjectDetails = (props) => {

  const { showPipelineModel, setShowPipelineModel, editRecord, setProjectDetails, projectDetails, isRead, setIsRead } = props;

  const pipelines = [
    {
      projectInfo: [
        { key: 'Proposal Number', value: 'WE24-Hxxx' },
        { key: 'Project Name', value: 'Rio Bravo' },
        { key: 'Contractor', value: 'TBD' },
        { key: 'Technical Specification', value: 'API 1104' },
        { key: 'Client Specification', value: 'N/A' },
        { key: 'Pipe Diameter', value: '48.0"' },
        { key: 'Wall Thickness', value: '1.250"' },
      ],
      wireInfo: [
        { key: 'Internal Wire Diameter', value: '0.035"' },
        { key: 'External Wire Diameter', value: '0.040"' },
        { key: 'Internal Wire Wastage', value: '30%' },
        { key: 'External Wire Wastage', value: '20%' },
        { key: 'Number of IWM Heads', value: '4' },
        { key: 'Transfer Efficiency', value: '96%' },
      ],
      productionInfo: [
        { key: 'Production Welds', value: '11' },
        { key: 'WPQ welds', value: '0' },
        { key: 'Training Welds', value: '0' },
        { key: 'Total Welds', value: '11' },
        { key: 'Average welds per day', value: '70' },
        { key: 'Plan start date', value: new Date() },
        { key: 'Planned complete date', value: new Date() },
        { key: 'working days per week', value: '5' },
      ],
      weldDesignData: {
        bevelDesign: {
          angles: { A: '5°', B: '45°', C: '37.5°' },
          dimensions: { D: '0.110"', E: '0.210"', F: '0.050"', G: '0.050"', H: '1.250"' },
        },
        fillCalculations: {
          'Top of Bevel': '0.402"',
          'Fill Cross Section Area': '0.323 in2',
          'Target Cap Leave': '0.039"',
          'Target Fill Pass Area': '0.308 in2',
          'Theoretical Fill Rate': '0.024',
          'Calc. No. Fill Passes': '12',
        },
      },
      weldingFillParams: [
        {
          passNumber: 'Root',
          wireFeedSpeed: '380 IPM',
          travelSpeed: '29.0 IPM',
          poundsPerPass: '0.59 lbs',
          shieldGasType: '75Ar/25CO2',
          gasFlow: '100 cfh',
          totalCubicFeet: '9.13 ft3'
        },
        {
          passNumber: 'Hot Pass',
          wireFeedSpeed: '420 IPM',
          travelSpeed: '52.5 IPM',
          poundsPerPass: '0.45 lbs',
          shieldGasType: '75Ar/25CO2',
          gasFlow: '75 cfh',
          totalCubicFeet: '3.59 ft3'
        },
        {
          passNumber: '12 Fill(s)',
          wireFeedSpeed: '400 IPM',
          travelSpeed: '18.0 IPM',
          poundsPerPass: '15.39 lbs',
          shieldGasType: '85Ar/15CO2',
          gasFlow: '60 cfh',
          totalCubicFeet: '103.89 ft3'
        },
        {
          passNumber: '2 Cap(s)',
          wireFeedSpeed: '355 IPM',
          travelSpeed: '18.0 IPM',
          poundsPerPass: '2.20 lbs',
          shieldGasType: '85Ar/15CO2',
          gasFlow: '60 cfh',
          totalCubicFeet: '16.76 ft3'
        },
      ],
      consumableCalcs: {
        perWeld: [
          { key: 'Estimated Weight of Root', value: '0.59 lbs', metric: '0.27 kg' },
          { key: 'Estimated Weight of Hot, Fills & Caps (P600)', value: '18.04 lbs', metric: '8.18 kg' },
          { key: 'Estimated Total Weight per Weld', value: '18.63 lbs', metric: '8.45 kg' },
          { key: 'Volume of 75Ar/25CO2', value: '12.72 ft3', metric: '0.36 m3' },
          { key: 'Volume of 85AR/15CO2', value: '120.65 ft3', metric: '3.41 m3' },
        ],
        total: [
          { key: 'Estimated Weight of Root', value: '8 lbs', metric: '4 kg' },
          { key: 'Estimated Weight of Hot, Fills & Caps (P600)', value: '238 lbs', metric: '108 kg' },
          { key: 'Estimated Total Weight per Weld', value: '247 lbs', metric: '112 kg' },
          { key: 'Volume of 75Ar/25CO2', value: '182 ft3', metric: '5 m3' },
          { key: 'Volume of 85AR/15CO2', value: '1593 ft3', metric: '45 m3' },
        ],
      }

    },
    {
      projectInfo: [
        { key: 'Proposal Number', value: 'WE24-Hxxx' },
        { key: 'Project Name', value: 'Rio Bravo' },
        { key: 'Contractor', value: 'TBD' },
        { key: 'Technical Specification', value: 'API 1104' },
        { key: 'Client Specification', value: 'N/A' },
        { key: 'Pipe Diameter', value: '48.0"' },
        { key: 'Wall Thickness', value: '1.250"' },
      ],
      wireInfo: [
        { key: 'Internal Wire Diameter', value: '0.035"' },
        { key: 'External Wire Diameter', value: '0.040"' },
        { key: 'Internal Wire Wastage', value: '30%' },
        { key: 'External Wire Wastage', value: '20%' },
        { key: 'Number of IWM Heads', value: '4' },
        { key: 'Transfer Efficiency', value: '96%' },
      ],
      productionInfo: [
        { key: 'Production Welds', value: '11' },
        { key: 'WPQ welds', value: '0' },
        { key: 'Training Welds', value: '0' },
        { key: 'Total Welds', value: '11' },
        { key: 'Average welds per day', value: '70' },
        { key: 'Plan start date', value: new Date() },
        { key: 'Planned complete date', value: new Date() },
        { key: 'working days per week', value: '5' },
      ],
      weldDesignData: {
        bevelDesign: {
          angles: { A: '5°', B: '45°', C: '37.5°' },
          dimensions: { D: '0.110"', E: '0.210"', F: '0.050"', G: '0.050"', H: '1.250"' },
        },
        fillCalculations: {
          'Top of Bevel': '0.402"',
          'Fill Cross Section Area': '0.323 in2',
          'Target Cap Leave': '0.039"',
          'Target Fill Pass Area': '0.308 in2',
          'Theoretical Fill Rate': '0.024',
          'Calc. No. Fill Passes': '12',
        },
      },
      weldingFillParams: [
        {
          passNumber: 'Root',
          wireFeedSpeed: '380 IPM',
          travelSpeed: '29.0 IPM',
          poundsPerPass: '0.59 lbs',
          shieldGasType: '75Ar/25CO2',
          gasFlow: '100 cfh',
          totalCubicFeet: '9.13 ft3'
        },
        {
          passNumber: 'Hot Pass',
          wireFeedSpeed: '420 IPM',
          travelSpeed: '52.5 IPM',
          poundsPerPass: '0.45 lbs',
          shieldGasType: '75Ar/25CO2',
          gasFlow: '75 cfh',
          totalCubicFeet: '3.59 ft3'
        },
        {
          passNumber: '12 Fill(s)',
          wireFeedSpeed: '400 IPM',
          travelSpeed: '18.0 IPM',
          poundsPerPass: '15.39 lbs',
          shieldGasType: '85Ar/15CO2',
          gasFlow: '60 cfh',
          totalCubicFeet: '103.89 ft3'
        },
        {
          passNumber: '2 Cap(s)',
          wireFeedSpeed: '355 IPM',
          travelSpeed: '18.0 IPM',
          poundsPerPass: '2.20 lbs',
          shieldGasType: '85Ar/15CO2',
          gasFlow: '60 cfh',
          totalCubicFeet: '16.76 ft3'
        },
      ],
      consumableCalcs: {
        perWeld: [
          { key: 'Estimated Weight of Root', value: '0.59 lbs', metric: '0.27 kg' },
          { key: 'Estimated Weight of Hot, Fills & Caps (P600)', value: '18.04 lbs', metric: '8.18 kg' },
          { key: 'Estimated Total Weight per Weld', value: '18.63 lbs', metric: '8.45 kg' },
          { key: 'Volume of 75Ar/25CO2', value: '12.72 ft3', metric: '0.36 m3' },
          { key: 'Volume of 85AR/15CO2', value: '120.65 ft3', metric: '3.41 m3' },
        ],
        total: [
          { key: 'Estimated Weight of Root', value: '8 lbs', metric: '4 kg' },
          { key: 'Estimated Weight of Hot, Fills & Caps (P600)', value: '238 lbs', metric: '108 kg' },
          { key: 'Estimated Total Weight per Weld', value: '247 lbs', metric: '112 kg' },
          { key: 'Volume of 75Ar/25CO2', value: '182 ft3', metric: '5 m3' },
          { key: 'Volume of 85AR/15CO2', value: '1593 ft3', metric: '45 m3' },
        ],
      }

    },
    {
      projectInfo: [
        { key: 'Proposal Number', value: 'WE24-Hxxx' },
        { key: 'Project Name', value: 'Rio Bravo' },
        { key: 'Contractor', value: 'TBD' },
        { key: 'Technical Specification', value: 'API 1104' },
        { key: 'Client Specification', value: 'N/A' },
        { key: 'Pipe Diameter', value: '48.0"' },
        { key: 'Wall Thickness', value: '1.250"' },
      ],
      wireInfo: [
        { key: 'Internal Wire Diameter', value: '0.035"' },
        { key: 'External Wire Diameter', value: '0.040"' },
        { key: 'Internal Wire Wastage', value: '30%' },
        { key: 'External Wire Wastage', value: '20%' },
        { key: 'Number of IWM Heads', value: '4' },
        { key: 'Transfer Efficiency', value: '96%' },
      ],
      productionInfo: [
        { key: 'Production Welds', value: '11' },
        { key: 'WPQ welds', value: '0' },
        { key: 'Training Welds', value: '0' },
        { key: 'Total Welds', value: '11' },
        { key: 'Average welds per day', value: '70' },
        { key: 'Plan start date', value: new Date() },
        { key: 'Planned complete date', value: new Date() },
        { key: 'working days per week', value: '5' },
      ],
      weldDesignData: {
        bevelDesign: {
          angles: { A: '5°', B: '45°', C: '37.5°' },
          dimensions: { D: '0.110"', E: '0.210"', F: '0.050"', G: '0.050"', H: '1.250"' },
        },
        fillCalculations: {
          'Top of Bevel': '0.402"',
          'Fill Cross Section Area': '0.323 in2',
          'Target Cap Leave': '0.039"',
          'Target Fill Pass Area': '0.308 in2',
          'Theoretical Fill Rate': '0.024',
          'Calc. No. Fill Passes': '12',
        },
      },
      weldingFillParams: [
        {
          passNumber: 'Root',
          wireFeedSpeed: '380 IPM',
          travelSpeed: '29.0 IPM',
          poundsPerPass: '0.59 lbs',
          shieldGasType: '75Ar/25CO2',
          gasFlow: '100 cfh',
          totalCubicFeet: '9.13 ft3'
        },
        {
          passNumber: 'Hot Pass',
          wireFeedSpeed: '420 IPM',
          travelSpeed: '52.5 IPM',
          poundsPerPass: '0.45 lbs',
          shieldGasType: '75Ar/25CO2',
          gasFlow: '75 cfh',
          totalCubicFeet: '3.59 ft3'
        },
        {
          passNumber: '12 Fill(s)',
          wireFeedSpeed: '400 IPM',
          travelSpeed: '18.0 IPM',
          poundsPerPass: '15.39 lbs',
          shieldGasType: '85Ar/15CO2',
          gasFlow: '60 cfh',
          totalCubicFeet: '103.89 ft3'
        },
        {
          passNumber: '2 Cap(s)',
          wireFeedSpeed: '355 IPM',
          travelSpeed: '18.0 IPM',
          poundsPerPass: '2.20 lbs',
          shieldGasType: '85Ar/15CO2',
          gasFlow: '60 cfh',
          totalCubicFeet: '16.76 ft3'
        },
      ],
      consumableCalcs: {
        perWeld: [
          { key: 'Estimated Weight of Root', value: '0.59 lbs', metric: '0.27 kg' },
          { key: 'Estimated Weight of Hot, Fills & Caps (P600)', value: '18.04 lbs', metric: '8.18 kg' },
          { key: 'Estimated Total Weight per Weld', value: '18.63 lbs', metric: '8.45 kg' },
          { key: 'Volume of 75Ar/25CO2', value: '12.72 ft3', metric: '0.36 m3' },
          { key: 'Volume of 85AR/15CO2', value: '120.65 ft3', metric: '3.41 m3' },
        ],
        total: [
          { key: 'Estimated Weight of Root', value: '8 lbs', metric: '4 kg' },
          { key: 'Estimated Weight of Hot, Fills & Caps (P600)', value: '238 lbs', metric: '108 kg' },
          { key: 'Estimated Total Weight per Weld', value: '247 lbs', metric: '112 kg' },
          { key: 'Volume of 75Ar/25CO2', value: '182 ft3', metric: '5 m3' },
          { key: 'Volume of 85AR/15CO2', value: '1593 ft3', metric: '45 m3' },
        ],
      }

    },
  ]

  const fcawgDummyData = [
    {
      client: 'TBD',
      proposalNumber: 'WE24-Hxxx',
      project: 'Rio Bravo',
      designStandard: 'API 1104',
      clientSpecification: 'N/A',
      pipeDiameter: '48"',
      wallThickness: '1.250"',
      wireType: 'TBQ',
      extSpoolWeight: '11.0 lbs',
      wireDiameter: '0.047"',
      rootMethod: 'SMAW Root, Hot, Fill 1',
      compoundBevel: 'Yes',
      transferEfficiency: '85%',
      wireWastage: '20%',

      productionWelds: 111,
      trainingWelds: 0,
      wPQWelds:'11',
      totalWelds: 111,
      averageWeldsPerDay:'10',
      planStartDate: new Date(),
      plannedCompleteDate: new Date(),
      workingDaysPerWeek:'6',

      weldingParams: [
        {
          passNumber: 'SMAW Root, Hot, Fill 1',
          wireFeedSpeed: '',
          travelSpeed: '',
          depositionRate: '',
          poundsPerPassGroup: '',
          shieldGasType: '',
          gasFlow: '',
          cubicFeet: '',
        },
        {
          passNumber: '0 Fill(s)',
          wireFeedSpeed: '280 IPM',
          travelSpeed: '12.0 IPM',
          depositionRate: '7.08 lbs/hr',
          poundsPerPassGroup: '0.00',
          shieldGasType: '75Ar/25CO2',
          gasFlow: '60 cfh',
          cubicFeet: '0.00',
        },
        {
          passNumber: '13 Fill(s)',
          wireFeedSpeed: '280 IPM',
          travelSpeed: '6.5 IPM',
          depositionRate: '7.08 lbs/hr',
          poundsPerPassGroup: '42.77',
          shieldGasType: '75Ar/25CO2',
          gasFlow: '60 cfh',
          cubicFeet: '301.60',
        },
        {
          passNumber: '2 Cap(s)',
          wireFeedSpeed: '250 IPM',
          travelSpeed: '9.0 IPM',
          depositionRate: '6.33 lbs/hr',
          poundsPerPassGroup: '4.24',
          shieldGasType: '75Ar/25CO2',
          gasFlow: '60 cfh',
          cubicFeet: '33.52',
        },
      ],

      wireConsumption: [
        { item: 'Weight of TBQ per Weld', english: '47.01 lbs', metric: '21.32 kg' },
        { item: 'Total Wire', english: '5218 lbs', metric: '2367 kg' },
        { item: 'Total spools of Wire', english: '475 Spools = 5225 lbs', metric: '' },
      ],

      gasConsumption: [
        { item: 'Volume of 75Ar/25CO2 per Weld', english: '335.12 ft3', metric: '9.48 m3' },
        { item: 'Total Gas Volume', english: '37199 ft3' },
        { item: 'Average Deposition Rate', english: '6.83 lbs/hr', metric: '3.10 kg/hr' },
      ],

      bevelDrawing: {
        // This could be an SVG string or path to an image file
        svg: '<svg>...</svg>'
      }
    },
    {
      client: 'TBD',
      proposalNumber: 'WE24-Hxxx',
      project: 'Rio Bravo',
      designStandard: 'API 1104',
      clientSpecification: 'N/A',
      pipeDiameter: '48"',
      wallThickness: '1.250"',
      wireType: 'TBQ',
      extSpoolWeight: '11.0 lbs',
      wireDiameter: '0.047"',
      rootMethod: 'SMAW Root, Hot, Fill 1',
      compoundBevel: 'Yes',
      transferEfficiency: '85%',
      wireWastage: '20%',

      productionWelds: 111,
      trainingWelds: 0,
      totalWelds: 111,

      weldingParams: [
        {
          passNumber: 'SMAW Root, Hot, Fill 1',
          wireFeedSpeed: '',
          travelSpeed: '',
          depositionRate: '',
          poundsPerPassGroup: '',
          shieldGasType: '',
          gasFlow: '',
          cubicFeet: '',
        },
        {
          passNumber: '0 Fill(s)',
          wireFeedSpeed: '280 IPM',
          travelSpeed: '12.0 IPM',
          depositionRate: '7.08 lbs/hr',
          poundsPerPassGroup: '0.00',
          shieldGasType: '75Ar/25CO2',
          gasFlow: '60 cfh',
          cubicFeet: '0.00',
        },
        {
          passNumber: '13 Fill(s)',
          wireFeedSpeed: '280 IPM',
          travelSpeed: '6.5 IPM',
          depositionRate: '7.08 lbs/hr',
          poundsPerPassGroup: '42.77',
          shieldGasType: '75Ar/25CO2',
          gasFlow: '60 cfh',
          cubicFeet: '301.60',
        },
        {
          passNumber: '2 Cap(s)',
          wireFeedSpeed: '250 IPM',
          travelSpeed: '9.0 IPM',
          depositionRate: '6.33 lbs/hr',
          poundsPerPassGroup: '4.24',
          shieldGasType: '75Ar/25CO2',
          gasFlow: '60 cfh',
          cubicFeet: '33.52',
        },
      ],

      wireConsumption: [
        { item: 'Weight of TBQ per Weld', english: '47.01 lbs', metric: '21.32 kg' },
        { item: 'Total Wire', english: '5218 lbs', metric: '2367 kg' },
        { item: 'Total spools of Wire', english: '475 Spools = 5225 lbs', metric: '' },
      ],

      gasConsumption: [
        { item: 'Volume of 75Ar/25CO2 per Weld', english: '335.12 ft3', metric: '9.48 m3' },
        { item: 'Total Gas Volume', english: '37199 ft3' },
        { item: 'Average Deposition Rate', english: '6.83 lbs/hr', metric: '3.10 kg/hr' },
      ],

      bevelDrawing: {
        // This could be an SVG string or path to an image file
        svg: '<svg>...</svg>'
      }
    },
    {
      client: 'TBD',
      proposalNumber: 'WE24-Hxxx',
      project: 'Rio Bravo',
      designStandard: 'API 1104',
      clientSpecification: 'N/A',
      pipeDiameter: '48"',
      wallThickness: '1.250"',
      wireType: 'TBQ',
      extSpoolWeight: '11.0 lbs',
      wireDiameter: '0.047"',
      rootMethod: 'SMAW Root, Hot, Fill 1',
      compoundBevel: 'Yes',
      transferEfficiency: '85%',
      wireWastage: '20%',

      productionWelds: 111,
      trainingWelds: 0,
      totalWelds: 111,

      weldingParams: [
        {
          passNumber: 'SMAW Root, Hot, Fill 1',
          wireFeedSpeed: '',
          travelSpeed: '',
          depositionRate: '',
          poundsPerPassGroup: '',
          shieldGasType: '',
          gasFlow: '',
          cubicFeet: '',
        },
        {
          passNumber: '0 Fill(s)',
          wireFeedSpeed: '280 IPM',
          travelSpeed: '12.0 IPM',
          depositionRate: '7.08 lbs/hr',
          poundsPerPassGroup: '0.00',
          shieldGasType: '75Ar/25CO2',
          gasFlow: '60 cfh',
          cubicFeet: '0.00',
        },
        {
          passNumber: '13 Fill(s)',
          wireFeedSpeed: '280 IPM',
          travelSpeed: '6.5 IPM',
          depositionRate: '7.08 lbs/hr',
          poundsPerPassGroup: '42.77',
          shieldGasType: '75Ar/25CO2',
          gasFlow: '60 cfh',
          cubicFeet: '301.60',
        },
        {
          passNumber: '2 Cap(s)',
          wireFeedSpeed: '250 IPM',
          travelSpeed: '9.0 IPM',
          depositionRate: '6.33 lbs/hr',
          poundsPerPassGroup: '4.24',
          shieldGasType: '75Ar/25CO2',
          gasFlow: '60 cfh',
          cubicFeet: '33.52',
        },
      ],

      wireConsumption: [
        { item: 'Weight of TBQ per Weld', english: '47.01 lbs', metric: '21.32 kg' },
        { item: 'Total Wire', english: '5218 lbs', metric: '2367 kg' },
        { item: 'Total spools of Wire', english: '475 Spools = 5225 lbs', metric: '' },
      ],

      gasConsumption: [
        { item: 'Volume of 75Ar/25CO2 per Weld', english: '335.12 ft3', metric: '9.48 m3' },
        { item: 'Total Gas Volume', english: '37199 ft3' },
        { item: 'Average Deposition Rate', english: '6.83 lbs/hr', metric: '3.10 kg/hr' },
      ],

      bevelDrawing: {
        // This could be an SVG string or path to an image file
        svg: '<svg>...</svg>'
      }
    },
  ];

  const [activePipeIndex, setActivePipeIndex] = useState(0);
  const [activeSideBar, setActiveSideBar] = useState('1');

  const handleMainTabChange = (key) => {
    setActivePipeIndex(Number(key));
  };

  const handleSideBarChange = (key) => {
    setActiveSideBar(key);
  };

  // Reusable function to render lists
  const renderInfoList = (data) => (
    <ul className="info-list">
      {data.map((item, index) => (
        <li key={index}>
          <Text strong>{item.key}:</Text>
          <span>{item.value}</span>
        </li>
      ))}
    </ul>
  );

  const renderWeldProductionList = (data) => (
    <ul className="info-list">
      {data.map((item, index) => (
        <li key={index}>
          <Text strong>{item.key}:</Text>
          <span>{(item.key === 'Planned complete date' || item.key === 'Plan start date') 
        ? moment(item.value).format('DD-MMM-YYYY') 
        : item.value}</span>
        </li>
      ))}
    </ul>
  );

  // const renderWeldProductionList = (data) => (
  //   <ul className="info-list">
  //     {data.map((item, index) => (
  //       <li key={index}>
  //         <Text strong>{item.key}:</Text>
  //         <Input 
  //           value={item.value}
  //           // onChange={(e) => handleInputChange(index, e.target.value)}
  //           style={{ marginLeft: '8px' }}
  //           size='small'
  //         />
  //       </li>
  //     ))}
  //   </ul>
  // );

  const renderWeldDesign = (pipelineData) => (
    <Card
      title="WELD DESIGN"
      className="weld-design-card"
    // extra={<Tooltip title="Weld Design Calculations"><InfoCircleOutlined /></Tooltip>}
    >
      <Row gutter={16}>
        <Col xs={24} sm={8}>
          <Card title="Bevel Design" size="small" bordered={false} className="bevel-design">
            <LineChartOutlined className="icon" />
          </Card>
        </Col>
        <Col xs={24} sm={3}>
          <Card title="Angles" size="small" bordered={false}>
            {/* Render Angles */}
            {Object.entries(pipelineData.weldDesignData.bevelDesign.angles).map(([key, value]) => (
              <div key={key}>{key}: {value}</div>
            ))}
          </Card>
        </Col>
        <Col xs={24} sm={4}>
          <Card title="Dimensions" size="small" bordered={false}>
            {/* Render Dimensions */}
            {Object.entries(pipelineData.weldDesignData.bevelDesign.dimensions).map(([key, value]) => (
              <div key={key}>{key}: {value}</div>
            ))}
          </Card>
        </Col>
        <Col xs={24} sm={9}>
          <Card title="Fill Calculations" size="small" bordered={false}>
            {/* Render Fill Calculations */}
            <ul className="info-list">
              {Object.entries(pipelineData.weldDesignData.fillCalculations).map(([key, value]) => (
                <li key={key}>
                  <Text strong>{key}:</Text>
                  <span>{value}</span>
                </li>
              ))}
            </ul>
          </Card>
        </Col>
      </Row>
    </Card>
  );

  const weldingFillColumns = [
    { title: 'Pass Number', dataIndex: 'passNumber', key: 'passNumber' },
    { title: 'Wire Feed Speed', dataIndex: 'wireFeedSpeed', key: 'wireFeedSpeed' },
    { title: 'Travel Speed', dataIndex: 'travelSpeed', key: 'travelSpeed' },
    { title: 'Pounds per Pass', dataIndex: 'poundsPerPass', key: 'poundsPerPass' },
    { title: 'Shield Gas Type', dataIndex: 'shieldGasType', key: 'shieldGasType' },
    { title: 'Gas Flow', dataIndex: 'gasFlow', key: 'gasFlow' },
    { title: 'Total Cubic Feet (ft³)', dataIndex: 'totalCubicFeet', key: 'totalCubicFeet' },
  ];

  // Render tab content based on active pipeline data for iwm
  const renderPipelineContent = (pipelineData) => (
    <div className="weld-design-dashboard">
      <Row gutter={16}>
        <Col span={7}>
          {/* <Card title="Project Information" className="card-wrapper">
            {renderInfoList(pipelineData.projectInfo)}
          </Card> */}
          <Card title="Wire / Process Information" className="card-wrapper">
            {renderInfoList(pipelineData.wireInfo)}
          </Card>
          <Card title="Production Information" className="card-wrapper">
            {renderWeldProductionList(pipelineData.productionInfo)}
          </Card>
          <Card title="comments" className="card-wrapper">
            <p>*Total gas volume does not include wastage or loss.  This should be considered when ordering project quantities </p>
          </Card>
        </Col>
        <Col span={17}>
          {renderWeldDesign(pipelineData)}
          <Card title="WELDING FILL PARAMETERS" className="table-wrapper">
            <Table
              columns={weldingFillColumns}
              dataSource={pipelineData.weldingFillParams}
              pagination={false}
              size="small"
            />
          </Card>
          <Card title="CONSUMABLE CALCULATIONS" className="consumable-calculations">
            <Row gutter={16}>
              <Col span={12}>
                <Card title="Per Weld Weights/volumes (no waste)" size="small" className="calc-card">
                  <Table
                    columns={[
                      { title: 'Item', dataIndex: 'key', key: 'key' },
                      { title: 'Imperial', dataIndex: 'value', key: 'value' },
                      { title: 'Metric', dataIndex: 'metric', key: 'metric' },
                    ]}
                    dataSource={pipelineData.consumableCalcs.perWeld}
                    pagination={false}
                    size="small"
                  />
                </Card>
              </Col>
              <Col span={12}>
                <Card title="Total Weights/volumes (with wastage)" size="small" className="calc-card">
                  <Table
                    columns={[
                      { title: 'Item', dataIndex: 'key', key: 'key' },
                      { title: 'Imperial', dataIndex: 'value', key: 'value' },
                      { title: 'Metric', dataIndex: 'metric', key: 'metric' },
                    ]}
                    dataSource={pipelineData.consumableCalcs.total}
                    pagination={false}
                    size="small"
                  />
                </Card>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
      {/* <Row gutter={16}>
        <Col span={24}>
          <Card title="CONSUMABLE CALCULATIONS" className="consumable-calculations">
            <Row gutter={16}>
              <Col span={12}>
                <Card title="Per Weld Weights/volumes (no waste)" size="small" className="calc-card">
                  <Table
                    columns={[
                      { title: 'Item', dataIndex: 'key', key: 'key' },
                      { title: 'Imperial', dataIndex: 'value', key: 'value' },
                      { title: 'Metric', dataIndex: 'metric', key: 'metric' },
                    ]}
                    dataSource={pipelineData.consumableCalcs.perWeld}
                    pagination={false}
                    size="small"
                  />
                </Card>
              </Col>
              <Col span={12}>
                <Card title="Total Weights/volumes (with wastage)" size="small" className="calc-card">
                  <Table
                    columns={[
                      { title: 'Item', dataIndex: 'key', key: 'key' },
                      { title: 'Imperial', dataIndex: 'value', key: 'value' },
                      { title: 'Metric', dataIndex: 'metric', key: 'metric' },
                    ]}
                    dataSource={pipelineData.consumableCalcs.total}
                    pagination={false}
                    size="small"
                  />
                </Card>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row> */}
    </div>
  );

  // Render tab content based on active pipeline data for fcaw-g
  const FCWAgTab = (projectData) => {
    const projectInfoColumns = [
      { title: '', dataIndex: 'field', key: 'field' },
      { title: '', dataIndex: 'value', key: 'value' },
    ];


    const projectInfoData = Object.entries(projectData)
      .filter(([key]) => !['productionWelds', 'trainingWelds', 'totalWelds', 'weldingParams', 'wireConsumption', 'gasConsumption', 'averageDepositionRate', 'bevelDrawing'].includes(key))
      .map(([field, value]) => ({ field, value: value.toString() }));

    const productionInfoData = [
      { field: 'Production Welds', value: projectData.productionWelds },
      { field: 'Training Welds', value: projectData.trainingWelds },
      { field: 'WPQ welds', value: projectData.wPQWelds },
      { field: 'Total Welds', value: projectData.totalWelds },
      { field: 'Average welds per day', value: projectData.averageWeldsPerDay },
      { field: 'Plan start date', value: projectData.planStartDate },
      { field: 'Planned complete date', value: projectData.plannedCompleteDate },
      { field: 'Working days per week', value: projectData.workingDaysPerWeek },
    ];

    const weldingParamsColumns = [
      { title: 'Pass Number', dataIndex: 'passNumber', key: 'passNumber' },
      { title: 'Wire Feed Speed', dataIndex: 'wireFeedSpeed', key: 'wireFeedSpeed' },
      { title: 'Travel Speed', dataIndex: 'travelSpeed', key: 'travelSpeed' },
      { title: 'Deposition Rate', dataIndex: 'depositionRate', key: 'depositionRate' },
      { title: 'Pounds per Pass Group', dataIndex: 'poundsPerPassGroup', key: 'poundsPerPassGroup' },
      { title: 'Shield Gas Type', dataIndex: 'shieldGasType', key: 'shieldGasType' },
      { title: 'Gas Flow', dataIndex: 'gasFlow', key: 'gasFlow' },
      { title: 'Cubic Feet (ft³)', dataIndex: 'cubicFeet', key: 'cubicFeet' },
    ];

    return (
      <div className="fcaw-g-tab">
        <Row gutter={16}>
          <Col span={6}>
            {/* <Card title="Project Information" className="info-card">
              <Table
                columns={projectInfoColumns}
                dataSource={projectInfoData}
                pagination={false}
                size="small"
              />
            </Card> */}
            <Card title="Production Information" className="info-card">
              <Table
                columns={projectInfoColumns}
                dataSource={productionInfoData}
                pagination={false}
                size="small"
              />
            </Card>
            <Card title="comments" className="card-wrapper">
            <p>*Total gas volume does not include wastage or loss.  This should be considered when ordering project quantities </p>
          </Card>
          </Col>
          <Col span={18}>
            <Card title="Welding Parameters" className="params-card">
              <Table
                columns={weldingParamsColumns}
                dataSource={projectData.weldingParams}
                pagination={false}
                size="small"
              />
            </Card>
            <Row gutter={16}>
              <Col span={12}>
                <Card title="Wire Consumption" className="consumption-card">
                  <Table
                    columns={[
                      { title: '', dataIndex: 'item', key: 'item' },
                      { title: 'English', dataIndex: 'english', key: 'english' },
                      { title: 'Metric', dataIndex: 'metric', key: 'metric' },
                    ]}
                    dataSource={projectData.wireConsumption}
                    pagination={false}
                    size="small"
                  />
                </Card>
                <Card title="Gas Consumption" className="consumption-card">
                  <Table
                    columns={[
                      { title: '', dataIndex: 'item', key: 'item' },
                      { title: '', dataIndex: 'english', key: 'english' },
                      { title: '', dataIndex: 'metric', key: 'metric' },
                    ]}
                    dataSource={projectData.gasConsumption}
                    pagination={false}
                    size="small"
                  />
                </Card>
              </Col>
              {/* <Col span={12}>
                <Card title="Wire Consumption" className="consumption-card">
                  <Table
                    columns={[
                      { title: '', dataIndex: 'item', key: 'item' },
                      { title: 'English', dataIndex: 'english', key: 'english' },
                      { title: 'Metric', dataIndex: 'metric', key: 'metric' },
                    ]}
                    dataSource={projectData.wireConsumption}
                    pagination={false}
                    size="small"
                  />
                </Card>
                <Card title="Gas Consumption" className="consumption-card">
                  <Table
                    columns={[
                      { title: '', dataIndex: 'item', key: 'item' },
                      { title: 'English', dataIndex: 'english', key: 'english' },
                      { title: 'Metric', dataIndex: 'metric', key: 'metric' },
                    ]}
                    dataSource={projectData.gasConsumption}
                    pagination={false}
                    size="small"
                  />
                </Card>
              </Col> */}
              <Col span={12}>
                <Card title="Bevel Drawing" className="bevel-card">
                  <div dangerouslySetInnerHTML={{ __html: projectData.bevelDrawing.svg }} />
                </Card>
              </Col>
            </Row>
            {/* <Row gutter={16}>
              <Col span={12}>
                <Card title="Production Information" className="info-card">
                  <Table
                    columns={projectInfoColumns}
                    dataSource={productionInfoData}
                    pagination={false}
                    size="small"
                  />
                </Card>
              </Col>
              <Col span={12}>
                <Card title="Bevel Drawing" className="bevel-card">
                  <div dangerouslySetInnerHTML={{ __html: projectData.bevelDrawing.svg }} />
                </Card>
              </Col>
            </Row> */}
          </Col>
        </Row>
      </div>
    );
  };

  console.log("activeSideBar", activeSideBar);
  return (
    <div style={{ height: '100%' }}>
      <div>
        <div className="d-flex mb-3 align-items-center">
          <div className='back_equip' >
            <Tooltip title={'Back'} >
              <ArrowLeftOutlined onClick={() => {
                setIsRead(false)
                setShowPipelineModel(false)
                history.push('/welding-wire');
              }} />
            </Tooltip>
          </div>

          <Title className="mb-0 add-new-calibration" level={3} style={{ paddingLeft: '30px' }}>
            Weld Engineering - IWM/Dual Torch - Project Consumable Calculation
          </Title>
        </div>
        <div className="custom-line" />
        <Collapse ghost className="project-detail-collapse pricing-updateFrom">
          <Panel
            header="Project Details"
            className="project-sub-title pricing-updateFrom"
          >
            <div className="d-flex justify-content-between mb-2 overflow-auto">
              {/* Project Name */}
              <div className="col-content-inner">
                <div className="project-details-wrapper text-center">
                  <p className="fw-medium title">Project Name</p>
                  <div className="d-flex edit-session justify-content-center mb-6">
                    <Tooltip placement="topLeft" title={projectDetails.projectName}>
                      <p className="mb-0 name-max-width">{projectDetails.projectName}</p>
                    </Tooltip>
                  </div>
                </div>
              </div>
              {/* CRC Evans WBS Project */}
              <div className="col-content-inner">
                <div className="project-details-wrapper text-center">
                  <p className="fw-medium title">CRC Evans WBS Project</p>
                  <div className="d-flex edit-session justify-content-center mb-6">
                    <Tooltip placement="topLeft" title={projectDetails.crcEvansWbsProject}>
                      <p className="mb-0 name-max-width">{projectDetails.crcEvansWbsProject}</p>
                    </Tooltip>
                  </div>
                </div>
              </div>
              {/* Contractor */}
              <div className="col-content-inner">
                <div className="project-details-wrapper text-center">
                  <p className="fw-medium title">Contractor</p>
                  <div className="d-flex edit-session justify-content-center mb-6">
                    <Tooltip placement="topLeft" title={projectDetails.contractor}>
                      <p className="mb-0 name-max-width">{projectDetails.contractor}</p>
                    </Tooltip>
                  </div>
                </div>
              </div>
              {/* Design Standard */}
              <div className="col-content-inner">
                <div className="project-details-wrapper text-center">
                  <p className="fw-medium title">Design Standard</p>
                  <div className="d-flex edit-session justify-content-center mb-6">
                    <Tooltip placement="topLeft" title={projectDetails.designStandard}>
                      <p className="mb-0 name-max-width">{projectDetails.designStandard}</p>
                    </Tooltip>
                  </div>
                </div>
              </div>
              {/* Client Specification */}
              <div className="col-content-inner">
                <div className="project-details-wrapper text-center">
                  <p className="fw-medium title">Client Specification</p>
                  <div className="d-flex edit-session justify-content-center mb-6">
                    <Tooltip placement="topLeft" title={projectDetails.clientSpecification}>
                      <p className="mb-0 name-max-width">{projectDetails.clientSpecification}</p>
                    </Tooltip>
                  </div>
                </div>
              </div>
              {/* Client Shipping Address */}
              <div className="col-content-inner">
                <div className="project-details-wrapper text-center">
                  <p className="fw-medium title">Client Shipping Address</p>
                  <div className="d-flex edit-session justify-content-center mb-6">
                    <Tooltip placement="topLeft" title={projectDetails.clientShippingAddress}>
                      <p className="mb-0 name-max-width">{projectDetails.clientShippingAddress}</p>
                    </Tooltip>
                  </div>
                </div>
              </div>
              {/* Technician */}
              <div className="col-content-inner">
                <div className="project-details-wrapper text-center">
                  <p className="fw-medium title">Technician</p>
                  <div className="d-flex edit-session justify-content-center mb-6">
                    <Tooltip placement="topLeft" title={projectDetails.technician}>
                      <p className="mb-0 name-max-width">{projectDetails.technician}</p>
                    </Tooltip>
                  </div>
                </div>
              </div>
              {/* Project Weld Engineer */}
              <div className="col-content-inner">
                <div className="project-details-wrapper text-center">
                  <p className="fw-medium title">Project Weld Engineer</p>
                  <div className="d-flex edit-session justify-content-center mb-6">
                    <Tooltip placement="topLeft" title={projectDetails.projectWeldEngineer}>
                      <p className="mb-0 name-max-width">{projectDetails.projectWeldEngineer}</p>
                    </Tooltip>
                  </div>
                </div>
              </div>
              {/* CRC Evans WBS WPQ */}
              <div className="col-content-inner">
                <div className="project-details-wrapper text-center">
                  <p className="fw-medium title">CRC Evans WBS WPQ</p>
                  <div className="d-flex edit-session justify-content-center mb-6">
                    <Tooltip placement="topLeft" title={projectDetails.crcEvansWbsWpq}>
                      <p className="mb-0 name-max-width">{projectDetails.crcEvansWbsWpq}</p>
                    </Tooltip>
                  </div>
                </div>
              </div>
              {/* WPQ Location */}
              <div className="col-content-inner">
                <div className="project-details-wrapper text-center">
                  <p className="fw-medium title">WPQ Location</p>
                  <div className="d-flex edit-session justify-content-center mb-6">
                    <Tooltip placement="topLeft" title={projectDetails.wpqLocation}>
                      <p className="mb-0 name-max-width">{projectDetails.wpqLocation}</p>
                    </Tooltip>
                  </div>
                </div>
              </div>
              {/* WPQ Start Date */}
              <div className="col-content-inner">
                <div className="project-details-wrapper text-center">
                  <p className="fw-medium title">WPQ Start Date</p>
                  <div className="d-flex edit-session justify-content-center mb-6">
                    <Tooltip placement="topLeft" title={moment(projectDetails.wpqStartDate).format('DD-MMM-YYYY')}>
                      <p className="mb-0 name-max-width">
                        {moment(projectDetails.wpqStartDate).format('DD-MMM-YYYY')}
                      </p>
                    </Tooltip>
                  </div>
                </div>
              </div>
              {/* Number of IWM Heads */}
              <div className="col-content-inner">
                <div className="project-details-wrapper text-center">
                  <p className="fw-medium title">Number of IWM Heads</p>
                  <div className="d-flex edit-session justify-content-center mb-6">
                    <Tooltip placement="topLeft" title={projectDetails.numberOfIWMHeads}>
                      <p className="mb-0 name-max-width">{projectDetails.numberOfIWMHeads}</p>
                    </Tooltip>
                  </div>
                </div>
              </div>

              {/* P600 Torch Split Distance */}
              <div className="col-content-inner">
                <div className="project-details-wrapper text-center">
                  <p className="fw-medium title">P600 Torch Split Distance</p>
                  <div className="d-flex edit-session justify-content-center mb-6">
                    <Tooltip placement="topLeft" title={projectDetails.P600TorchSplitDistance}>
                      <p className="mb-0 name-max-width">{projectDetails.P600TorchSplitDistance}</p>
                    </Tooltip>
                  </div>
                </div>
              </div>

              {/* IWM Transfer Efficiency */}
              <div className="col-content-inner">
                <div className="project-details-wrapper text-center">
                  <p className="fw-medium title">IWM Transfer Efficiency</p>
                  <div className="d-flex edit-session justify-content-center mb-6">
                    <Tooltip placement="topLeft" title={projectDetails.IWMTransferEfficiency}>
                      <p className="mb-0 name-max-width">{projectDetails.IWMTransferEfficiency}</p>
                    </Tooltip>
                  </div>
                </div>
              </div>

              {/* Root Method */}
              <div className="col-content-inner">
                <div className="project-details-wrapper text-center">
                  <p className="fw-medium title">Root Method</p>
                  <div className="d-flex edit-session justify-content-center mb-6">
                    <Tooltip placement="topLeft" title={projectDetails.rootMethod}>
                      <p className="mb-0 name-max-width">{projectDetails.rootMethod}</p>
                    </Tooltip>
                  </div>
                </div>
              </div>

              {/* Compound Bevel */}
              <div className="col-content-inner">
                <div className="project-details-wrapper text-center">
                  <p className="fw-medium title">Compound Bevel</p>
                  <div className="d-flex edit-session justify-content-center mb-6">
                    <Tooltip placement="topLeft" title={projectDetails.compoundBevel}>
                      <p className="mb-0 name-max-width">{projectDetails.compoundBevel}</p>
                    </Tooltip>
                  </div>
                </div>
              </div>

              {/* Transfer Efficiency */}
              <div className="col-content-inner">
                <div className="project-details-wrapper text-center">
                  <p className="fw-medium title">Transfer Efficiency</p>
                  <div className="d-flex edit-session justify-content-center mb-6">
                    <Tooltip placement="topLeft" title={projectDetails.transferEfficiency}>
                      <p className="mb-0 name-max-width">{projectDetails.transferEfficiency}</p>
                    </Tooltip>
                  </div>
                </div>
              </div>

              {/* Arc Time Efficiency */}
              <div className="col-content-inner">
                <div className="project-details-wrapper text-center">
                  <p className="fw-medium title">Arc Time Efficiency</p>
                  <div className="d-flex edit-session justify-content-center mb-6">
                    <Tooltip placement="topLeft" title={projectDetails.arcTimeEfficiency}>
                      <p className="mb-0 name-max-width">{projectDetails.arcTimeEfficiency}</p>
                    </Tooltip>
                  </div>
                </div>
              </div>

            </div>
          </Panel>
        </Collapse>
        <div className="custom-line" />
        {/* // */}
        <Tabs
          className="custom-tab h-100"
          activeKey={activePipeIndex.toString()}
          onChange={handleMainTabChange}
        >
          {pipelines.map((pipeline, index) => (
            <TabPane tab={`PIPE ${index + 1}`} key={index.toString()}>
              <div
                className={isRead ? 'pipeline-modal2 report-checkbox2' : 'pipeline-modal report-checkbox'}
                style={{ paddingTop: '0px' }}
              >
                <Tabs
                  className="custom-tab h-100"
                  activeKey={activeSideBar}
                  tabPosition="left"
                  onChange={handleSideBarChange}
                >
                  <TabPane
                    tab="IWM"
                    key="1"
                    style={{ height: 'calc(100vh - 180px)', overflowY: 'auto', overflowX: 'hidden' }}
                  >
                    <div style={{ pointerEvents: isRead ? 'none' : 'auto' }}>
                      {renderPipelineContent(pipelines[activePipeIndex])}
                    </div>
                  </TabPane>
                  <TabPane
                    tab="FCAW-G"
                    key="2"
                    style={{ height: 'calc(100vh - 180px)', overflowY: 'auto', overflowX: 'hidden' }}
                  >
                    <div style={{ pointerEvents: isRead ? 'none' : 'auto' }}>
                      {FCWAgTab(fcawgDummyData[activePipeIndex])}
                    </div>
                  </TabPane>
                </Tabs>
              </div>
            </TabPane>
          ))}
        </Tabs>
      </div>

    </div>
  );
}

export default WeldingWireProjectDetails