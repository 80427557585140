// src/components/ProjectModal.js
import React, { useEffect, useState } from 'react';
import { Modal, Form, Input, Select, Checkbox, Button, Row, Col, Radio,Space,Typography,Divider } from 'antd';
import { climates, countries_List, diameters, externalEquipments, inspectionTypes, materials, positions, powerSupplies, rootBeadTypes, terrains, types, weldingProcesses } from './DatabseList';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
const { Option } = Select;

const MasterModal = (props) => {

    const {
        isMasterModalVisible,
     
        setIsMasterModalVisible
    } = props;
    const [form] = Form.useForm();
    const [formData, setFormData] = useState({});
    const [newItem, setNewItem] = useState([]);

    const handleOk = () => {
        form.validateFields().then((values) => {
            setFormData(values);
            setIsMasterModalVisible(false);
        });
    };
    useEffect(() => {
        console.log(formData)

    }, [formData])

    const handleCancel = () => {
        form.resetFields();
        setIsMasterModalVisible(false)
      
    };

    
    const setAddNewItem = (event) => {
        setNewItem(event.target.value);
    };

    const addItem = () => {
        if (newItem) {
            //list.push({ Category: newItem });
            setNewItem('');
        }
    };

    return (
        <Modal
            width={1100}
            visible={isMasterModalVisible}
            title="Add Item"
            className="trackrecord-modal"
            // onOk={handleOk}
            //onCancel={handleCancel}
            footer={[
                <Button key="back" onClick={handleCancel}>
                    Cancel
                </Button>,
                <Button key="submit" type="primary" onClick={handleOk}>
                    Submit
                </Button>,
            ]}
        >
            <Form form={form}
                name="project_form"
                layout="vertical"
                autoComplete="off"
                getcontainer={false}
            >
                <Row gutter={24} className="upload-row">

                    <Col lg={6}>
                        <Form.Item name="location" label="Location">

                            <Select className='maintenance-select' placeholder={'Select category'}
                                showSearch
                                allowClear
                                dropdownRender={menu => (
                                    <>
                                        {menu}
                                        <Divider style={{ margin: '8px 0', borderTop: '1px solid lightgrey' }} />
                                        <Space align="center" style={{ padding: '0 8px 4px' }}>
                                            <Input placeholder="Add Category" value={newItem} onChange={setAddNewItem} />

                                            <Typography.Link onClick={addItem} style={{ whiteSpace: 'nowrap', color: "green" }}>
                                                <CheckOutlined />
                                            </Typography.Link>
                                            <Typography.Link onClick={() => setNewItem('')} style={{ whiteSpace: 'nowrap', color: "red" }}>
                                                <CloseOutlined />
                                            </Typography.Link>
                                        </Space>
                                    </>
                                )}
                                >

                                {countries_List &&
                                    countries_List
                                        .sort((a, b) => a.country.localeCompare(b.country)) // Sort by category
                                        .map((item, i) => (
                                            <Option key={i} value={item.country}> {/* Use item.category as value */}
                                                {item.country}
                                            </Option>
                                        ))
                                }
                            </Select>
                        </Form.Item>
                    </Col>

                    <Col lg={6}>
                        <Form.Item name="material" label="Material">
                            <Select className='maintenance-select' placeholder={'Select category'}
                                showSearch
                                allowClear>

                                {materials &&
                                    materials
                                        .sort((a, b) => a.material.localeCompare(b.material)) // Sort by category
                                        .map((item, i) => (
                                            <Option key={i} value={item.material}> {/* Use item.category as value */}
                                                {item.material}
                                            </Option>
                                        ))
                                }
                            </Select>
                        </Form.Item>
                    </Col>

                    <Col lg={6}>
                        <Form.Item name="diameter" label="Diameter">
                            <Select className='maintenance-select' placeholder={'Select category'}
                                showSearch
                                allowClear>

                                {diameters &&
                                    diameters
                                        // .sort((a, b) => a.diameter.localeCompare(b.diameter)) // Sort by category
                                        .map((item, i) => (
                                            <Option key={i} value={item.diameter}> {/* Use item.category as value */}
                                                {item.diameter}
                                            </Option>
                                        ))
                                }
                            </Select>
                        </Form.Item>
                    </Col>



                    <Col lg={6}>
                        <Form.Item name="type" label="Type">
                            <Select className='maintenance-select' placeholder={'Select category'}
                                showSearch
                                allowClear>

                                {types &&
                                    types
                                        // .sort((a, b) => a.diameter.localeCompare(b.diameter)) // Sort by category
                                        .map((item, i) => (
                                            <Option key={i} value={item.type}> {/* Use item.category as value */}
                                                {item.type}
                                            </Option>
                                        ))
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col lg={6}>
                        <Form.Item name="position" label="Position">
                            <Select className='maintenance-select' placeholder={'Select category'}
                                showSearch
                                allowClear>

                                {positions &&
                                    positions
                                        // .sort((a, b) => a.diameter.localeCompare(b.diameter)) // Sort by category
                                        .map((item, i) => (
                                            <Option key={i} value={item.position}> {/* Use item.category as value */}
                                                {item.position}
                                            </Option>
                                        ))
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                    
                    
                    <Col lg={6}>
                        <Form.Item name="rootBeadType" label="Root Bead Type">
                            <Select className='maintenance-select' placeholder={'Select category'}
                                showSearch
                                allowClear>

                                {rootBeadTypes &&
                                    rootBeadTypes
                                        // .sort((a, b) => a.diameter.localeCompare(b.diameter)) // Sort by category
                                        .map((item, i) => (
                                            <Option key={i} value={item.root_bead_type}> {/* Use item.category as value */}
                                                {item.root_bead_type}
                                            </Option>
                                        ))
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col lg={6}>
                        <Form.Item name="externalEquip" label="External Equip">
                            <Select className='maintenance-select' placeholder={'Select category'}
                                showSearch
                                allowClear>

                                {externalEquipments &&
                                    externalEquipments
                                        // .sort((a, b) => a.diameter.localeCompare(b.diameter)) // Sort by category
                                        .map((item, i) => (
                                            <Option key={i} value={item.external_equipment}> {/* Use item.category as value */}
                                                {item.external_equipment}
                                            </Option>
                                        ))
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col lg={6}>
                        <Form.Item name="weldingProcess" label="Welding Process">
                            <Select className='maintenance-select' placeholder={'Select category'}
                                showSearch
                                allowClear>

                                {weldingProcesses &&
                                    weldingProcesses
                                        // .sort((a, b) => a.diameter.localeCompare(b.diameter)) // Sort by category
                                        .map((item, i) => (
                                            <Option key={i} value={item.welding_process}> {/* Use item.category as value */}
                                                {item.welding_process}
                                            </Option>
                                        ))
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col lg={6}>
                        <Form.Item name="powerSupply" label="Power Supply">
                            <Select className='maintenance-select' placeholder={'Select category'}
                                showSearch
                                allowClear>

                                {powerSupplies &&
                                    powerSupplies
                                        // .sort((a, b) => a.diameter.localeCompare(b.diameter)) // Sort by category
                                        .map((item, i) => (
                                            <Option key={i} value={item.power_supply}> {/* Use item.category as value */}
                                                {item.power_supply}
                                            </Option>
                                        ))
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                    
                    <Col lg={6}>
                        <Form.Item name="weldingWireType" label="Welding Wire Type">
                            <Select className='maintenance-select' placeholder={'Select category'}
                                showSearch
                                allowClear>

                                {powerSupplies &&
                                    powerSupplies
                                        // .sort((a, b) => a.diameter.localeCompare(b.diameter)) // Sort by category
                                        .map((item, i) => (
                                            <Option key={i} value={item.power_supply}> {/* Use item.category as value */}
                                                {item.power_supply}
                                            </Option>
                                        ))
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col lg={6}>
                        <Form.Item name="terrain" label="Terrain">
                            <Select className='maintenance-select' placeholder={'Select category'}
                                showSearch
                                allowClear>

                                {terrains &&
                                    terrains
                                        // .sort((a, b) => a.diameter.localeCompare(b.diameter)) // Sort by category
                                        .map((item, i) => (
                                            <Option key={i} value={item.terrain}> {/* Use item.category as value */}
                                                {item.terrain}
                                            </Option>
                                        ))
                                }
                            </Select>

                        </Form.Item>
                    </Col>
                    <Col lg={6}>
                        <Form.Item name="climate" label="Climate">
                            <Select className='maintenance-select' placeholder={'Select category'}
                                showSearch
                                allowClear>

                                {climates &&
                                    climates
                                        // .sort((a, b) => a.diameter.localeCompare(b.diameter)) // Sort by category
                                        .map((item, i) => (
                                            <Option key={i} value={item.climate}> {/* Use item.category as value */}
                                                {item.climate}
                                            </Option>
                                        ))
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                   
                    <Col lg={6}>
                        <Form.Item name="inspectionType" label="Inspection Type">
                            <Select className='maintenance-select' placeholder={'Select category'}
                                showSearch
                                allowClear>

                                {inspectionTypes &&
                                    inspectionTypes
                                        // .sort((a, b) => a.diameter.localeCompare(b.diameter)) // Sort by category
                                        .map((item, i) => (
                                            <Option key={i} value={item.inspection_type}> {/* Use item.category as value */}
                                                {item.inspection_type}
                                            </Option>
                                        ))
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                  
                </Row>
            </Form>
        </Modal>
    );
};

export default MasterModal;
