import {
  put, call, all, takeLatest,
} from 'redux-saga/effects';
import { notification } from 'antd';
import get from 'lodash/get'
import axiosConfig from '../../services/licenseManagerConfig';

import { fetchLicenseListSuccess, fetchLicenseListFailure, generatelicenseSuccess, generateLicenseFailure, shareLicenseInfoSuccess, shareLicenseInfoFailure, sharefetchLicenseListSuccess, sharefetchLicenseListFailure, fetchGroupLicenseListFailure, fetchGroupLicenseListSuccess, fetchUniqueIdFailure, fetchUniqueIdSuccess } from "./action"

import { GENERATELICENSE, FETCH_LICENSE_LIST, SHARELICENSEINFO, SHARE_FETCH_LICENSE_LIST, FETCH_GROUP_LICENSE_LIST, FETCH_UNIQUE_ID } from './types';
import NotificationEmailsConfig from '../../services/NotificationEmailsAxiosConfig';

function* generatelicense({ data }) {
  const user = JSON.parse(localStorage.getItem("user"));
  try {
    if (user) {
      yield UpdateStatus({
        "SuccessMsg": (user ? 'Generated user: ' + user.name : '')

      });
      data['createdBy'] = user.email
      yield UpdateStatus(data);
    }
    const licenseurl = process.env.REACT_APP_GENERATELICENSE_URL;//"https://pjt636fz4a.execute-api.us-west-2.amazonaws.com/dev/"

    const res = yield fetch(`${licenseurl}`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-type': 'application/json',
        // Authorization: token
      },
      body: JSON.stringify(data),
    });

    notification.destroy();
    //const response = yield res.json();
    // const liceData = {
    //   LicenseData:data
    // }

    //console.log(res.body)
    if (res.status === 200) {
      const responseData = yield res.json(); // Get the JSON response data

      let allValid = true;

      responseData.forEach(item => {
        if (!item.isValidLicense) {
          allValid = false;
          // Notify about the specific invalid license
          notification.error({
            message: `License Generation Failed for ${item.featureType}`,
            description: 'The license is not valid. Please contact the digital.solutions@crce.com.',
            duration: 3,
          });
        }
      });

      if (allValid) {
        // Notify success if all licenses are valid
        notification.success({
          message: 'Licenses Generated Successfully.',
          duration: 3,
        });
      } else {
        // Notify that some licenses were successfully generated
        if (responseData.length > 1)
          notification.error({
            message: 'Some licenses were generated successfully, but others failed.',
            duration: 3,
          });
        else if (responseData.length > 0) {
          notification.error({
            message: ' Licenses  generated Faild.',
            duration: 3,
          });
          yield UpdateStatus({
            "SuccessMsg": `License Generation Failed for ${responseData[0].featureType}`
          });
          yield put(generateLicenseFailure())
          return
        }
      }


      yield UpdateStatus({
        "SuccessMsg": 'License Generated Successfully '

      });

      if (res.status === 200) {
        //const responseData = get(res, ['data', 'data'], {});
        // yield put(fetchLicenseListSuccess({ responseData }));

        //console.log(data,user)
        yield put(generatelicenseSuccess(res.body))
         const stage = process.env.REACT_APP_STAGE
        let componentName = ''
        if (stage == 'prod') {
          componentName = 'License'
        }
        {
          const emailData = {
            TitleContent: "License file generation notification",
            Subject: "New license generated by " + user.name,
            Component: componentName,
            data: {
              "User Identifier": data.fileName,
              "Unique ID": data.uniqueId,
              "Customer Name": data.customerName,
              "Location": data.location,
              "Contract": data.Contractor,
              "Machine Type": data.typeOfDevice,
              "Board Type": data.boardType,
              "License Type": data.featureType
            }
          }
          yield NotificationEmailsConfig.post(`NotifyEmails`, emailData);
        }
      }
      yield fetchGroupLicenseList();
    }


    else {
      yield put(generateLicenseFailure())
      notification.error({
        message: 'Something went wrong.',
      });
      yield UpdateStatus({
        "SuccessMsg": 'License Generation failed '
      });

    }
  } catch (error) {
    notification.destroy();
    notification.error({
      message: 'License Creation Submission Failed.',
      description: `${error}`,
    });
    yield UpdateStatus({
      "SuccessMsg": 'License Generation failed '

    });
  }
}
function* fetchLicenseList(payload = {}) {
  //console.log(payload)
  try {
    let url;
    if (payload && payload.pageNumber && payload.pageSize && payload.searchKey) {
      url = `/grouplicenseList?pageNumber=${payload.pageNumber}&pageSize=${payload.pageSize}&searchKey=${payload.searchKey}`;
    }
    else if (payload && payload.pageNumber && payload.pageSize) {
      url = `/grouplicenseList?pageNumber=${payload.pageNumber}&pageSize=${payload.pageSize}`
    }
    else if (payload.searchKey) {
      url = `/grouplicenseList?searchKey=${payload.searchKey}`;
    }
    else {
      url = `/grouplicenseList`
    }
    if (url) {
      // console.log(url)
      const res = yield axiosConfig.get(url);
      //console.log(responseData)
      if (res.status === 200) {
        const responseData = get(res, ['data', 'data'], {});
        yield put(fetchLicenseListSuccess({ responseData }));

      }
    }
    else {
      notification.error({
        message: 'Something went wrong.',
      });
    }

  } catch (error) {
    yield put(fetchLicenseListFailure());
    notification.destroy();
    notification.error({
      message: 'A problem occured while fetching the license list.',
      description: `${error.error}`,
    });
    yield UpdateStatus({
      "SuccessMsg": 'A problem occured while fetching the license list. ' + error.error

    });
  }
}
function* sharefetchLicenseList(payload = {}) {
  //console.log(payload)
  try {
    let url;
    if (payload && payload.pageNumber && payload.pageSize && payload.searchKey) {
      url = `/licenseList?pageNumber=${payload.pageNumber}&pageSize=${payload.pageSize}&searchKey=${payload.searchKey}`;
    }
    else if (payload && payload.pageNumber && payload.pageSize) {
      url = `/licenseList?pageNumber=${payload.pageNumber}&pageSize=${payload.pageSize}`
    }
    else if (payload.searchKey) {
      url = `/licenseList?searchKey=${payload.searchKey}`;
    }
    else {
      url = `/licenseList`
    }
    if (url) {
      //console.log(url)
      const res = yield axiosConfig.get(url);
      //console.log(responseData)
      if (res.status === 200) {
        const responseData = get(res, ['data', 'data'], {});
        yield put(sharefetchLicenseListSuccess({ responseData }));

      }
    }
    else {
      notification.error({
        message: 'Something went wrong.',
      });
    }

  } catch (error) {
    yield put(sharefetchLicenseListFailure());
    notification.destroy();
    notification.error({
      message: 'A problem occured while fetch sharing the license list.',
      description: `${error.error}`,
    });
    yield UpdateStatus({
      "SuccessMsg": 'A problem occured while fetch sharing the license list. ' + error.error

    });
  }
}
function* shareLicenseListInfo(payload = {}) {
  //console.log(payload)
  const user = JSON.parse(localStorage.getItem("user"));
  if (user) {
    yield UpdateStatus({
      "SuccessMsg": (user ? 'sharing user: ' + user.name : '')
    });

  }
  try {
    const licenseurl = process.env.REACT_APP_LICENSEMANAGER_ADMIN_URL;

    let url;
    if (payload && payload.emailAddress && payload.selectedRow) {

      //console.log(payload.selectedRow);
      url = `${licenseurl}shareLicense?emailToDestinations=${payload.emailAddress}&selectedRow=${payload.selectedRow}`

      const res = yield fetch(`${url}`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-type': 'application/json',
          // Authorization: token
        },
        body: JSON.stringify(payload.selectedRow),
      });

      notification.destroy();

      if (res.status === 200) {
        //const response = yield res.json();
        notification.success({
          message: 'Licenses Shared Successfully.',
          duration: 3,
        });
        yield UpdateStatus({
          "SuccessMsg": 'Licenses Shared Successfully :'
        });
        yield put(shareLicenseInfoSuccess())
      }
    }

    else {
      notification.error({
        message: 'Something went wrong.',
      });
      yield put(shareLicenseInfoFailure())
      yield UpdateStatus({
        "SuccessMsg": 'Share License Info Submission Failed:'

      });
    }
  } catch (error) {
    notification.destroy();
    notification.error({
      message: 'Share License Info Submission Failed.',
      description: `${error}`,
    });
    yield UpdateStatus({
      "SuccessMsg": 'Share License Info Submission Failed:' + error

    });
  }
}

function* fetchGroupLicenseList(payload = {}) {
  //console.log("payload called")
  try {

    let url;
    if (payload && payload.pageNumber && payload.pageSize && payload.searchKey) {
      url = `/grouplicenseList?pageNumber=${payload.pageNumber}&pageSize=${payload.pageSize}&searchKey=${payload.searchKey}`;
    }
    else if (payload && payload.pageNumber && payload.pageSize) {
      url = `/grouplicenseList?pageNumber=${payload.pageNumber}&pageSize=${payload.pageSize}`
    }
    else if (payload.searchKey) {
      url = `/grouplicenseList?searchKey=${payload.searchKey}`;
    }
    else {
      url = `/grouplicenseList`
    }
    if (url) {
      //console.log(url)
      const res = yield axiosConfig.get(url);
      //console.log(responseData)
      if (res.status === 200) {
        const responseData = get(res, ['data', 'data'], {});
        yield put(fetchGroupLicenseListSuccess({ responseData }));

      }
    }
    else {
      notification.error({
        message: 'Something went wrong.',
      });
    }

  } catch (error) {
    yield put(fetchGroupLicenseListFailure());
    notification.destroy();
    notification.error({
      message: 'A problem occured while fetching the group license list.',
      description: `${error.error}`,
    });
    yield UpdateStatus({
      "SuccessMsg": 'A problem occured while fetching the group license list. ' + error.error

    });
  }
}


function* fetchUniqueId(payload = {}) {

  try {

    if (payload.SuccessMsg) {

      // let url = `/GetLicenseData?SuccessMsg=${payload.SuccessMsg}`;
      // const res = yield axiosConfig.post(url);
      // //console.log(responseData)
      // if (res.status === 200) {

      // }
      return
    }

    const res = yield axiosConfig.post(`GetLicenseData`, payload);
    //const response = get(res, ['data', 'data'], {});



    if (res.status === 200) {
      const responseData = get(res, ['data', 'data'], {});
      yield put(fetchUniqueIdSuccess({ responseData }));

    }

    else {
      notification.error({
        message: 'Something went wrong.',
      });
    }

  } catch (error) {
    yield put(fetchUniqueIdFailure());
    notification.destroy();
    notification.error({
      message: 'A problem occured while fetching the License byte array.',
      description: `${error.error}`,
    });
    yield UpdateStatus({
      "SuccessMsg": 'A problem occured while fetching the License byte array ' + error.error

    });
  }
}
function* licenseWatcher() {
  yield takeLatest(GENERATELICENSE, generatelicense);
  yield takeLatest(FETCH_LICENSE_LIST, fetchLicenseList);
  yield takeLatest(SHARELICENSEINFO, shareLicenseListInfo);
  yield takeLatest(SHARE_FETCH_LICENSE_LIST, sharefetchLicenseList);
  yield takeLatest(FETCH_GROUP_LICENSE_LIST, fetchGroupLicenseList);
  yield takeLatest(FETCH_UNIQUE_ID, fetchUniqueId);

}

function* licenseSagas() {
  yield all([
    call(licenseWatcher),
  ]);
}

export default licenseSagas;

export const UpdateStatus = async (payload) => {

  try {
    const res = await axiosConfig.post(`UpdateStatus`, payload);
    // if (payload.SuccessMsg) {

    //   let url = `/GetLicenseData?SuccessMsg=${payload.SuccessMsg}`;
    //   const res = yield axiosConfig.post(url);
    //   //console.log(responseData)
    //   if (res.status === 200) {

    //   }
    //   return
    // }
  }
  catch (error) {

  }
}


export const getCategoryListFromDatabse = async () => {
  try {
    let url;


    url = `/GetCategoryTypes`;

    const res = await axiosConfig.get(url);
    if (res.status === 403) {
      notification.error({
        message: 'Unauthorized',
      });
      return null;
    } if (res.status === 200) {
      const data = get(res, ['data', 'data'], {});
      //console.log('testType', data)
      return data;
    }
    return null;
  } catch (error) {
    return null;
  }
}


export const GetUniqueId = async (payload = {}) => {

  try {


    const res = await axiosConfig.post(`GetLicenseData`, payload);
    //const response = get(res, ['data', 'data'], {});

    if (res.status === 200) {
      const responseData = get(res, ['data', 'data'], {});
      return responseData

    }

    else {

      notification.error({
        message: 'Something went wrong.',
      });
      return null
    }

  } catch (error) {
    notification.destroy();
    notification.error({
      message: 'A problem occured while fetching the License byte array.',
      description: `${error.error}`,
    });
    return null
  }
}