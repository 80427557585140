// import React from 'react';
// import { Form, Input, Button, Row, Col, Layout, Table } from 'antd';
// import { Content, Footer } from 'antd/lib/layout/layout';

// const EquipmentForm = (props) => {
//     const {
//         calculatedWeldPrice,
//         userWeldPrice,
//         setUserWeldPrice,
//     } = props;

//     const handleInputChange = (name, value) => {
//         value = Number(value);
//         setUserWeldPrice(prevData => ({ ...prevData, [name]: value }));
//     };

//     const dataList = [
//         { label: 'Rent', key: 'Rent $' },
//         { label: 'Mob', key: 'Mob $' },
//         { label: 'Spares', key: 'Spares $' },
//         { label: 'Wire', key: 'Wire $' },
//         { label: 'Total', key: 'Total $' },
//     ];

//     const columns = [
//         {
//             title: '',
//             dataIndex: 'label',
//             key: 'label',
//             render: text => <strong>{text}</strong>,
//         },
//         {
//             title: 'Calculated Values ($)',
//             dataIndex: 'calculatedValue',
//             key: 'calculatedValue',
//             render: (text, record) => (
//                 <Input
//                     value={text}
//                     disabled
//                     style={{ textAlign: 'center', whiteSpace: 'nowrap' }}
//                 />
//             ),
//         },
//         {
//             title: 'Manual Input ($)',
//             dataIndex: 'manualInput',
//             key: 'manualInput',
//             render: (text, record) => (
//                 <Input
//                     type="number"
//                     value={text}
//                     onChange={e => handleInputChange(record.label, e.target.value)}
//                     style={{ textAlign: 'center', whiteSpace: 'nowrap', width: '100%' }}
//                 />
//             ),
//         },
//     ];

//     const dataSource = dataList.map((item) => ({
//         key: item.key,
//         label: item.label,
//         calculatedValue: parseFloat(calculatedWeldPrice[item.label] ?? '0').toFixed(2),
//         manualInput: parseFloat(userWeldPrice[item.label] ?? '0').toFixed(2),
//     }));

//     return (
//         <Layout style={{ width: '100%' }}>
//             <Content>
//                 <Form name="dataListForm" autoComplete="off" layout="vertical" className='equipment-calculation'>
//                     <Table
//                     className='pricing-equipment-table pricing-calculation-table'
//                         dataSource={dataSource}
//                         columns={columns}
//                         pagination={false}
//                         bordered
//                     />
//                 </Form>
//             </Content>
//         </Layout>
//     );
// };

// export default EquipmentForm;

import React, { useEffect } from 'react';
import { Form, Input, Layout, Table } from 'antd';
import { Content } from 'antd/lib/layout/layout';

const EquipmentForm = (props) => {
    const { calculatedWeldPrice, userWeldPrice, setUserWeldPrice, setIsManualInputfeildChanged } = props;

    const handleInputChange = (name, value) => {
        setIsManualInputfeildChanged(true)
        const numValue = value === '' ? 0 : Number(value);

        setUserWeldPrice(prevData => ({ ...prevData, [name]: numValue }));
    };
    useEffect(() => {
        const numValue = userWeldPrice['Rent'] + userWeldPrice['Mob'] + userWeldPrice['Spares'] + userWeldPrice['Wire']
        setUserWeldPrice(prevData => ({ ...prevData, ['Total']: numValue }));
    }, [userWeldPrice])

    const roundToTwoDecimalPlaces = (num) => Math.round(num * 100) / 100;
    const formatCurrency = (value) => {
        if (value == '')
            return '-'
        return value ? `$${parseFloat(value).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}` : '';
    };
    const dataSource = [
        {
            key: 'calculatedValue',
            label: 'Calculated Values ',
            Rent: roundToTwoDecimalPlaces(parseFloat(calculatedWeldPrice['Rent'] ?? 0)),
            Mob: roundToTwoDecimalPlaces(parseFloat(calculatedWeldPrice['Mob'] ?? 0)),
            Spares: roundToTwoDecimalPlaces(parseFloat(calculatedWeldPrice['Spares'] ?? 0)),
            Wire: roundToTwoDecimalPlaces(parseFloat(calculatedWeldPrice['Wire'] ?? 0)),
            Total: roundToTwoDecimalPlaces(parseFloat(calculatedWeldPrice['Total'] ?? 0)),
        },
        {
            key: 'manualInput',
            label: 'Manual Input',
            Rent: roundToTwoDecimalPlaces(parseFloat(userWeldPrice['Rent'] ?? 0)),
            Mob: roundToTwoDecimalPlaces(parseFloat(userWeldPrice['Mob'] ?? 0)),
            Spares: roundToTwoDecimalPlaces(parseFloat(userWeldPrice['Spares'] ?? 0)),
            Wire: roundToTwoDecimalPlaces(parseFloat(userWeldPrice['Wire'] ?? 0)),
            Total: roundToTwoDecimalPlaces(parseFloat(userWeldPrice['Total'] ?? 0)),
        },
    ];

    const columnsList = [
        {
            title: '',
            dataIndex: 'label',
            key: 'label',
            align: 'right',
            width: '15%',
            render: text => <strong >{text}</strong>,
        },
        {
            title: 'Rent',
            dataIndex: 'Rent',
            key: 'Rent',
            align: 'center',
            render: (text, record) => (
                record.key === 'manualInput' ? (
                    // <Input
                    //     type="number"
                    //     value={text}
                    //     onChange={e => handleInputChange('Rent', e.target.value)}
                    //     style={{ textAlign: 'center', width: '60%' }}
                    // />
                    <Input addonBefore={<div style={{ width: '100%', textAlign: 'center' }}>$</div>}
                    className='Data360-addbefore'
                    value={text}
                    onChange={e => handleInputChange('Rent', e.target.value)}
                    min={0}
                    type='number'
                    style={{ width: '70%', textAlign: 'left',  fontSize:'18px'}} 
                />

                ) : (
                    <div style={{ fontSize: '15px' }}>{formatCurrency(text)}</div>
                )
            ),
        },
        {
            title: 'Mob',
            dataIndex: 'Mob',
            key: 'Mob',
            align: 'center',
            render: (text, record) => (
                record.key === 'manualInput' ? (
                    // <Input
                    //     type="number"
                    //     value={text}
                    //     onChange={e => handleInputChange('Mob', e.target.value)}
                    //     style={{ textAlign: 'center',width:'60%' }}
                    // />
                    <Input addonBefore={<div style={{ width: '100%', textAlign: 'center' }}>$</div>}
                        className='Data360-addbefore'
                        value={text}
                        onChange={e => handleInputChange('Mob', e.target.value)}
                        min={0}
                        type='number'
                        style={{ width: '70%', textAlign: 'left',  fontSize:'18px'}} 
                    />

                ) : (
                    <div style={{ fontSize: '15px' }}>{formatCurrency(text)}</div>
                )
            ),
        },
        {
            title: 'Spares',
            dataIndex: 'Spares',
            key: 'Spares',
            align: 'center',
            render: (text, record) => (
                record.key === 'manualInput' ? (
                    // <Input
                    //     type="number"
                    //     value={text}
                    //     onChange={e => handleInputChange('Spares', e.target.value)}
                    //     style={{ textAlign: 'center', width: '60%' }}
                    // />
                    <Input addonBefore={<div style={{ width: '100%', textAlign: 'center' }}>$</div>}
                    className='Data360-addbefore'
                    value={text}
                    onChange={e => handleInputChange('Spares', e.target.value)}
                    type='number'
                    style={{ width: '70%', textAlign: 'left',  fontSize:'18px'}} 
                />
                ) : (
                    <div style={{ fontSize: '15px' }}>{formatCurrency(text)}</div>
                )
            ),
        },
        {
            title: 'Wire',
            dataIndex: 'Wire',
            key: 'Wire',
            align: 'center',
            render: (text, record) => (
                record.key === 'manualInput' ? (
                    // <Input
                    //     type="number"
                    //     value={text}
                    //     onChange={e => handleInputChange('Wire', e.target.value)}
                    //     style={{ textAlign: 'center', width: '60%' }}
                    // />
                    <Input addonBefore={<div style={{ width: '100%', textAlign: 'center' }}>$</div>}
                    className='Data360-addbefore'
                    value={text}
                    onChange={e => handleInputChange('Wire', e.target.value)}
                    type='number'
                    style={{ width: '60%', textAlign: 'left',  fontSize:'18px'}} 
                    />
                    
                ) : (
                    <div style={{ fontSize: '15px', paddingTop:'5px' }}>{formatCurrency(text)}</div>
                )
            ),
        },
        {
            title: 'Total',
            dataIndex: 'Total',
            key: 'Total',
            // align:'center',
            // render: (text, record) => (
            //    // record.key === 'manualInput' ? 
            //     // (
            //     //     <Input
            //     //         type="number"
            //     //         value={text}
            //     //         onChange={e => handleInputChange('Total', e.target.value)}
            //     //         style={{ textAlign: 'center' }}
            //     //     />
            //     // ) : (
            //         <Input   type="number" value={text} disabled style={{ textAlign: 'center' }} />


            // ),
            render: (text, record) => {
                return <div style={{ fontSize: '15px' }}>{formatCurrency(text)}</div>
            },
        },
    ];

    return (

        <Form name="dataListForm" autoComplete="off" layout="vertical" >
            <Table style={{ paddingTop: '0px', borderColor: 'transparent', marginLeft: '10%', paddingBottom: '0px', width: '80%' }}
                className='pricing-equipment-table pricing-calculation-table'
                dataSource={dataSource}
                columns={columnsList}
                pagination={false}
            // bordered
            />
        </Form>

    );
};

export default EquipmentForm;

