/* eslint-disable no-param-reassign */
/* eslint-disable react/no-unescaped-entities */
import { ArrowLeftOutlined, CloudUploadOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import {
    Button, Form,
    Input,
    Modal,
    Space,
    Table,
    Tabs,
    Tooltip,
    Typography,
    Upload, notification, Row, Col
} from 'antd';

import { get } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import * as XLSX from 'xlsx';
import ExcelJS from 'exceljs';
import history from '../../services/history.js';
import { AddOrUpdateMasterData, DeleteMasterDetails, getMasterListFromDatabase } from '../../redux/Trackrecord/saga.js';


const { TabPane } = Tabs;
const { Title, Text } = Typography;
//const { TabPane } = Tabs;
//const { Column } = Table;
//const { Search } = Input;

//const { Option } = Select;
const MasteForm = (props) => {

    const {
        setIsMasterModalVisible,
        isMasterModalVisible,
        selectedTap
    } = props;

    const dataSource = [
        {
            key: '1',
            partNumber: 'C235418',
            description: 'HOLDER,TOOL PIGGY-BACK   5 DEG, 110 OFFS',
            quantity_5000_Welds: 1,
            category: 'BEVELLING',
            multiple_item: 'Yes',
            cost: '23',
            where_Used: 'BD4 BEV-CBUC-HW',
            UOM: 'EA',
            alternate_Parts: 'F2M0044-R',
            // Add other properties as needed
        },
    ]
    const [UpdateModalForm] = Form.useForm()

    const [searchText, setSearchText] = useState({});
    const [searchedColumn, setSearchedColumn] = useState('');

    const [currentPageNumber, setCurrentPageNumber] = useState(1);
    const [currentPagesize, setCurrentPagesize] = useState(10);

    const [masterUploadForm] = Form.useForm()
    const [addNewForm] = Form.useForm()
    const [disableFileUpload, setDisableFileUpload] = useState(false);
    const [fileList, setFileList] = useState([]);
    const [bulkUplaodModal, setBulkUplaodModal] = useState(false);
    const [databaseList, setDatabaseList] = useState([]);
    const [showAddNewModal, setShowAddNewModal] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [editRecord, seteditRecord] = useState('');

    const [confirmLoading, setConfirmLoading] = useState(false);
    const [matrialClassTypes, setMatrialClassTypes] = useState([]);
    const [selectedMatrialClass, setSelectedMatrialClass] = useState('');

    const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
    const [selectedData, setSelectedData] = useState('');
    const [isDeleteLoading, setIsDeleteLoading] = useState(false)
    const [activeKey, setActiveKey] = useState('1')
    const [activeKeyLine, setActiveKeyLine] = useState('1')
    const [searchCriteria, setSearchCriteria] = useState({});
    const [downloadLoading, setDownloadLoading] = useState(false);
    const [uploadLoading, setUploadLoading] = useState(false);

    const [addLoding, setAddLaoding] = useState(false)
    const [loading, setloading] = useState(false);
    const [edittModalVisible, setEdittModalVisible] = useState(false)
    const [isUpdateLoading, setUpdateLoading] = useState(false)
    const [addItem, setAddItem] = useState('');
    const generateColumns = (title, dataIndex) => {
        return [
            {
                title: title,
                dataIndex: dataIndex,
                key: dataIndex,
                align: 'left',
                //sorter: (a, b) => (a.dataIndex.localeCompare(b.dataIndex))
            },
            {
                title: 'Actions',
                key: 'action',
                dataIndex: 'action',
                render: (text, record) => {
                    return (
                        <div>
                            <Tooltip title="Update">
                                <span className="icon-18 mr-2 cursor-pointer edit-hover">
                                    <EditOutlined style={{ fontSize: '18px' }}
                                        onClick={() => onUpdateClick(record)}
                                    />
                                </span>
                            </Tooltip>
                            <Tooltip title="Delete">
                                <span className="icon-18 mr-2 cursor-pointer edit-hover" style={{ marginLeft: '20px' }}>
                                    <DeleteOutlined style={{ fontSize: '18px' }}
                                        onClick={() => showDeleteModal(record)}
                                    />
                                </span>
                            </Tooltip>
                        </div>
                    );
                },
                align: 'center'
            }
        ];
    };

    const [data_List, setData_List] = useState({});


    const tableNames = {
        countries_List: 'Country',
        diameters: 'Diameter',
        saleContracts: 'Sale Contract',
        projecttypes: 'Project type',
        rootBeadTypes: 'Root Bead Type',
        externalEquipments: 'External Equipment',
        weldingProcesses: 'Welding Process',
        powerSupplies: 'Power Supply',
        wireTypes: 'Wire Type',
        terrains: 'Terrain',
        climates: 'Climate',
        positions: 'Position',
        inspectionTypes: 'Inspection Type',
        materials: 'Material'
    };

    const coulumNames = {
        countries_List: 'country',
        diameters: 'diameter',
        saleContracts: 'sale_contract',
        projecttypes: 'type',
        rootBeadTypes: 'root_bead_type',
        externalEquipments: 'external_equipment',
        weldingProcesses: 'welding_process',
        powerSupplies: 'power_supply',
        wireTypes: 'wireType',
        terrains: 'terrain',
        climates: 'climate',
        positions: 'position',
        inspectionTypes: 'inspection_type',
        materials: 'material'
    };

    useEffect(() => {
        (async () => {
            if (data_List)
                setloading(true)
            const response = await getMasterListFromDatabase();
            if (response) {
                setData_List(response);
                console.log('Record Item', response);

                // Set the initial selected material class
                const initialClass = Object.keys(tableNames)[0];
                setSelectedMatrialClass(initialClass);
                setActiveKeyLine(initialClass);
                setloading(false)
            }
        })();
    }, []);

    const handleTabChange = async (key) => {
        setActiveKeyLine(key);
        setSelectedMatrialClass(key);
        console.log(key)
    };

    const handleUpdateOk = async () => {

        UpdateModalForm
            .validateFields()
            .then(async (data) => {
                if (editRecord) {
                    setUpdateLoading(true)
                    const obj = {
                        columnName: coulumNames[activeKeyLine],
                        tableName: activeKeyLine,
                        data: {
                            [coulumNames[activeKeyLine]]: data[coulumNames[activeKeyLine]],
                            ID: editRecord.ID
                        }
                    };
                    console.log(obj);
                    // Uncomment and implement this function
                    await AddOrUpdateMasterData(obj)
                    setUpdateLoading(false)
                    setEdittModalVisible(false)
                    setloading(true)
                    await UpdateMasterList();
                    setloading(false)

                }
            });


    };

    const onUpdateClick = (record) => {

        if (record) {
            //UpdateModalForm.resetFields()

            UpdateModalForm.setFieldsValue(record)
            setAddItem(record[activeKeyLine])
            console.log(record)
            seteditRecord(record)
            setEdittModalVisible(true)
        }

    }

    const handleCancel = async () => {


        setEdittModalVisible(false)
        UpdateModalForm.resetFields()
    };

    const handleAddCancel = async () => {


        setShowAddNewModal(false)
        UpdateModalForm.resetFields()
    };
    const getPipelineTabpanes = () => {


        return (
            <div className="custom-tab h-100" style={{ paddingTop: '10px' }}>
                <Tabs activeKey={selectedMatrialClass} onChange={handleTabChange} defaultActiveKey={Object.keys(tableNames)[0]}>
                    {Object.keys(tableNames).map((row) => (
                        <TabPane tab={tableNames[row]} key={row}>
                            <Row gutter={24}>
                                <Col span={24}>
                                    <div className='addTeamDetails'>
                                        <Table
                                            className="h-100 pipeline-table equipPaginationTable"
                                            scroll={{ y: 'calc(100vh - 290px)' }}
                                            pagination={false}
                                            dataSource={data_List[row] || []}
                                            columns={generateColumns(tableNames[row], coulumNames[row])}
                                            loading={loading}
                                        />
                                    </div>


                                </Col>
                            </Row>
                        </TabPane>
                    ))}
                </Tabs>
            </div>
        );
    };
    const handleCreateNew = () => {
        addNewForm
            .validateFields()
            .then(async (data) => {


                if (addItem == '') {
                    notification.warning({
                        message: 'Enter the category class to proceed!'
                    });
                    return
                }
                console.log(addItem)
                setAddLaoding(true)
                const obj = {
                    columnName: coulumNames[activeKeyLine],
                    tableName: activeKeyLine,
                    data: {
                        [coulumNames[activeKeyLine]]: addItem
                    }
                };
                console.log(obj);
                // Uncomment and implement this function
                await AddOrUpdateMasterData(obj)
                setAddLaoding(false)
                setShowAddNewModal(false);
                setloading(true)
                await UpdateMasterList();
                setloading(false)
            })
            .catch(errorInfo => {
                console.error('Validation failed:', errorInfo);
            });
    };

    /** Display Confirmation while deleting */
    const showDeleteModal = (record) => {
        setIsDeleteModalVisible(true);
        setSelectedData(record)
    };
    /** Deleting Spare parts data on confirm */
    const handleDeleteOk = async () => {
        setIsDeleteLoading(true)
        const obj = {
            columnName: coulumNames[activeKeyLine],
            tableName: activeKeyLine,
            data: selectedData
        };
        console.log(obj);
        await DeleteMasterDetails(obj)
        setIsDeleteLoading(false)
        setIsDeleteModalVisible(false);

        setSelectedData('');
        setloading(true)
        await UpdateMasterList();
        setloading(false)




    }

    const UpdateMasterList = async () => {
        const response = await getMasterListFromDatabase();
        if (response) {
            setData_List(response);
            console.log('Record Item', response);

            // Set the initial selected material class
            //const initialClass = Object.keys(tableNames)[0];
            setSelectedMatrialClass(activeKeyLine);
            setActiveKeyLine(activeKeyLine);
        }
    }
    /** Cancelling the deletion of spare parts data */
    const handleDeleteCancel = () => {
        setIsDeleteModalVisible(false);
    }

    return (

        <div>
            <div className="d-flex mb-3 align-items-center">
                <div className='back_equip' >
                    <Tooltip title={'Back'} >
                        <ArrowLeftOutlined onClick={() => {

                            setIsMasterModalVisible(false)
                        }} />
                    </Tooltip>
                </div>

                <Title className="mb-0 add-new-calibration" level={3} style={{ paddingLeft: '25px', paddingTop: '5px' }}>
                   {selectedTap} Confguration
                </Title>

                <div className="new-calibration">

                    {/* <Button shape="round" key="apply" type="primary" onClick={handleDownloadTemplate} loading={downloadLoading}>
                        Download Master List
                    </Button> */}

                    <Text onClick={() => setShowAddNewModal(true)} className="ps-3 add-new-calibration" >
                        <span className="icon-22" style={{ scale: '20px' }}>
                            <svg>
                                <use xlinkHref="#add" />
                            </svg>
                        </span>
                        Add New
                    </Text>

                </div>

            </div>
            <div className="custom-line" />
            {getPipelineTabpanes()}

            {/* {
                showAddNewModal &&
                <MasterAddnew
                    showAddNewModal={showAddNewModal}
                    setShowAddNewModal={setShowAddNewModal}
                    addNewForm={addNewForm}
                    isEdit={isEdit}
                    setIsEdit={setIsEdit}
                    editRecord={editRecord}
                    seteditRecord={seteditRecord}
                >

                </MasterAddnew>
            } */}
            <Modal width="30vw"
                className="pipeline-modal"
                title='Update'
                centered
                visible={edittModalVisible}
                footer={[
                    <Button key="cancel" onClick={handleCancel}>
                        Cancel
                    </Button>,
                    <Button key="apply" type="primary" onClick={handleUpdateOk} loading={isUpdateLoading}>
                        {isUpdateLoading ? 'Updating' : 'Update'}
                    </Button>
                ]}
            >
                <Form
                    form={UpdateModalForm}
                    name="basic"
                    layout="vertical"
                    autoComplete="off"
                >
                    <Row gutter={16}>
                        <Col lg={20}>
                            <Form.Item
                                name={coulumNames[activeKeyLine]}
                                label={tableNames[activeKeyLine]}
                            //required
                            >
                                <Input style={{ height: '35px' }} onChange={(e) => setAddItem(e.target.value)} />
                            </Form.Item>
                        </Col>

                    </Row>
                </Form>

            </Modal>
            <Modal width="30vw"
                className="pipeline-modal"
                title='Add New'
                centered
                visible={showAddNewModal}
                footer={[
                    <Button key="cancel" onClick={handleAddCancel}>
                        Cancel
                    </Button>,
                    <Button key="apply" type="primary" onClick={handleCreateNew} loading={addLoding}>
                        {showAddNewModal ? 'Add' : 'Adding'}
                    </Button>
                ]}
            >
                <Form
                    form={addNewForm}
                    name="basic"
                    layout="vertical"
                    autoComplete="off"
                >
                    <Row gutter={16}>
                        <Col lg={20}>
                            <Form.Item
                                name={coulumNames[activeKeyLine]}
                                label={tableNames[activeKeyLine]}
                            //required
                            >
                                <Input style={{ height: '35px' }} onChange={(e) => setAddItem(e.target.value)} />
                            </Form.Item>
                        </Col>

                    </Row>
                </Form>

            </Modal>

            <Modal
                width="45vw"
                className="pipeline-modal"
                title="Delete  Details"
                centered
                visible={isDeleteModalVisible}
                footer={[
                    <Button key="cancel" onClick={handleDeleteCancel}>
                        Cancel
                    </Button>,
                    <Button key="apply" type="primary" onClick={handleDeleteOk} loading={isDeleteLoading}>
                        {isDeleteLoading ? 'Deleting' : 'Delete'}
                    </Button>
                ]}
            >
                <div>
                    <Text style={{ fontSize: '16px' }}>
                        <p>Deleting all the corresponding and related information.</p>
                        Are you sure you want to delete  ?</Text>
                </div>
            </Modal>

        </div>
    );
};



export default connect(null, null)(MasteForm);

