/* eslint-disable no-param-reassign */
/* eslint-disable react/no-unescaped-entities */
import { ArrowLeftOutlined, RightCircleOutlined } from '@ant-design/icons';
import { Button, Tabs, Tooltip, Typography, Select, Row, Col, Card } from 'antd';

import { get } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import history from '../../services/history';


const { Title } = Typography;

const ProjectMarginCards = () => {
    return (
        <div className="d-flex flex-column h-100">
            <div className='back_equip' >
                <Tooltip title={'Back'} >
                    <ArrowLeftOutlined onClick={(e) => {
                        history.push('/FinanceMapping');
                    }} />
                </Tooltip>
            </div>
            <hr/>
            <div >
                <Row gutter={24} className="detail-rows">
                    <Col span={40}>
                        <Card className='custom-card'
                            onClick={() => {
                                history.push('/raw-cards');
                            }}
                            style={{ background: '#ef314c', borderColor: '#ef314c' }}>
                            <div style={{ marginTop: '-10px' }} >
                                <div >
                                    {"Raw Data"}
                                </div>
                                <div style={{ paddingTop: '5px' }}>
                                    <RightCircleOutlined></RightCircleOutlined>
                                </div>
                            </div>
                        </Card>
                    </Col>
                    <Col span={40}>
                        <Card className='custom-card'
                            onClick={() => {
                                history.push('/general-tabs');
                            }}
                            style={{ background: '#ef314c', borderColor: '#ef314c' }}>
                            <div style={{ marginTop: '-10px' }} >
                                <div >
                                    {"General"}
                                </div>
                                <div style={{ paddingTop: '5px' }}>
                                    <RightCircleOutlined></RightCircleOutlined>
                                </div>
                            </div>
                        </Card>
                    </Col>
                    <Col span={40}>
                        <Card className='custom-card'
                            onClick={() => {
                                history.push('/weekly-reporting');
                            }}
                            style={{ background: '#ef314c', borderColor: '#ef314c' }}>
                            <div style={{ marginTop: '-10px' }} >
                                <div >
                                    {"Weekly Reporting"}
                                </div>
                                <div style={{ paddingTop: '5px' }}>
                                    <RightCircleOutlined></RightCircleOutlined>
                                </div>
                            </div>
                        </Card>
                    </Col>
                    <Col span={40}>
                        <Card className='custom-card'
                            onClick={() => {
                                history.push('/me-reporting');
                            }}
                            style={{ background: '#ef314c', borderColor: '#ef314c' }}>
                            <div style={{ marginTop: '-10px' }} >
                                <div >
                                    {"ME Reporting"}
                                </div>
                                <div style={{ paddingTop: '5px' }}>
                                    <RightCircleOutlined></RightCircleOutlined>
                                </div>
                            </div>
                        </Card>
                    </Col>
                    <Col span={40}>
                        <Card className='custom-card'
                            onClick={() => {
                                history.push('/checks3');
                            }}
                            style={{ background: '#ef314c', borderColor: '#ef314c' }}>
                            <div style={{ marginTop: '-10px' }} >
                                <div >
                                    {"Check3"}
                                </div>
                                <div style={{ paddingTop: '5px' }}>
                                    <RightCircleOutlined></RightCircleOutlined>
                                </div>
                            </div>
                        </Card>
                    </Col>
                    <Col span={40}>
                        <Card className='custom-card'
                            onClick={() => {
                                history.push('/check2-commercial');
                            }}
                            style={{ background: '#ef314c', borderColor: '#ef314c' }}>
                            <div style={{ marginTop: '-10px' }} >
                                <div >
                                    {"Check2 Commercial"}
                                </div>
                                <div style={{ paddingTop: '5px' }}>
                                    <RightCircleOutlined></RightCircleOutlined>
                                </div>
                            </div>
                        </Card>
                    </Col>
                    <Col span={40}>
                        <Card className='custom-card'
                            onClick={() => {
                                history.push('/check1');
                            }}
                            style={{ background: '#ef314c', borderColor: '#ef314c' }}>
                            <div style={{ marginTop: '-10px' }} >
                                <div >
                                    {"Check1"}
                                </div>
                                <div style={{ paddingTop: '5px' }}>
                                    <RightCircleOutlined></RightCircleOutlined>
                                </div>
                            </div>
                        </Card>
                    </Col>
                    <Col span={40}>
                        <Card className='custom-card'
                            onClick={() => {
                                history.push('/brazil');
                            }}
                            style={{ background: '#ef314c', borderColor: '#ef314c' }}>
                            <div style={{ marginTop: '-10px' }} >
                                <div >
                                    {"Brazil"}
                                </div>
                                <div style={{ paddingTop: '5px' }}>
                                    <RightCircleOutlined></RightCircleOutlined>
                                </div>
                            </div>
                        </Card>
                    </Col>
                    <Col span={40}>
                        <Card className='custom-card'
                            onClick={() => {
                                history.push('/supplement');
                            }}
                            style={{ background: '#ef314c', borderColor: '#ef314c' }}>
                            <div style={{ marginTop: '-10px' }} >
                                <div >
                                    {"Supplemental"}
                                </div>
                                <div style={{ paddingTop: '5px' }}>
                                    <RightCircleOutlined></RightCircleOutlined>
                                </div>
                            </div>
                        </Card>
                    </Col>
                </Row>
            </div>
        </div>
    );
};

export default ProjectMarginCards