/* eslint-disable linebreak-style */
/* eslint-disable react/prop-types */
import React, { useState, useEffect, useRef } from 'react';
import { Typography, Table, Tooltip, Button, Modal, Upload, Input, Space, DatePicker, notification } from 'antd';
import moment from 'moment';
import { FilePdfOutlined, CloudUploadOutlined, SearchOutlined, ShareAltOutlined, EditOutlined,BellOutlined } from '@ant-design/icons'
import { connect } from 'react-redux';
import { get } from 'lodash';
import ExcelJS from 'exceljs';
import { fetchCalibration, shareCalibration, uploadCalibrationFile } from '../redux/calibration/action';
import CalibrationForm from '../components/CalibrationForm.js';

const { Title, Text } = Typography;

const Calibration = (props) => {

  const {
    calibrationData,
    iscalbrationLoading,
    onFetchCalibration,
    onShareCalibration,
    onUploadCalibrationFile,
    isUploadCalibration,
    calibrationTotalCount
  } = props;

  const [calibrationFormModal, setCalibrationFormModal] = useState(false);
  const [bulkUplaodModal, setBulkUplaodModal] = useState(false);
  const [shareCalibrationModal, setShareCalibrationModal] = useState(false);
  const [emailAddress, setEmailAddress] = useState('');
  const [calibrationCertificateId, setcalibrationCertificateId] = useState(0);
  const [expiryDateSearch, setExpiryDateSearch] = useState('');
  const [searchText, setSearchText] = useState('');
  const [disableFileUpload, setDisableFileUpload] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [paginationClear, setPaginationClear] = useState(false);
  const [editValue, setEditValue] = useState(false);
  const [calibrationRecord, setCalibrationRecord] = useState({});
  const [editData, setEditData] = useState({});
  const [searchCriteria, setSearchCriteria] = useState({});

  useEffect(() => {
    onFetchCalibration();

  }, []);

  useEffect(() => {
    onFetchCalibration({ searchKey: searchText, expiryDate: expiryDateSearch })
  }, [expiryDateSearch, searchText]);

  useEffect(() => {
    if (!isUploadCalibration) {
      setFileList([]);
      setBulkUplaodModal(false);
      setDisableFileUpload(false);
    }
  }, [isUploadCalibration]);

  const sorter = async (order, key) => {
    console.log("order------------->", order);
  }

  const columns = [
    {
      title: 'Item',
      dataIndex: 'item',
      key: 'item',
      align: 'center',
    },
    {
      title: 'Serial Number',
      dataIndex: 'serialNumber',
      key: 'serialNumber',
      align: 'center',
    },
    {
      title: 'Certificate Number',
      dataIndex: 'certificateNumber',
      key: 'certificateNumber',
      align: 'center',
    },
    {
      title: 'Expiry Date',
      dataIndex: 'expiryDate',
      key: 'expiryDate',
      align: 'center',
      sortDirections: ["ascend", "descend"],
      sorter: (a, b, order) => {
        sorter(order, "expiryDate");
      },
      sorter: (a, b) => a.expiryDate.localeCompare(b.expiryDate)
    },
    {
      title: 'Location',
      dataIndex: 'location',
      key: 'location',
      align: 'center',
    },
    {
      title: 'Certificate',
      dataIndex: 'certificate',
      key: 'certificate',
      align: 'center',
      render: (_, record) => {
          const max = record.certificate_history.reduce(function(prev, current) {
            return (prev.version > current.version) ? prev : current
            
        }) 
        return(
          <Tooltip title="Download Certificate">
            <Button 
            href={max?.certificateURL?.fileURL} 
            className='p-0 pdfIcon border-0' size='small' 
            icon={<FilePdfOutlined />}
            >
            </Button>
          </Tooltip>
        )
      }
    },
    {
      title: 'Actions',
      key: 'action',
      dataIndex: 'action',
      render: (text, record) => {
        return (
          <div>
            <Tooltip title="Update">
                <EditOutlined onClick={() => { showEditModal(record) }} style={{marginRight: '10px', color:'#EF314C', fontSize:'16px'}}  />
            </Tooltip>
            <Tooltip title="Share Certificate">
              <ShareAltOutlined onClick={() => { showShareCalibrationModal(record) }} style={{marginRight: '10px', color:'#EF314C', fontSize:'16px'}}/>
            </Tooltip>
            {/* <Tooltip title="Reminder">
              <BellOutlined onClick={() => { showShareCalibrationModal(record) }} style={{marginRight: '10px', color:'#EF314C', fontSize:'16px'}}/>
            </Tooltip> */}
          </div>
        );
      },
      align: 'center'
    }
  ];

  const showEditModal = async (record) => {
    let editData = null;
    if (record != null) {
        editData = {
            item: record.item,
            serialNumber: record.serialNumber,
            certificateNumber: record.certificateNumber,
            expiryDate: moment(record.expiryDate, "MM-DD-YYYY"),
            location: record.location,
            certificate_history: record.certificate_history 
        };
    }
    setEditValue(true);
    setEditData(editData)
    setCalibrationRecord(record)
    setCalibrationFormModal(true)
}

  const onSearchCalibration = async (event) => {
    setSearchText(event.target.value)
  }

  const showBulkUploadModal = () => {
    setBulkUplaodModal(true);
  }

  const showShareCalibrationModal = async (record) => {
    setcalibrationCertificateId(record.id)
    setShareCalibrationModal(true);
  }

  const handleCancelUploadFile = () => {
    setFileList([]);
    setBulkUplaodModal(false);
    setDisableFileUpload(false);
  }

  const handleCancelShareCalibrationModal = () => {
    setShareCalibrationModal(false);
    setEmailAddress('');
  }

  const shareCalibration = async () => {
    const emailAddressArray = emailAddress.split(',')
    await onShareCalibration({ calibrationCertificateId, emailAddress: emailAddressArray })
    setShareCalibrationModal(false)
    setEmailAddress('');
  }

  const handleDateSelect = async (date, dateString) => {
    if (date)
      setExpiryDateSearch(moment(date).format('MM-DD-YYYY'))
    else
      setExpiryDateSearch('')
  }

  const fileProps = {
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
      setDisableFileUpload(false);
    },
    beforeUpload: (file) => {
      setFileList([file]);
      setDisableFileUpload(true);
      return false;
    },
    fileList,
  };

  const handleUploadFile = async () => {
    if (fileList.length > 0) {
      await onUploadCalibrationFile({ fileList, isExcelUpload: 1 });
    } else {
      notification.warn({ message: 'Please select a file to upload.' })
    }
  }

  const handleDownloadTemplate = async () => {
    const data = await createExcelTemplate();
    const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = 'template.xlsx';
    link.click();
};

const createExcelTemplate = () => {
  const workbook = new ExcelJS.Workbook();
  const calibrationSheet = workbook.addWorksheet('calibration');
  const headerRow = calibrationSheet.getRow(1)
  headerRow.height = 60
  calibrationSheet.columns = [
      { header: 'Item', key: 'item', width: 20 },
      { header: 'Serial Number', key: 'serialNumber', width: 20 },
      { header: 'Certificate Number', key: 'certificateNumber', width: 20 },
      { header: 'Expiry Date', key: 'expiryDate', width: 20 },
      { header: 'Location', key: 'location', width: 20 }
  ];
  return workbook.xlsx.writeBuffer();
};
useEffect(() => {

  var obj = {
      pageSize: 10,
      pageNumber: 1,
      searchKey: searchCriteria
  }

  //onFetchSparePartsMasterData(obj)

}, [searchCriteria]);
const handleSearchInputChange = (fieldName, value) => {
  setSearchCriteria(prevSearchCriteria => ({
      ...prevSearchCriteria,
      [fieldName]: value
  }));
};
const handleSearchDateSelect = (fieldName, date) => {
  const formattedDate = date ? moment(date).format('YYYY-MM-DD') : '';
  handleSearchInputChange(fieldName, formattedDate);
};

const updatedColumns = columns.map(column => {
  if (column.dataIndex === 'expiryDate') {
      return {
          ...column,
          title: (
              <Space style={{ display: 'flow', alignItems: 'center', width: '100%' }}>
                  {column.title}
                  <DatePicker
                      allowClear
                      style={{ height: '28px', border: 'line', width: '100%' }}
                      format="DD-MM-YYYY"
                      placeholder='Search'
                      onChange={(date, dateString) => handleDateSelect(date, dateString)}
                      //onChange={date => handleSearchDateSelect(column.dataIndex, date)}
                      bordered
                  />
              </Space>
          ),
      };
  } else if (column.dataIndex !== 'action') {
      return {
          ...column,
          title: (
              <Space style={{ display: 'flow', alignItems: 'center', width: '100%' }}>
                  {column.title}
                  <div className='searchEquip'>
                      <Input
                          placeholder='Search'
                         // value={searchCriteria[column.dataIndex]}
                          //onChange={e => handleSearchInputChange(column.dataIndex, e.target.value)}
                          onChange={onSearchCalibration}
                          allowClear
                      />
                  </div>
              </Space>
          ),
      };
  } else {
      return column;
  }
});

  return (
    <div className="flex-auto">
      <div className="d-flex mb-3 align-items-center tab-content-title" style={{ height: '50px' }}>
        <Title className="mb-0" level={4}>
          List of Calibration
        </Title>
        <div className="new-calibration">
          <Text onClick={() => {
          
          setEditValue(false);
          setEditData({})
          setCalibrationRecord({})
          setCalibrationFormModal(true) 
          }} className="ps-3 add-new-calibration" >
            <span className="icon-22">
              <svg>
                <use xlinkHref="#add" />
              </svg>
            </span>
            Add New
          </Text>
          <Text onClick={showBulkUploadModal} className="ps-3 add-new-calibration" >
            <span className="icon-22">
              <svg>
                <use xlinkHref="#add" />
              </svg>
            </span>
            Bulk Upload
          </Text>
          <Text onClick={handleDownloadTemplate} className="ps-3 add-new-calibration" >
              Download template
          </Text>
        </div>
      </div>
      <div className="custom-line" />
      {/* <div className="d-flex mb-3 align-items-center tab-content-title" style={{ height: '40px' }}>
        <div className="searchEquip">
          <Input
            placeholder='Search Item'
            onChange={onSearchCalibration}
            allowClear
          />
        </div>
        <div className="searchEquip">
          <Input
            placeholder='Search Serial Number'
            allowClear
            onChange={onSearchCalibration}
          />
        </div>
        <div className="searchEquip">
          <Input
            placeholder='Certificate Number'
            allowClear
            onChange={onSearchCalibration}
          />
        </div>
        <div className="searchEquip">
          <Input
            placeholder='Location'
            allowClear
            onChange={onSearchCalibration}
          />
        </div>
        <div className="searchEquip">
          <DatePicker
            allowClear={true}
            style={{ width: '250px' }}
            format="DD-MM-YYYY"
            placeholder='Search By Expiry Date'
            onChange={(date, dateString) => handleDateSelect(date, dateString)}
          />
        </div>
      </div> */}
      <div className='addTeamDetails'>
        <Table
          columns={updatedColumns}
          dataSource={[...calibrationData]}
          className="calibration-table"
          // pagination={false}
          scroll={{ y: 'calc(100vh - 250px)' }}
          pagination={paginationClear ? false : {
            position: ['bottomRight'],
            showSizeChanger: true,
            total: calibrationTotalCount,
            defaultPageSize: 10,
            onChange: async (pageNumber, pageSize) => {
              await onFetchCalibration({
                pageSize: pageSize,
                pageNumber: pageNumber,
              });
            }
          }}
          loading={iscalbrationLoading}
        />
      </div>
      {calibrationFormModal &&
        <CalibrationForm
          setCalibrationFormModal={setCalibrationFormModal}
          calibrationFormModal={calibrationFormModal}
          editData={editData}
          calibrationRecord={calibrationRecord}
          editValue={editValue}
          setEditData={setEditData}
          setCalibrationRecord={setCalibrationRecord}
          setEditValue={setEditValue}
        />
      }
      <Modal
        className="pipeline-modal"
        title='Share Calibration Certificate'
        centered
        open={shareCalibrationModal}
        footer={[
          <Button key="cancel" onClick={handleCancelShareCalibrationModal}>
            {'Cancel'}
          </Button>,
          <Button key="apply" type="primary" onClick={shareCalibration}>
            Share
          </Button>
        ]}
      >
        <div className='uploadFile_admin'>
          <Input placeholder="Enter Emails Followed By Comma (,)" style={{ height: '35px' }} value={emailAddress} onChange={(e) => setEmailAddress(e.target.value)} />
          <span>eg: 1234@mail.com, 234@email.com</span>
        </div>
      </Modal>
      <Modal
        className="pipeline-modal"
        title='Upload Calibration'
        centered
        open={bulkUplaodModal}
        footer={[
          <Button key="cancel" onClick={handleCancelUploadFile}>
            {'Cancel'}
          </Button>,
          <Button key="apply" type="primary" onClick={handleUploadFile} loading={isUploadCalibration}>
            {isUploadCalibration ? 'uploading' : 'Upload'}
          </Button>
        ]}
      >
        <div className='uploadFile_admin'>
          <Upload {...fileProps} maxCount={1} accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" >
            <Button disabled={disableFileUpload} icon={<CloudUploadOutlined className='icon-22' />}>Select File</Button>
          </Upload>
        </div>
      </Modal>
    </div>
  )
};

const mapStateToProps = ({ calibrationReducer }) => {
  const calibrationData = get(calibrationReducer, 'calibrationData', []);
  const iscalbrationLoading = get(calibrationReducer, 'iscalbrationLoading', false);
  const isUploadCalibration = get(calibrationReducer, 'isUploadCalibration', false);
  const calibrationTotalCount = get(calibrationReducer, 'calibrationTotalCount', null);
  return {
    calibrationData,
    iscalbrationLoading,
    isUploadCalibration,
    calibrationTotalCount
  }
}

const mapDispatchToProps = {
  onFetchCalibration: fetchCalibration,
  onShareCalibration: shareCalibration,
  onUploadCalibrationFile: uploadCalibrationFile
}

export default connect(mapStateToProps, mapDispatchToProps)(Calibration);
