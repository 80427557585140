export const GENERATESPAREPARTS ="@@generate_recordlist";
export const GENERATESPAREPARTS_SUCCESS = "@@generate_recordlist_Success"
export const GENERATESPAREPARTS_FAILURE = "@@generate_recordlist_failure"

export const FETCHPRICINGLIST = '@@fetch_pricing_List';
export const FETCHPRICINGLIST_SUCCESS = '@@fetch_pricinglist_Success';
export const FETCHPRICINGLIST_FAILURE = '@@fetch_pricinglist_Failure';

export const DELETEPRICING = '@@delete_pricing_List';
export const DELETEPRICING_SUCCESS = '@@delete_pricing_List_Success';
export const DELETEPRICING_FAILURE = '@@delete_pricing_List_Failure';

export const FETCH_PRICING_LIST = '@@fetch-pricing-list';
export const FETCH_PRICING_LIST_SUCCESS = '@@fetch-pricing-list-success';
export const FETCH_PRICING_LIST_FAILURE = '@@fetch-pricing-list-failure';
