import React from 'react';
import { Checkbox, Col, Form, Row, Radio, Input } from 'antd';
import { connect } from 'react-redux';
import Equipment from './Equipment';
import DetailCalculationForm from './DetailCalculationForm';
import EquipmentTable from './EquipmentTable';


const EquipmentsForm = (props) => {
    const {
        setFormData,
        formData,
        setCheckedState,
        checkedState,
        setSummaryData,
        summaryData

    } = props;

    const handleInputChange = (name, value) => {
        value = Number(value);
        setFormData(prevData => ({ ...prevData, [name]: value }));
        //console.log(name,value ,formData)
    };

    const handleInputRadioChange = (name, value) => {
        setFormData(prevData => ({ ...prevData, [name]: value }));
    };

    const handleRadioChange = (value) => {
        setFormData(prevData => ({ ...prevData, 'Equipment_Type': value }));
    };

    const handleCheckboxChange = (name, checked) => {
        setCheckedState(prevData => ({ ...prevData, [name]: checked }));
        // console.log(checkedState)
    };

    const formItemLayout = {
        display: 'flex',
        alignItems: 'center',
        marginBottom: '15px',
    };

    const inputStyle = {
        width: '100px', // Reduced the width of input fields
        marginTop: '0px',
        marginLeft: '10px'
    };

    const inputStyle1 = {
        width: '100px',
        marginTop: '0px',
        marginLeft: '10px'
    };
    const inputStyle3 = {
        width: '100px', // Reduced the width of input fields
        marginTop: '0px',
        marginLeft: '20px'
    };

    const inputWrapperStyle = {
        display: 'flex',
        alignItems: 'center', // Vertically centers the checkbox and input
        gap: '0px', // Space between the checkbox and input
        marginBottom: '0px', // Space between each row
    };
    const checkboxStyle = {
        minWidth: '200px', // Ensures all checkboxes/radios start from the same position
    };




    return (
        <div className="pipeline-modal">
            <Form name="equipmentsForm" autoComplete="off" layout="vertical">
                <Row gutter={[16, 16]}>
                    <Col lg={8}>
                        <Equipment>
                            setFormData={setFormData}
                            formData={formData}
                            setCheckedState={setCheckedState}
                            checkedState={checkedState}

                        </Equipment>
                    </Col>
                    <Col lg={16}>
                        <DetailCalculationForm
                         setSummaryData ={setSummaryData}
                         summaryData ={summaryData}
                        
                        ></DetailCalculationForm>
                    </Col>
                    </Row>
                {/* <Row gutter={[16, 16]}>
                <Col lg={12}>
                        <div style={inputWrapperStyle}>
                            <Radio  size="small"
                                className='report-radio'
                                value="IWM"
                                onChange={(e) => handleRadioChange(e.target.value)}
                                checked={formData.Equipment_Type === 'IWM'}
                                style={checkboxStyle}
                            >
                                IWM
                            </Radio>
                            {formData.Equipment_Type === 'IWM' && (
                                <>
                                    <Input
                                        placeholder="IWM Qty"
                                        type="number"
                                        size="small"
                                        min={0}
                                        value={formData['IWM']}
                                        style={inputStyle}
                                        onChange={e => handleInputChange('IWM', e.target.value)}
                                    />
                                    <Input
                                        placeholder="IWM Spare Qty"
                                        type="number"
                                        size="small"
                                        value={formData['IWM Major Spares']}
                                        min={0}
                                        style={inputStyle}
                                        onChange={e => handleInputChange('IWM Major Spares', e.target.value)}
                                    />
                                </>
                            )}
                        </div>
                        <div style={inputWrapperStyle}>
                            <Radio 
                                className='report-radio'
                                value="CBUC"  size="middle"
                                onChange={(e) => handleRadioChange(e.target.value)}
                                checked={formData.Equipment_Type === 'CBUC'}
                                style={checkboxStyle}
                            >
                                CBUC
                            </Radio>
                            {formData.Equipment_Type === 'CBUC' && (
                                <>
                                    <Input
                                        placeholder="CBUC Qty"
                                        type="number"
                                        size="small"
                                        min={0}
                                        value={formData['CBUC']}
                                        style={inputStyle}
                                        onChange={e => handleInputChange('CBUC', e.target.value)}
                                    />
                                    <Input
                                        placeholder="CBUC Spare Qty"
                                        type="number"
                                        size="small"
                                        style={inputStyle}
                                        value={formData['CBUC Spare Copper Sets']}
                                        min={0}
                                        onChange={e => handleInputChange('CBUC Spare Copper Sets', e.target.value)}
                                    />
                                    <Input
                                        placeholder="CBUC Major Spares"
                                        type="number"
                                        size="small"
                                        min={0}
                                        value={formData['CBUC Major Spares']}
                                        style={inputStyle}
                                        onChange={e => handleInputChange('CBUC Major Spares', e.target.value)}
                                    />
                                </>
                            )}
                        </div>
                    </Col>
                    <Col lg={12}>
                        {['40 Feet', '80 Feet'].map(reachRod => (
                            <div key={reachRod} style={inputWrapperStyle}>
                                <Checkbox checked={checkedState[reachRod]}  size="small"  style={checkboxStyle}
                                    onChange={e =>
                                        handleCheckboxChange(reachRod, e.target.checked)
                                    }
                                >
                                    {reachRod}
                                </Checkbox>
                                {checkedState[reachRod] && (
                                    <Input
                                        placeholder={`${reachRod} Qty`}
                                        type="number"
                                        size="small"
                                        min={0}
                                        value={formData[reachRod === '40 Feet' ? 'R/R 40FT' : 'R/R 80FT']}
                                        style={inputStyle}
                                        onChange={e => handleInputChange(reachRod === '40 Feet' ? 'R/R 40FT' : 'R/R 80FT', e.target.value)}
                                    />
                                )}
                            </div>
                        ))}
                    </Col>

                    <Col lg={12}>
                        {['Aligners', 'Pipe'].map(band => (
                            <div key={band} style={inputWrapperStyle}>  
                                <Checkbox checked={checkedState[band]}  style={checkboxStyle}
                                    onChange={e =>
                                        handleCheckboxChange(band, e.target.checked)
                                    }
                                >
                                    {band}
                                </Checkbox>
                                {checkedState[band] && (
                                    <Input
                                        placeholder={`${band} Qty`}
                                        type="number"
                                        size="small"
                                        min={0}
                                        value={formData[band === 'Aligners' ? 'A/BAND' : 'P/BAND']}
                                        style={inputStyle}
                                        onChange={e => handleInputChange(band === 'Aligners' ? 'A/BAND' : 'P/BAND', e.target.value)}
                                    />
                                )}
                            </div>
                        ))}
                    </Col>

                    <Col lg={12}>
                        {['Shelters', 'Tie in Shelters'].map(shelter => (
                            <div key={shelter} style={formItemLayout}>
                                <Checkbox checked={checkedState[shelter]}   style={checkboxStyle}
                                    onChange={e =>
                                        handleCheckboxChange( shelter, e.target.checked)
                                    }
                                >
                                    {shelter}
                                </Checkbox>
                                {checkedState[shelter] && (
                                    <Input
                                        placeholder={`${shelter} Qty`}
                                        type="number"
                                        size="small"
                                        min={0}
                                        value={formData[`Welding ${shelter}`]}
                                        style={inputStyle}
                                        onChange={e => handleInputChange(`Welding ${shelter}`, e.target.value)}
                                    />
                                )}
                            </div>
                        ))}
                    </Col>

                    <Col lg={12}>
                        {['Miller XMT 350', 'Lincoln', 'Fronius'].map(supply => (
                            <div key={supply} style={formItemLayout}>
                                <Checkbox checked={checkedState[supply]}  style={checkboxStyle}
                                    onChange={e =>
                                        handleCheckboxChange(supply, e.target.checked)
                                    }
                                >
                                    {supply}
                                </Checkbox>
                                {checkedState[supply] && (
                                    <Input
                                        placeholder={`${supply} Qty`}
                                        type="number"
                                        size="small"
                                        min={1}
                                        value={formData[`${supply} Power Supply`]}
                                        style={inputStyle}
                                        onChange={e => handleInputChange(`${supply} Power Supply`, e.target.value)}
                                    />
                                )}
                            </div>
                        ))}
                    </Col>

                    <Col lg={12}>
                        {['P-600', 'M-300C', 'M-500', 'P-625'].map(welder => (
                            <div key={welder} style={formItemLayout}>
                                <Checkbox  checked={checkedState[welder]}  style={checkboxStyle}
                                    onChange={e => handleCheckboxChange( welder, e.target.checked)}
                                >
                                    {welder}
                                </Checkbox>
                                {checkedState[welder] && (
                                    <>
                                        <Input
                                            placeholder="Stations Qty"
                                            type="number"
                                            size="small"
                                            min={0}
                                            value={formData[`${welder} ${welder =='M-500' ? 'Station' : 'Stations'}`]}
                                            style={inputStyle1}
                                            onChange={e => handleInputChange(`${welder} ${welder =='M-500' ? 'Station' : 'Stations'}`, e.target.value)}
                                        />
                                        <Input
                                            placeholder="Spare Qty"
                                            type="number"
                                            size="small"
                                            min={0}
                                            value={formData[`${welder} ${welder =='M-500' ? 'Major Spare' :'Major Spare'}`]}
                                            style={inputStyle1}
                                            onChange={e => handleInputChange(`${welder} ${welder =='M-500' ? 'Major Spare' :'Major Spare'}`, e.target.value)}
                                        />
                                        <Input
                                            placeholder="Bugs Qty"
                                            type="number"
                                            size="small"
                                            min={0}
                                            value={formData[`${welder} Spare Bugs`]}
                                            style={inputStyle1}
                                            onChange={e => handleInputChange(`${welder} Spare Bugs`, e.target.value)}
                                        />
                                    </>
                                )}
                            </div>
                        ))}
                    </Col>

                    <Col lg={12}>
                        {['Support', 'WPQ', 'WHS Containers', 'Spares & Tools', 'Cold Weather Pkg'].map(cost => (
                            <div key={cost} style={formItemLayout}>
                                <Checkbox checked={checkedState[cost]}  style={checkboxStyle}
                                    onChange={e =>
                                        handleCheckboxChange(cost, e.target.checked)
                                    }
                                >
                                    {cost}
                                </Checkbox>
                                {checkedState[cost] && (
                                    <Input
                                        placeholder={`${cost} Qty`}
                                        type="number"
                                        size="small"
                                        min={0}
                                        value={formData[cost]}
                                        style={inputStyle}
                                        onChange={e => handleInputChange(cost, e.target.value)}
                                    />
                                )}
                            </div>
                        ))}
                    </Col>

                    <Col lg={12}>
                        {['Pipe Facing', 'HPU', 'Cutting Bugs'].map(equipment => (
                            <div key={equipment} style={formItemLayout}>
                                <Checkbox checked={checkedState[equipment]}   style={checkboxStyle}
                                    onChange={e =>
                                        handleCheckboxChange(equipment, e.target.checked)
                                    }
                                >
                                    {equipment}
                                </Checkbox>
                                {checkedState[equipment] && (
                                    <Input
                                        placeholder={`${equipment} Qty`}
                                        type="number"
                                        size="small"
                                        min={0}
                                        value={formData[equipment]}
                                        style={inputStyle}
                                        onChange={e => handleInputChange(equipment, e.target.value)}
                                    />
                                )}
                            </div>
                        ))}
                    </Col>
                </Row> */}
            </Form>
        </div>
    );
};

export default connect(null, null)(EquipmentsForm);
