import React from 'react';
import ReactDOM from 'react-dom/client';
import './global-styles/admin.scss'
import './global-styles/admin-lightTheme.scss'
import './index.css';
import App from './App';
import './global-styles/base-style.scss';
import './global-styles/antd-custom.scss';
import './global-styles/light-theme.scss';

import "ag-grid-enterprise";
import { LicenseManager } from "@ag-grid-enterprise/core";
LicenseManager.setLicenseKey(process.env.REACT_APP_AG_GRID_ENTERPRISE_LICENSE)

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
