/* eslint-disable linebreak-style */
import React from 'react';
import history from '../services/history';
import { Button, notification } from 'antd'
import { signOut } from '../redux/actions/authActions';
import { connect } from 'react-redux';
import { func } from 'prop-types';

const SignOutPage = ({ onSignout }) => {
    // const {
    //     onSignout
    // } = props;

    const redirectError = () => {
        console.log('clicked logout');
       // onSignout();
        history.push('/login')
        localStorage.clear();
        sessionStorage.clear();
        notification.success({
            duration: 3,
            message: 'Successfully Logged Out',
            description: '',
        });
    };


    return (
        <div className="h-100 d-flex align-items-center justify-content-center">
            {
                    notification.success({
                        duration: 3,
                        message: 'Successfully Logged Out',
                        description: '',
                    })
                }
        </div>
    );

};

SignOutPage.propTypes = {
    onSignout: func.isRequired,
};

const mapDispatchToProps = {
    onSignout: signOut,
};
export default connect(null, mapDispatchToProps)(SignOutPage);
