/* eslint-disable linebreak-style */

import { ArrowLeftOutlined, DeleteOutlined, EditOutlined, EyeOutlined, FilePdfOutlined } from '@ant-design/icons';
import { Button, DatePicker, Form, Input, Modal, Space, Table, Tooltip, Typography } from 'antd';
import { get } from 'lodash';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import history from '../services/history.js';

import moment from 'moment';
import MasterUploadForm from '../components/Spare Parts generator/MasterUploadForm.js';
import SaparepartsUpdateForm from '../components/Spare Parts generator/SaparepartsUpdateForm.js';
import { authenticateCurrentUser } from '../services/auth.js';
import WeldingWireForm from '../components/WeldingWire/WeldingWireForm.js';
import WeldingWireProjectDetails from '../components/WeldingWire/WeldingWireProjectDetails.js';
import ConfigWeldWire from '../components/WeldingWire/ConfigWeldWire.js';

const { Title, Text } = Typography;



const WeldingWireCalculator = () => {

    const data = [
        {
            "proposalNumber": "WE24-Hxxx",
            "revisionNumber": "0",
            "date": new Date(),
            "projectName": "Rio Bravo",
            "crcEvansWbsProject": "TBD",
            "contractor": "TBD",
            "designStandard": "API 1104",
            "clientSpecification": "N/A",
            "clientShippingAddress": "TBD",
            "technician": "TBD",
            "projectWeldEngineer": "TBD",
            "crcEvansWbsWpq": 'TBD',
            "wpqLocation": 'Houston',
            "wpqStartDate": new Date(),
            "NumberOfPipes": 1,
            "pipeLineData": [
                {
                    "pipeDiameter": '10',
                    "wallThickness": '10',
                    "bevelDesign": '5-deg K-prep',
                    "wallThicknessUnit": 'mm',
                }
            ],
            "numberOfIWMHeads": "Number of IWM Heads",
            "P600TorchSplitDistance": "5",
            "IWMTransferEfficiency": "96",
            "rootMethod": "SMAW Root",
            "compoundBevel": "Yes",
            "transferEfficiency": "85",
            "arcTimeEfficiency": "70",
            "pipe1": {
                "pipeDiameter": '10',
                "wallThickness": '10',
                "bevelDesign": '5-deg K-prep',
                "wallThicknessUnit": 'mm',
                "pipeDiameterUnit": 'mm',
                "iwmProductionWeldCount": '11',
                "iwmTrainingWeldAllowance": '11',
                "iwmWPQWelds": '11',
                "iwmAverageWeldsPerDay": '10',
                "iwmPlanStartDate": new Date(),
                "iwmPlannedCompleteDate": new Date(),
                "iwmWorkingDaysPerWeek": '6',
                "m300ProductionWeldCount": '11',
                "m300TrainingWeldAllowance": '11',
                "m300WPQWelds": '11',
                "m300AverageWeldsPerDay": '10',
                "m300PlanStartDate": new Date(),
                "m300PlannedCompleteDate": new Date(),
                "m300WorkingDaysPerWeek": '6'
            },
            "pipe2": {
                "pipeDiameter": '10',
                "wallThickness": '10',
                "bevelDesign": '5-deg K-prep',
                "wallThicknessUnit": 'mm',
                "pipeDiameterUnit": 'mm',
                "iwmProductionWeldCount": '11',
                "iwmTrainingWeldAllowance": '11',
                "iwmWPQWelds": '11',
                "iwmAverageWeldsPerDay": '10',
                "iwmPlanStartDate": new Date(),
                "iwmPlannedCompleteDate": new Date(),
                "iwmWorkingDaysPerWeek": '6',
                "m300ProductionWeldCount": '11',
                "m300TrainingWeldAllowance": '11',
                "m300WPQWelds": '11',
                "m300AverageWeldsPerDay": '10',
                "m300PlanStartDate": new Date(),
                "m300PlannedCompleteDate": new Date(),
                "m300WorkingDaysPerWeek": '6'
            },
            "pipe3": {
                "pipeDiameter": '10',
                "wallThickness": '10',
                "bevelDesign": '5-deg K-prep',
                "wallThicknessUnit": 'mm',
                "pipeDiameterUnit": 'mm',
                "iwmProductionWeldCount": '11',
                "iwmTrainingWeldAllowance": '11',
                "iwmWPQWelds": '11',
                "iwmAverageWeldsPerDay": '10',
                "iwmPlanStartDate": new Date(),
                "iwmPlannedCompleteDate": new Date(),
                "iwmWorkingDaysPerWeek": '6',
                "m300ProductionWeldCount": '11',
                "m300TrainingWeldAllowance": '11',
                "m300WPQWelds": '11',
                "m300AverageWeldsPerDay": '10',
                "m300PlanStartDate": new Date(),
                "m300PlannedCompleteDate": new Date(),
                "m300WorkingDaysPerWeek": '6'
            },
            "pipe4": {
                "pipeDiameter": '10',
                "wallThickness": '10',
                "bevelDesign": '5-deg K-prep',
                "wallThicknessUnit": 'mm',
                "pipeDiameterUnit": 'mm',
                "iwmProductionWeldCount": '11',
                "iwmTrainingWeldAllowance": '11',
                "iwmWPQWelds": '11',
                "iwmAverageWeldsPerDay": '10',
                "iwmPlanStartDate": new Date(),
                "iwmPlannedCompleteDate": new Date(),
                "iwmWorkingDaysPerWeek": '6',
                "m300ProductionWeldCount": '11',
                "m300TrainingWeldAllowance": '11',
                "m300WPQWelds": '11',
                "m300AverageWeldsPerDay": '10',
                "m300PlanStartDate": new Date(),
                "m300PlannedCompleteDate": new Date(),
                "m300WorkingDaysPerWeek": '6'
            },
            "pipe5": {
                "pipeDiameter": '10',
                "wallThickness": '10',
                "bevelDesign": '5-deg K-prep',
                "wallThicknessUnit": 'mm',
                "pipeDiameterUnit": 'mm',
                "iwmProductionWeldCount": '11',
                "iwmTrainingWeldAllowance": '11',
                "iwmWPQWelds": '11',
                "iwmAverageWeldsPerDay": '10',
                "iwmPlanStartDate": new Date(),
                "iwmPlannedCompleteDate": new Date(),
                "iwmWorkingDaysPerWeek": '6',
                "m300ProductionWeldCount": '11',
                "m300TrainingWeldAllowance": '11',
                "m300WPQWelds": '11',
                "m300AverageWeldsPerDay": '10',
                "m300PlanStartDate": new Date(),
                "m300PlannedCompleteDate": new Date(),
                "m300WorkingDaysPerWeek": '6'
            },
            "report": ''
        }
    ];

    const columns = [
        {
            title: 'Proposal Number',
            dataIndex: 'proposalNumber',
            key: 'proposalNumber',
            align: 'center',
        },
        {
            title: 'Revision Number',
            dataIndex: 'revisionNumber',
            key: 'revisionNumber',
            align: 'center',
        },
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
            align: 'center',
            render: (text) => {
                return (
                    <div>{text ? moment(text).format('DD-MMM-YYYY') : '-'}</div>
                );
            },
        },
        {
            title: 'Project Name',
            dataIndex: 'projectName',
            key: 'projectName',
            align: 'center',
        },
        {
            title: 'CRC-Evans WBS (Project)',
            dataIndex: 'crcEvansWbsProject',
            key: 'crcEvansWbsProject',
            align: 'center',
        },
        {
            title: 'Contractor',
            dataIndex: 'contractor',
            key: 'contractor',
            align: 'center',
        },
        {
            title: 'Design Standard',
            dataIndex: 'designStandard',
            key: 'designStandard',
            align: 'center',
        },
        {
            title: 'Client Specification',
            dataIndex: 'clientSpecification',
            key: 'clientSpecification',
            align: 'center',
        },
        {
            title: 'Client Shipping Address',
            dataIndex: 'clientShippingAddress',
            key: 'clientShippingAddress',
            align: 'center',
        },
        {
            title: 'Technician',
            dataIndex: 'technician',
            key: 'technician',
            align: 'center',
        },
        {
            title: 'Project Weld Engineer',
            dataIndex: 'projectWeldEngineer',
            key: 'projectWeldEngineer',
            align: 'center',
        },
        {
            title: 'CRC-Evans WBS (WPQ)',
            dataIndex: 'crcEvansWbsWpq',
            key: 'crcEvansWbsWpq',
            align: 'center',
        },
        {
            title: 'WPQ Location',
            dataIndex: 'wpqLocation',
            key: 'wpqLocation',
            align: 'center',
        },
        {
            title: 'No. of Pipes',
            dataIndex: 'NumberOfPipes',
            key: 'NumberOfPipes',
            align: 'center',
        },
        {
            title: 'WPQ start date',
            dataIndex: 'wpqStartDate',
            key: 'wpqStartDate',
            align: 'center',
            render: (text) => {
                return (
                    <div>{text ? moment(text).format('DD-MMM-YYYY') : '-'}</div>
                );
            },
        },
        {
            title: 'Number of IWM Heads',
            dataIndex: 'numberOfIWMHeads',
            key: 'numberOfIWMHeads',
            align: 'center',
        },
        {
            title: 'P600 Torch Split Distance',
            dataIndex: 'P600TorchSplitDistance',
            key: 'P600TorchSplitDistance',
            align: 'center',
        },
        {
            title: 'IWM Transfer Efficiency',
            dataIndex: 'IWMTransferEfficiency',
            key: 'IWMTransferEfficiency',
            align: 'center',
        },
        {
            title: 'Root Method (Dropdown)',
            dataIndex: 'rootMethod',
            key: 'rootMethod',
            align: 'center',
        },
        {
            title: 'Compound Bevel (Yes/No)',
            dataIndex: 'compoundBevel',
            key: 'compoundBevel',
            align: 'center',
        },
        {
            title: 'Transfer Efficiency',
            dataIndex: 'transferEfficiency',
            key: 'transferEfficiency',
            align: 'center',
        },
        {
            title: 'Arc Time Efficiency',
            dataIndex: 'arcTimeEfficiency',
            key: 'arcTimeEfficiency',
            align: 'center',
        },
        // {
        //     title: 'Report',
        //     dataIndex: 'report',
        //     key: 'report',
        //     align: 'center',
        //     render: (text, record) => {
        //         return (
        //             <>
        //                 <Tooltip title="Download file" >
        //                     <Button href={record.reportUrl} className='p-0 border-0' size='small' target='_blank'>
        //                         <FilePdfOutlined className='pdfIconRed' />
        //                     </Button>
        //                 </Tooltip>
        //             </>
        //         )
        //     }
        // },
        {
            title: '',
            dataIndex: 'edit_delete',
            key: 'edit_delete',
            align: 'center',
            fixed: 'right',
            render: (text, record) => {
                return (
                    <div>

                        <Tooltip title="View Parts" >
                            <span className="icon-18 mr-2 cursor-pointer edit-hover" style={{ marginRight: '20px', }} >
                                <EyeOutlined style={{ fontSize: '18px' }}
                                    onClick={() => onReadParts(record)}
                                />
                            </span>
                        </Tooltip>
                        <Tooltip title="Update">
                            <span className="icon-18 mr-2 cursor-pointer edit-hover">
                                <EditOutlined style={{ fontSize: '18px' }}
                                    onClick={() => onUpdateClick(record)}
                                />
                            </span>
                        </Tooltip>
                        {/* { record.Url &&
                        <Tooltip title="Download report file" >

                            <Button href={record.Url} className='p-0 border-0' size='small' target='_blank'  style={{ marginLeft: '20px' }}>
                                <FilePdfOutlined className='pdfIconRed' />
                            </Button>

                        </Tooltip>
            } */}
                        <Tooltip title="Delete">
                            <span className="icon-18 mr-2 cursor-pointer edit-hover" style={{ marginLeft: '20px' }}>
                                <DeleteOutlined style={{ fontSize: '18px' }}
                                    onClick={() => showDeleteModal(record)}
                                />
                            </span>
                        </Tooltip>
                    </div>
                )
            },
        },
    ];

    const [isEdit, setIsEdit] = useState(false);
    const [editRecord, setEditRecord] = useState(null);
    const [projectDetailForm] = Form.useForm()
    const [showGenerateModel, setShowGenerateModel] = useState(false)
    const [showPipelineModel, setShowPipelineModel] = useState(false)
    const [pipelineData, setPipelineData] = useState([
        {
            pipeDiameter: '',
            wallThickness: '',
            bevelDesign: "",
        }
    ]);
    const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
    const [selectedData, setSelectedData] = useState('');
    //
    const [projectDetails, setProjectDetails] = useState([]);
    const [totalRows, setTotalRows] = useState(data.length)

    const [currentPageNumber, setCurrentPageNumber] = useState(1);
    const [currentPagesize, setCurrentPagesize] = useState(10);
    const [isRead, setIsRead] = useState(false);
    const [userGroup, setUserGroup] = useState([]);
    const [numberOfPipelines, setNumberOfPipelines] = useState(1);
    const [welddata, setWeldData] = useState([...data]);
    const [isConfigModalVisible, setIsConfigModalVisible] = useState(false);

    /** get user Access */
    useEffect(() => {
        authenticateCurrentUser()
            .then(async (data) => {
                if (data.signInUserSession.accessToken.payload["cognito:groups"]) {
                    let response = data.signInUserSession.accessToken.payload["cognito:groups"] //await getA(data.username)
                    setUserGroup(response);
                }
            })
            .catch((error) => {
                console.log('error', error);
            });
    }, []);

    //on click on update project details
    const onUpdateClick = (record) => {
        if (record) {
            let editData = {
                "proposalNumber": record.proposalNumber,
                "revisionNumber": record.revisionNumber,
                "date": moment(record.date, "DD-MMM-YYYY"),
                "projectName": record.projectName,
                "crcEvansWbsProject": record.crcEvansWbsProject,
                "contractor": record.contractor,
                "designStandard": record.designStandard,
                "clientSpecification": record.clientSpecification,
                "clientShippingAddress": record.clientShippingAddress,
                "technician": record.technician,
                "projectWeldEngineer": record.projectWeldEngineer,
                "crcEvansWbsWpq": record.crcEvansWbsWpq,
                "wpqLocation": record.wpqLocation,
                "NumberOfPipes": record.NumberOfPipes,
                "wpqStartDate": moment(record.wpqStartDate, "DD-MMM-YYYY"),
                'numberOfIWMHeads': record.numberOfIWMHeads,
                "P600TorchSplitDistance": record.P600TorchSplitDistance,
                "IWMTransferEfficiency": record.IWMTransferEfficiency,
                "rootMethod": record.rootMethod,
                "compoundBevel": record.compoundBevel,
                "transferEfficiency": record.transferEfficiency,
                "arcTimeEfficiency": record.arcTimeEfficiency,
                //pipe1
                "pipe1PipeDiameter": record.pipe1.pipeDiameter,
                "pipe1WallThickness": record.pipe1.wallThickness,
                "pipe1BevelDesign": record.pipe1.bevelDesign,
                "pipe1WallThicknessUnit": record.pipe1.wallThicknessUnit,
                "pipe1PipeDiameterUnit": record.pipe1.pipeDiameterUnit,
                //
                "pipe1IwmProductionWeldCount": record.pipe1.iwmProductionWeldCount,
                "pipe1IwmTrainingWeldAllowance": record.pipe1.iwmTrainingWeldAllowance,
                "pipe1IwmWPQWelds": record.pipe1.iwmWPQWelds,
                "pipe1IwmAverageWeldsPerDay": record.pipe1.iwmAverageWeldsPerDay,
                "pipe1IwmPlanStartDate": moment(record.pipe1.iwmPlanStartDate, "DD-MMM-YYYY"),
                "pipe1IwmPlannedCompleteDate": moment(record.pipe1.iwmPlannedCompleteDate, "DD-MMM-YYYY"),
                "pipe1IwmWorkingDaysPerWeek": record.pipe1.iwmWorkingDaysPerWeek,
                //
                "pipe1M300ProductionWeldCount": record.pipe1.m300ProductionWeldCount,
                "pipe1M300TrainingWeldAllowance": record.pipe1.m300TrainingWeldAllowance,
                "pipe1M300WPQWelds": record.pipe1.m300WPQWelds,
                "pipe1M300AverageWeldsPerDay": record.pipe1.m300AverageWeldsPerDay,
                "pipe1M300PlanStartDate": moment(record.pipe1.m300PlanStartDate, "DD-MMM-YYYY"),
                "pipe1M300PlannedCompleteDate": moment(record.pipe1.m300PlannedCompleteDate, "DD-MMM-YYYY"),
                "pipe1M300WorkingDaysPerWeek": record.pipe1.m300WorkingDaysPerWeek,
                // Pipe 2
                "pipe2PipeDiameter": record.pipe2.pipeDiameter,
                "pipe2WallThickness": record.pipe2.wallThickness,
                "pipe2BevelDesign": record.pipe2.bevelDesign,
                "pipe2WallThicknessUnit": record.pipe2.wallThicknessUnit,
                "pipe2PipeDiameterUnit": record.pipe2.pipeDiameterUnit,
                //
                "pipe2IwmProductionWeldCount": record.pipe2.iwmProductionWeldCount,
                "pipe2IwmTrainingWeldAllowance": record.pipe2.iwmTrainingWeldAllowance,
                "pipe2IwmWPQWelds": record.pipe2.iwmWPQWelds,
                "pipe2IwmAverageWeldsPerDay": record.pipe2.iwmAverageWeldsPerDay,
                "pipe2IwmPlanStartDate": moment(record.pipe2.iwmPlanStartDate, "DD-MMM-YYYY"),
                "pipe2IwmPlannedCompleteDate": moment(record.pipe2.iwmPlannedCompleteDate, "DD-MMM-YYYY"),
                "pipe2IwmWorkingDaysPerWeek": record.pipe2.iwmWorkingDaysPerWeek,
                //
                "pipe2M300ProductionWeldCount": record.pipe2.m300ProductionWeldCount,
                "pipe2M300TrainingWeldAllowance": record.pipe2.m300TrainingWeldAllowance,
                "pipe2M300WPQWelds": record.pipe2.m300WPQWelds,
                "pipe2M300AverageWeldsPerDay": record.pipe2.m300AverageWeldsPerDay,
                "pipe2M300PlanStartDate": moment(record.pipe2.m300PlanStartDate, "DD-MMM-YYYY"),
                "pipe2M300PlannedCompleteDate": moment(record.pipe2.m300PlannedCompleteDate, "DD-MMM-YYYY"),
                "pipe2M300WorkingDaysPerWeek": record.pipe2.m300WorkingDaysPerWeek,

                // Pipe 3
                "pipe3PipeDiameter": record.pipe3.pipeDiameter,
                "pipe3WallThickness": record.pipe3.wallThickness,
                "pipe3BevelDesign": record.pipe3.bevelDesign,
                "pipe3WallThicknessUnit": record.pipe3.wallThicknessUnit,
                "pipe3PipeDiameterUnit": record.pipe3.pipeDiameterUnit,
                //
                "pipe3IwmProductionWeldCount": record.pipe3.iwmProductionWeldCount,
                "pipe3IwmTrainingWeldAllowance": record.pipe3.iwmTrainingWeldAllowance,
                "pipe3IwmWPQWelds": record.pipe3.iwmWPQWelds,
                "pipe3IwmAverageWeldsPerDay": record.pipe3.iwmAverageWeldsPerDay,
                "pipe3IwmPlanStartDate": moment(record.pipe3.iwmPlanStartDate, "DD-MMM-YYYY"),
                "pipe3IwmPlannedCompleteDate": moment(record.pipe3.iwmPlannedCompleteDate, "DD-MMM-YYYY"),
                "pipe3IwmWorkingDaysPerWeek": record.pipe3.iwmWorkingDaysPerWeek,
                //
                "pipe3M300ProductionWeldCount": record.pipe3.m300ProductionWeldCount,
                "pipe3M300TrainingWeldAllowance": record.pipe3.m300TrainingWeldAllowance,
                "pipe3M300WPQWelds": record.pipe3.m300WPQWelds,
                "pipe3M300AverageWeldsPerDay": record.pipe3.m300AverageWeldsPerDay,
                "pipe3M300PlanStartDate": moment(record.pipe3.m300PlanStartDate, "DD-MMM-YYYY"),
                "pipe3M300PlannedCompleteDate": moment(record.pipe3.m300PlannedCompleteDate, "DD-MMM-YYYY"),
                "pipe3M300WorkingDaysPerWeek": record.pipe3.m300WorkingDaysPerWeek,
                // Pipe 4
                "pipe4PipeDiameter": record.pipe4.pipeDiameter,
                "pipe4WallThickness": record.pipe4.wallThickness,
                "pipe4BevelDesign": record.pipe4.bevelDesign,
                "pipe4WallThicknessUnit": record.pipe4.wallThicknessUnit,
                "pipe4PipeDiameterUnit": record.pipe4.pipeDiameterUnit,
                //
                "pipe4IwmProductionWeldCount": record.pipe4.iwmProductionWeldCount,
                "pipe4IwmTrainingWeldAllowance": record.pipe4.iwmTrainingWeldAllowance,
                "pipe4IwmWPQWelds": record.pipe4.iwmWPQWelds,
                "pipe4IwmAverageWeldsPerDay": record.pipe4.iwmAverageWeldsPerDay,
                "pipe4IwmPlanStartDate": moment(record.pipe4.iwmPlanStartDate, "DD-MMM-YYYY"),
                "pipe4IwmPlannedCompleteDate": moment(record.pipe4.iwmPlannedCompleteDate, "DD-MMM-YYYY"),
                "pipe4IwmWorkingDaysPerWeek": record.pipe4.iwmWorkingDaysPerWeek,
                //
                "pipe4M300ProductionWeldCount": record.pipe4.m300ProductionWeldCount,
                "pipe4M300TrainingWeldAllowance": record.pipe4.m300TrainingWeldAllowance,
                "pipe4M300WPQWelds": record.pipe4.m300WPQWelds,
                "pipe4M300AverageWeldsPerDay": record.pipe4.m300AverageWeldsPerDay,
                "pipe4M300PlanStartDate": moment(record.pipe4.m300PlanStartDate, "DD-MMM-YYYY"),
                "pipe4M300PlannedCompleteDate": moment(record.pipe4.m300PlannedCompleteDate, "DD-MMM-YYYY"),
                "pipe4M300WorkingDaysPerWeek": record.pipe4.m300WorkingDaysPerWeek,
                // Pipe 5
                "pipe5PipeDiameter": record.pipe5.pipeDiameter,
                "pipe5WallThickness": record.pipe5.wallThickness,
                "pipe5BevelDesign": record.pipe5.bevelDesign,
                "pipe5WallThicknessUnit": record.pipe5.wallThicknessUnit,
                "pipe5PipeDiameterUnit": record.pipe5.pipeDiameterUnit,
                //
                "pipe5IwmProductionWeldCount": record.pipe5.iwmProductionWeldCount,
                "pipe5IwmTrainingWeldAllowance": record.pipe5.iwmTrainingWeldAllowance,
                "pipe5IwmWPQWelds": record.pipe5.iwmWPQWelds,
                "pipe5IwmAverageWeldsPerDay": record.pipe5.iwmAverageWeldsPerDay,
                "pipe5IwmPlanStartDate": moment(record.pipe5.iwmPlanStartDate, "DD-MMM-YYYY"),
                "pipe5IwmPlannedCompleteDate": moment(record.pipe5.iwmPlannedCompleteDate, "DD-MMM-YYYY"),
                "pipe5IwmWorkingDaysPerWeek": record.pipe5.iwmWorkingDaysPerWeek,
                //
                "pipe5M300ProductionWeldCount": record.pipe5.m300ProductionWeldCount,
                "pipe5M300TrainingWeldAllowance": record.pipe5.m300TrainingWeldAllowance,
                "pipe5M300WPQWelds": record.pipe5.m300WPQWelds,
                "pipe5M300AverageWeldsPerDay": record.pipe5.m300AverageWeldsPerDay,
                "pipe5M300PlanStartDate": moment(record.pipe5.m300PlanStartDate, "DD-MMM-YYYY"),
                "pipe5M300PlannedCompleteDate": moment(record.pipe5.m300PlannedCompleteDate, "DD-MMM-YYYY"),
                "pipe5M300WorkingDaysPerWeek": record.pipe5.m300WorkingDaysPerWeek,
                "report": record.report,
            };
            const pipeDetails = record.pipeLineData.map(item => ({
                pipeDiameter: item.pipeDiameter,
                wallThickness: item.wallThickness,
                wallThicknessUnit: item.wallThicknessUnit,
                bevelDesign: item.bevelDesign,
            }));
            setPipelineData(pipeDetails)
            setNumberOfPipelines(record.numberOfPipelines)
            projectDetailForm.setFieldsValue(editData)
            setEditRecord(record)
            setIsEdit(true)
            setShowGenerateModel(true)
        }

    }

    /** Display Confirmation while deleting */
    const showDeleteModal = (record) => {
        console.log("record", record.projectName);

        if (typeof record.projectName === 'string') {
            setSelectedData(record.projectName);
        } else {
            setSelectedData(JSON.stringify(record.projectName));
        }

        setIsDeleteModalVisible(true);
    };


    /** Deleting Spare parts data on confirm */
    const handleDeleteOk = async () => {
        // await onDeleteSpareparts({ data: selectedData })

    }

    /** Cancelling the deletion of spare parts data */
    const handleDeleteCancel = () => {
        setIsDeleteModalVisible(false);
        setSelectedData(null);
    }


    const onReadParts = async (record) => {
        if (record) {
            let editData = {
                "proposalNumber": record.proposalNumber,
                "revisionNumber": record.revisionNumber,
                "date": moment(record.date, "DD-MMM-YYYY"),
                "projectName": record.projectName,
                "crcEvansWbsProject": record.crcEvansWbsProject,
                "contractor": record.contractor,
                "designStandard": record.designStandard,
                "clientSpecification": record.clientSpecification,
                "clientShippingAddress": record.clientShippingAddress,
                "technician": record.technician,
                "projectWeldEngineer": record.projectWeldEngineer,
                "crcEvansWbsWpq": record.crcEvansWbsWpq,
                "wpqLocation": record.wpqLocation,
                "NumberOfPipes": record.NumberOfPipes,
                "wpqStartDate": moment(record.wpqStartDate, "DD-MMM-YYYY"),
                'numberOfIWMHeads': record.numberOfIWMHeads,
                "P600TorchSplitDistance": record.P600TorchSplitDistance,
                "IWMTransferEfficiency": record.IWMTransferEfficiency,
                "rootMethod": record.rootMethod,
                "compoundBevel": record.compoundBevel,
                "transferEfficiency": record.transferEfficiency,
                "arcTimeEfficiency": record.arcTimeEfficiency,
                //pipe1
                "pipe1PipeDiameter": record.pipe1.pipeDiameter,
                "pipe1WallThickness": record.pipe1.wallThickness,
                "pipe1BevelDesign": record.pipe1.bevelDesign,
                "pipe1WallThicknessUnit": record.pipe1.wallThicknessUnit,
                //
                "pipe1IwmProductionWeldCount": record.pipe1.iwmProductionWeldCount,
                "pipe1IwmTrainingWeldAllowance": record.pipe1.iwmTrainingWeldAllowance,
                "pipe1IwmWPQWelds": record.pipe1.iwmWPQWelds,
                "pipe1IwmAverageWeldsPerDay": record.pipe1.iwmAverageWeldsPerDay,
                "pipe1IwmPlanStartDate": moment(record.pipe1.iwmPlanStartDate, "DD-MMM-YYYY"),
                "pipe1IwmPlannedCompleteDate": moment(record.pipe1.iwmPlannedCompleteDate, "DD-MMM-YYYY"),
                "pipe1IwmWorkingDaysPerWeek": record.pipe1.iwmWorkingDaysPerWeek,
                //
                "pipe1M300ProductionWeldCount": record.pipe1.m300ProductionWeldCount,
                "pipe1M300TrainingWeldAllowance": record.pipe1.m300TrainingWeldAllowance,
                "pipe1M300WPQWelds": record.pipe1.m300WPQWelds,
                "pipe1M300AverageWeldsPerDay": record.pipe1.m300AverageWeldsPerDay,
                "pipe1M300PlanStartDate": moment(record.pipe1.m300PlanStartDate, "DD-MMM-YYYY"),
                "pipe1M300PlannedCompleteDate": moment(record.pipe1.m300PlannedCompleteDate, "DD-MMM-YYYY"),
                "pipe1M300WorkingDaysPerWeek": record.pipe1.m300WorkingDaysPerWeek,
                // Pipe 2
                "pipe2PipeDiameter": record.pipe2.pipeDiameter,
                "pipe2WallThickness": record.pipe2.wallThickness,
                "pipe2BevelDesign": record.pipe2.bevelDesign,
                "pipe2WallThicknessUnit": record.pipe2.wallThicknessUnit,
                //
                "pipe2IwmProductionWeldCount": record.pipe2.iwmProductionWeldCount,
                "pipe2IwmTrainingWeldAllowance": record.pipe2.iwmTrainingWeldAllowance,
                "pipe2IwmWPQWelds": record.pipe2.iwmWPQWelds,
                "pipe2IwmAverageWeldsPerDay": record.pipe2.iwmAverageWeldsPerDay,
                "pipe2IwmPlanStartDate": moment(record.pipe2.iwmPlanStartDate, "DD-MMM-YYYY"),
                "pipe2IwmPlannedCompleteDate": moment(record.pipe2.iwmPlannedCompleteDate, "DD-MMM-YYYY"),
                "pipe2IwmWorkingDaysPerWeek": record.pipe2.iwmWorkingDaysPerWeek,
                //
                "pipe2M300ProductionWeldCount": record.pipe2.m300ProductionWeldCount,
                "pipe2M300TrainingWeldAllowance": record.pipe2.m300TrainingWeldAllowance,
                "pipe2M300WPQWelds": record.pipe2.m300WPQWelds,
                "pipe2M300AverageWeldsPerDay": record.pipe2.m300AverageWeldsPerDay,
                "pipe2M300PlanStartDate": moment(record.pipe2.m300PlanStartDate, "DD-MMM-YYYY"),
                "pipe2M300PlannedCompleteDate": moment(record.pipe2.m300PlannedCompleteDate, "DD-MMM-YYYY"),
                "pipe2M300WorkingDaysPerWeek": record.pipe2.m300WorkingDaysPerWeek,

                // Pipe 3
                "pipe3PipeDiameter": record.pipe3.pipeDiameter,
                "pipe3WallThickness": record.pipe3.wallThickness,
                "pipe3BevelDesign": record.pipe3.bevelDesign,
                "pipe3WallThicknessUnit": record.pipe3.wallThicknessUnit,
                //
                "pipe3IwmProductionWeldCount": record.pipe3.iwmProductionWeldCount,
                "pipe3IwmTrainingWeldAllowance": record.pipe3.iwmTrainingWeldAllowance,
                "pipe3IwmWPQWelds": record.pipe3.iwmWPQWelds,
                "pipe3IwmAverageWeldsPerDay": record.pipe3.iwmAverageWeldsPerDay,
                "pipe3IwmPlanStartDate": moment(record.pipe3.iwmPlanStartDate, "DD-MMM-YYYY"),
                "pipe3IwmPlannedCompleteDate": moment(record.pipe3.iwmPlannedCompleteDate, "DD-MMM-YYYY"),
                "pipe3IwmWorkingDaysPerWeek": record.pipe3.iwmWorkingDaysPerWeek,
                //
                "pipe3M300ProductionWeldCount": record.pipe3.m300ProductionWeldCount,
                "pipe3M300TrainingWeldAllowance": record.pipe3.m300TrainingWeldAllowance,
                "pipe3M300WPQWelds": record.pipe3.m300WPQWelds,
                "pipe3M300AverageWeldsPerDay": record.pipe3.m300AverageWeldsPerDay,
                "pipe3M300PlanStartDate": moment(record.pipe3.m300PlanStartDate, "DD-MMM-YYYY"),
                "pipe3M300PlannedCompleteDate": moment(record.pipe3.m300PlannedCompleteDate, "DD-MMM-YYYY"),
                "pipe3M300WorkingDaysPerWeek": record.pipe3.m300WorkingDaysPerWeek,
                // Pipe 4
                "pipe4PipeDiameter": record.pipe4.pipeDiameter,
                "pipe4WallThickness": record.pipe4.wallThickness,
                "pipe4BevelDesign": record.pipe4.bevelDesign,
                "pipe4WallThicknessUnit": record.pipe4.wallThicknessUnit,
                //
                "pipe4IwmProductionWeldCount": record.pipe4.iwmProductionWeldCount,
                "pipe4IwmTrainingWeldAllowance": record.pipe4.iwmTrainingWeldAllowance,
                "pipe4IwmWPQWelds": record.pipe4.iwmWPQWelds,
                "pipe4IwmAverageWeldsPerDay": record.pipe4.iwmAverageWeldsPerDay,
                "pipe4IwmPlanStartDate": moment(record.pipe4.iwmPlanStartDate, "DD-MMM-YYYY"),
                "pipe4IwmPlannedCompleteDate": moment(record.pipe4.iwmPlannedCompleteDate, "DD-MMM-YYYY"),
                "pipe4IwmWorkingDaysPerWeek": record.pipe4.iwmWorkingDaysPerWeek,
                //
                "pipe4M300ProductionWeldCount": record.pipe4.m300ProductionWeldCount,
                "pipe4M300TrainingWeldAllowance": record.pipe4.m300TrainingWeldAllowance,
                "pipe4M300WPQWelds": record.pipe4.m300WPQWelds,
                "pipe4M300AverageWeldsPerDay": record.pipe4.m300AverageWeldsPerDay,
                "pipe4M300PlanStartDate": moment(record.pipe4.m300PlanStartDate, "DD-MMM-YYYY"),
                "pipe4M300PlannedCompleteDate": moment(record.pipe4.m300PlannedCompleteDate, "DD-MMM-YYYY"),
                "pipe4M300WorkingDaysPerWeek": record.pipe4.m300WorkingDaysPerWeek,
                // Pipe 5
                "pipe5PipeDiameter": record.pipe5.pipeDiameter,
                "pipe5WallThickness": record.pipe5.wallThickness,
                "pipe5BevelDesign": record.pipe5.bevelDesign,
                "pipe5WallThicknessUnit": record.pipe5.wallThicknessUnit,
                //
                "pipe5IwmProductionWeldCount": record.pipe5.iwmProductionWeldCount,
                "pipe5IwmTrainingWeldAllowance": record.pipe5.iwmTrainingWeldAllowance,
                "pipe5IwmWPQWelds": record.pipe5.iwmWPQWelds,
                "pipe5IwmAverageWeldsPerDay": record.pipe5.iwmAverageWeldsPerDay,
                "pipe5IwmPlanStartDate": moment(record.pipe5.iwmPlanStartDate, "DD-MMM-YYYY"),
                "pipe5IwmPlannedCompleteDate": moment(record.pipe5.iwmPlannedCompleteDate, "DD-MMM-YYYY"),
                "pipe5IwmWorkingDaysPerWeek": record.pipe5.iwmWorkingDaysPerWeek,
                //
                "pipe5M300ProductionWeldCount": record.pipe5.m300ProductionWeldCount,
                "pipe5M300TrainingWeldAllowance": record.pipe5.m300TrainingWeldAllowance,
                "pipe5M300WPQWelds": record.pipe5.m300WPQWelds,
                "pipe5M300AverageWeldsPerDay": record.pipe5.m300AverageWeldsPerDay,
                "pipe5M300PlanStartDate": moment(record.pipe5.m300PlanStartDate, "DD-MMM-YYYY"),
                "pipe5M300PlannedCompleteDate": moment(record.pipe5.m300PlannedCompleteDate, "DD-MMM-YYYY"),
                "pipe5M300WorkingDaysPerWeek": record.pipe5.m300WorkingDaysPerWeek,
                "report": record.report,
            };
            const pipeDetails = record.pipeLineData.map(item => ({
                pipeDiameter: item.pipeDiameter,
                wallThickness: item.wallThickness,
                wallThicknessUnit: item.wallThicknessUnit,
                bevelDesign: item.bevelDesign,
            }));
            setPipelineData(pipeDetails)
            setNumberOfPipelines(record.numberOfPipelines)
            projectDetailForm.setFieldsValue(editData)
            setProjectDetails(editData);
            setEditRecord(record)
            setIsEdit(true)
            setShowGenerateModel(false)
            setIsRead(true)
        }
    }



    const [searchCriteria, setSearchCriteria] = useState({
        "proposalNumber": "",
        "revisionNumber": '',
        "date": "",
        "projectName": "",
        "crcEvansWbsProject": "",
        "contractor": "",
        "designStandard": "",
        "clientSpecification": "",
        "clientShippingAddress": "",
        "technician": "",
        "projectWeldEngineer": "",
        "crcEvansWbsWpq": '',
        "wpqLocation": '',
        "NumberOfPipes": '',
        "wpqStartDate": '',
        'numberOfIWMHeads': '',
        "P600TorchSplitDistance": '',
        "IWMTransferEfficiency": '',
        "rootMethod": '',
        "compoundBevel": '',
        "transferEfficiency": '',
        "arcTimeEfficiency": '',
        // "creationDate": '',
        // "updatedDate": ''
    });

    function hasNonEmptyTag(jsonObj) {
        for (const key in jsonObj) {
            if (jsonObj.hasOwnProperty(key)) {
                const value = jsonObj[key];
                //console.log(value)

                if (value.trim() !== '') {
                    return true;

                }
            }
        }
        return false;
    }

    useEffect(() => {

        const isValid = hasNonEmptyTag(searchCriteria)
        let obj = {
            pageSize: isValid ? 10 : currentPagesize,
            pageNumber: isValid ? 1 : currentPageNumber,
            searchKey: searchCriteria

        };

    }, [searchCriteria]);

    const handleSearchInputChange = (fieldName, value) => {
        setSearchCriteria(prevSearchCriteria => ({
            ...prevSearchCriteria,
            [fieldName]: value
        }));
    };

    const handleSearchDateSelect = (fieldName, date) => {
        const formattedDate = date ? moment(date).format("DD-MMM-YYYY") : '';
        handleSearchInputChange(fieldName, formattedDate);
    };

    const searchInputs = [
        { field: 'proposalNumber', placeholder: 'Search Proposal Number' },
        { field: 'revisionNumber', placeholder: 'Search Revision Number' },
        { field: 'date', placeholder: 'Search Date' },
        { field: 'projectName', placeholder: 'Search Project Name' },
        { field: 'crcEvansWbsProject', placeholder: 'Search CRC-Evans WBS (Project)' },
        { field: 'contractor', placeholder: 'Search Contractor' },
        { field: 'designStandard', placeholder: 'Search Design Standard' },
        { field: 'clientSpecification', placeholder: 'Search Client Specification' },
        { field: 'clientShippingAddress', placeholder: 'Search Client Shipping Address' },
        { field: 'technician', placeholder: 'Search Technician' },
        { field: 'projectWeldEngineer', placeholder: 'Search Project Weld Engineer' },
        { field: 'crcEvansWbsWpq', placeholder: 'Search CRC-Evans WBS (WPQ)' },
        { field: 'wpqLocation', placeholder: 'Search WPQ Location' },
        { field: 'numberOfIWMHeads', placeholder: 'Search Number of IWM Heads' },
        { field: 'P600TorchSplitDistance', placeholder: 'Search P600 Torch Split Distance' },
        { field: 'IWMTransferEfficiency', placeholder: 'Search IWM Transfer Efficiency' },
        { field: 'rootMethod', placeholder: 'Search Root Method' },
        { field: 'compoundBevel', placeholder: 'Search Compound Bevel' },
        { field: 'transferEfficiency', placeholder: 'Search Transfer Efficiency' },
        { field: 'arcTimeEfficiency', placeholder: 'Search Arc Time Efficiency' },
        { field: 'wpqStartDate', placeholder: 'Search WPQ Start Date' },
        { field: 'numberOfPipes', placeholder: 'Search No. of Pipes' },
    ];


    const updatedColumns = columns.map(column => {
        if (column.dataIndex === 'creationDate' || column.dataIndex === 'updatedDate' || column.dataIndex === 'wpqStartDate') {
            return {
                ...column,
                title: (
                    <Space style={{ display: 'flow', alignItems: 'center', }}>
                        {column.title}
                        <div className='date-picker'>
                            <DatePicker
                                allowClear
                                style={{ height: '30px', border: 'line', }}
                                format="DD-MMM-YYYY"
                                placeholder='Search'
                                onChange={date => handleSearchDateSelect(column.dataIndex, date)}
                                bordered
                            />
                        </div>
                    </Space>
                ),
            };
        } else if (column.dataIndex !== 'edit_delete' && column.dataIndex !== 'report') {
            return {
                ...column,
                title: (
                    <Space style={{ display: 'flow', alignItems: 'center', }}>
                        {column.title}
                        <div className='searchEquip'>
                            <Input
                                placeholder={searchInputs.find(input => input.field === column.dataIndex)?.placeholder}
                                value={searchCriteria[column.dataIndex]}
                                onChange={e => handleSearchInputChange(column.dataIndex, e.target.value)}
                                allowClear
                            />
                        </div>
                    </Space>
                ),
            };
        } else {
            return column;
        }
    });

    const showConfigModal = () => {
        setIsConfigModalVisible(true);
    }


    return (
        <div className="d-flex flex-column h-100">
            {!isConfigModalVisible ? <div className="flex-auto">
                {!isRead && <div className="flex-auto">
                    <div className="d-flex mb-3 align-items-center tab-content-title">
                        <div className='back_equip' >
                            <Tooltip title={'Back'} >
                                <ArrowLeftOutlined onClick={(e) => {
                                    history.push('/SmartLync-HomePage');
                                }} />
                            </Tooltip>
                        </div>

                        <Title className="mb-0 add-new-calibration" level={3} style={{ paddingLeft: '30px' }}>
                            Welding Wire Calculator List
                        </Title>

                        <div className="new-calibration">
                            <Button shape="round" key="apply" type="primary"
                                onClick={showConfigModal}
                            >
                                Configuration
                            </Button>
                            <Text onClick={() => setShowGenerateModel(true)} className="ps-3 add-new-calibration" style={{}}>
                                <span className="icon-22" style={{ scale: '20px' }}>
                                    <svg>
                                        <use xlinkHref="#add" />
                                    </svg>
                                </span>
                                Create Welding Wire
                            </Text>
                        </div>
                    </div>

                    <div className="custom-line" />

                    <div className='addTeamDetails'>
                        <Table columns={updatedColumns}

                            className="h-100 pipeline-table equip_table equipPaginationTable pricing-fixed-Table"
                            scroll={{ x: 4050, y: 'calc(100vh - 200px)' }}
                            rowKey={'projectID'}
                            pagination={
                                totalRows > 10 ? {
                                    position: ['bottomRight'],
                                    className: 'fixed-pagination',
                                    showSizeChanger: true,
                                    total: totalRows,
                                    defaultPageSize: 10,
                                    defaultCurrent: 1,
                                    pageSize: currentPagesize,
                                    onChange: async (pageNumber, pageSize) => {
                                        setCurrentPageNumber(pageNumber);
                                        setCurrentPagesize(pageSize);
                                        var obj = {
                                            pageSize: pageSize,
                                            pageNumber: pageNumber,
                                            searchKey: searchCriteria
                                        }
                                        // await onFetchSparePartsData(obj)

                                    }
                                }
                                    : false
                            }
                            dataSource={[...data]}
                        // loading={isSpartPartsLoading}
                        />

                    </div>
                </div>}
                {showGenerateModel &&
                    <WeldingWireForm
                        setShowGenerateModel={setShowGenerateModel}
                        showGenerateModel={showGenerateModel}
                        isEdit={isEdit}
                        setIsEdit={setIsEdit}
                        projectDetailForm={projectDetailForm}
                        pipelineData={pipelineData}
                        setPipelineData={setPipelineData}
                        numberOfPipelines={numberOfPipelines}
                        setNumberOfPipelines={setNumberOfPipelines}
                        setWeldData={setWeldData}
                    />
                }

                {isRead &&
                    <WeldingWireProjectDetails
                        showPipelineModel={showPipelineModel}
                        setShowPipelineModel={setShowPipelineModel}
                        editRecord={editRecord}
                        setProjectDetails={setProjectDetails}
                        projectDetails={projectDetails}
                        isRead={isRead}
                        setIsRead={setIsRead}
                    />
                }


                <Modal
                    width="45vw"
                    className="pipeline-modal"
                    title="Delete Spare part Details"
                    centered
                    visible={isDeleteModalVisible}
                    footer={[
                        <Button key="cancel" onClick={handleDeleteCancel}>
                            Cancel
                        </Button>,
                        <Button key="apply" type="primary" onClick={handleDeleteOk}>
                            Delete
                        </Button>,
                        {/* <Button key="apply" type="primary" onClick={handleDeleteOk} loading={isDeleteLoading}>
                            {isDeleteLoading ? 'Deleting' : 'Delete'}
                        </Button> */}
                    ]}
                >
                    <div>
                        <Text style={{ fontSize: '16px' }}>
                            <p>Deleting welding wire parts Details will delete all the corresponding pipe line and related information.</p>
                            Are you sure you want to delete <b style={{ color: "#EF314C" }}>{selectedData}</b> welding wire?</Text>
                    </div>
                </Modal>

            </div>
                :
                <ConfigWeldWire
                    setIsConfigModalVisible={setIsConfigModalVisible}
                />
            }
        </div>
    )
}

export default WeldingWireCalculator