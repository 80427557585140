/* eslint-disable no-param-reassign */
/* eslint-disable react/no-unescaped-entities */
import { ArrowLeftOutlined, CloudUploadOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import {
    Button, Form,
    Input,
    Modal,
    Space,
    Table,
    Tabs,
    Tooltip,
    Typography,
    Upload, notification,DatePicker
} from 'antd';
import ExcelJS from 'exceljs';
import { get } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import * as XLSX from 'xlsx';
import history from '../../services/history';




const { Title, Text } = Typography;
//const { TabPane } = Tabs;
//const { Column } = Table;
//const { Search } = Input;

//const { Option } = Select;
const TrainingManagement = (props) => {

    const {

    } = props;

    const dataSource = [
        {
            key: '1',
            EmpolyeeName: 'Sella',
            TrainingName: 'Raja',
            Status: 'Progress',
            DueDate: '2024-05-21',
            CompletionDate: '2024-05-25',
          
            // Add other properties as needed
        },
        {
            key: '2',
            EmpolyeeName: 'Sella',
            TrainingName: 'Raja',
            Status: 'Progress',
            DueDate: '2024-05-21',
            CompletionDate: '2024-05-25',
          
            // Add other properties as needed
        },
    ]

    const [searchText, setSearchText] = useState({});
    const [searchedColumn, setSearchedColumn] = useState('');

    const [TrainingUploadForm] = Form.useForm()
    const [addNewForm] = Form.useForm()
    const [disableFileUpload, setDisableFileUpload] = useState(false);
    const [fileList, setFileList] = useState([]);
    const [bulkUplaodModal, setBulkUplaodModal] = useState(false);
    const [databaseList, setDatabaseList] = useState([]);
    const [showAddNewModal, setShowAddNewModal] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [editRecord, seteditRecord] = useState('');
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
    const [selectedData, setSelectedData] = useState('');

    const columns = [
        {
            title: 'Employee Name',
            dataIndex: 'EmpolyeeName',
            key: 'EmpolyeeName',
            align: 'center',

        },
        {
            title: 'Training Name',
            dataIndex: 'TrainingName',
            key: 'TrainingName',
            align: 'center',

        },

        {
            title: 'Status',
            dataIndex: 'Status',
            key: 'Status',
            align: 'center'
        },
        {
            title: 'Due Date',
            dataIndex: 'DueDate',
            key: 'DueDate',
            align: 'center',
            render: (text) => {
                return (
                    <div>{text ? moment(text).format('DD MMM YYYY') : '-'}</div>
                );
            },
            // sorter: (a, b) => moment(a.File_Creation_Date).valueOf() - moment(b.File_Creation_Date).valueOf()
        },
        {
            title: 'Completion Date',
            dataIndex: 'CompletionDate',
            key: 'CompletionDate',
            align: 'center',
            render: (text) => {
                return (
                    <div>{text ? moment(text).format('DD MMM YYYY') : '-'}</div>
                );
            },
            // sorter: (a, b) => a.Expiration_Date - b.Expiration_Date,
            //sorter: (a, b) => moment(a.Expiration_Date).valueOf() - moment(b.Expiration_Date).valueOf()
        },


        {
            title: 'Actions',
            key: 'action',
            dataIndex: 'action',
            render: (text, record) => {
                return (
                    <div>
                        <Tooltip title="Update">
                            <span className="icon-18 mr-2 cursor-pointer edit-hover">
                                <EditOutlined style={{ fontSize: '18px' }} onClick={() => onUpdateClick(record)} />
                            </span>
                        </Tooltip>
                        <Tooltip title="Delete">
                            <span className="icon-18 mr-2 cursor-pointer edit-hover" style={{ marginLeft: '20px' }}>
                                <DeleteOutlined style={{ fontSize: '18px' }} onClick={() => showDeleteModal(record)} />
                            </span>
                        </Tooltip>
                    </div>
                );
            },
            align: 'center'
        }
    ];

    const onUpdateClick = (record) => {
        // console.log(record)
        if (record) {

            const editData = {
                'partNumber': record.partNumber,
                'description': record.description,
                'quantity_5000_Welds': record.quantity_5000_Welds,
                'category': record.category,
                'multiply_Item': record.multiply_Item,
                'cost': record.cost,
                'where_Used': record.where_Used,
                'UOM': record.UOM,
                'alternate_Parts': record.alternate_Parts,
            }
            addNewForm.setFieldsValue(editData)

            seteditRecord(record)
            setIsEdit(true)
            setShowAddNewModal(true)
        }

    }
    // const onDeleteClick = async (record) => {
    //     //console.log(record)
    //     if (record) {
    //         await OnDeleteMasterList({ data: record })
    //     }

    // }

    /** Display Confirmation while deleting */
    const showDeleteModal = (record) => {
        setIsDeleteModalVisible(true);
        setSelectedData(record)
    };
    /** Deleting master Spare parts data on confirm */
    const handleDeleteOk = async () => {
        //  if (selectedData)
        // await OnDeleteMasterList({ data: selectedData })

    }
    /** Cancelling the deletion of spare parts data */
    const handleDeleteCancel = () => {
        setIsDeleteModalVisible(false);
        setSelectedData('');
    }




    const fileProps = {
        onRemove: (file) => {
            const index = fileList.indexOf(file);
            const newFileList = fileList.slice();
            newFileList.splice(index, 1);
            setFileList(newFileList);
            setDisableFileUpload(false);
        },
        beforeUpload: (file) => {

            setFileList([file]);
            setDisableFileUpload(true);
            return false;
        },
        fileList,
    };
    const handleCancelUploadFile = () => {
        setBulkUplaodModal(false);
    }

    useEffect(() => {

        if (databaseList && databaseList.length > 0) {
            //console.log('Master Databse List', databaseList)
            const workbook = new ExcelJS.Workbook();
            const masterstationSheet = workbook.addWorksheet('Spare parts');
            const headerRow = masterstationSheet.getRow(1);
            headerRow.height = 20;



            masterstationSheet.columns = [
                { header: 'ID', key: 'ID', width: 5 },
                { header: 'Part Number', key: 'PART NUMBER', width: 10 },
                { header: 'Description', key: 'DESCRIPTION', width: 20 },
                { header: 'Qty/5000 Welds', key: 'QTY/5000 WELDS', width: 20 },
                { header: 'Category', key: 'CATEGORY', width: 20 },
                { header: 'Multiply Item', key: 'MULTIPLY ITEM', width: 20 },
                { header: 'Cost', key: 'COST', width: 20 },
                { header: 'Where Used', key: 'WHERE USED', width: 20 },
                { header: 'UOM', key: 'UOM', width: 20 },
                { header: 'Alternate Parts', key: 'ALTERNATE PARTS', width: 20 },
            ];

            headerRow.eachCell((cell) => {
                cell.font = { bold: true };
            });

            (async () => {

                const ws = workbook.getWorksheet('Spare parts');

                // Add data to the worksheet
                ws.addRows(databaseList);

                // Convert to buffer
                workbook.xlsx.writeBuffer().then((buffer) => {
                    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                    const url = URL.createObjectURL(blob);
                    const link = document.createElement('a');
                    link.href = url;
                    link.download = 'Master_Spare_Parts_List.xlsx';
                    link.click();
                });
            })();

        }
        setDatabaseList([])

    }, [databaseList])
    const handleDownloadTemplate = async () => {


    };

    const ExcelDateToJSDate = (date) => {
        const convertedDate = new Date(Math.round((date - 25569) * 864e5));
        return String(convertedDate).slice(4, 15);
    };


    const handleUploadFile = async () => {
        if (fileList.length > 0) {
            const file = fileList[0];

            if (file.type === 'application/vnd.ms-excel' || file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
                const reader = new FileReader();

                reader.onload = async (event) => {
                    try {
                        const data = new Uint8Array(event.target.result);
                        const workbook = XLSX.read(data, { type: 'array' });

                        const sheetName = workbook.SheetNames[0];
                        const sheet = workbook.Sheets[sheetName];

                        const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });
                        if (jsonData[1].length === 0) {
                            notification.error({ message: 'Invalid file. Please select a valid file.' });
                            return;
                            //throw new Error('Invalid file. Please select a valid file.');
                        }
                        //console.log(jsonData)
                        //const headers = jsonData[0];

                        const keyMapping = {
                            'ID': 'ID',
                            'Part Number': 'partNumber',
                            'Description': 'description',
                            'Qty/5000 Welds': 'quantity_5000_Welds',
                            'Category': 'category',
                            'Multiply Item': 'multiply_Item',
                            'Cost': 'cost',
                            'Where Used': 'where_Used',
                            'UOM': 'UOM',
                            'Alternate Parts': 'alternate_Parts',

                        };


                        const headers = jsonData[0];

                        const allHeadersAvailable = Object.keys(keyMapping)
                            .filter(key => key !== 'ID') // Exclude 'ID' key
                            .every(header => headers.includes(header));
                        console.log(allHeadersAvailable);

                        if (!allHeadersAvailable) {
                            notification.error({ message: 'Invalid header file. Please select a valid file.' });
                            return;
                            //throw new Error('Invalid file. Please select a valid file.');
                        }
                        const formattedData = jsonData.slice(1).map(row => {
                            const rowData = {};
                            headers.forEach((header, index) => {
                                const mappedKey = keyMapping[header]
                                const value = row[index];
                                if (mappedKey !== undefined) {
                                    rowData[mappedKey] = value !== undefined ? value : ''; // Set value to empty string if undefined
                                }
                            });
                            return rowData;
                        });

                        setConfirmLoading(true)
                        // await onUploaMasterSpareparts({ data: formattedData })
                        // Send the formatted data to your API

                        //setTimeout(UpdateUplaodmastrList, 1000)


                    } catch (error) {
                        notification.error({ message: 'Error parsing Excel file. Please select a valid file.' });
                    }
                };

                reader.readAsArrayBuffer(file);
            } else {
                notification.warn({ message: 'Please select a valid Excel file (XLS or XLSX) to upload.' });
            }
        } else {
            notification.warn({ message: 'Please select a file to upload.' });
        }
    };
    const showBulkUploadModal = () => {

        setBulkUplaodModal(true);
    }
    const [searchCriteria, setSearchCriteria] = useState({

        'partNumber': '',
        'description': '',
        'quantity_5000_Welds': '',
        'category': '',
        'multiply_Item': '',
        'where_Used': '',
        'cost': '',
        'UOM': '',
        'alternate_Parts': '',
    });
    useEffect(() => {

        var obj = {
            pageSize: 10,
            pageNumber: 1,
            searchKey: searchCriteria
        }

        //onFetchSparePartsMasterData(obj)

    }, [searchCriteria]);
    const handleSearchInputChange = (fieldName, value) => {
        setSearchCriteria(prevSearchCriteria => ({
            ...prevSearchCriteria,
            [fieldName]: value
        }));
    };
    const handleSearchDateSelect = (fieldName, date) => {
        const formattedDate = date ? moment(date).format('YYYY-MM-DD') : '';
        handleSearchInputChange(fieldName, formattedDate);
    };

    const updatedColumns = columns.map(column => {
        if (column.dataIndex === 'CompletionDate' || column.dataIndex === 'DueDate') {
            return {
                ...column,
                title: (
                    <Space style={{ display: 'flow', alignItems: 'center', width: '100%' }}>
                        {column.title}
                        <DatePicker
                            allowClear
                            style={{ height: '28px', border: 'line', width: '100%' }}
                            format="DD-MM-YYYY"
                            placeholder='Search'
                            onChange={date => handleSearchDateSelect(column.dataIndex, date)}
                            bordered
                        />
                    </Space>
                ),
            };
        } else if (column.dataIndex !== 'action') {
            return {
                ...column,
                title: (
                    <Space style={{ display: 'flow', alignItems: 'center', width: '100%' }}>
                        {column.title}
                        <div className='searchEquip'>
                            <Input
                                placeholder='Search'
                                value={searchCriteria[column.dataIndex]}
                                onChange={e => handleSearchInputChange(column.dataIndex, e.target.value)}
                                allowClear
                            />
                        </div>
                    </Space>
                ),
            };
        } else {
            return column;
        }
    });

    return (

        <div>
            <div className="d-flex mb-3 align-items-center">
                <div className='back_equip' >
                    <Tooltip title={'Back'} >
                        <ArrowLeftOutlined onClick={() => {
                            history.push('/SmartLync-HomePage');
                        }} />
                    </Tooltip>
                </div>

                <Title className="mb-0 add-new-calibration" level={3} style={{ paddingLeft: '30px' }}>
                    Training Management
                </Title>

                <div className="new-calibration">



                    {/* <Text onClick={() => setShowAddNewModal(true)} className="ps-3 add-new-calibration" >
                        <span className="icon-22" style={{ scale: '20px' }}>
                            <svg>
                                <use xlinkHref="#add" />
                            </svg>
                        </span>
                        Add New
                    </Text> */}


                    <Text onClick={showBulkUploadModal} className="ps-3 add-new-calibration" >
                        <span className="icon-22" style={{ scale: '20px' }}>
                            <svg>
                                <use xlinkHref="#add" />
                            </svg>
                        </span>
                        Bulk Upload
                    </Text>
                </div>

            </div>
            <div className="custom-line" />
            <div className='addTeamDetails' >
                <Table
                    className="h-100 pipeline-table equip_table equipPaginationTable"
                    scroll={{ y: 'calc(100vh - 230px)' }}
                    // pagination={
                    //     sparePartsMasterListCount > 10 ? {
                    //         position: ['bottomRight'],
                    //         showSizeChanger: true,
                    //         total: sparePartsMasterListCount,
                    //         defaultPageSize: 10,
                    //         defaultCurrent: 1,

                    //         onChange: async (pageNumber, pageSize) => {
                    //             // console.log(licenseTotalCount, pageNumber, pageSize)
                    //             var obj = {
                    //                 pageSize: pageSize,
                    //                 pageNumber: pageNumber
                    //             }
                    //           //  await onFetchSparePartsMasterData(obj)

                    //         }
                    //     }
                    //         : false
                    // }


                    dataSource={[...dataSource]}
                    //loading={isSpartPartsMasterLoading}
                    columns={updatedColumns}

                >

                </Table>
            </div>
            <Modal
                className="pipeline-modal"
                title='Upload Training Details'
                centered
                visible={bulkUplaodModal}
                footer={[
                    <Button key="cancel" onClick={handleCancelUploadFile}>
                        {'Cancel'}
                    </Button>,
                    <Button key="apply" type="primary"
                    // onClick={handleUploadFile2} loading={isDatabaseMasterLoading}
                    >
                        {'Upload'}
                    </Button>
                ]}
            >
                <div className='uploadFile_admin'>
                    <Upload {...fileProps} maxCount={1} accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" >
                        <Button disabled={disableFileUpload} icon={<CloudUploadOutlined className='icon-22' />}>Select File</Button>
                    </Upload>
                </div>
            </Modal>


            <Modal
                width="45vw"
                className="pipeline-modal"
                title="Delete Training Details"
                centered
                visible={isDeleteModalVisible}
                footer={[
                    <Button key="cancel" onClick={handleDeleteCancel}>
                        Cancel
                    </Button>,
                    <Button key="apply" type="primary" onClick={handleDeleteOk} >
                        {'Delete'}
                    </Button>
                ]}
            >
                {/* <div>
                    <Text style={{ fontSize: '16px' }}>
                        <p>Deleting Master Spare Parts Details will delete all the corresponding  related information.</p>
                        Are you sure you want to delete <b style={{ color: "#EF314C" }}>{selectedData && selectedData.partNumber}</b> part number?</Text>
                </div> */}
            </Modal>

        </div>
    );
};
const mapStateToProps = ({ sparepartsreducer }) => {
    // const databaseMasterList = get(sparepartsreducer, 'DatabaseMasterList', []);
    // const isDatabaseMasterLoading = get(sparepartsreducer, 'isUploadMasterSparts', false);
    // const databaseMasterListCount = get(sparepartsreducer, 'DatabaseMasterListCount', null);

    // const sparePartsMasterData = get(sparepartsreducer, 'SparePartsMasterListPage', []);
    // const isSpartPartsMasterLoading = get(sparepartsreducer, 'isMasterListPageLoading', false);
    // const sparePartsMasterListCount = get(sparepartsreducer, 'SparePartsMasterListPageCount', null);

    // const isDeleteLoading = get(sparepartsreducer, 'isDeleteMasterLoading', false);
    return {
        // databaseMasterList,
        // isDatabaseMasterLoading,
        // databaseMasterListCount,


        // sparePartsMasterData,
        // isSpartPartsMasterLoading,
        // // isUploadLicsense,
        // sparePartsMasterListCount,
        // isDeleteLoading
    }
}

const mapDispatchToProps = {


}


export default connect(mapStateToProps, mapDispatchToProps)(TrainingManagement);

