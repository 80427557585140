/* eslint-disable no-param-reassign */
/* eslint-disable react/no-unescaped-entities */
import React, { useEffect, useState } from 'react';
import {
    Button, Form, Input, DatePicker, Modal, notification, Upload
} from 'antd';
import moment from 'moment';
import { CloudUploadOutlined } from '@ant-design/icons';
import { addCalibration, updateCalibration } from '../redux/calibration/action';
import { connect } from 'react-redux';
import { get } from 'lodash';

const CalibrationForm = (props) => {

    const {
        setCalibrationFormModal,
        calibrationFormModal,
        onAddCalibrationaction,
        editData,
        calibrationRecord,
        editValue,
        onUpdateCalibration,
        setEditData,
        setCalibrationRecord,
        setEditValue,
        isAddUpdateCalibration,
    } = props;

    const [item, setItem] = useState('');
    const [serialNumber, setSerialNumber] = useState('');
    const [certificateNumber, setCertificateNumber] = useState('');
    const [expiryDate, setExpiryDate] = useState(null);
    const [location, setLocation] = useState('');
    const [fileList, setFileList] = useState([]);

    const [calibrationForm] = Form.useForm()

    useEffect(() => {
        if (!editValue)
            calibrationForm.resetFields()
        else
            calibrationForm.setFieldsValue(editData);
    }, [editValue])

    const generatecalibrationApi = async (obj) => {
        if (editValue) {
            await onUpdateCalibration({ consumableRecord: calibrationRecord, data: obj });
        } else {
            await onAddCalibrationaction({ data: obj });
        }
    }

    const handleOk = async () => {
        calibrationForm
            .validateFields()
            .then((data) => {

                if (data.item && data.serialNumber && data.certificateNumber && data.expiryDate && data.location && (fileList.length > 0 || editValue === true)) {

                    if (fileList.length === 0) {
                        var obj = {
                            "item": data.item,
                            "serialNumber": data.serialNumber,
                            "certificateNumber": data.certificateNumber,
                            "expiryDate": moment(data.expiryDate).format('MM-DD-YYYY'),
                            "location": data.location
                            // "certificate": fileList
                        }
                    } else {
                        var obj = {
                            "item": data.item,
                            "serialNumber": data.serialNumber,
                            "certificateNumber": data.certificateNumber,
                            "expiryDate": moment(data.expiryDate).format('MM-DD-YYYY'),
                            "location": data.location,
                            "certificate": fileList
                        }
                    }

                    generatecalibrationApi(obj)
                    setCalibrationFormModal(false);
                    setEditValue(false);
                    setEditData({})
                    setCalibrationRecord({})
                }
                else {
                    notification.warning({
                        message: 'Select All Required Fields to proceed!'
                    });

                }

            });

    };
    const handleCancel = () => {
        setCalibrationFormModal(false);
        setEditValue(false);
        setEditData({})
        setCalibrationRecord({})
    };

    const fileProps = {
        onRemove: (file) => {
            const index = fileList.indexOf(file);
            const newFileList = fileList.slice();
            newFileList.splice(index, 1);
            setFileList(newFileList);
        },
        beforeUpload: (file) => {
            setFileList([file]);
            return false;
        },
        fileList,
    };

//     useEffect(() => {

//         if(!isAddUpdateCalibration){
// console.log("here.................................................")
//             setCalibrationFormModal(false)
//             setEditValue(false);
//             setEditData({})
//             setCalibrationRecord({})
//         }

// }, [isAddUpdateCalibration])

    return (

        <div className="login-form reset-form">
            <Modal
                width="45vw"
                className="pipeline-modal"
                title="Calibration"
                centered
                open={calibrationFormModal}
                footer={[
                    <Button key="cancel"
                        onClick={handleCancel}
                    >
                        Cancel
                    </Button>,
                    <Button key="apply" type="primary"
                        onClick={handleOk}
                        loading={isAddUpdateCalibration}
                    >
                        {!editValue ? (isAddUpdateCalibration ? 'Adding' : 'Add') : (isAddUpdateCalibration ? 'Updating' : 'Update')}
                    </Button>
                ]}
            >
                <div>
                    <Form
                        form={calibrationForm}
                        name="basic"
                        layout="vertical"
                        autoComplete="off"
                    >
                        <Form.Item
                            name="item"
                            label="Item"
                            required
                        >
                            <Input disabled={editValue} placeholder="Item" style={{ height: '35px' }} value={item} onChange={(e) => setItem(e.target.value)} />
                        </Form.Item>
                        <Form.Item
                            name="serialNumber"
                            label="Serial Number"
                            required
                        >
                            <Input disabled={editValue} placeholder="Enter Serial Number" style={{ height: '35px' }} value={serialNumber} onChange={(e) => setSerialNumber(e.target.value)} />
                        </Form.Item>
                        <Form.Item
                            name="certificateNumber"
                            label="Certificate Number"
                            required
                        >
                            <Input placeholder="Enter Certificate Number" style={{ height: '35px' }} value={certificateNumber} onChange={(e) => setCertificateNumber(e.target.value)} />
                        </Form.Item>
                        <Form.Item
                            className="date-picker"
                            label="Expiry Date"
                            name="expiryDate"
                            required
                        >
                            <DatePicker style={{ width: '250px' }}
                                format="DD-MM-YYYY"
                                onChange={(date, dateString) => setExpiryDate(dateString)}
                                // disabledDate={current => current && current && current < moment().subtract(1, "days")}
                            />
                        </Form.Item>
                        <Form.Item
                            name="location"
                            label="Location"
                            required
                        >
                            <Input placeholder="Enter Location" style={{ height: '35px' }} value={location} onChange={(e) => setLocation(e.target.value)} />
                        </Form.Item>

                        {editValue ?

                            (
                                <Form.Item>
                                    <ul>
                                        {
                                            editData.certificate_history.map((item, i) =>
                                            item.certificateURL?.fileURL ?  
                                               ( <li>
                                                    <a href={item.certificateURL?.fileURL} target='_blank'>Certificate Version {item?.version}</a>
                                                </li>
                                               ) :

                                               <li>No Certificate Uploaded</li>
                                            )
                                        }
                                    </ul>
                                </Form.Item>
                            )
                            : null
                        }

                        <Form.Item
                            name="certificate"
                            label="Upload Certificate"
                            required
                        >
                            <Upload {...fileProps} maxCount={1} accept=".doc,.docx,application/pdf" >
                                <Button icon={<CloudUploadOutlined className='icon-22' />}>Select File</Button>
                            </Upload>
                        </Form.Item>
                    </Form>
                </div>
            </Modal >
        </div>
    );
};


const mapStateToProps = ({ calibrationReducer }) => {
    const isAddUpdateCalibration = get(calibrationReducer, 'isAddUpdateCalibration', false);
    return {
        isAddUpdateCalibration,
    }
}

const mapDispatchToProps = {
    onAddCalibrationaction: addCalibration,
    onUpdateCalibration: updateCalibration,
};

export default connect(mapStateToProps, mapDispatchToProps)(CalibrationForm);

