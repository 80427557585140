import { React, useState, useEffect } from 'react';
import { Modal, Form, Input, Button, Row, Col, DatePicker, InputNumber, Select, notification, Radio, Typography, Space, Divider } from 'antd';
import { ArrowLeftOutlined, CheckOutlined, CloseOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import moment from 'moment';
import TextArea from 'antd/lib/input/TextArea';

const { Option } = Select;

const WeldingWireForm = (props) => {
    const { setShowGenerateModel, showGenerateModel, isEdit, setIsEdit, projectDetailForm, pipelineDetails, pipelineData, setPipelineData, numberOfPipelines, setNumberOfPipelines, setWeldData } = props;

    console.log("pipelineData", pipelineData)
    const [isNextButton, setIsNextButton] = useState(false);
    const [modalWidth, setModalWidth] = useState('70vw');
    useEffect(() => {
        const handleResize = () => {
            console.log("window.innerWidth", window.innerWidth)
            if (window.innerWidth <= 1536) {
                setModalWidth('75vw');
            } else {
                setModalWidth('65vw');
            }
        };

        handleResize();
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const validateForm = (values) => {
        const errors = {};

        for (let i = 1; i <= numberOfPipelines; i++) {
            // Validate Pipe Diameter
            if (!values[`pipe${i}PipeDiameter`]) {
                errors[`pipe${i}PipeDiameter`] = 'Pipe Diameter is required';
            }

            // Validate Wall Thickness
            if (!values[`pipe${i}WallThickness`]) {
                errors[`pipe${i}WallThickness`] = 'Wall Thickness is required';
            }

            // Validate Bevel Design
            if (!values[`pipe${i}BevelDesign`]) {
                errors[`pipe${i}BevelDesign`] = 'Bevel Design is required';
            }

            // Validate IWM Production Weld Count
            if (!values[`pipe${i}IwmProductionWeldCount`]) {
                errors[`pipe${i}IwmProductionWeldCount`] = 'IWM Production Weld Count is required';
            }

            // Validate M300 Production Weld Count
            if (!values[`pipe${i}M300ProductionWeldCount`]) {
                errors[`pipe${i}M300ProductionWeldCount`] = 'M300 Production Weld Count is required';
            }

            // Validate IWM Plan Start Date
            if (!values[`pipe${i}IwmPlanStartDate`]) {
                errors[`pipe${i}IwmPlanStartDate`] = 'IWM Plan Start Date is required';
            }

            // Validate M300 Plan Start Date
            if (!values[`pipe${i}M300PlanStartDate`]) {
                errors[`pipe${i}M300PlanStartDate`] = 'M300 Plan Start Date is required';
            }

            // Add more validations as needed...
        }

        // Cross-field validations
        for (let i = 1; i <= numberOfPipelines; i++) {
            const iwmStartDate = values[`pipe${i}IwmPlanStartDate`];
            const iwmEndDate = values[`pipe${i}IwmPlannedCompleteDate`];
            const m300StartDate = values[`pipe${i}M300PlanStartDate`];
            const m300EndDate = values[`pipe${i}M300PlannedCompleteDate`];

            if (iwmStartDate && iwmEndDate && moment(iwmStartDate).isAfter(iwmEndDate)) {
                errors[`pipe${i}IwmPlannedCompleteDate`] = 'IWM End Date must be after Start Date';
            }

            if (m300StartDate && m300EndDate && moment(m300StartDate).isAfter(m300EndDate)) {
                errors[`pipe${i}M300PlannedCompleteDate`] = 'M300 End Date must be after Start Date';
            }
        }

        return errors;
    };
    const handleOk = () => {
        projectDetailForm.validateFields().then(values => {
            const requiredFields = [
                //
                'proposalNumber',
                'revisionNumber',
                'date',
                'projectName',
                'crcEvansWbsProject',
                'contractor',
                'designStandard',
                'clientSpecification',
                'clientShippingAddress',
                'technician',
                'projectWeldEngineer',
                //
                'crcEvansWbsWpq',
                'wpqLocation',
                'wpqStartDate',
                'NumberOfPipes',
                //
                'numberOfIWMHeads',
                "P600TorchSplitDistance",
                "IWMTransferEfficiency",
                "rootMethod",
                "compoundBevel",
                "transferEfficiency",
                "arcTimeEfficiency"
                //
            ];

            const emptyFields = requiredFields.filter(field => !values[field]);

            if (emptyFields.length > 0) {
                notification.warning({
                    message: 'Empty Fields Warning',
                    description: `Please fill all required fields`,
                    placement: 'topRight',
                });
                return;
            }

            console.log("submit values", values);
            console.log("pipelineData", pipelineData);

            let data = {
                "proposalNumber": values.proposalNumber,
                "revisionNumber": values.revisionNumber,
                "date": values.date,
                "projectName": values.projectName,
                "crcEvansWbsProject": values.crcEvansWbsProject,
                "contractor": values.contractor,
                "designStandard": values.designStandard,
                "clientSpecification": values.clientSpecification,
                "clientShippingAddress": values.clientShippingAddress,
                "technician": values.technician,
                "projectWeldEngineer": values.projectWeldEngineer,
                //
                "crcEvansWbsWpq": values.crcEvansWbsWpq,
                "wpqLocation": values.wpqLocation,
                "wpqStartDate": moment(values.wpqStartDate, "DD-MMM-YYYY"),
                "NumberOfPipes": values.NumberOfPipes,
                //
                'numberOfIWMHeads': values.numberOfIWMHeads,
                "P600TorchSplitDistance": values.P600TorchSplitDistance,
                "IWMTransferEfficiency": values.IWMTransferEfficiency,
                "rootMethod": values.rootMethod,
                "compoundBevel": values.compoundBevel,
                "transferEfficiency": values.transferEfficiency,
                "arcTimeEfficiency": values.arcTimeEfficiency
                //
            };

            // Proceed with data submission here
            setWeldData(data);
            // ...

            // Reset fields and close modal after submission
            setTimeout(() => {
                setShowGenerateModel(false);
                projectDetailForm.resetFields();
                setNumberOfPipelines(1);
                setPipelineData([{
                    pipeDiameter: '',
                    wallThickness: '',
                    bevelDesign: "",
                }]);
            }, 1000);
        });
    };

    const handleCancel = () => {
        setShowGenerateModel(false);
        setIsEdit(false);
        projectDetailForm.resetFields();
        setNumberOfPipelines(1)
        setPipelineData([
            {
                pipeDiameter: '',
                wallThickness: '',
                bevelDesign: "",
            }
        ])
    };

    const handleNumberOfPipelinesChange = (value) => {
        if (!value) return;
        let updatedGridData = [...pipelineData];

        if (value > numberOfPipelines) {
            for (let index = numberOfPipelines + 1; index <= value; index++) {
                const newItem = { pipeDiameter: '', wallThickness: '', bevelDesign: '' };
                updatedGridData.push(newItem);
            }
        } else if (value < numberOfPipelines) {
            updatedGridData = updatedGridData.slice(0, value);
        }

        setNumberOfPipelines(value);
        setPipelineData(updatedGridData);
        projectDetailForm.setFieldsValue({ NumberOfPipes: value });
    };

    const handleInputChange = (index, field, value) => {
        const newPipelineData = [...pipelineData];
        newPipelineData[index][field] = value;
        setPipelineData(newPipelineData);
    };

    const renderPipelineRows = () => {
        return pipelineData.map((row, index) => (
            <Row key={index} gutter={16} style={{ marginBottom: '8px' }}>
                {/* Row Index */}
                <Col span={1} style={{ fontWeight: 'bold' }}>
                    {index + 1}
                </Col>

                {/* Pipe Diameter */}
                <Col span={6}>
                    <Form.Item>
                        <Select
                            className='maintenance-select'
                            value={row.pipeDiameter}
                            onChange={(value) => handleInputChange(index, 'pipeDiameter', value)}
                            placeholder="Select Pipe Diameter"
                        >
                            <Option value="10">10</Option>
                            <Option value="20">20</Option>
                            <Option value="30">30</Option>
                        </Select>
                    </Form.Item>
                </Col>

                {/* Wall Thickness */}
                <Col span={10}>
                    {/* <Form.Item>
                        <Input
                            value={row.wallThickness}
                            placeholder="Wall Thickness"
                            onChange={(e) => handleInputChange(index, 'wallThickness', e.target.value)}
                        />
                    </Form.Item> */}
                    <Form.Item >
                        <div style={{
                            border: '1px solid #d9d9d9',
                            padding: '5px',
                            borderRadius: '5px',
                            display: 'flex',
                            alignItems: 'center',
                            marginTop: '-8px'

                        }}>

                            <Input style={{ flex: '0 0 45%' }}
                                value={row.wallThickness}
                                onChange={(e) => handleInputChange(index, 'wallThickness', e.target.value)}
                                size="medium"
                                type='number' />
                            <div className='report-radio' style={{ flex: '0 0 40%', paddingLeft: '5px' }}>
                                <Radio.Group style={{ display: "flex", }}
                                    value={row.wallThicknessUnit}
                                    onChange={(e) => handleInputChange(index, 'wallThicknessUnit', e.target.value)}
                                >
                                    <Radio value={'mm'}>mm</Radio>
                                    <Radio value={'inch'}>inch</Radio>
                                </Radio.Group>
                            </div>
                        </div>

                    </Form.Item>
                </Col>
                Bevel Design - Dropdown ( 5 deg K-Prep, 10 deg K-prep, 5 deg  J-prep, V-Prep)
                {/* Bevel Design */}
                <Col span={7}>
                    <Form.Item>
                        <Select
                            className='maintenance-select'
                            value={row.bevelDesign}
                            onChange={(value) => handleInputChange(index, 'bevelDesign', value)}
                            placeholder="Select Bevel Design"
                        >
                            <Option value="5 deg K-Prep">5 deg K-Prep</Option>
                            <Option value="10 deg K-prep">10 deg K-prep</Option>
                            <Option value="5 deg  J-prep">5 deg  J-prep</Option>
                            <Option value="V-Prep">V-Prep</Option>
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
        ));
    };

    const onNextPage = () => {
        setIsNextButton(true);
    }

    return (
        <Modal
            width={modalWidth}
            visible={showGenerateModel}
            title={!isEdit ? "Add Welding Wire Data" : "Update Welding Wire Data"}
            className="pipeline-modal trackrecord-modal Tracker-modal"
            footer={[
                <Button key="back" onClick={handleCancel}>
                    Cancel
                </Button>,
                isNextButton && <Button key="back" onClick={() => setIsNextButton(false)}>
                    Back
                </Button>,
                <Button key="submit" type="primary" onClick={!isNextButton ? onNextPage : isEdit ? handleOk : handleOk}>
                    {/* {isEdit ? 'Update' : 'Submit'} */}
                    {!isNextButton ? 'Next' : isEdit ? 'Update' : 'Submit'}
                </Button>,
            ]}
        >
            <Form
                form={projectDetailForm}
                name="pipelineForm"
                layout="vertical"
                autoComplete="off"
            >

                {!isNextButton ?
                    <>
                        <Row gutter={24}>
                            <Col lg={5}>
                                <Form.Item name="proposalNumber" label="Proposal Number" required>
                                    <Select
                                        size="large"
                                        placeholder="Select Proposal Number"
                                        className='maintenance-select'
                                        dropdownRender={menu => (
                                            <>
                                                {menu}
                                                <Divider style={{ margin: '8px 0', borderTop: '1px solid lightgrey' }} />
                                                <Space align="center" style={{ padding: '0 8px 4px' }}>
                                                    <Input placeholder="Add Proposal Number" />
                                                    <Typography.Link style={{ whiteSpace: 'nowrap', color: "green" }}>
                                                        <CheckOutlined />
                                                    </Typography.Link>
                                                    <Typography.Link style={{ whiteSpace: 'nowrap', color: "red" }}>
                                                        <CloseOutlined />
                                                    </Typography.Link>
                                                </Space>
                                            </>
                                        )}
                                    >
                                        <Option value="Fill">Temp!</Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col lg={5}>
                                <Form.Item name="revisionNumber" label="Revision Number" required>
                                    <Input size="large" />
                                </Form.Item>
                            </Col>
                            <Col lg={5}>
                                <Form.Item name="date" label="Date" required className="date-picker">
                                    <DatePicker format="DD-MMM-YYYY" size="large" />
                                </Form.Item>
                            </Col>
                            <Col lg={5}>
                                <Form.Item name="projectName" label="Project Name" required>
                                    <Select
                                        size="large"
                                        placeholder="Select Project Name"
                                        className='maintenance-select'
                                        dropdownRender={menu => (
                                            <>
                                                {menu}
                                                <Divider style={{ margin: '8px 0', borderTop: '1px solid lightgrey' }} />
                                                <Space align="center" style={{ padding: '0 8px 4px' }}>
                                                    <Input placeholder="Add Project Name" />
                                                    <Typography.Link style={{ whiteSpace: 'nowrap', color: "green" }}>
                                                        <CheckOutlined />
                                                    </Typography.Link>
                                                    <Typography.Link style={{ whiteSpace: 'nowrap', color: "red" }}>
                                                        <CloseOutlined />
                                                    </Typography.Link>
                                                </Space>
                                            </>
                                        )}
                                    >
                                        <Option value="Fill">Temp!</Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col lg={5}>
                                <Form.Item name="crcEvansWbsProject" label="CRC-Evans WBS (Project)" required>
                                    <Select
                                        size="large"
                                        placeholder="Select CRC-Evans WBS"
                                        className='maintenance-select'
                                        dropdownRender={menu => (
                                            <>
                                                {menu}
                                                <Divider style={{ margin: '8px 0', borderTop: '1px solid lightgrey' }} />
                                                <Space align="center" style={{ padding: '0 8px 4px' }}>
                                                    <Input placeholder="Add CRC-Evans WBS" />
                                                    <Typography.Link style={{ whiteSpace: 'nowrap', color: "green" }}>
                                                        <CheckOutlined />
                                                    </Typography.Link>
                                                    <Typography.Link style={{ whiteSpace: 'nowrap', color: "red" }}>
                                                        <CloseOutlined />
                                                    </Typography.Link>
                                                </Space>
                                            </>
                                        )}
                                    >
                                        <Option value="Fill">Temp!</Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col lg={5}>
                                <Form.Item name="contractor" label="Contractor" required>
                                    <Select
                                        size="large"
                                        placeholder="Select Contractor"
                                        className='maintenance-select'
                                        dropdownRender={menu => (
                                            <>
                                                {menu}
                                                <Divider style={{ margin: '8px 0', borderTop: '1px solid lightgrey' }} />
                                                <Space align="center" style={{ padding: '0 8px 4px' }}>
                                                    <Input placeholder="Add Contractor" />
                                                    <Typography.Link style={{ whiteSpace: 'nowrap', color: "green" }}>
                                                        <CheckOutlined />
                                                    </Typography.Link>
                                                    <Typography.Link style={{ whiteSpace: 'nowrap', color: "red" }}>
                                                        <CloseOutlined />
                                                    </Typography.Link>
                                                </Space>
                                            </>
                                        )}
                                    >
                                        <Option value="Fill">Temp!</Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col lg={5}>
                                <Form.Item name="designStandard" label="Design Standard" required>
                                    <Select
                                        size="large"
                                        placeholder="Select Design Standard"
                                        className='maintenance-select'
                                        dropdownRender={menu => (
                                            <>
                                                {menu}
                                                <Divider style={{ margin: '8px 0', borderTop: '1px solid lightgrey' }} />
                                                <Space align="center" style={{ padding: '0 8px 4px' }}>
                                                    <Input placeholder="Add Design Standard" />
                                                    <Typography.Link style={{ whiteSpace: 'nowrap', color: "green" }}>
                                                        <CheckOutlined />
                                                    </Typography.Link>
                                                    <Typography.Link style={{ whiteSpace: 'nowrap', color: "red" }}>
                                                        <CloseOutlined />
                                                    </Typography.Link>
                                                </Space>
                                            </>
                                        )}
                                    >
                                        <Option value="Fill">Temp!</Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col lg={5}>
                                <Form.Item name="clientSpecification" label="Client Specification" required>
                                    <Input size="large" />
                                </Form.Item>
                            </Col>
                            <Col lg={5}>
                                <Form.Item name="technician" label="Technician" required>
                                    <Input size="large" />
                                </Form.Item>
                            </Col>
                            <Col lg={5}>
                                <Form.Item name="projectWeldEngineer" label="Project Weld Engineer" required>
                                    <Input size="large" />
                                </Form.Item>
                            </Col>
                            <Col lg={5}>
                                <Form.Item name="crcEvansWbsWpq" label="CRC-Evans WBS (WPQ)" required>
                                    <Select
                                        size="large"
                                        placeholder="Select CRC-Evans WBS"
                                        className='maintenance-select'
                                        dropdownRender={menu => (
                                            <>
                                                {menu}
                                                <Divider style={{ margin: '8px 0', borderTop: '1px solid lightgrey' }} />
                                                <Space align="center" style={{ padding: '0 8px 4px' }}>
                                                    <Input placeholder="Add CRC-Evans WBS" />
                                                    <Typography.Link style={{ whiteSpace: 'nowrap', color: "green" }}>
                                                        <CheckOutlined />
                                                    </Typography.Link>
                                                    <Typography.Link style={{ whiteSpace: 'nowrap', color: "red" }}>
                                                        <CloseOutlined />
                                                    </Typography.Link>
                                                </Space>
                                            </>
                                        )}
                                    >
                                        <Option value="Fill">Temp!</Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col lg={5}>
                                <Form.Item name="wpqLocation" label="WPQ Location" required>
                                    <Select
                                        size="large"
                                        placeholder="Select WPQ Location"
                                        className='maintenance-select'
                                        dropdownRender={menu => (
                                            <>
                                                {menu}
                                                <Divider style={{ margin: '8px 0', borderTop: '1px solid lightgrey' }} />
                                                <Space align="center" style={{ padding: '0 8px 4px' }}>
                                                    <Input placeholder="Add WPQ Location" />
                                                    <Typography.Link style={{ whiteSpace: 'nowrap', color: "green" }}>
                                                        <CheckOutlined />
                                                    </Typography.Link>
                                                    <Typography.Link style={{ whiteSpace: 'nowrap', color: "red" }}>
                                                        <CloseOutlined />
                                                    </Typography.Link>
                                                </Space>
                                            </>
                                        )}
                                    >
                                        <Option value="Fill">Temp!</Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col lg={5}>
                                <Form.Item name="wpqStartDate" label="WPQ Start Date" required>
                                    <DatePicker format="DD-MMM-YYYY" size="large" style={{ width: '100%' }} />
                                </Form.Item>
                            </Col>
                            <Col lg={5}>
                                <Form.Item
                                    name="numberOfIWMHeads"
                                    label="Number of IWM Heads"
                                    required
                                >
                                    <Input size="large" />
                                </Form.Item>
                            </Col>

                            <Col lg={5}>
                                <Form.Item name="clientShippingAddress" label="Client Shipping Address" required>
                                    <TextArea size="large" style={{ height: '140px' }} />
                                </Form.Item>
                            </Col>

                            <Col lg={5} style={{ marginTop: '-90px' }}>
                                <Form.Item
                                    name="IWMTransferEfficiency"
                                    label="IWM Transfer Efficiency"
                                    required
                                >
                                    <Input type='number' size="large" addonAfter='%' className='Data360-addafter' />
                                </Form.Item>
                            </Col>
                            <Col lg={5} style={{ marginTop: '-90px' }}>
                                <Form.Item
                                    name="rootMethod"
                                    label="Root Method"
                                    rules={[{ required: true }]}
                                >
                                    <Select size="large" placeholder="Select a method" className='maintenance-select'>
                                        <Option value="SMAW Root">SMAW Root</Option>
                                        <Option value="Hot">Hot</Option>
                                        <Option value="Fill">Fill</Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col lg={5} style={{ marginTop: '-90px' }}>
                                <Form.Item
                                    name="compoundBevel"
                                    label="Compound Bevel"
                                    rules={[{ required: true }]}
                                >
                                    <Select size="large" placeholder="Select Yes or No" className='maintenance-select'>
                                        <Option value="Yes">Yes</Option>
                                        <Option value="No">No</Option>
                                    </Select>
                                </Form.Item>
                            </Col>

                            <Col lg={5} style={{ marginTop: '-90px' }}>
                                <Form.Item
                                    name="transferEfficiency"
                                    label="Transfer Efficiency"
                                    rules={[{ required: true }]}
                                >
                                    <Input type='number' size="large" addonAfter='%' className='Data360-addafter' />
                                </Form.Item>
                            </Col>

                            <Col lg={5}></Col>
                            <Col lg={5}>
                                <Form.Item
                                    name="P600TorchSplitDistance"
                                    label="P600 Torch Split Distance"
                                    required
                                >
                                    <Input size="large" />
                                </Form.Item>
                            </Col>

                            <Col lg={5}>
                                <Form.Item
                                    name="arcTimeEfficiency"
                                    label="Arc Time Efficiency"
                                    required
                                >
                                    <Input type='number' size="large" addonAfter='%' className='Data360-addafter' />
                                </Form.Item>
                            </Col>
                            {/* <Col lg={5}>
                                <Form.Item name="clientShippingAddress" label="Client Shipping Address" required>
                                    <TextArea size="large" />
                                </Form.Item>
                            </Col> */}
                            <Col lg={5}>
                                <Form.Item name="NumberOfPipes" label="No. of Pipes" required>
                                    <InputNumber
                                        defaultValue={numberOfPipelines}
                                        value={numberOfPipelines}
                                        onChange={handleNumberOfPipelinesChange}
                                        min={1}
                                        max={5}
                                        size="large"
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                    </>
                    :
                    <div>
                        <Row gutter={24} >
                            <Col lg={24} >
                                <Row gutter={24} style={{ marginBottom: '8px', fontWeight: 'bold' }}>
                                    <Col span={4}></Col>
                                    <Col span={4}>PIPE 1</Col>
                                    {(numberOfPipelines >= 2) && <Col span={4}>PIPE 2</Col>}
                                    {(numberOfPipelines >= 3) && <Col span={4}>PIPE 3</Col>}
                                    {(numberOfPipelines >= 4) && <Col span={4}>PIPE 4</Col>}
                                    {(numberOfPipelines >= 5) && <Col span={4}>PIPE 5</Col>}
                                </Row>
                                <Row gutter={24} style={{ marginBottom: '12px' }}>
                                    <Col span={4} style={{ marginTop: '6px' }}>Pipe Diameter</Col>
                                    <Col span={4}>
                                        <Input.Group compact>
                                            <Form.Item name="pipe1PipeDiameter" noStyle>
                                                <Input type='number' size="medium" style={{ width: '55%', borderRadius: '5px' }} />
                                            </Form.Item>
                                            <Form.Item name="pipe1PipeDiameterUnit" initialValue="mm" noStyle>
                                                <Select className='diameter-select' style={{ width: '45%', marginTop: '-5px' }} size='medium'>
                                                    <Option value="mm">mm</Option>
                                                    <Option value="inch">inch</Option>
                                                </Select>
                                            </Form.Item>
                                        </Input.Group>
                                    </Col>

                                    {(numberOfPipelines >= 2) && (
                                        <Col span={4}>
                                            <Input.Group compact>
                                                <Form.Item name="pipe2PipeDiameter" noStyle>
                                                    <Input type='number' size="medium" style={{ width: '55%', borderRadius: '5px' }} />
                                                </Form.Item>
                                                <Form.Item name="pipe2PipeDiameterUnit" initialValue="mm" noStyle>
                                                    <Select className='diameter-select' style={{ width: '45%', marginTop: '-5px' }} size='medium'>
                                                        <Option value="mm">mm</Option>
                                                        <Option value="inch">inch</Option>
                                                    </Select>
                                                </Form.Item>
                                            </Input.Group>
                                        </Col>
                                    )}
                                    {(numberOfPipelines >= 3) && (
                                        <Col span={4}>
                                            <Input.Group compact>
                                                <Form.Item name="pipe3PipeDiameter" noStyle>
                                                    <Input type='number' size="medium" style={{ width: '55%', borderRadius: '5px' }} />
                                                </Form.Item>
                                                <Form.Item name="pipe3PipeDiameterUnit" initialValue="mm" noStyle>
                                                    <Select className='diameter-select' style={{ width: '45%', marginTop: '-5px' }} size='medium'>
                                                        <Option value="mm">mm</Option>
                                                        <Option value="inch">inch</Option>
                                                    </Select>
                                                </Form.Item>
                                            </Input.Group>
                                        </Col>
                                    )}
                                    {(numberOfPipelines >= 4) && (
                                        <Col span={4}>
                                            <Input.Group compact>
                                                <Form.Item name="pipe4PipeDiameter" noStyle>
                                                    <Input type='number' size="medium" style={{ width: '55%', borderRadius: '5px' }} />
                                                </Form.Item>
                                                <Form.Item name="pipe4PipeDiameterUnit" initialValue="mm" noStyle>
                                                    <Select className='diameter-select' style={{ width: '45%', marginTop: '-5px' }} size='medium'>
                                                        <Option value="mm">mm</Option>
                                                        <Option value="inch">inch</Option>
                                                    </Select>
                                                </Form.Item>
                                            </Input.Group>
                                        </Col>
                                    )}
                                    {(numberOfPipelines >= 5) && (
                                        <Col span={4}>
                                            <Input.Group compact>
                                                <Form.Item name="pipe5PipeDiameter" noStyle>
                                                    <Input type='number' size="medium" style={{ width: '55%', borderRadius: '5px' }} />
                                                </Form.Item>
                                                <Form.Item name="pipe5PipeDiameterUnit" initialValue="mm" noStyle>
                                                    <Select className='diameter-select' style={{ width: '45%', marginTop: '-5px' }} size='medium'>
                                                        <Option value="mm">mm</Option>
                                                        <Option value="inch">inch</Option>
                                                    </Select>
                                                </Form.Item>
                                            </Input.Group>
                                        </Col>
                                    )}
                                </Row>

                                {/* Repeat the pattern for other rows */}
                                {/* Wall thickness */}
                                <Row gutter={24} style={{ marginBottom: '12px' }}>
                                    <Col span={4} style={{ marginTop: '6px' }}>Wall thickness</Col>
                                    <Col span={4}>
                                        <Input.Group compact>
                                            <Form.Item name="pipe1WallThickness" noStyle>
                                                <Input type='number' size="medium" style={{ width: '55%', borderRadius: '5px' }} />
                                            </Form.Item>
                                            <Form.Item name="pipe1WallThicknessUnit" initialValue="mm" noStyle>
                                                <Select className='diameter-select' style={{ width: '45%', marginTop: '-5px' }} size='medium'>
                                                    <Option value="mm">mm</Option>
                                                    <Option value="inch">inch</Option>
                                                </Select>
                                            </Form.Item>
                                        </Input.Group>
                                    </Col>
                                    {(numberOfPipelines >= 2) && (
                                        <Col span={4}>
                                            <Input.Group compact>
                                                <Form.Item name="pipe2WallThickness" noStyle>
                                                    <Input type='number' size="medium" style={{ width: '55%', borderRadius: '5px' }} />
                                                </Form.Item>
                                                <Form.Item name="pipe2WallThicknessUnit" initialValue="mm" noStyle>
                                                    <Select className='diameter-select' style={{ width: '45%', marginTop: '-5px' }} size='medium'>
                                                        <Option value="mm">mm</Option>
                                                        <Option value="inch">inch</Option>
                                                    </Select>
                                                </Form.Item>
                                            </Input.Group>
                                        </Col>
                                    )}
                                    {(numberOfPipelines >= 3) && (
                                        <Col span={4}>
                                            <Input.Group compact>
                                                <Form.Item name="pipe3WallThickness" noStyle>
                                                    <Input type='number' size="medium" style={{ width: '55%', borderRadius: '5px' }} />
                                                </Form.Item>
                                                <Form.Item name="pipe3WallThicknessUnit" initialValue="mm" noStyle>
                                                    <Select className='diameter-select' style={{ width: '45%', marginTop: '-5px' }} size='medium'>
                                                        <Option value="mm">mm</Option>
                                                        <Option value="inch">inch</Option>
                                                    </Select>
                                                </Form.Item>
                                            </Input.Group>
                                        </Col>
                                    )}
                                    {(numberOfPipelines >= 4) && (
                                        <Col span={4}>
                                            <Input.Group compact>
                                                <Form.Item name="pipe4WallThickness" noStyle>
                                                    <Input type='number' size="medium" style={{ width: '55%', borderRadius: '5px' }} />
                                                </Form.Item>
                                                <Form.Item name="pipe4WallThicknessUnit" initialValue="mm" noStyle>
                                                    <Select className='diameter-select' style={{ width: '45%', marginTop: '-5px' }} size='medium'>
                                                        <Option value="mm">mm</Option>
                                                        <Option value="inch">inch</Option>
                                                    </Select>
                                                </Form.Item>
                                            </Input.Group>
                                        </Col>
                                    )}
                                    {(numberOfPipelines >= 5) && (
                                        <Col span={4}>
                                            <Input.Group compact>
                                                <Form.Item name="pipe5WallThickness" noStyle>
                                                    <Input type='number' size="medium" style={{ width: '55%', borderRadius: '5px' }} />
                                                </Form.Item>
                                                <Form.Item name="pipe5WallThicknessUnit" initialValue="mm" noStyle>
                                                    <Select className='diameter-select' style={{ width: '45%', marginTop: '-5px' }} size='medium'>
                                                        <Option value="mm">mm</Option>
                                                        <Option value="inch">inch</Option>
                                                    </Select>
                                                </Form.Item>
                                            </Input.Group>
                                        </Col>
                                    )}
                                </Row>

                                {/* Bevel Design */}
                                <Row gutter={24} style={{ marginBottom: '12px' }}>
                                    <Col span={4} style={{ marginTop: '6px' }}> Bevel Design</Col>
                                    <Col span={4}>
                                        <Form.Item name="pipe1BevelDesign" noStyle>
                                            <Select className='bevel-select' placeholder="Select Bevel" size='medium'>
                                                <Option value="5 deg K-Prep">5 deg K-Prep</Option>
                                                <Option value="10 deg K-prep">10 deg K-prep</Option>
                                                <Option value="5 deg  J-prep">5 deg  J-prep</Option>
                                                <Option value="V-Prep">V-Prep</Option>
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    {(numberOfPipelines >= 2) && (
                                        <Col span={4}>
                                            <Form.Item name="pipe2BevelDesign" noStyle>
                                                <Select className='bevel-select' placeholder="Select Bevel">
                                                    <Option value="5 deg K-Prep">5 deg K-Prep</Option>
                                                    <Option value="10 deg K-prep">10 deg K-prep</Option>
                                                    <Option value="5 deg  J-prep">5 deg  J-prep</Option>
                                                    <Option value="V-Prep">V-Prep</Option>
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                    )}
                                    {(numberOfPipelines >= 3) && (
                                        <Col span={4}>
                                            <Form.Item name="pipe3BevelDesign" noStyle>
                                                <Select className='bevel-select' placeholder="Select Bevel">
                                                    <Option value="5 deg K-Prep">5 deg K-Prep</Option>
                                                    <Option value="10 deg K-prep">10 deg K-prep</Option>
                                                    <Option value="5 deg  J-prep">5 deg  J-prep</Option>
                                                    <Option value="V-Prep">V-Prep</Option>
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                    )}
                                    {(numberOfPipelines >= 4) && (
                                        <Col span={4}>
                                            <Form.Item name="pipe4BevelDesign" noStyle>
                                                <Select className='bevel-select' placeholder="Select Bevel">
                                                    <Option value="5 deg K-Prep">5 deg K-Prep</Option>
                                                    <Option value="10 deg K-prep">10 deg K-prep</Option>
                                                    <Option value="5 deg  J-prep">5 deg  J-prep</Option>
                                                    <Option value="V-Prep">V-Prep</Option>
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                    )}
                                    {(numberOfPipelines >= 5) && (
                                        <Col span={4}>
                                            <Form.Item name="pipe5BevelDesign" noStyle>
                                                <Select className='bevel-select' placeholder="Select Bevel">
                                                    <Option value="5 deg K-Prep">5 deg K-Prep</Option>
                                                    <Option value="10 deg K-prep">10 deg K-prep</Option>
                                                    <Option value="5 deg  J-prep">5 deg  J-prep</Option>
                                                    <Option value="V-Prep">V-Prep</Option>
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                    )}
                                </Row>

                                <Row gutter={24} style={{ marginBottom: '12px' }}>
                                    <Col span={24}>
                                        <h6 style={{ color: '#EF314C' }}>IWM/P4XX Weld Production</h6>
                                    </Col>
                                </Row>
                                <Row gutter={24} style={{ marginBottom: '12px' }}>
                                    <Col span={4} style={{ marginTop: '6px' }}>Production Weld Count</Col>
                                    <Col span={4}>
                                        <Form.Item name="pipe1IwmProductionWeldCount" noStyle>
                                            <Input type="number" size="medium" style={{ borderRadius: '5px' }} />
                                        </Form.Item>
                                    </Col>
                                    {(numberOfPipelines >= 2) && (
                                        <Col span={4}>
                                            <Form.Item name="pipe2IwmProductionWeldCount" noStyle>
                                                <Input type="number" size="medium" style={{ borderRadius: '5px' }} />
                                            </Form.Item>
                                        </Col>
                                    )}
                                    {(numberOfPipelines >= 3) && (
                                        <Col span={4}>
                                            <Form.Item name="pipe3IwmProductionWeldCount" noStyle>
                                                <Input type="number" size="medium" style={{ borderRadius: '5px' }} />
                                            </Form.Item>
                                        </Col>
                                    )}
                                    {(numberOfPipelines >= 4) && (
                                        <Col span={4}>
                                            <Form.Item name="pipe4IwmProductionWeldCount" noStyle>
                                                <Input type="number" size="medium" style={{ borderRadius: '5px' }} />
                                            </Form.Item>
                                        </Col>
                                    )}
                                    {(numberOfPipelines >= 5) && (
                                        <Col span={4}>
                                            <Form.Item name="pipe5IwmProductionWeldCount" noStyle>
                                                <Input type="number" size="medium" style={{ borderRadius: '5px' }} />
                                            </Form.Item>
                                        </Col>
                                    )}
                                </Row>
                                <Row gutter={24} style={{ marginBottom: '12px' }}>
                                    <Col span={4} style={{ marginTop: '6px' }}>Training Weld Allowance</Col>
                                    <Col span={4}>
                                        <Form.Item name="pipe1IwmTrainingWeldAllowance" noStyle>
                                            <Input type="number" size="medium" style={{ borderRadius: '5px' }} />
                                        </Form.Item>
                                    </Col>
                                    {(numberOfPipelines >= 2) && (
                                        <Col span={4}>
                                            <Form.Item name="pipe2IwmTrainingWeldAllowance" noStyle>
                                                <Input type="number" size="medium" style={{ borderRadius: '5px' }} />
                                            </Form.Item>
                                        </Col>
                                    )}
                                    {(numberOfPipelines >= 3) && (
                                        <Col span={4}>
                                            <Form.Item name="pipe3IwmTrainingWeldAllowance" noStyle>
                                                <Input type="number" size="medium" style={{ borderRadius: '5px' }} />
                                            </Form.Item>
                                        </Col>
                                    )}
                                    {(numberOfPipelines >= 4) && (
                                        <Col span={4}>
                                            <Form.Item name="pipe4IwmTrainingWeldAllowance" noStyle>
                                                <Input type="number" size="medium" style={{ borderRadius: '5px' }} />
                                            </Form.Item>
                                        </Col>
                                    )}
                                    {(numberOfPipelines >= 5) && (
                                        <Col span={4}>
                                            <Form.Item name="pipe5IwmTrainingWeldAllowance" noStyle>
                                                <Input type="number" size="medium" style={{ borderRadius: '5px' }} />
                                            </Form.Item>
                                        </Col>
                                    )}
                                </Row>
                                <Row gutter={24} style={{ marginBottom: '12px' }}>
                                    <Col span={4} style={{ marginTop: '6px' }}>Iwm WPQ Welds</Col>
                                    <Col span={4}>
                                        <Form.Item name="pipe1IwmWPQWelds" noStyle>
                                            <Input type="number" size="medium" style={{ borderRadius: '5px' }} />
                                        </Form.Item>
                                    </Col>
                                    {(numberOfPipelines >= 2) && (
                                        <Col span={4}>
                                            <Form.Item name="pipe2IwmWPQWelds" noStyle>
                                                <Input type="number" size="medium" style={{ borderRadius: '5px' }} />
                                            </Form.Item>
                                        </Col>
                                    )}
                                    {(numberOfPipelines >= 3) && (
                                        <Col span={4}>
                                            <Form.Item name="pipe3IwmWPQWelds" noStyle>
                                                <Input type="number" size="medium" style={{ borderRadius: '5px' }} />
                                            </Form.Item>
                                        </Col>
                                    )}
                                    {(numberOfPipelines >= 4) && (
                                        <Col span={4}>
                                            <Form.Item name="pipe4IwmWPQWelds" noStyle>
                                                <Input type="number" size="medium" style={{ borderRadius: '5px' }} />
                                            </Form.Item>
                                        </Col>
                                    )}
                                    {(numberOfPipelines >= 5) && (
                                        <Col span={4}>
                                            <Form.Item name="pipe5IwmWPQWelds" noStyle>
                                                <Input type="number" size="medium" style={{ borderRadius: '5px' }} />
                                            </Form.Item>
                                        </Col>
                                    )}
                                </Row>

                                {/* Average Welds Per Day */}
                                <Row gutter={24} style={{ marginBottom: '12px' }}>
                                    <Col span={4} style={{ marginTop: '6px' }}>Average Welds Per Day</Col>
                                    <Col span={4}>
                                        <Form.Item name="pipe1IwmAverageWeldsPerDay" noStyle>
                                            <Input type="number" size="medium" style={{ borderRadius: '5px' }} />
                                        </Form.Item>
                                    </Col>
                                    {(numberOfPipelines >= 2) && (
                                        <Col span={4}>
                                            <Form.Item name="pipe2IwmAverageWeldsPerDay" noStyle>
                                                <Input type="number" size="medium" style={{ borderRadius: '5px' }} />
                                            </Form.Item>
                                        </Col>
                                    )}
                                    {(numberOfPipelines >= 3) && (
                                        <Col span={4}>
                                            <Form.Item name="pipe3IwmAverageWeldsPerDay" noStyle>
                                                <Input type="number" size="medium" style={{ borderRadius: '5px' }} />
                                            </Form.Item>
                                        </Col>
                                    )}
                                    {(numberOfPipelines >= 4) && (
                                        <Col span={4}>
                                            <Form.Item name="pipe4IwmAverageWeldsPerDay" noStyle>
                                                <Input type="number" size="medium" style={{ borderRadius: '5px' }} />
                                            </Form.Item>
                                        </Col>
                                    )}
                                    {(numberOfPipelines >= 5) && (
                                        <Col span={4}>
                                            <Form.Item name="pipe5IwmAverageWeldsPerDay" noStyle>
                                                <Input type="number" size="medium" style={{ borderRadius: '5px' }} />
                                            </Form.Item>
                                        </Col>
                                    )}
                                </Row>

                                {/* Plan Start Date */}
                                <Row gutter={24} style={{ marginBottom: '12px' }}>
                                    <Col span={4} style={{ marginTop: '6px' }}>Plan Start Date</Col>
                                    <Col span={4}>
                                        <Form.Item name="pipe1IwmPlanStartDate" noStyle>
                                            <DatePicker format="DD-MMM-YYYY" size="medium" style={{ width: '100%', borderRadius: '5px', height: '30px' }} />
                                        </Form.Item>
                                    </Col>
                                    {(numberOfPipelines >= 2) && (
                                        <Col span={4}>
                                            <Form.Item name="pipe2IwmPlanStartDate" noStyle>
                                                <DatePicker format="DD-MMM-YYYY" size="medium" style={{ width: '100%', borderRadius: '5px', height: '30px' }} />
                                            </Form.Item>
                                        </Col>
                                    )}
                                    {(numberOfPipelines >= 3) && (
                                        <Col span={4}>
                                            <Form.Item name="pipe3IwmPlanStartDate" noStyle>
                                                <DatePicker format="DD-MMM-YYYY" size="medium" style={{ width: '100%', borderRadius: '5px', height: '30px' }} />
                                            </Form.Item>
                                        </Col>
                                    )}
                                    {(numberOfPipelines >= 4) && (
                                        <Col span={4}>
                                            <Form.Item name="pipe4IwmPlanStartDate" noStyle>
                                                <DatePicker format="DD-MMM-YYYY" size="medium" style={{ width: '100%', borderRadius: '5px', height: '30px' }} />
                                            </Form.Item>
                                        </Col>
                                    )}
                                    {(numberOfPipelines >= 5) && (
                                        <Col span={4}>
                                            <Form.Item name="pipe5IwmPlanStartDate" noStyle>
                                                <DatePicker format="DD-MMM-YYYY" size="medium" style={{ width: '100%', borderRadius: '5px', height: '30px' }} />
                                            </Form.Item>
                                        </Col>
                                    )}
                                </Row>

                                {/* Planned Complete Date */}
                                <Row gutter={24} style={{ marginBottom: '12px' }}>
                                    <Col span={4} style={{ marginTop: '6px' }}>Planned Complete Date</Col>
                                    <Col span={4}>
                                        <Form.Item name="pipe1IwmPlannedCompleteDate" noStyle>
                                            <DatePicker format="DD-MMM-YYYY" size="medium" style={{ width: '100%', borderRadius: '5px', height: '30px' }} />
                                        </Form.Item>
                                    </Col>
                                    {(numberOfPipelines >= 2) && (
                                        <Col span={4}>
                                            <Form.Item name="pipe2IwmPlannedCompleteDate" noStyle>
                                                <DatePicker format="DD-MMM-YYYY" size="medium" style={{ width: '100%', borderRadius: '5px', height: '30px' }} />
                                            </Form.Item>
                                        </Col>
                                    )}
                                    {(numberOfPipelines >= 3) && (
                                        <Col span={4}>
                                            <Form.Item name="pipe3IwmPlannedCompleteDate" noStyle>
                                                <DatePicker format="DD-MMM-YYYY" size="medium" style={{ width: '100%', borderRadius: '5px', height: '30px' }} />
                                            </Form.Item>
                                        </Col>
                                    )}
                                    {(numberOfPipelines >= 4) && (
                                        <Col span={4}>
                                            <Form.Item name="pipe4IwmPlannedCompleteDate" noStyle>
                                                <DatePicker format="DD-MMM-YYYY" size="medium" style={{ width: '100%', borderRadius: '5px', height: '30px' }} />
                                            </Form.Item>
                                        </Col>
                                    )}
                                    {(numberOfPipelines >= 5) && (
                                        <Col span={4}>
                                            <Form.Item name="pipe5IwmPlannedCompleteDate" noStyle>
                                                <DatePicker format="DD-MMM-YYYY" size="medium" style={{ width: '100%', borderRadius: '5px', height: '30px' }} />
                                            </Form.Item>
                                        </Col>
                                    )}
                                </Row>

                                {/* Working Days Per Week */}
                                <Row gutter={24} style={{ marginBottom: '12px' }}>
                                    <Col span={4} style={{ marginTop: '6px' }}>Working Days Per Week</Col>
                                    <Col span={4}>
                                        <Form.Item name="pipe1IwmWorkingDaysPerWeek" noStyle>
                                            <Input type="number" size="medium" style={{ borderRadius: '5px' }} />
                                        </Form.Item>
                                    </Col>
                                    {(numberOfPipelines >= 2) && (
                                        <Col span={4}>
                                            <Form.Item name="pipe2IwmWorkingDaysPerWeek" noStyle>
                                                <Input type="number" size="medium" style={{ borderRadius: '5px' }} />
                                            </Form.Item>
                                        </Col>
                                    )}
                                    {(numberOfPipelines >= 3) && (
                                        <Col span={4}>
                                            <Form.Item name="pipe3IwmWorkingDaysPerWeek" noStyle>
                                                <Input type="number" size="medium" style={{ borderRadius: '5px' }} />
                                            </Form.Item>
                                        </Col>
                                    )}
                                    {(numberOfPipelines >= 4) && (
                                        <Col span={4}>
                                            <Form.Item name="pipe4IwmWorkingDaysPerWeek" noStyle>
                                                <Input type="number" size="medium" style={{ borderRadius: '5px' }} />
                                            </Form.Item>
                                        </Col>
                                    )}
                                    {(numberOfPipelines >= 5) && (
                                        <Col span={4}>
                                            <Form.Item name="pipe5IwmWorkingDaysPerWeek" noStyle>
                                                <Input type="number" size="medium" style={{ borderRadius: '5px' }} />
                                            </Form.Item>
                                        </Col>
                                    )}
                                </Row>
                                {/* // */}
                                <Row gutter={24} style={{ marginBottom: '12px' }}>
                                    <Col span={24}>
                                        <h6 style={{ color: '#EF314C' }}>IWM/P4XX Weld Production</h6>
                                    </Col>
                                </Row>
                                <Row gutter={24} style={{ marginBottom: '12px' }}>
                                    <Col span={4} style={{ marginTop: '6px' }}>Production Weld Count</Col>
                                    <Col span={4}>
                                        <Form.Item name="pipe1M300ProductionWeldCount" noStyle>
                                            <Input type="number" size="medium" style={{ borderRadius: '5px' }} />
                                        </Form.Item>
                                    </Col>
                                    {(numberOfPipelines >= 2) && (
                                        <Col span={4}>
                                            <Form.Item name="pipe2M300ProductionWeldCount" noStyle>
                                                <Input type="number" size="medium" style={{ borderRadius: '5px' }} />
                                            </Form.Item>
                                        </Col>
                                    )}
                                    {(numberOfPipelines >= 3) && (
                                        <Col span={4}>
                                            <Form.Item name="pipe3M300ProductionWeldCount" noStyle>
                                                <Input type="number" size="medium" style={{ borderRadius: '5px' }} />
                                            </Form.Item>
                                        </Col>
                                    )}
                                    {(numberOfPipelines >= 4) && (
                                        <Col span={4}>
                                            <Form.Item name="pipe4M300ProductionWeldCount" noStyle>
                                                <Input type="number" size="medium" style={{ borderRadius: '5px' }} />
                                            </Form.Item>
                                        </Col>
                                    )}
                                    {(numberOfPipelines >= 5) && (
                                        <Col span={4}>
                                            <Form.Item name="pipe5M300ProductionWeldCount" noStyle>
                                                <Input type="number" size="medium" style={{ borderRadius: '5px' }} />
                                            </Form.Item>
                                        </Col>
                                    )}
                                </Row>
                                <Row gutter={24} style={{ marginBottom: '12px' }}>
                                    <Col span={4} style={{ marginTop: '6px' }}>Training Weld Allowance</Col>
                                    <Col span={4}>
                                        <Form.Item name="pipe1M300TrainingWeldAllowance" noStyle>
                                            <Input type="number" size="medium" style={{ borderRadius: '5px' }} />
                                        </Form.Item>
                                    </Col>
                                    {(numberOfPipelines >= 2) && (
                                        <Col span={4}>
                                            <Form.Item name="pipe2M300TrainingWeldAllowance" noStyle>
                                                <Input type="number" size="medium" style={{ borderRadius: '5px' }} />
                                            </Form.Item>
                                        </Col>
                                    )}
                                    {(numberOfPipelines >= 3) && (
                                        <Col span={4}>
                                            <Form.Item name="pipe3M300TrainingWeldAllowance" noStyle>
                                                <Input type="number" size="medium" style={{ borderRadius: '5px' }} />
                                            </Form.Item>
                                        </Col>
                                    )}
                                    {(numberOfPipelines >= 4) && (
                                        <Col span={4}>
                                            <Form.Item name="pipe4M300TrainingWeldAllowance" noStyle>
                                                <Input type="number" size="medium" style={{ borderRadius: '5px' }} />
                                            </Form.Item>
                                        </Col>
                                    )}
                                    {(numberOfPipelines >= 5) && (
                                        <Col span={4}>
                                            <Form.Item name="pipe5M300TrainingWeldAllowance" noStyle>
                                                <Input type="number" size="medium" style={{ borderRadius: '5px' }} />
                                            </Form.Item>
                                        </Col>
                                    )}
                                </Row>
                                <Row gutter={24} style={{ marginBottom: '12px' }}>
                                    <Col span={4} style={{ marginTop: '6px' }}>M300 WPQ Welds</Col>
                                    <Col span={4}>
                                        <Form.Item name="pipe1M300WPQWelds" noStyle>
                                            <Input type="number" size="medium" style={{ borderRadius: '5px' }} />
                                        </Form.Item>
                                    </Col>
                                    {(numberOfPipelines >= 2) && (
                                        <Col span={4}>
                                            <Form.Item name="pipe2M300WPQWelds" noStyle>
                                                <Input type="number" size="medium" style={{ borderRadius: '5px' }} />
                                            </Form.Item>
                                        </Col>
                                    )}
                                    {(numberOfPipelines >= 3) && (
                                        <Col span={4}>
                                            <Form.Item name="pipe3M300WPQWelds" noStyle>
                                                <Input type="number" size="medium" style={{ borderRadius: '5px' }} />
                                            </Form.Item>
                                        </Col>
                                    )}
                                    {(numberOfPipelines >= 4) && (
                                        <Col span={4}>
                                            <Form.Item name="pipe4M300WPQWelds" noStyle>
                                                <Input type="number" size="medium" style={{ borderRadius: '5px' }} />
                                            </Form.Item>
                                        </Col>
                                    )}
                                    {(numberOfPipelines >= 5) && (
                                        <Col span={4}>
                                            <Form.Item name="pipe5M300WPQWelds" noStyle>
                                                <Input type="number" size="medium" style={{ borderRadius: '5px' }} />
                                            </Form.Item>
                                        </Col>
                                    )}
                                </Row>

                                {/* Average Welds Per Day */}
                                <Row gutter={24} style={{ marginBottom: '12px' }}>
                                    <Col span={4} style={{ marginTop: '6px' }}>Average Welds Per Day</Col>
                                    <Col span={4}>
                                        <Form.Item name="pipe1M300AverageWeldsPerDay" noStyle>
                                            <Input type="number" size="medium" style={{ borderRadius: '5px' }} />
                                        </Form.Item>
                                    </Col>
                                    {(numberOfPipelines >= 2) && (
                                        <Col span={4}>
                                            <Form.Item name="pipe2M300AverageWeldsPerDay" noStyle>
                                                <Input type="number" size="medium" style={{ borderRadius: '5px' }} />
                                            </Form.Item>
                                        </Col>
                                    )}
                                    {(numberOfPipelines >= 3) && (
                                        <Col span={4}>
                                            <Form.Item name="pipe3M300AverageWeldsPerDay" noStyle>
                                                <Input type="number" size="medium" style={{ borderRadius: '5px' }} />
                                            </Form.Item>
                                        </Col>
                                    )}
                                    {(numberOfPipelines >= 4) && (
                                        <Col span={4}>
                                            <Form.Item name="pipe4M300AverageWeldsPerDay" noStyle>
                                                <Input type="number" size="medium" style={{ borderRadius: '5px' }} />
                                            </Form.Item>
                                        </Col>
                                    )}
                                    {(numberOfPipelines >= 5) && (
                                        <Col span={4}>
                                            <Form.Item name="pipe5M300AverageWeldsPerDay" noStyle>
                                                <Input type="number" size="medium" style={{ borderRadius: '5px' }} />
                                            </Form.Item>
                                        </Col>
                                    )}
                                </Row>

                                {/* Plan Start Date */}
                                <Row gutter={24} style={{ marginBottom: '12px' }}>
                                    <Col span={4} style={{ marginTop: '6px' }}>Plan Start Date</Col>
                                    <Col span={4}>
                                        <Form.Item name="pipe1M300PlanStartDate" noStyle>
                                            <DatePicker format="DD-MMM-YYYY" size="medium" style={{ width: '100%', borderRadius: '5px', height: '30px' }} />
                                        </Form.Item>
                                    </Col>
                                    {(numberOfPipelines >= 2) && (
                                        <Col span={4}>
                                            <Form.Item name="pipe2M300PlanStartDate" noStyle>
                                                <DatePicker format="DD-MMM-YYYY" size="medium" style={{ width: '100%', borderRadius: '5px', height: '30px' }} />
                                            </Form.Item>
                                        </Col>
                                    )}
                                    {(numberOfPipelines >= 3) && (
                                        <Col span={4}>
                                            <Form.Item name="pipe3M300PlanStartDate" noStyle>
                                                <DatePicker format="DD-MMM-YYYY" size="medium" style={{ width: '100%', borderRadius: '5px', height: '30px' }} />
                                            </Form.Item>
                                        </Col>
                                    )}
                                    {(numberOfPipelines >= 4) && (
                                        <Col span={4}>
                                            <Form.Item name="pipe4M300PlanStartDate" noStyle>
                                                <DatePicker format="DD-MMM-YYYY" size="medium" style={{ width: '100%', borderRadius: '5px', height: '30px' }} />
                                            </Form.Item>
                                        </Col>
                                    )}
                                    {(numberOfPipelines >= 5) && (
                                        <Col span={4}>
                                            <Form.Item name="pipe5M300PlanStartDate" noStyle>
                                                <DatePicker format="DD-MMM-YYYY" size="medium" style={{ width: '100%', borderRadius: '5px', height: '30px' }} />
                                            </Form.Item>
                                        </Col>
                                    )}
                                </Row>

                                {/* Planned Complete Date */}
                                <Row gutter={24} style={{ marginBottom: '12px' }}>
                                    <Col span={4} style={{ marginTop: '6px' }}>Planned Complete Date</Col>
                                    <Col span={4}>
                                        <Form.Item name="pipe1M300PlannedCompleteDate" noStyle>
                                            <DatePicker format="DD-MMM-YYYY" size="medium" style={{ width: '100%', borderRadius: '5px', height: '30px' }} />
                                        </Form.Item>
                                    </Col>
                                    {(numberOfPipelines >= 2) && (
                                        <Col span={4}>
                                            <Form.Item name="pipe2M300PlannedCompleteDate" noStyle>
                                                <DatePicker format="DD-MMM-YYYY" size="medium" style={{ width: '100%', borderRadius: '5px', height: '30px' }} />
                                            </Form.Item>
                                        </Col>
                                    )}
                                    {(numberOfPipelines >= 3) && (
                                        <Col span={4}>
                                            <Form.Item name="pipe3M300PlannedCompleteDate" noStyle>
                                                <DatePicker format="DD-MMM-YYYY" size="medium" style={{ width: '100%', borderRadius: '5px', height: '30px' }} />
                                            </Form.Item>
                                        </Col>
                                    )}
                                    {(numberOfPipelines >= 4) && (
                                        <Col span={4}>
                                            <Form.Item name="pipe4M300PlannedCompleteDate" noStyle>
                                                <DatePicker format="DD-MMM-YYYY" size="medium" style={{ width: '100%', borderRadius: '5px', height: '30px' }} />
                                            </Form.Item>
                                        </Col>
                                    )}
                                    {(numberOfPipelines >= 5) && (
                                        <Col span={4}>
                                            <Form.Item name="pipe5M300PlannedCompleteDate" noStyle>
                                                <DatePicker format="DD-MMM-YYYY" size="medium" style={{ width: '100%', borderRadius: '5px', height: '30px' }} />
                                            </Form.Item>
                                        </Col>
                                    )}
                                </Row>

                                {/* Working Days Per Week */}
                                <Row gutter={24} style={{ marginBottom: '12px' }}>
                                    <Col span={4} style={{ marginTop: '6px' }}>Working Days Per Week</Col>
                                    <Col span={4}>
                                        <Form.Item name="pipe1M300WorkingDaysPerWeek" noStyle>
                                            <Input type="number" size="medium" style={{ borderRadius: '5px' }} />
                                        </Form.Item>
                                    </Col>
                                    {(numberOfPipelines >= 2) && (
                                        <Col span={4}>
                                            <Form.Item name="pipe2M300WorkingDaysPerWeek" noStyle>
                                                <Input type="number" size="medium" style={{ borderRadius: '5px' }} />
                                            </Form.Item>
                                        </Col>
                                    )}
                                    {(numberOfPipelines >= 3) && (
                                        <Col span={4}>
                                            <Form.Item name="pipe3M300WorkingDaysPerWeek" noStyle>
                                                <Input type="number" size="medium" style={{ borderRadius: '5px' }} />
                                            </Form.Item>
                                        </Col>
                                    )}
                                    {(numberOfPipelines >= 4) && (
                                        <Col span={4}>
                                            <Form.Item name="pipe4M300WorkingDaysPerWeek" noStyle>
                                                <Input type="number" size="medium" style={{ borderRadius: '5px' }} />
                                            </Form.Item>
                                        </Col>
                                    )}
                                    {(numberOfPipelines >= 5) && (
                                        <Col span={4}>
                                            <Form.Item name="pipe5M300WorkingDaysPerWeek" noStyle>
                                                <Input type="number" size="medium" style={{ borderRadius: '5px' }} />
                                            </Form.Item>
                                        </Col>
                                    )}
                                </Row>
                            </Col>
                        </Row>

                        {/* {renderPipelineRows()} */}
                    </div>
                }
            </Form>
        </Modal>
    );
};

export default WeldingWireForm;