import get from 'lodash/get';
import { ADD_CALIBRATION, 
    ADD_CALIBRATION_FAILURE, 
    ADD_CALIBRATION_SUCCESS, 
    FETCH_CALIBRATION, 
    FETCH_CALIBRATION_SUCCESS, 
    FETCH_CALIBRATION_FAILURE,
    SHARE_CALIBRATION,
    SHARE_CALIBRATION_SUCCESS,
    SHARE_CALIBRATION_FAILURE,
    UPLOAD_CALIBRATION_FILE,
    UPLOAD_CALIBRATION_FILE_SUCCESS,
    UPLOAD_CALIBRATION_FILE_FAILURE,
    UPDATE_CALIBRATION,
    UPDATE_CALIBRATION_SUCCESS,
    UPDATE_CALIBRATION_FAILURE
   } from './types';

const initialState = {
    // calibrationData: [],
    iscalbrationLoading: false,
    isUploadCalibration: false,
    isAddUpdateCalibration: false
};

const calibrationReducer = (state = initialState, action) => {
    switch (action.type) {

        case FETCH_CALIBRATION: {
            return {
                ...state,
                iscalbrationLoading: true
            }
        }

        case FETCH_CALIBRATION_SUCCESS: {
            const resData = get(action, 'response', []);
            return {
                ...state,
                calibrationData: resData,
                calibrationTotalCount: resData[0]?.totalCount,
                iscalbrationLoading: false
            }
        }

        case FETCH_CALIBRATION_FAILURE: {
            return {
                ...state,
                iscalbrationLoading: false
            }
        }

        case ADD_CALIBRATION: {
            return {
                ...state,
                isAddUpdateCalibration: true
            }
        }

        case ADD_CALIBRATION_SUCCESS: {
            return {
                ...state,
                isAddUpdateCalibration: false
            }
        }

        case ADD_CALIBRATION_FAILURE: {
            return {
                ...state,
                isAddUpdateCalibration: false
            }
        }

        case SHARE_CALIBRATION: {
            return {
                ...state,
                // isRolesLoading: true
            }
        }

        case SHARE_CALIBRATION_SUCCESS: {
            return {
                ...state,
                iscalbrationLoading: false
            }
        }

        case SHARE_CALIBRATION_FAILURE: {
            return {
                ...state,
                iscalbrationLoading: false
            }
        }

        case UPLOAD_CALIBRATION_FILE: {
            return {
                ...state,
                isUploadCalibration: true
            }
        }

        case UPLOAD_CALIBRATION_FILE_SUCCESS: {
            const resData = get(action, 'response', []);
            return {
                ...state,
                uploadResponse: '',
                isUploadCalibration: false
            }
        }

        case UPLOAD_CALIBRATION_FILE_FAILURE: {
            return {
                ...state,
                isUploadCalibration: false
            }
        }

        case UPDATE_CALIBRATION: {
            return {
                ...state,
                isAddUpdateCalibration: true
            }
        }

        case UPDATE_CALIBRATION_SUCCESS: {
            const resData = get(action, 'response', []);
            return {
                ...state,
                consumables: resData,
                isAddUpdateCalibration: false
            }
        }

        case UPDATE_CALIBRATION_FAILURE: {
            return {
                ...state,
                isAddUpdateCalibration: false
            }
        }

        default: {
            return state;
        }
    }
};

export default calibrationReducer;