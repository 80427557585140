/* eslint-disable no-param-reassign */
/* eslint-disable react/no-unescaped-entities */
import {
    Card, Checkbox,
    Col,
    Form,
    Radio,
    Row,
} from 'antd';
import React, { useEffect, useState } from 'react';

import { connect } from 'react-redux';

//const { Option } = Select;
const WeldingForm = (props) => {

    const {
        Specification,
        partsData,
        setPartsData,
        activeLine,
        weldingData,
        isEdit
    } = props;


    const [selectedOptions, setSelectedOptions] = useState({
        m_Series: [],
        P625: {
            P_625: "",
            Torch: "",
            CMT_Torch: "",
            CMT_Material: "",
            CMT_Wire_Size: ""
        },

    });

    useEffect(() => {

        if (isEdit && weldingData) {
            const data = weldingData[activeLine]?.Welding
           // console.log('weldingData', isEdit, data, selectedOptions)

            if (isEdit && data) {
                setSelectedOptions(prevState => ({
                    ...prevState,
                    m_Series: data.m_Series ? data.m_Series.split(',') : [],
                    P625:
                    {
                        P_625: data.P_625,
                        Torch: data.Torch,
                        CMT_Torch: data.CMT_Torch,
                        CMT_Material: data.CMT_Material,
                        CMT_Wire_Size: data.CMT_Wire_Size
                    }
                }));
            }
        }

    }, [weldingData, activeLine, Specification])

    useEffect(() => {
        if (Specification) {
            setPartsData((prevOptions) => {


                return {
                    ...prevOptions,
                    [`Line_${activeLine + 1}`]: {
                        ...prevOptions[`Line_${activeLine + 1}`],
                        WeldingData: selectedOptions
                    }
                };
            });
           // console.log(selectedOptions);
            // Update the state or perform any other actions with the updated partsData
        }
    }, [selectedOptions, Specification, activeLine]);

    const handleCheckboxChange = (category, checkedValues) => {
        //console.log(category, checkedValues, selectedOptions);

        setSelectedOptions((prevOptions) => {
            return {
                ...prevOptions,
                [category]: checkedValues,
            };
        });
    };
    const handleHeaderCheckboxChange = (category, isChecked, value) => {
        // console.log(category, isChecked, selectedOptions);
        setSelectedOptions(prevOptions => {
            let updatedCategory = { ...prevOptions[category] };

            if (isChecked) {
                // Add values when the header checkbox is checked
                updatedCategory = value;
            } else {
                // Remove values when the header checkbox is unchecked
                updatedCategory = '';
            }

            return {
                ...prevOptions,
                [category]: updatedCategory,
            };
        });
    };

    const handleCheckboxChange1 = (category, type, checkedValues) => {
      

        setSelectedOptions((prevOptions) => {
            return {
                ...prevOptions,
                [category]: {
                    ...prevOptions[category],
                    [type]: checkedValues,
                },
            };
        });
    };
    const updateP625Value= (category, isChecked) =>
    { setSelectedOptions((prevOptions) => {
        const updatedCategory = { ...prevOptions[category] };
       
        if (!isChecked && category=='P625') {
              
            // If the checkbox is unchecked, clear the values for those types
            updatedCategory['P_625'] = ''; 
            updatedCategory['Torch'] = ''; // Clear value
            updatedCategory['CMT_Torch'] = ''; // Clear value
            updatedCategory['CMT_Material'] = ''; // Clear value
            updatedCategory['CMT_Wire_Size'] = ''; // Clear value
        }

        return {
            ...prevOptions,
            [category]: updatedCategory,
        };
    });
        
    }
    const handleHeaderCheckboxChange1 = (category, type, isChecked, value) => {
        
        setSelectedOptions((prevOptions) => {
            const updatedCategory = { ...prevOptions[category] };
           
            if (isChecked) {
                // Add values when the header checkbox is checked
                if(!isArrayNotEmpty(category, type))
                updatedCategory[type] = value;
            }
            else {
                updatedCategory[type] = '';
            }

            return {
                ...prevOptions,
                [category]: updatedCategory,
            };
        });
    }

    const handleRadioChange = (category, type, value) => {
        if (!getCategoryValue1('P625', 'CMT_Torch') || !getCategoryValue1('P625', 'CMT_Material') || !!getCategoryValue1('P625', 'CMT_Wire_Size')) {
            handleHeaderCheckboxChange1('P625', 'CMT_Torch',isArrayNotEmpty('P625', "P_625"), 'F-ORIG')
            handleHeaderCheckboxChange1('P625', 'CMT_Material', isArrayNotEmpty('P625', "P_625"), 'CS')
            handleHeaderCheckboxChange1('P625', 'CMT_Wire_Size',isArrayNotEmpty('P625', "P_625"), '1.0')
        }
        setSelectedOptions((prevOptions) => {

            return {
                ...prevOptions,
                [category]: {
                    ...prevOptions[category],
                    [type]: value,
                },
            };

        });
        // console.log(category, value, selectedOptions)
    };
    
    const handleTorchRadioChange = (category, type, value) => {
        
        setSelectedOptions((prevOptions) => {

            return {
                ...prevOptions,
                [category]: {
                    ...prevOptions[category],
                    [type]: value,
                },
            };

        });
        // console.log(category, value, selectedOptions)
    };


    const isArrayNotEmpty = (category, type) => {
        return (
            selectedOptions.hasOwnProperty(category) && // Check if the category exists in selectedOptions
            selectedOptions[category][type] != '' // Check if the array is not empty
        );
    };
    const isArrayNotEmpty1 = (category, type) => {
        return selectedOptions[category] && Array.isArray(selectedOptions[category][type]) && selectedOptions[category][type].length > 0;
    };

    const getCategoryValue = (category) => {
        if (selectedOptions.hasOwnProperty(category)) { // Check if the category exists in selectedOptions
            return selectedOptions[category]; // Return the value for the category
        } else {
            return null; // Return null if the category does not exist
        }
    };
    const getCategoryValue1 = (category, type) => {
        if (selectedOptions.hasOwnProperty(category)) { // Check if the category exists in selectedOptions
            return selectedOptions[category][type]; // Return the value for the category
        } else {
            return null; // Return null if the category does not exist
        }
    };
    const isArrayContainValue1 = (category, type) => {
        return (
            selectedOptions[category] &&
                Array.isArray(selectedOptions[category][type])
                ? selectedOptions[category][type]
                : []
        );
    };
    const isAnyPropertyWithValue = () => {
        return (

            isArrayNotEmpty('P625', 'CMT_Torch') || isArrayNotEmpty('P625', 'CMT_Material') ||
            isArrayNotEmpty('P625', 'CMT_Wire_Size')
        );
    };

    return (


        <div className="pipeline-modal">

            <div style={{ marginTop: '-10px' }}>
                <Row justify="start">
                    <Col span={4}>
                        <div className="project-details-wrapper text-center">
                            <p className="mb-0" style={{ fontSize: '18px' }}>Specification :</p>

                        </div>
                    </Col>
                    <Col span={4}>
                        <div className="project-details-wrapper text-center">
                            <p className="fw-medium title">Pipe Size(inch)</p>
                            <div className="d-flex edit-session justify-content-center mb-3">
                                <p className="mb-0">{Specification.col1}</p>
                            </div>
                        </div>
                    </Col>
                    <Col span={4}>
                        <div className="project-details-wrapper text-center">
                            <p className="fw-medium title">Wall Thickness(mm)</p>
                            <div className="d-flex edit-session justify-content-center mb-3">
                                <p className="mb-0">{Specification.col2}</p>
                            </div>
                        </div>
                    </Col>
                    <Col span={4}>
                        <div className="project-details-wrapper text-center">
                            <p className="fw-medium title">No. of Welds</p>
                            <div className="d-flex edit-session justify-content-center mb-3">
                                <p className="mb-0">{Specification.col3}</p>
                            </div>
                        </div>
                    </Col>
                    <Col span={4}>
                        <div className="project-details-wrapper text-center">
                            <p className="fw-medium title">No. of Station</p>
                            <div className="d-flex edit-session justify-content-center mb-3">
                                <p className="mb-0">{Specification.col4}</p>
                            </div>
                        </div>
                    </Col>



                </Row>
            </div>
            <div className="custom-line" style={{ marginTop: '-10px' }} />
            <Form

                name="basic"
                autoComplete="off"
                width='100%'
                style={{ marginTop: '-10px' }}
            >



                <Card className="custom-card2" title="EWM" style={{ background: 'rgba(255, 255, 255, 0.1)',height: '400px', fontSize: '20px' }}>
                    <Row gutter={16}>
                        <Col lg={6}>

                            <Checkbox.Group style={{ display: 'grid', fontSize: '10px' }} value={getCategoryValue('m_Series')}
                                onChange={(checkedValues) => handleCheckboxChange('m_Series', checkedValues)}>

                                <Checkbox style={{ fontSize: '18px' }} value='M-500'>M-500</Checkbox>

                                <Checkbox style={{ fontSize: '18px', marginLeft: '-0px', paddingTop: '20px' }} value='M-400'>M-400</Checkbox>

                                <Checkbox style={{ fontSize: '18px', marginLeft: '-0px', paddingTop: '20px' }} value='M-300'>M-300</Checkbox>

                            </Checkbox.Group >
                            <div style={{ display: 'grid', fontSize: '10px', paddingTop: '20px' }} className='report-radio'>
                                <Checkbox style={{ fontSize: '18px', marginLeft: '-0px' }} checked={isArrayNotEmpty('P625', "P_625")}

                                    onChange={(e) => {
                                        updateP625Value("P625", e.target.checked)
                                        handleHeaderCheckboxChange1('P625', 'P_625', e.target.checked, 'P-625')
                                        handleHeaderCheckboxChange1('P625', 'Torch', e.target.checked, 'Single Torch')
                                    }
                                    }>P625</Checkbox>
                            </div>

                        </Col>
                        <Col lg={12}>
                            {
                                isArrayNotEmpty('P625', "P_625") &&
                                <div style={{ display: 'grid', fontSize: '10px' }} className='report-radio'>

                                    {/* <Checkbox style={{ fontSize: '18px' }} checked={isArrayNotEmpty('p625')}

                                    onChange={(e) => handleHeaderCheckboxChange('p625', e.target.checked, 'Single Torch')}>P625</Checkbox> */}
                                    <Form.Item style={{ paddingTop: '10px', paddingLeft: '0px', cursor: 'default' }}
                                        label='Torch Type:'
                                        className='report-radio'
                                    >


                                        <Radio.Group style={{ display: "flex" }} value={getCategoryValue1('P625', 'Torch')}
                                            onChange={(e) => handleTorchRadioChange('P625', 'Torch', e.target.value)} >
                                            <Radio value='Single Torch'>Single </Radio>
                                            <Radio value='Dual Torch'>Dual</Radio>
                                        </Radio.Group>
                                    </Form.Item>
                                    <div style={{ display: 'grid', fontSize: '10px', paddingLeft: '80px' }}>
                                        <Checkbox style={{ fontSize: '18px' }} checked={isAnyPropertyWithValue()}
                                            onChange={(e) => {
                                                handleHeaderCheckboxChange1('P625', 'CMT_Torch', e.target.checked, 'F-ORIG')
                                                handleHeaderCheckboxChange1('P625', 'CMT_Material', e.target.checked, 'CS')
                                                handleHeaderCheckboxChange1('P625', 'CMT_Wire_Size', e.target.checked, '1.0')
                                            }} >CMT</Checkbox>

                                        <Form.Item style={{ paddingTop: '10px', paddingLeft: '20px', cursor: 'default' }}
                                            label='Torch:'
                                            className='report-radio'
                                        >
                                            <div style={{ flexDirection: 'column', paddingTop: '5px' }}>
                                                <Radio.Group style={{ display: "flex" }} onChange={(e) => handleRadioChange('P625', "CMT_Torch", e.target.value)}
                                                    value={getCategoryValue1('P625', 'CMT_Torch')}>
                                                    <Radio value='F-ORIG' >Fronius original</Radio>
                                                    <Radio value='F-NEW' >Fronius New</Radio>
                                                    <Radio value='EWS' >European welding supplies </Radio>
                                                </Radio.Group>
                                            </div>
                                        </Form.Item>
                                        <Form.Item style={{ paddingTop: '0px', paddingLeft: '20px', cursor: 'default' }}
                                            label='Material:'
                                            className='report-radio'
                                        >
                                            <div style={{ display: 'grid', fontSize: '10px' }} className='report-radio'>
                                                <>
                                                    <Radio.Group style={{ display: "flex" }} onChange={(e) => handleRadioChange('P625', "CMT_Material", e.target.value)}
                                                        value={getCategoryValue1('P625', "CMT_Material")}>
                                                        <Radio value='CS'>Carbon steel</Radio>
                                                        <Radio value='SS' >Stainless steel</Radio>
                                                    </Radio.Group>
                                                </>
                                            </div>
                                        </Form.Item>
                                        <Form.Item style={{ paddingTop: '0px', paddingLeft: '20px', cursor: 'default' }}
                                            label='Wire size:'
                                            className='report-radio'
                                        >
                                            <div style={{ flexDirection: 'column', paddingTop: '00px' }}>

                                                <Radio.Group style={{ display: "flex" }}
                                                    onChange={(e) => handleRadioChange('P625', "CMT_Wire_Size", e.target.value)} value={getCategoryValue1('P625', "CMT_Wire_Size")}
                                                >
                                                    <Radio value='1.0' >1.0</Radio>
                                                    <Radio value='1.2'>1.2</Radio>
                                                </Radio.Group>



                                            </div>
                                        </Form.Item>
                                    </div>

                                </div>
                            }
                        </Col>

                    </Row>
                </Card>
            </Form>


        </div>
    );
};



export default connect(null, null)(WeldingForm);

