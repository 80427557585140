/* eslint-disable no-param-reassign */
/* eslint-disable react/no-unescaped-entities */
import { FilePdfOutlined } from '@ant-design/icons';
import {
    Button,
    Col,
    Form, Input,
    Modal,
    Row,
    Select,
    Table, Tooltip,
    notification
} from 'antd';
import { get } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { shareLicenseInfo, sharefetchLicenseList } from '../redux/LicenseManager/action';
const { Option } = Select;
//const { Option } = Select;
const ShareLicenseForm = (props) => {

    const {
        onshareLicenseInfo,
        onFetchLicenseData,
        licenseData,
        licenseTotalCount,
        isLicenseLoading,
        setShowShareModel,
        showShareModel,
        shareForm,
        uniqueIdData, setuniqueIdData,
        licenseUniqueIdList, setLicenseUniqueIdList

    } = props;
    const M500_application_features = ["Solid Wire", "Horizontal Tracking", "TIG Welding"];
    const P625_application_features = ["360 Degree Welding", "Zone to Zone Ramping", "Touch and Ramp"];
    // const M500_application_features = [{ value: "Solid Wire",id:"1" }, { value: "Horizontal Tracking",id:"2" }, { value: "TIG Welding",id:"3" }];
    //const P625_application_features = [{ value: "360 Degree Welding" }, { value: "Zone to Zone Ramping" }, { value: "Touch and Ramp" }, { value: "Fronius TPSi" }];
    const application_types = [{ value: "M500" }, { value: "P625" }]
    const [fileName, setfileName] = useState("")
    const [uniqueId, setUniqueId] = useState('');
    // const [m500Setected, setM500Selected] = useState('');
    const [applicationType, setApplicationType] = useState('M500');
    const [chxList, setChxList] = useState(M500_application_features);
    const [selectedchxList, setSelectedChxList] = useState([]);
    // const [uniqueIdData, setuniqueIdData] = useState([]);
    const [email, setEmail] = useState('');
    // const [licenseUniqueIdList, setLicenseUniqueIdList] = useState([]);
    const [searchText, setSearchText] = useState('')
    const [selectedRow, setSelectedRow] = useState([]);

    const update = async () => {

        setLicenseUniqueIdList([])
        await onFetchLicenseData();

    }
    useEffect(() => {
        if (!isLicenseLoading)
            setuniqueIdData([...licenseData])

    }, [isLicenseLoading])


    useEffect(() => {

        if (licenseUniqueIdList.length == 0 && uniqueIdData) {
            const a = uniqueIdData.map((item, i) => {
                return item.Unique_Id
            }
            )
            const uniqueArray = a.filter(function (item, pos) {
                return a.indexOf(item) == pos;
            })
            // if (licenseUniqueIdList && licenseUniqueIdList.length == 0)
            setLicenseUniqueIdList([...uniqueArray]);
        }

    }, [uniqueIdData])
    const columns = [

        {
            title: 'User Identifier',
            dataIndex: 'License_File_Name',
            key: 'License_File_Name',
            filterSearch: true,
            width: '150px',
            sorter: (a, b) => (a.License_File_Name.localeCompare(b.License_File_Name)),
            render: (text) => <a style={{ paddingLeft: '2px' }}>{text}</a>,
        },
        {
            title: 'Unique Id',
            dataIndex: 'Unique_Id',
            key: 'Unique_Id',
            width: '220px',
            sorter: (a, b) => a.Unique_Id.localeCompare(b.Unique_Id),
            render: (text) => <a>{text}</a>,
            align: 'center',
        },
        {
            title: 'Machine Type',
            dataIndex: 'Type_Of_Device',
            key: 'Type_Of_Device',
            align: 'center',
            sorter: (a, b) => a.Type_Of_Device.localeCompare(b.Type_Of_Device)
        },
        {
            title: 'Board Type',
            dataIndex: 'Board_Type',
            key: 'Board_Type',
            align: 'center',
            sorter: (a, b) => (a.Board_Type.localeCompare(b.Board_Type))
        },
        {
            title: 'Feature Type',
            dataIndex: 'Feature_Type',
            key: 'Feature_Type',
            align: 'center',
            width: '160px',
            sorter: (a, b) => (a.Feature_Type.localeCompare(b.Feature_Type))
        },
        {
            title: 'Customer Name',
            dataIndex: 'Customer_Name',
            key: 'Customer_Name',
            align: 'center',
            render: (text) => <div style={{ display: 'flex', placeContent: 'center' }}>{text ? text : '-'}</div>,
            sorter: (a, b) => (a.Customer_Name.localeCompare(b.Customer_Name))
        },
        {
            title: 'Machine Serial Number',
            dataIndex: 'Serial_Number',
            key: 'Serial_Number',
            width: '160px',
            align: 'center',
            render: (text) => <div style={{ placeContent: 'center' }}>{text ? text : '-'}</div>,
            sorter: (a, b) => (a.Serial_Number.localeCompare(b.Serial_Number))
        },
        {
            title: 'Contractor',
            dataIndex: 'Contractor',
            key: 'Contractor',
            
            align: 'center',
            render: (text) => <div style={{ placeContent: 'center' }}>{text ? text : '-'}</div>,
            sorter: (a, b) => (a.Contractor.localeCompare(b.Contractor))
        },
        {
            title: 'Create Date',
            dataIndex: 'File_Creation_Date',
            key: 'File_Creation_Date',
            align: 'center',
            render: (text) => {
                return (
                    <div>{text ? moment(text).format('DD MMM YYYY') : '-'}</div>
                );
            },
            sorter: (a, b) => moment(a.File_Creation_Date).valueOf() - moment(b.File_Creation_Date).valueOf()
        },
        {
            title: 'Expiration Date',
            dataIndex: 'Expiration_Date',
            key: 'Expiration_Date',
            align: 'center',
            render: (text) => {
                return (
                    <div>{text ? moment(text).format('DD MMM YYYY') : '-'}</div>
                );
            },
            // sorter: (a, b) => a.Expiration_Date - b.Expiration_Date,
            sorter: (a, b) => moment(a.Expiration_Date).valueOf() - moment(b.Expiration_Date).valueOf()
        },

        {
            title: 'Link',
            dataIndex: 'File_Link',
            key: 'File_Link',
            render: (text, record) => {
                return (
                    <>
                        <Tooltip title="Download license file" >

                            <Button href={record.File_Link} className='p-0 border-0' size='small' target='_blank'>
                                <FilePdfOutlined className='pdfIconRed' />
                            </Button>

                        </Tooltip>
                    </>
                )
            }
        },
    ];
    useEffect(() => {
        if (applicationType === 'M500') {
            setChxList(M500_application_features)
        }
        else {
            setChxList(P625_application_features)
        }

    }, [applicationType])

    useEffect(() => {
        if (licenseUniqueIdList.length > 0) {
            var obj = {
                // pageSize: 10,
                //pageNumber: 1,
                searchKey: searchText
            }


            onFetchLicenseData(obj)

        }
    }, [searchText]);
    useEffect(() => {
        if (!showShareModel) {
            setuniqueIdData([])

            shareForm.resetFields();
            setSearchText('')
            setLicenseUniqueIdList([])
            console.log(" handle false")
        }
        else {
            update()
        }

    }, [showShareModel])
    const validateEmail = (email) => {
        const regexp = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
        return regexp.test(email);
    }
    // const [shareForm] = Form.useForm()

    const handleOk = async () => {
        console.log()
        try {



            shareForm
                .validateFields()
                .then((data) => {

                    if (!data.email && !selectedRow) {
                        notification.warning({
                            message: 'Select All Required Fields to proceed!'
                        });
                    } else if (!data.email || !validateEmail(email)) {
                        notification.warning({
                            message: 'please enter valid mail id  to proceed!'
                        });
                    } else if (!selectedRow || selectedRow.length == 0) {
                        notification.warning({
                            message: 'Select Row  to proceed!'
                        });
                    }
                    else {
                        //console.log(selectedRow)
                        var obj = {
                            "emailAddress": data.email,
                            "uniqueId": data.uniqueId,
                            "selectedRow": selectedRow
                        }
                        onshareLicenseInfo(obj)
                        handleCancel();
                    }

                });
        } catch (error) {
            notification.destroy();
            notification.error({
                message: error.message,
            });
        }

    };
    const handleCancel = async () => {
        setSearchText('')
        shareForm.resetFields();

        setLicenseUniqueIdList([])
        setuniqueIdData([])

        var obj = {
            //   pageSize: 10,
            //  pageNumber: 1,
            searchKey: ''
        }

        await onFetchLicenseData(obj)
        setShowShareModel(false);

    };


    const onChangeUniqueid = async (value) => {

        if (value) {
            setSearchText(value);
        }
        else
            setSearchText('')
    }


    return (

        <div>
            <div>

                <Modal
                    width="100%"
                    height='100%'
                    className="pipeline-modal"
                    title="Share License"
                    centered
                    visible={showShareModel}
                    footer={[
                        <div style={{ paddingTop: '40px', paddingRight: '10px' }}>
                            <Button key="cancel"
                                onClick={handleCancel}
                            >
                                Cancel
                            </Button>
                            <Button key="apply" type="primary"
                                onClick={handleOk}
                                disabled={(email.length > 0 && selectedRow.length > 0) ? false : true}
                                style={{
                                    backgroundColor: (email.length > 0 && selectedRow.length > 0) ? "#ef314c" : "gray",
                                }}
                            // loading={isLogReportsLoading}
                            >
                                Share
                            </Button>
                        </div>
                    ]}
                >
                    <div>
                        <Form
                            form={shareForm}
                            name="basic"
                            layout="vertical"
                            autoComplete="off"
                        >
                            <Row gutter={16}>
                                <Col lg={8} style={{ marginBottom: '10px' }}>
                                    <Form.Item
                                        label="Email"
                                        name="email"
                                        required
                                        style={{ marginBottom: 0 }}
                                    // rules={[
                                    //     {
                                    //         required: true,
                                    //         message: 'Please input your registered email!',
                                    //     },
                                    //     {
                                    //         pattern:EMAIL_PATTERN,

                                    //         message: 'Provide a valid email.',
                                    //     }
                                    // ]}
                                    >
                                        <Input
                                            // disabled
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value.toLowerCase())}
                                            size="large"
                                            placeholder='Enter emails followed by comma (,)'
                                        // onKeyDown={onKeyDown}
                                        />

                                    </Form.Item>
                                    <span style={{ fontSize: '12px', position: 'relative', left: '10px', color: 'gray' }}>eg: 1234@mail.com, 234@email.com</span>
                                </Col>
                                <Col lg={8} style={{ marginBottom: '10px' }}>
                                    <Form.Item
                                        label="Unique Id"
                                        name="uniqueId"

                                    >
                                        <Select
                                            className='client-select'
                                            placeholder='Select Unique Id'
                                            showSearch
                                            allowClear
                                            optionFilterProp="children"
                                            onChange={onChangeUniqueid}
                                            style={{
                                                height: '30px',
                                                position: 'relative',
                                                width: '250px',
                                            }}
                                        >
                                            {licenseUniqueIdList && licenseUniqueIdList.map((item, i) =>

                                                <Option key={i} value={item}>{item}</Option>)}
                                        </Select>
                                    </Form.Item>
                                </Col>

                            </Row>
                        </Form>
                    </div>
                    <div className='addTeamDetails'>
                        <Table columns={columns}
                            dataSource={[...licenseData]}
                            className="h-100 pipeline-table equip_table equipPaginationTable"
                           scroll={{ y: 'calc(80vh - 260px)' }}
                          // scroll={{ y: 'calc(100vh - 320px)' ,x:1600}}
                            rowKey={(record) => record.id}


                            rowSelection={{

                                // onChange: (selectedRowKeys, selectedRows) => {
                                //     console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
                                // },
                                onSelect: (record, selected, selectedRows) => {
                                    //  const   data = selectedRows.filter(function( element ) {
                                    //         return element !== undefined;
                                    //      });
                                    //     console.log(selected);
                                    //     console.log(data);

                                    //     const array3 = [...selectedRow, ...data]
                                    //      console.log(array3);
                                    setSelectedRow(selectedRows);

                                    //console.log(selectedRow);
                                },
                                onSelectAll: (selected, selectedRows, changeRows) => {

                                    // const array3 = [...selectedRow, ...selectedRows]
                                    setSelectedRow(selectedRows);
                                    //console.log(selectedRow);
                                },
                            }}
                            pagination={licenseTotalCount > 10 ? {
                                position: ['bottomRight'],
                                //className: 'fixed-pagination2',
                                showSizeChanger: true,
                                total: licenseTotalCount,
                                defaultPageSize: 10,
                                defaultCurrent: 1,

                                //  onChange: async (pageNumber, pageSize,selectedRows) => {
                                // console.log(licenseTotalCount, pageNumber, pageSize)
                                // var obj = {
                                //     pageSize: pageSize,
                                //     pageNumber: pageNumber
                                // }
                                // await onFetchLicenseData(obj)
                                //   selectedRows =selectedRow
                                //  console.log(selectedRows);


                                //}
                            }
                                : false
                            }
                            loading={isLicenseLoading}
                        />

                    </div>
                </Modal >
            </div>
        </div>
    );
};

const mapStateToProps = ({ licenseMangerReducer }) => {
    const licenseData = get(licenseMangerReducer, 'shareList', []);
    // let licenseUniqueIdList = get(licenseMangerReducer, 'LicenseData', []);
    // if (licenseData) {
    //     const a = licenseData.map((item, i) => {
    //         return item.Unique_Id
    //     }
    //     )
    //     const uniqueArray = a.filter(function (item, pos) {
    //         return a.indexOf(item) == pos;
    //     })
    //     licenseUniqueIdList = uniqueArray;
    // }


    const isLicenseLoading = get(licenseMangerReducer, 'isLicenseLoading', false);
    //const isUploadLicsense = get(licenseMangerReducer, 'isUploadLicsense', false);
    const licenseTotalCount = get(licenseMangerReducer, 'LicenseListCount', null);
    return {
        licenseData,
        isLicenseLoading,
        // isUploadLicsense,
        licenseTotalCount
    }
}



const mapDispatchToProps = {
    onshareLicenseInfo: shareLicenseInfo,
    onFetchLicenseData: sharefetchLicenseList,

};

export default connect(mapStateToProps, mapDispatchToProps)(ShareLicenseForm);

