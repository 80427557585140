/* eslint-disable no-param-reassign */
/* eslint-disable react/no-unescaped-entities */
import {
    Card, Checkbox,
    Col,
    Form,
    Row
} from 'antd';
import React, { useEffect, useState } from 'react';

import { connect } from 'react-redux';


//const { Option } = Select;
const BevellingForm = (props) => {

    const {
        Specification,
        partsData,
        setPartsData,
        activeLine,
        bevelingData,
        isEdit
    } = props;
    const containerStyle = {
        display: 'grid',
        gridTemplateColumns: 'repeat(5, 1fr)', // Adjust the number based on the desired columns
        gap: '5px',
        margin: '5px',
    };
    // const [bevelling, setBevelling] = useState()
    const [selectedOptions, setSelectedOptions] = useState({
        HPU: { CRCE: [], Goriziance: [] },
        PFM: { CRCE: [], Goriziance: [] },
        Bevel: { IWM: [], CMT: [], CBUC: [] },
    });
    useEffect(() => {
        ///console.log(bevelingData)
        //console.log(activeLine)
        const editBevelingData = bevelingData[activeLine]?.Beveling
       // console.log('editBevelingData', editBevelingData)
        if (isEdit && editBevelingData) {
            if (isEdit && editBevelingData) {
                Object.keys(editBevelingData).forEach(key => {
                    const parts = key.split('_');
                    if (parts.length === 2) {
                        const [prefix, suffix] = parts;
                        if (selectedOptions.hasOwnProperty(prefix) && selectedOptions[prefix].hasOwnProperty(suffix)) {
                            let values = [];
                            if (editBevelingData[key]) { // Check if the value is not empty
                                values = editBevelingData[key].split(','); // Split the string into individual values
                            }
                            setSelectedOptions(prevState => ({
                                ...prevState,
                                [prefix]: {
                                    ...prevState[prefix],
                                    [suffix]: values // Assign the array of values directly
                                }
                            }));
                        }
                    }
                });
            }
        }

    }, [bevelingData, activeLine])

    const handleCheckboxChange = (category, type, checkedValues) => {
        //console.log(category, type, checkedValues, selectedOptions);

        setSelectedOptions((prevOptions) => {
            return {
                ...prevOptions,
                [category]: {
                    ...prevOptions[category],
                    [type]: checkedValues,
                },
            };
        });
    };
    const handleHeaderCheckboxChange = (category, type, isChecked, value) => {
        setSelectedOptions((prevOptions) => {
            const updatedCategory = { ...prevOptions[category] };

            if (isChecked) {
                // Add values when the header checkbox is checked
                updatedCategory[type] = value;
            } else {
                // Remove values when the header checkbox is unchecked
                updatedCategory[type] = [];
            }

            return {
                ...prevOptions,
                [category]: updatedCategory,
            };
        });
    }

    useEffect(() => {
        if (Specification) {

            setPartsData(prevOptions => {
          
                return {
                    ...prevOptions,
                    [`Line_${activeLine + 1}`]: {
                        ...prevOptions[`Line_${activeLine + 1}`],
                        BevelingData: selectedOptions
                    }
                };
            });

           
            // Update the state or perform any other actions with the updatedProjectDetails

        }
       // console.log(selectedOptions);
    }, [selectedOptions, Specification]);



    const isArrayNotEmpty = (category, type) => {
        return selectedOptions[category] && Array.isArray(selectedOptions[category][type]) && selectedOptions[category][type].length > 0;
    };
    const isArrayContainValue = (category, type) => {
        return (
            selectedOptions[category] &&
                Array.isArray(selectedOptions[category][type])
                ? selectedOptions[category][type]
                : []
        );
    };

    return (
        <div  >

            <div style={{ marginTop: '-10px' }}>
                <Row justify="start">
                    <Col span={4}>
                        <div className="project-details-wrapper text-center">
                            <p className="mb-0" style={{ fontSize: '18px' }}>Specification :</p>

                        </div>
                    </Col>
                    <Col span={4}>
                        <div className="project-details-wrapper text-center">
                            <p className="fw-medium title">Pipe Size(inch)</p>
                            <div className="d-flex edit-session justify-content-center mb-3">
                                <p className="mb-0">{Specification.col1}</p>
                            </div>
                        </div>
                    </Col>
                    <Col span={4}>
                        <div className="project-details-wrapper text-center">
                            <p className="fw-medium title">Wall Thickness(mm)</p>
                            <div className="d-flex edit-session justify-content-center mb-3">
                                <p className="mb-0">{Specification.col2}</p>
                            </div>
                        </div>
                    </Col>
                    <Col span={4}>
                        <div className="project-details-wrapper text-center">
                            <p className="fw-medium title">No. of Welds</p>
                            <div className="d-flex edit-session justify-content-center mb-3">
                                <p className="mb-0">{Specification.col3}</p>
                            </div>
                        </div>
                    </Col>
                    <Col span={4}>
                        <div className="project-details-wrapper text-center">
                            <p className="fw-medium title">No. of Station</p>
                            <div className="d-flex edit-session justify-content-center mb-3">
                                <p className="mb-0">{Specification.col4}</p>
                            </div>
                        </div>
                    </Col>



                </Row>
            </div>
            <div className="custom-line" style={{ marginTop: '-10px' }} />
            <Form

                name="basic"
                autoComplete="off"
                width='100%'
                scroll={{ y: 'calc(100vh - 230px)' }}
                style={{ marginTop: '-10px' }}
            >

                <Row gutter={8} >

                    <Col lg={4} span={2}>
                        <Card className='custom-card3'
                            scroll={{ y: 'calc(100vh - 220px)' }}
                            // headStyle={{ alignContent: 'start' }}

                            headStyle={{ alignItems: 'start', display: 'flow', justifyContent: 'space-between', width: '100%' }}
                            title={
                                <div style={{ position: 'relative', display: 'flex', width: '100%' }}>
                                    <span style={{ marginLeft: '0px' }}>HPU</span>
                                    {/* <Checkbox style={{ marginLeft: 'auto' }} checked={isArrayNotEmpty('HPU','CE')}
                                     onChange={(e) => handleHeaderCheckboxChange('HPU', 'CE', e.target.checked, 'True')} >CE</Checkbox> */}
                                </div>
                            }
                            style={{ background: 'rgba(255, 255, 255, 0.1)', borderColor: (255, 255, 255, 0.1), height: '400px', fontSize: '20px' }}
                        >


                            <div style={{ display: 'Grid' }}>
                                <Checkbox style={{ fontSize: '18px' }} checked={isArrayNotEmpty('HPU', 'CRCE')}
                                    onChange={(e) => handleHeaderCheckboxChange('HPU', 'CRCE', e.target.checked, ['Electric','Diesel'])} >CRCE</Checkbox>

                                <Checkbox.Group style={{ display: 'Grid' }} value={isArrayContainValue('HPU', 'CRCE')}
                                    onChange={(checkedValues) => handleCheckboxChange('HPU', 'CRCE', checkedValues)}>
                                    <Checkbox style={{ marginLeft: '20px' }} value='Electric'>Electric</Checkbox>
                                    <Checkbox style={{ marginLeft: '20px' }} value='Diesel'>Diesel</Checkbox>
                                </Checkbox.Group>


                            </div>

                            <div style={{ display: 'Grid', paddingTop: '30px' }}>
                                <Checkbox style={{ fontSize: '18px' }} checked={isArrayNotEmpty('HPU', 'Goriziance')}
                                    onChange={(e) => handleHeaderCheckboxChange('HPU', 'Goriziance', e.target.checked, ['Electric','Diesel'])}>Goriziane</Checkbox>

                                <Checkbox.Group style={{ display: 'Grid' }} value={isArrayContainValue('HPU', 'Goriziance')}
                                    onChange={(checkedValues) => handleCheckboxChange('HPU', 'Goriziance', checkedValues)}>
                                    <Checkbox style={{ marginLeft: '20px' }} value='Electric'>Electric</Checkbox>
                                    <Checkbox style={{ marginLeft: '20px' }} value='Diesel'>Diesel</Checkbox>
                                </Checkbox.Group>

                            </div>

                        </Card>
                    </Col>


                    <Col lg={12}>
                        <Card className='custom-card3'
                            scroll={{ y: 'calc(100vh - 400px)' }}

                            headStyle={{ alignItems: 'start', display: 'flow', justifyContent: 'space-between', width: '100%' }}
                            title={
                                <div style={{ position: 'relative', display: 'flex', width: '100%' }}>
                                    <span style={{ marginLeft: '0px' }}>PFM</span>
                                    {/* <Checkbox style={{ marginLeft: 'auto' }} checked={isArrayNotEmpty('PFM','CE')}
                                    onChange={(e) => handleHeaderCheckboxChange('PFM', 'CE', e.target.checked, 'True')}>CE</Checkbox> */}
                                </div>
                            }
                            style={{ background: 'rgba(255, 255, 255, 0.1)', borderColor: (255, 255, 255, 0.1), height: '400px', fontSize: '20px' }}>
                            <div>
                                <Checkbox style={{ fontSize: '18px', paddingRight: '700px' }} checked={isArrayNotEmpty('PFM', 'CRCE')}
                                    onChange={(e) => handleHeaderCheckboxChange('PFM', 'CRCE', e.target.checked, ['16','18','20'
                                    ,'22','24','26','28','30','32','34','36','38','40OS','40NS','42OS','42NS','48OS','48NS' ,'56'],)} >CRCE</Checkbox>


                                <Checkbox.Group style={containerStyle} value={isArrayContainValue('PFM', 'CRCE')}
                                    onChange={(checkedValues) => handleCheckboxChange('PFM', 'CRCE', checkedValues)} >
                                    <Checkbox value='16' style={{ marginLeft: '7px' }}>16</Checkbox>
                
                                    <Checkbox value='18' >18</Checkbox>

                                    <Checkbox value='20' >20</Checkbox>
                                    <Checkbox value='22' >22</Checkbox>
                                    <Checkbox value='24'>24</Checkbox>
                                    <Checkbox value='26'>26</Checkbox>
                                    <Checkbox value='28'>28</Checkbox>
                                    <Checkbox value='30' >30</Checkbox>
                                    <Checkbox value='32' >32</Checkbox>
                                   

                                    <Checkbox value='36'>36</Checkbox>
                                    <Checkbox value='38' >38</Checkbox>
                                    <Checkbox value='40OS' >40 OS</Checkbox>
                                    <Checkbox value='40NS' >40 NS</Checkbox>
                                    <Checkbox value='42OS' >42 OS</Checkbox>
                                    <Checkbox value='42NS' >42 NS</Checkbox>
                                    <Checkbox value='48OS' >48 OS</Checkbox>
                                    <Checkbox value='48NS' >48 NS</Checkbox>
                                    <Checkbox value='56' >56</Checkbox>
                                </Checkbox.Group>
                            </div>

                            <div>
                                <Checkbox style={{ fontSize: '18px', paddingRight: '700px' }} checked={isArrayNotEmpty('PFM', 'Goriziance')}
                                    onChange={(e) => handleHeaderCheckboxChange('PFM', 'Goriziance', e.target.checked, ['8','10','12','14','16','18','20'
                                    ,'22','24','26','28','30','32','36','40','42','44','46','48','56'])}>Goriziane</Checkbox>

                                <Checkbox.Group style={containerStyle} value={isArrayContainValue('PFM', 'Goriziance')}
                                    onChange={(checkedValues) => handleCheckboxChange('PFM', 'Goriziance', checkedValues)} >

                                    <Checkbox value='8' style={{ marginLeft: '7px' }}>8</Checkbox>
                                   
                                    <Checkbox value='10' >10</Checkbox>
                                    <Checkbox value='12' >12</Checkbox>
                                    <Checkbox value='14' >14</Checkbox>
                                    <Checkbox value='16' >16</Checkbox>
                                    <Checkbox value='18' >18</Checkbox>

                                    <Checkbox value='20' >20</Checkbox>
                                    <Checkbox value='22' >22</Checkbox>
                                    <Checkbox value='24'>24</Checkbox>
                                    <Checkbox value='26'>26</Checkbox>
                                    <Checkbox value='28'>28</Checkbox>
                                    <Checkbox value='30' >30</Checkbox>
                                    <Checkbox value='32' >32</Checkbox>


                                    <Checkbox value='36'>36</Checkbox>

                                    <Checkbox value='40'>40 </Checkbox>

                                    <Checkbox value='42'>42 </Checkbox>
                                    <Checkbox value='44'>44 </Checkbox>
                                    <Checkbox value='46'>46 </Checkbox>
                                    <Checkbox value='48'>48 </Checkbox>
                                    <Checkbox value='56'>56</Checkbox>
                                </Checkbox.Group>
                            </div>

                        </Card>
                    </Col>
                    <Col lg={8}>
                        <Card className='custom-card2' title='Bevel'
                            style={{ background: 'rgba(255, 255, 255, 0.1)', borderColor: (255, 255, 255, 0.1), height: '400px', fontSize: '20px' }}
                        >

                            <div style={{ display: 'Grid' }}>
                                <Checkbox style={{ fontSize: '18px' }} checked={isArrayNotEmpty('Bevel', 'IWM')}
                                    onChange={(e) => handleHeaderCheckboxChange('Bevel', 'IWM', e.target.checked, ['10deg-116off-(below-15mm-WT)','5deg-110off-(above-15mm-WT)'])} >IWM</Checkbox>
                                <Checkbox.Group style={{ display: 'Grid' }} value={isArrayContainValue('Bevel', 'IWM')}
                                    onChange={(checkedValues) => handleCheckboxChange('Bevel', 'IWM', checkedValues)}>
                                    <Checkbox style={{ marginLeft: '20px' }} value='10deg-116off-(below-15mm-WT)'>10&#176;, 116 off (below 15mm WT)</Checkbox>

                                    <Checkbox style={{ marginLeft: '20px' }} value='5deg-110off-(above-15mm-WT)'>5&#176;, 110 off (above 15mm WT)</Checkbox>
                                </Checkbox.Group>
                            </div>



                            <div style={{ display: 'Grid', paddingTop: '30px' }}>

                                <Checkbox style={{ fontSize: '18px' }} checked={isArrayNotEmpty('Bevel', 'CMT')}
                                    onChange={(e) => handleHeaderCheckboxChange('Bevel', 'CMT', e.target.checked, ['5deg-130off'])}>CMT </Checkbox>
                                <Checkbox.Group style={{ display: 'Grid' }} value={isArrayContainValue('Bevel', 'CMT')}
                                    onChange={(checkedValues) => handleCheckboxChange('Bevel', 'CMT', checkedValues)}>
                                    <Checkbox style={{ marginLeft: '20px' }} value='5deg-130off'>5&#176;, 130 off</Checkbox>
                                </Checkbox.Group>

                            </div>
                            <div style={{ display: 'Grid', paddingTop: '30px' }}>


                                <Checkbox style={{ fontSize: '18px' }} checked={isArrayNotEmpty('Bevel', 'CBUC')}
                                    onChange={(e) => handleHeaderCheckboxChange('Bevel', 'CBUC', e.target.checked, ['5deg-110off','3deg-116off'])}>CBUC </Checkbox>
                                <Checkbox.Group style={{ display: 'Grid' }} value={isArrayContainValue('Bevel', 'CBUC')}
                                    onChange={(checkedValues) => handleCheckboxChange('Bevel', 'CBUC', checkedValues)}>

                                    <Checkbox style={{ marginLeft: '20px' }} value='5deg-110off'>5&#176;, 110 off</Checkbox>

                                    <Checkbox style={{ marginLeft: '20px' }} value='3deg-116off'>3&#176;, 116 off</Checkbox>
                                </Checkbox.Group>
                            </div>

                        </Card>
                    </Col>
                </Row>


            </Form>
        </div>

    );
};



export default connect(null, null)(BevellingForm);

