/* eslint-disable no-param-reassign */
/* eslint-disable react/no-unescaped-entities */
import {
    Card,
    Col,
    Form,
    Radio,
    Row, Checkbox, Typography, InputNumber
} from 'antd';
import React, { useEffect, useState } from 'react';


import { connect } from 'react-redux';
const { Title, Text } = Typography;




//const { Option } = Select;
const SupportItemsForm = (props) => {

    const {
        Specification,
        partsData,
        setPartsData,
        activeLine,
        supportItemData,
        isEdit

    } = props;

    const [selectedOptions, setSelectedOptions] = useState({
        projectType: 'None',
        grinding_Tool_Consumables_Required: 'No',
        PPE: {
            Required: "No",
            Project_Duration: 0,
            Mainline_Welders: 0,
            Repair_Welders: 0,
            Bevel_Machine_Operators: 0,
            Technicians:0,
            General_Helper: 0,
            Air_Fed_Welding_Hood: "No",
            Non_Air_Fed_Hood: "No",
            Cool_Vest: "No"
        }   

    });
    useEffect(() => {

        if (isEdit && supportItemData) {
            const data = supportItemData[activeLine]?.SupportItem
    
            if (isEdit && data) {
                setSelectedOptions({
                    projectType: data.ProjectType ?? '',
                    grinding_Tool_Consumables_Required: data.Grinding_tool_consumables_required ?? '',
                    PPE: data.PPE ??   {
                        Required: "No",
                        Project_Duration: 0,
                        Mainline_Welders: 0,
                        Repair_Welders: 0,
                        Bevel_Machine_Operators: 0,
                        Technicians:0,
                        General_Helper: 0,
                        Air_Fed_Welding_Hood: "No",
                        Non_Air_Fed_Hood: "No",
                        Cool_Vest: "No"
                    }   
                });
            }
        }

    }, [supportItemData, activeLine])
    useEffect(() => {
        if (Specification) {

            setPartsData((prevOptions) => {

                return {
                    ...prevOptions,
                    [`Line_${activeLine + 1}`]: {
                        ...prevOptions[`Line_${activeLine + 1}`],
                        SupportItemData: selectedOptions,
                    },
                };
            });
        }
    }, [selectedOptions, Specification, activeLine]);

    const handleRadioChange = (category, value) => {
        setSelectedOptions((prevOptions) => {
            return {
                ...prevOptions,
                [category]: value
            };
        });
    };

    const getCategoryValue = (category) => {

        if (selectedOptions.hasOwnProperty(category)) { // Check if the category exists in selectedOptions
            return selectedOptions[category]; // Return the value for the category
        } else {
            return null; // Return null if the category does not exist
        }
    };
    const getCategoryValue1 = (type) => {
        if (selectedOptions.hasOwnProperty('PPE')) { // Check if the category exists in selectedOptions
            return selectedOptions['PPE'][type]; // Return the value for the category
        } else {
            return null; // Return null if the category does not exist
        }
    };
    const getPPRequiredValue = (type) => {
        if (selectedOptions.hasOwnProperty('PPE')) { // Check if the category exists in selectedOptions
            return selectedOptions['PPE']['Required']; // Return the value for the category
        } else {
            return 'No'; // Return null if the category does not exist
        }
    };

    const handleInputChange = (type, values) => {
        const category = 'PPE'
        setSelectedOptions((prevOptions) => {
            return {
                ...prevOptions,
                [category]: {
                    ...prevOptions[category],
                    [type]: values,
                },
            };
        });
    };
    // const handleBlur = (row, col, value) => {
    //     // Ensure the value is not empty, set it to 0 if it is
    //     if (value === '') {
    //         handleInputChange(row, col, 0);
    //     }
    // };



    return (

        <div>

            <div style={{ marginTop: '-10px' }}>
                <Row justify="start">
                    <Col span={4}>
                        <div className="project-details-wrapper text-center">
                            <p className="mb-0" style={{ fontSize: '18px' }}>Specification :</p>

                        </div>
                    </Col>
                    <Col span={4}>
                        <div className="project-details-wrapper text-center">
                            <p className="fw-medium title">Pipe Size(inch)</p>
                            <div className="d-flex edit-session justify-content-center mb-3">
                                <p className="mb-0">{Specification.col1}</p>
                            </div>
                        </div>
                    </Col>
                    <Col span={4}>
                        <div className="project-details-wrapper text-center">
                            <p className="fw-medium title">Wall Thickness(mm)</p>
                            <div className="d-flex edit-session justify-content-center mb-3">
                                <p className="mb-0">{Specification.col2}</p>
                            </div>
                        </div>
                    </Col>
                    <Col span={4}>
                        <div className="project-details-wrapper text-center">
                            <p className="fw-medium title">No. of Welds</p>
                            <div className="d-flex edit-session justify-content-center mb-3">
                                <p className="mb-0">{Specification.col3}</p>
                            </div>
                        </div>
                    </Col>
                    <Col span={4}>
                        <div className="project-details-wrapper text-center">
                            <p className="fw-medium title">No. of Station</p>
                            <div className="d-flex edit-session justify-content-center mb-3">
                                <p className="mb-0">{Specification.col4}</p>
                            </div>
                        </div>
                    </Col>



                </Row>
            </div>
            <div className="custom-line" style={{ marginTop: '-10px' }} />
            <Form

                name="basic"
                autoComplete="off"
                width='100%'
                layout="vertical"
                style={{ marginTop: '-10px' }}
            >


                <Card className='custom-card5' title='Project Type'
                    style={{ background: 'rgba(255, 255, 255, 0.1)', borderColor: (255, 255, 255, 0.1), height: '400px' }}
                >
                    <Row gutter={16}>
                        <Col lg={10}>
                            <div style={{ position: 'relative', display: 'flex', flexDirection: 'column', paddingTop: '20px' }} >
                                <div style={{ marginBottom: '8px', paddingRight: '70px' }}>
                                    <span style={{ marginRight: '20px' }}>Types :</span>
                                    <Radio.Group style={{ display: "inline-flex" }} value={getCategoryValue('projectType')}
                                        onChange={(e) => handleRadioChange('projectType', e.target.value)}>
                                        <Radio value='Onshore'>Onshore</Radio>
                                        <Radio value='Offshore'>Offshore</Radio>
                                        <Radio value='None'>None</Radio>
                                    </Radio.Group>
                                </div>
                                <div style={{ marginTop: '20px' }}>
                                    <span style={{ marginRight: '20px' }}>Grinding tool consumables required? :</span>
                                    <Radio.Group style={{ display: "inline-flex" }} value={getCategoryValue('grinding_Tool_Consumables_Required')}
                                        onChange={(e) => handleRadioChange('grinding_Tool_Consumables_Required', e.target.value)}>
                                        <Radio value='Yes'>Yes</Radio>
                                        <Radio value='No'>No</Radio>
                                    </Radio.Group>
                                </div>
                               

                            </div>
                            <div style={{ marginTop: '20px',marginLeft:'-150px' }}>
                                    <span style={{ marginRight: '20px' }}>PPE Required :</span>
                                    <Radio.Group style={{ display: "inline-flex" }}
                                       onChange={(v) => handleInputChange('Required', v.target.value )} value={getPPRequiredValue()}>
                                    
                                        <Radio value='Yes'>Yes</Radio>
                                        <Radio value='No'>No</Radio>
                                    </Radio.Group>
                                </div>
                        </Col>

                        {
                            <Col lg={13}>
                                {/* <Row gutter={16} style={{ marginTop: '20px' }}>
                                    <Col lg={8}>
                                        <Checkbox style={{ fontSize: '18px', paddingRight: '20px' }}
                                            onChange={(v) => handleInputChange('Required', v.target.checked ? 'True' : 'False')}
                                        >PPE Required</Checkbox>
                                    </Col>
                                </Row> */}
                                {getPPRequiredValue('Required')=='Yes' &&
                                    <div>
                                        <Row gutter={16} style={{ marginTop: '20px' }}>
                                            <Col lg={8}>
                                                <Form.Item label="Project Duration" >
                                                    <InputNumber type="number" size="large" value={getCategoryValue1('Project_Duration')}
                                                    min={0}
                                                    max={1000}
                                                        onChange={(v) => handleInputChange('Project_Duration', v)}
                                                    />

                                                </Form.Item>
                                            </Col>
                                            <Col lg={8}>
                                                <Form.Item label="Mainline Welders" >
                                                    <InputNumber type="number" size="large"  value={getCategoryValue1('Mainline_Welders')}
                                                    min={0}
                                                    max={1000}
                                                        onChange={(v) => handleInputChange('Mainline_Welders', v)} />
                                                </Form.Item>
                                            </Col>
                                            <Col lg={8}>
                                                <Form.Item label="Repair Welders" >
                                                    <InputNumber type="number" size="large" value={getCategoryValue1('Repair_Welders')}
                                                    min={0}
                                                    max={1000}
                                                        onChange={(v) => handleInputChange('Repair_Welders', v)} />
                                                </Form.Item>
                                            </Col>
                                            <Col lg={8}>
                                                <Form.Item label="Bevel Machine Operators"  >
                                                    <InputNumber type="number" size="large" value={getCategoryValue1('Bevel_Machine_Operators')}
                                                        min={0}
                                                        max={1000}
                                                        onChange={(v) => handleInputChange('Bevel_Machine_Operators', v)} />
                                                </Form.Item>
                                            </Col>
                                            <Col lg={8}>
                                                <Form.Item label="Technicians" style={{ paddingTop: '0px' }}  >
                                                    <InputNumber type="number" size="large" value={getCategoryValue1('Technicians')}
                                                        min={0}
                                                        max={1000}
                                                        onChange={(v) => handleInputChange('Technicians', v)} />
                                                </Form.Item>
                                            </Col>
                                            <Col lg={8}>
                                                <Form.Item label="General / Helper" style={{ paddingTop: '0px' }}>
                                                    <InputNumber type="number" size="large" value={getCategoryValue1('General_Helper')}
                                                    min={0}
                                                    max={1000}
                                                    onChange={(v) => handleInputChange('General_Helper', v)}
                                                    />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row style={{ marginTop: '20px' }}>
                                            <Col lg={8}>
                                                <Checkbox onChange={(v) => handleInputChange('Air_Fed_Welding_Hood', v.target.checked ? 'Yes' : 'No')}
                                            checked={getCategoryValue1("Air_Fed_Welding_Hood") =='Yes' ?true:false}
                                                >Air Fed Welding Hood</Checkbox>

                                            </Col>
                                            <Col lg={8}>

                                                <Checkbox onChange={(v) => handleInputChange('Non_Air_Fed_Hood', v.target.checked ? 'Yes' : 'No')}
                                                 checked={getCategoryValue1("Non_Air_Fed_Hood") =='Yes' ?true:false}
                                                >Non Air Fed Hood</Checkbox>

                                            </Col>
                                            <Col lg={8}>

                                                <Checkbox onChange={(v) => handleInputChange('Cool_Vest', v.target.checked ? 'Yes' : 'No')}
                                                 checked={getCategoryValue1("Cool_Vest") =='Yes' ?true:false}
                                                >Cool Vest</Checkbox>
                                            </Col>
                                        </Row>
                                    </div>
                                }
                            </Col>
                        }
                    </Row>





                </Card>
            </Form>


        </div>
    );
};



export default connect(null, null)(SupportItemsForm);

