/* eslint-disable linebreak-style */
// auth
export const SIGNIN = '@@authentication/signIn';
export const SIGNOUT = '@@authentication/signOut';
export const SIGNINFAILURE = '@@authentication/signInFailure';
export const SIGNINSUCCESS = '@@authentication/signInSuccess';
export const SIGNUP = '@@authentication/signUp';
export const SIGNUPSUCCESS = '@@authentication/signUpSuccess';
export const SIGNUPFAILURE = '@@authentication/signUpFailure';

