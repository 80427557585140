

import get from 'lodash/get';
import {GENERATELICENSE,FETCH_LICENSE_LIST,FETCH_LICENSE_LIST_SUCECESS,FETCH_LICENSE_LIST_FAILURE,
  GENERATELICENSE_SUCCESS,GENERATELICENSE_FAILURE,SHARELICENSEINFO,SHARELICENSEINFO_SUCCESS,SHARELICENSEINFO_FAILURE,
  SHARE_FETCH_LICENSE_LIST,SHARE_FETCH_LICENSE_LIST_SUCECESS,SHARE_FETCH_LICENSE_LIST_FAILURE, FETCH_GROUP_LICENSE_LIST, 
  FETCH_GROUP_LICENSE_LIST_SUCECESS, FETCH_GROUP_LICENSE_LIST_FAILURE, FETCH_UNIQUE_ID, FETCH_UNIQUE_ID_SUCECESS, FETCH_UNIQUE_ID_FAILURE} from './types';
//import { fetchLicenseListSuccess } from './generatorLicense';
const initialState = {
  isLicenseLoading: false,
  isGenerateLicense:false,
  isFetchLincenseData:false
};
const licenseMangerReducer = (state = initialState, action) => {
  switch (action.type) {


    case GENERATELICENSE: {
      //console.log(action)
      return {
        ...state,
        isGenerateLicense: true
      };
    }
    case GENERATELICENSE_SUCCESS: {
      return {
        ...state,
        isGenerateLicense: false
      }
    }
    case GENERATELICENSE_FAILURE: {
      return {
        ...state,
        isGenerateLicense: false
      }
    }
    case FETCH_LICENSE_LIST: {
     // console.log('resducer called')
      return {
        ...state,
        isLicenseLoading: true,
      };
    }
    case FETCH_LICENSE_LIST_SUCECESS: {
      const resData = get(action, 'responseData', []);
     // console.log("resData", resData)
      return {
        ...state,
        LicenseDataUnikey: resData,
        LicenseListCount: resData.length > 0 ?resData[0].totalCount :0,
        isLicenseLoading: false
      }
    }
    case FETCH_LICENSE_LIST_FAILURE: {
      return {
        ...state,
        isLicenseLoading: false
      }
    }
    case SHARELICENSEINFO: {
      //console.log(action)
      return {
        ...state,
        isLicenseLoading: true
      };
    }
    case SHARELICENSEINFO_SUCCESS: {
      return {
        ...state,
        isLicenseLoading: false
      }
    }
    case SHARELICENSEINFO_FAILURE: {
      return {
        ...state,
        isLicenseLoading: false
      }
    }
    case SHARE_FETCH_LICENSE_LIST: {
      // console.log('resducer called')
       return {
         ...state,
         isLicenseLoading: true,
       };
     }
     case SHARE_FETCH_LICENSE_LIST_SUCECESS: {
       const resData = get(action, 'responseData', []);
      // console.log("resData", resData)
       return {
         ...state,
         shareList: resData,
         LicenseListCount: resData.length > 0 ?resData[0].totalCount :0,
         isLicenseLoading: false
       }
     }
     case SHARE_FETCH_LICENSE_LIST_FAILURE: {
       return {
         ...state,
         isLicenseLoading: false
       }
     }
     case FETCH_GROUP_LICENSE_LIST: {
       //console.log('resducer called')
       return {
         ...state,
         isLicenseLoading: true,
       };
     }
     case FETCH_GROUP_LICENSE_LIST_SUCECESS: {
       const resData = get(action, 'responseData', []);
        //console.log("resData", resData)
       return {
         ...state,
         LicenseData: resData,
         LicenseListCount: resData.length > 0 ?resData[0].totalCount :0,
         isLicenseLoading: false
       }
     }
     case FETCH_GROUP_LICENSE_LIST_FAILURE: {
       return {
         ...state,
         isLicenseLoading: false
       }
     }
     case FETCH_UNIQUE_ID: {
      //console.log('resducer called')
      return {
        ...state,
        isFetchLincenseData: true,
      };
    }
    case FETCH_UNIQUE_ID_SUCECESS: {
      const resData = get(action, 'responseData', []);
       //console.log("resData", resData)
      return {
        ...state,
        FetchUniqueId: resData,
        isFetchLincenseData: false
      }
    }
    case FETCH_UNIQUE_ID_FAILURE: {
      return {
        ...state,
        isFetchLincenseData: false
      }
    }


    default: {
      return state;
    }
  }
};

export default licenseMangerReducer;