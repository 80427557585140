export const GENERATESPAREPARTS ="@@generate_recordlist";
export const GENERATESPAREPARTS_SUCCESS = "@@generate_recordlist_Success"
export const GENERATESPAREPARTS_FAILURE = "@@generate_recordlist_failure"

export const FETCHRECORDSLIST = '@@fetch_record_List';
export const FETCHRECORDLIST_SUCCESS = '@@fetch_recordlist_Success';
export const FETCHRECORDLIST_FAILURE = '@@fetch_recordlist_Failure';

export const DELETERECORD = '@@delete_RecordList';
export const DELETERECORD_SUCCESS = '@@delete_recordlist_Success';
export const DELETERECORD_FAILURE = '@@delete_recordlist_Failure';
