import { notification } from 'antd';
import fetch from './fetch';
import { BASE_URL } from '../config';

export const saveUsers = async (userDetail) => {
  try {
    const res = await fetch(`${BASE_URL}/addUser`, {
      method: 'POST',
      body: JSON.stringify(userDetail),
      headers: {
        Accept: 'application/json',
      },
    });
    if (res.status === 403) {
      notification.error({
        message: 'Unauthorized',
      });
    } if (res.status === 200) {
      return res.status;
    }
    if (res.status === 502) {
      return res.status;
    }
    return null;
  } catch (error) {
    return null;
  }
};

export const updateAccess = async (userDetail) => {
  try {
    const res = await fetch(`${BASE_URL}/updateAccess`, {
      method: 'POST',
      body: JSON.stringify(userDetail),
      headers: {
        Accept: 'application/json',
      },
    });
    if (res.status === 403) {
      notification.error({
        message: 'Unauthorized',
      });
    } if (res.status === 200) {
      return res.status;
    }
    if (res.status === 502) {
      return res.status;
    }
    return null;
  } catch (error) {
    return null;
  }
};

export const removeUser = async (userDetail) => {
  try {
    const res = await fetch(`${BASE_URL}/deleteUser`, {
      method: 'DELETE',
      body: JSON.stringify(userDetail),
      headers: {
        Accept: 'application/json',
      },
    });
    if (res.status === 403) {
      notification.error({
        message: 'Unauthorized',
      });
      return null;
    } if (res.status === 200) {
      return true;
    }
    return null;
  } catch (error) {
    return null;
  }
};

export const resetUserPassword = async (userDetail) => {
  try {
    const res = await fetch(`${BASE_URL}/resetUserPassword`, {
      method: 'POST',
      body: JSON.stringify(userDetail),
      headers: {
        Accept: 'application/json',
      },
    });
    if (res.status === 403) {
      notification.error({
        message: 'Unauthorized',
      });
      return null;
    } if (res.status === 200) {
      return true;
    }
    return null;
  } catch (error) {
    return null;
  }
};

export const sendUserEmail = async (userDetail) => {
  try {
    const res = await fetch(`${BASE_URL}/sendLoginDetails`, {
      method: 'POST',
      body: JSON.stringify(userDetail),
      headers: {
        Accept: 'application/json',
      },
    });
    if (res.status === 403) {
      notification.error({
        message: 'Unauthorized',
      });
      return null;
    } if (res.status === 200) {
      return true;
    }
    return null;
  } catch (error) {
    return null;
  }
};
