// src/components/ProjectModal.js
import React, { useEffect, useState } from 'react';
import { Modal, Form, Input, Select, Checkbox, Button, Row, Col, Radio, notification, DatePicker } from 'antd';
import { climates, countries_List, diameters, externalEquipments, inspectionTypes, materials, positions, powerSupplies, rootBeadTypes, saleContracts, terrains, types, weldingProcesses, wireTypes } from './DatabseList';
import { AddOrUpdateProject, getCategoryListFromDatabse } from '../../redux/Trackrecord/saga';
import moment from 'moment';
import { EditFilled } from '@ant-design/icons';
const { Option } = Select;

const ProjectModal = (props) => {

    const {
        isModalVisible,
        onClose,
        setIsModalVisible,
        projectDetailForm,
        isEdit,
        setIsEdit,
        editRecord,
        onfetchList,
        userName
    } = props;
    // const [form] = Form.useForm();
    //const [formData, setFormData] = useState({});
    const [addLoding, setAddLaoding] = useState(false)
    const [data_List, setData_List] = useState({})
    const [startedYear, setStartedYear] = useState(moment('YYYY'))
    const [formData, setFormData] = useState({
        "project": "",
        "projectName": "",
        "contractor": "",
        "owningCo": "",
        "location": " ",
        "yearStarted": "",
        "material": "",
        "lengthKM": "",
        "lengthMiles": "",
        "numberOfWelds": "",
        "diameter": 0,
        "wt1": "",
        "wt2": "",
        "wt3": "",
        "wt4": "",
        rentalContract: "NO",
        serviceContract: "NO",
        saleContract: "NO",
        'saleContractInfo': '',
        "type": "",
        "position": "",
        "numberOfSpreads": "",
        "workmanship": "",
        "eca": "",
        "craMaterials": "",
        "specification": "",
        "numberOfStations": "",
        "rootBeadType": "",
        "externalEquip": "",
        "weldingProcess": "",
        "powerSupply": "",
        "weldingWireDiameter": "",
        "weldingWireType": "",
        "terrain": "",
        "climate": "",
        "prodRate": "",
        "repairRate": "",
        "numberOfRepairs": "",
        "inspectionType": "",
        "inspectionCompany": "",
        "comments": "",
        Units: {
            length: "KM",
            wt1: 'mm',
            wt2: "mm",
            wt3: "mm",
            wt4: "mm"
        }
    });
    useEffect(() => {


        (async () => {
            const response = await getCategoryListFromDatabse()
            //countries_List  =response.countries_List
            if (Object.keys(setData_List).length == 0 && response) {
                setData_List(response)
                //console.log('Record Item', response)
            }
        })();

    }, [])
    const handleOk = async () => {
        // projectDetailForm.validateFields().then(async (values) => {
        //     // console.log(values)
        //     // setAddLaoding(true)

        //     // setAddLaoding(false)
        //     // setFormData(values);


        // });
        if (formData['project'] == '') {
            notification.warning({
                message: 'Enter the Project  to proceed!'
            });
            return;
        }
        if (isEdit) {
            formData['ID'] = editRecord.ID
            formData['created_date'] = moment().format('YYYY-MM-DD').toString()
        }
        else {

            formData['created_date'] = moment().format('YYYY-MM-DD').toString()
        }
        formData['created_by'] = userName ?? ''
        //console.log('Record Item', formData)

        const unit = getCategoryUnitsValue1('length')
        if (unit === 'Miles' && formData.lengthKM != '') {
            //convertion of milesToKm
            const originalValue = Number(formData.lengthKM);
            const lengthKM = (originalValue * factorKM).toFixed(3);
            formData.lengthKM = lengthKM
            formData.lengthMiles = originalValue
            // console.log(formData.lengthKM)
        }
        else {
            //convertion of KM to miles
            const originalValue = Number(formData.lengthKM);
            const lengthMiles = (originalValue * factorMiles).toFixed(3);
            formData.lengthMiles = lengthMiles

        }
        console.log('Project data', formData)
        formData['isEdit'] = isEdit

        setAddLaoding(true)
        await AddOrUpdateProject(formData)
        setAddLaoding(false)
        setIsEdit(false)
        setIsModalVisible(false);
        await onfetchList()
    };
    // useEffect(() => {
    //     console.log(formData)

    // }, [formData])
    useEffect(() => {
        if (isEdit && editRecord) {

            if (!editRecord.Units) {
                editRecord.Units = formData.Units

            }
            setFormData(editRecord)
        }
        else {
            setFormData(formData)
            projectDetailForm.resetFields();

        }
        //console.log(isEdit, formData)

    }, [isEdit])

    const handleCancel = () => {
        projectDetailForm.resetFields();
        setIsEdit(false)

        setFormData({})
        onClose();
        setIsModalVisible(false)

    };
    const onValuesChange = (changedValues, allValues) => {
        // const edited = { ...formData };

        // Object.keys(changedValues).forEach((key) => {
        //     edited[key] = allValues;
        // }); 
        // console.log(allValues)
        // setEditedFields(edited);
        // setIsInputfeildChanged(true)
        //console.log(sortByPropertyAlpha(types,'type'));
        //console.log('Record Item',countries_List)
    };
    const sortByPropertyAlpha = (arr, property, order = 'asc') => {
        return arr.sort((a, b) => {
            if (order === 'asc') {
                return a[property].localeCompare(b[property]);
            } else {
                return b[property].localeCompare(a[property]);
            }
        });
    }

    const handleInputChange = (name, value) => {
        // if (name === 'trailDate')
        //     value = moment(value).format('YYYY-MM-DD');



        setFormData((prevData) => ({ ...prevData, [name]: value }));

    };
    const handleInputDateChange = (name, value) => {
        // if (name === 'trailDate')
        //     value = moment(value).format('YYYY-MM-DD');

       // console.log(value)

        setFormData((prevData) => ({ ...prevData, [name]: value }));

    };
    const factorMiles = 0.621371;
    const factorKM = 1.609344
    const handleLengthInputChange = (name, value) => {
        //   const unit =   getCategoryUnitsValue1('length')
        //   console.log(name,value,unit)
        //   if(unit ==='Miles')
        //   {
        //     //convertion of milesToKm
        //     const originalValue = Number(value);
        //     value = (originalValue * factorKM).toFixed(3); 
        //     console.log(value)
        //   }

        handleInputChange(name, value)

    }
    const getCategoryValue1 = (type) => {

        if (formData.hasOwnProperty(type)) { // Check if the category exists in selectedOptions
            return formData[type]; // Return the value for the category
        } else {
            return 'no'; // Return null if the category does not exist
        }
    };
    const handleUnitsChange = (type, value) => {
        //setIsInputfeildChanged(true)
        const category = 'Units'
        setFormData((prevOptions) => {
            const updatedCategory = { ...prevOptions[category] };
            updatedCategory[type] = value;


            return {
                ...prevOptions,
                [category]: updatedCategory,
            };
        });
    }

    const getCategoryUnitsValue1 = (type) => {
        const category = 'Units'
        if (formData.hasOwnProperty(category)) { // Check if the category exists in selectedOptions
            return formData[category][type]; // Return the value for the category
        } else {
            return type == 'length' ? 'KM' : 'no'; // Return null if the category does not exist
        }
    };
    return (
        <Modal
            width={1400}
            visible={isModalVisible}
            title="Global Project List"
            className="trackrecord-modal Tracker-modal"
            // onOk={handleOk}
            //onCancel={handleCancel}
            footer={[
                <Button key="back" onClick={handleCancel}>
                    Cancel
                </Button>,
                <Button key="submit" type="primary" onClick={handleOk} loading={addLoding}>
                    {isEdit ? 'Update' : 'Submit'}
                </Button>,
            ]}
        >
            <Form form={projectDetailForm}
                name="project_form"
                layout="vertical"
                autoComplete="off"
                getcontainer={false}
                onValuesChange={onValuesChange}
                width={1400}
            >
                <Row gutter={24} >
                    <Col lg={5} >
                        <Form.Item name="project" label="Project Number"
                        // rules={[{ required: true, message: 'Please input the project!' }]}
                        >
                            <Input onChange={(e) => handleInputChange('project', e.target.value)} size="large" />
                        </Form.Item>
                    </Col>
                    <Col lg={5}>
                        <Form.Item name="projectName" label="Project Name">
                            <Input onChange={(e) => handleInputChange('projectName', e.target.value)} size="large" />
                        </Form.Item>
                    </Col>
                    <Col lg={5}>
                        <Form.Item name="contractor" label="Contractor">
                            <Input onChange={(e) => handleInputChange('contractor', e.target.value)} size="large" />
                        </Form.Item>
                    </Col>
                    <Col lg={5}>
                        <Form.Item name="owningCo" label="Owning Co">
                            <Input onChange={(e) => handleInputChange('owningCo', e.target.value)} size="large" />
                        </Form.Item>
                    </Col>

                    <Col lg={5}>
                        <Form.Item name="location" label="Location">

                            <Select className='maintenance-select' placeholder={'Select category'} style={{ marginTop: '-5px' }}
                                onChange={(value) => handleInputChange('location', value)} size="large"
                                showSearch
                                allowClear>

                                {data_List.countries_List &&
                                    data_List.countries_List
                                        .sort((a, b) => a.country.localeCompare(b.country)) // Sort by category
                                        .map((item, i) => (
                                            <Option key={i} value={item.country}> {/* Use item.category as value */}
                                                {item.country}
                                            </Option>
                                        ))
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col lg={5}>
                        <Form.Item name="yearStarted2" label="Year Started">
                            <div className='date-picker'>                            
                                <DatePicker picker="year" 
                              
                                style={{border: 'line', width: '100%' }}
                                value={formData.yearStarted ? moment(formData.yearStarted, 'YYYY') : null}
                                onChange={(date, dateString) => handleInputDateChange('yearStarted', dateString)}

                                format="YYYY"
                            //disabledDate={disabledDate} 
                            />
                            </div>

                        </Form.Item>
                    </Col>


                    <Col lg={5}>
                        <Form.Item name="material" label="Material">
                            <Select className='maintenance-select' placeholder={'Select category'} style={{ marginTop: '-5px' }}
                                onChange={(value) => handleInputChange('material', value)} size="large"
                                showSearch
                                allowClear>

                                {data_List.materials &&
                                    data_List.materials
                                        .sort((a, b) => a.material.localeCompare(b.material)) // Sort by category
                                        .map((item, i) => (
                                            <Option key={i} value={item.material}> {/* Use item.category as value */}
                                                {item.material}
                                            </Option>
                                        ))
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col lg={5}>
                        <Form.Item name="lengthKM" label="Length">

                            <div style={{
                                border: '1px solid #d9d9d9',
                                padding: '5px',
                                borderRadius: '5px',
                                display: 'flex',
                                alignItems: 'center',  // Center vertically

                            }}>

                                <Input style={{ flex: '0 0 45%' }}
                                    value={formData.lengthKM}
                                    onChange={(e) => handleLengthInputChange('lengthKM', (e.target.value))}
                                    size="large"
                                    type='number' />
                                <div className='report-radio' style={{ flex: '0 0 40%', paddingLeft: '5px' }}>
                                    <Radio.Group style={{ display: "flex", }}
                                        value={getCategoryUnitsValue1('length')}
                                        onChange={(e) => {
                                            handleUnitsChange('length', e.target.value)

                                        }}
                                    >
                                        <Radio value={'KM'}>KM</Radio>
                                        <Radio value={'Miles'}>Miles</Radio>
                                    </Radio.Group>
                                </div>

                            </div>

                        </Form.Item>
                    </Col>

                    <Col lg={5}>
                        <Form.Item name="numberOfWelds" label="Number of Welds">
                            <Input onChange={(e) => handleInputChange('numberOfWelds', e.target.value)} type='number' size="large" />
                        </Form.Item>
                    </Col>
                    <Col lg={5}>
                        <Form.Item name="numberOfSpreads" label="Number of Spreads">
                            <Input onChange={(e) => handleInputChange('numberOfSpreads', e.target.value)} type='number' size="large" />
                        </Form.Item>
                    </Col>
                    <Col lg={24}>
                        <div style={{ marginBottom: '10px' }}>
                            <div className='heatp-bottom-border'>
                                &nbsp;
                            </div>
                        </div>
                    </Col>
                    <Col lg={5}>
                        <Form.Item name="diameter" label="Diameter">
                            <Select className='maintenance-select' placeholder={'Select category'} size="large" style={{ marginTop: '-5px' }}
                                showSearch onChange={(value) => handleInputChange('diameter', value)}
                                allowClear>

                                {data_List.diameters &&
                                    data_List.diameters
                                        //.sort((a, b) => a.diameter.localeCompare(b.diameter)) // Sort by category
                                        .map((item, i) => (
                                            <Option key={i} value={item.diameter}> {/* Use item.category as value */}
                                                {item.diameter}
                                            </Option>
                                        ))
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col lg={5}>
                        <Form.Item name="wt1" label="Wall Thickness 1">

                            <div style={{
                                border: '1px solid #d9d9d9',
                                padding: '5px',
                                borderRadius: '5px',
                                display: 'flex',
                                alignItems: 'center',  // Center vertically

                            }}>

                                <Input style={{ flex: '0 0 45%' }}
                                    value={formData.wt1}
                                    onChange={(e) => handleInputChange('wt1', (e.target.value))}
                                    size="large"
                                    type='number' />
                                <div className='report-radio' style={{ flex: '0 0 40%', paddingLeft: '5px' }}>
                                    <Radio.Group style={{ display: "flex", }}
                                        value={getCategoryUnitsValue1('wt1')}
                                        onChange={(e) => {
                                            handleUnitsChange('wt1', e.target.value)

                                        }}
                                    >
                                        <Radio value={'mm'}>mm</Radio>
                                        <Radio value={'inch'}>inch</Radio>
                                    </Radio.Group>
                                </div>

                            </div>
                        </Form.Item>
                    </Col>
                    <Col lg={5}>
                        <Form.Item name="wt2" label="Wall Thickness 2">

                            <div style={{
                                border: '1px solid #d9d9d9',
                                padding: '5px',
                                borderRadius: '5px',
                                display: 'flex',
                                alignItems: 'center',  // Center vertically

                            }}>

                                <Input style={{ flex: '0 0 45%' }}
                                    value={formData.wt2}
                                    onChange={(e) => handleInputChange('wt2', (e.target.value))}
                                    size="large"
                                    type='number' />
                                <div className='report-radio' style={{ flex: '0 0 40%', paddingLeft: '5px' }}>
                                    <Radio.Group style={{ display: "flex", }}
                                        value={getCategoryUnitsValue1('wt2')}
                                        onChange={(e) => {
                                            handleUnitsChange('wt2', e.target.value)

                                        }}
                                    >
                                        <Radio value={'mm'}>mm</Radio>
                                        <Radio value={'inch'}>inch</Radio>
                                    </Radio.Group>
                                </div>

                            </div>
                        </Form.Item>
                    </Col>
                    <Col lg={5}>
                        <Form.Item name="wt3" label="Wall Thickness 3">

                            <div style={{
                                border: '1px solid #d9d9d9',
                                padding: '5px',
                                borderRadius: '5px',
                                display: 'flex',
                                alignItems: 'center',  // Center vertically

                            }}>

                                <Input style={{ flex: '0 0 45%' }}
                                    value={formData.wt3}
                                    onChange={(e) => handleInputChange('wt3', (e.target.value))}
                                    size="large"
                                    type='number' />
                                <div className='report-radio' style={{ flex: '0 0 40%', paddingLeft: '5px' }}>
                                    <Radio.Group style={{ display: "flex", }}
                                        value={getCategoryUnitsValue1('wt3')}
                                        onChange={(e) => {
                                            handleUnitsChange('wt3', e.target.value)

                                        }}
                                    >
                                        <Radio value={'mm'}>mm</Radio>
                                        <Radio value={'inch'}>inch</Radio>
                                    </Radio.Group>
                                </div>

                            </div>
                        </Form.Item>
                    </Col>
                    <Col lg={5}>
                        <Form.Item name="wt4" label="Wall Thickness 4">

                            <div style={{
                                border: '1px solid #d9d9d9',
                                padding: '5px',
                                borderRadius: '5px',
                                display: 'flex',
                                alignItems: 'center',  // Center vertically

                            }}>

                                <Input style={{ flex: '0 0 45%' }}
                                    value={formData.wt4}
                                    onChange={(e) => handleInputChange('wt4', (e.target.value))}
                                    size="large"
                                    type='number' />
                                <div className='report-radio' style={{ flex: '0 0 40%', paddingLeft: '5px' }}>
                                    <Radio.Group style={{ display: "flex", }}
                                        value={getCategoryUnitsValue1('wt4')}
                                        onChange={(e) => {
                                            handleUnitsChange('wt4', e.target.value)

                                        }}
                                    >
                                        <Radio value={'mm'}>mm</Radio>
                                        <Radio value={'inch'}>inch</Radio>
                                    </Radio.Group>
                                </div>

                            </div>
                        </Form.Item>
                    </Col>

                    <Col lg={24}>
                        <div style={{ marginBottom: '10px' }}>
                            <div className='heatp-bottom-border'>
                                &nbsp;
                            </div>
                        </div>
                    </Col>
                    <Col lg={5}>
                        <Form.Item name="rentalContract" label="Rental Contract" className='report-radio'>
                            <Radio.Group
                                value={formData.rentalContract}
                                onChange={(e) => handleInputChange('rentalContract', e.target.value)}>
                                <Radio value={"Yes"}>Yes</Radio>
                                <Radio value={"No"}>No</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </Col>
                    <Col lg={5}>
                        <Form.Item name="serviceContract" label="Service Contract" className='report-radio'>
                            <Radio.Group value={getCategoryValue1('serviceContract')} onChange={(e) => handleInputChange('serviceContract', e.target.value)}>
                                <Radio value="Yes">Yes</Radio>
                                <Radio value="No">No</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </Col>
                    <Col lg={5}>
                        <Form.Item name="saleContract" label="Sale Contract" className='report-radio'>
                            <Radio.Group value={getCategoryValue1('saleContract')} onChange={(e) => handleInputChange('saleContract', e.target.value)}>
                                <Radio value="Yes">Yes</Radio>
                                <Radio value="No">No</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </Col>
                    {getCategoryValue1('saleContract') === 'Yes' &&
                        <Col lg={5}>
                            <Form.Item name="saleContractInfo" label="Sale Contract Info" className='report-radio'>
                                {/* <Radio.Group value={getCategoryValue1('saleContract')} onChange={(e) => handleInputChange('saleContract', e.target.value)}>
                                <Radio value="YES">Yes</Radio>
                                <Radio value="NO">No</Radio>
                            </Radio.Group> */}
                                <Select className='maintenance-select' placeholder={'Select category'} style={{ marginTop: '-5px' }}
                                    showSearch onChange={(value) => handleInputChange('saleContractInfo', value)} size="large"
                                    allowClear>

                                    {data_List.saleContracts &&
                                        data_List.saleContracts
                                            //.sort((a, b) => a.sale_contract.localeCompare(b.sale_contract)) // Sort by category
                                            .map((item, i) => (
                                                <Option key={i} value={item.sale_contract}> {/* Use item.category as value */}
                                                    {item.sale_contract}
                                                </Option>
                                            ))
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                    }
                    <Col lg={5}>
                        <Form.Item name="numberOfStations" label="Number of Stations">
                            <Input onChange={(e) => handleInputChange('numberOfStations', e.target.value)} size="large" type='number' />
                        </Form.Item>
                    </Col>
                    <Col lg={24}>
                        <div style={{ marginBottom: '10px' }}>
                            <div className='heatp-bottom-border'>
                                &nbsp;
                            </div>
                        </div>
                    </Col>

                    <Col lg={5}>
                        <Form.Item name="type" label="Project Type">
                            <Select className='maintenance-select' placeholder={'Select category'} style={{ marginTop: '-5px' }}
                                showSearch onChange={(value) => handleInputChange('type', value)} size="large"
                                allowClear>

                                {data_List.projecttypes &&
                                    data_List.projecttypes
                                        .sort((a, b) => a.type.localeCompare(b.type)) // Sort by category
                                        .map((item, i) => (
                                            <Option key={i} value={item.type}> {/* Use item.category as value */}
                                                {item.type}
                                            </Option>
                                        ))
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col lg={5}>
                        <Form.Item name="position" label="Position">
                            <Select className='maintenance-select' placeholder={'Select category'} style={{ marginTop: '-5px' }}
                                showSearch size="large"
                                onChange={(value) => handleInputChange('position', value)}
                                allowClear>

                                {data_List.positions &&
                                    data_List.positions
                                        //.sort((a, b) => a.diameter.localeCompare(b.diameter)) // Sort by category
                                        .map((item, i) => (
                                            <Option key={i} value={item.position}> {/* Use item.category as value */}
                                                {item.position}
                                            </Option>
                                        ))
                                }
                            </Select>
                        </Form.Item>
                    </Col>

                    <Col lg={5}>
                        <Form.Item name="workmanship" label="Workmanship" className='report-radio'>

                            <Radio.Group
                                value={formData.workmanship}
                                onChange={(e) => handleInputChange('workmanship', e.target.value)}>
                                <Radio value={"true"}>Yes</Radio>
                                <Radio value={"false"}>No</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </Col>
                    <Col lg={5}>
                        <Form.Item name="eca" label="ECA" className='report-radio'>

                            <Radio.Group
                                value={formData.eca}
                                onChange={(e) => handleInputChange('eca', e.target.value)}>
                                <Radio value={"true"}>Yes</Radio>
                                <Radio value={"false"}>No</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </Col>
                    <Col lg={5}>
                        <Form.Item name="craMaterials" label="CRA Materials" className='report-radio'>


                            <Radio.Group
                                value={formData.rentalContract}
                                onChange={(e) => handleInputChange('craMaterials', e.target.value)}>
                                <Radio value={"true"}>Yes</Radio>
                                <Radio value={"false"}>No</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </Col>
                    <Col lg={5}>
                        <Form.Item name="specification" label="Specification">
                            <Input onChange={(e) => handleInputChange('specification', e.target.value)} size="large" />
                        </Form.Item>
                    </Col>

                    <Col lg={5}>
                        <Form.Item name="rootBeadType" label="Root Bead Type">
                            <Select className='maintenance-select' placeholder={'Select category'} style={{ marginTop: '-5px' }}
                                showSearch size="large"
                                allowClear onChange={(value) => handleInputChange('rootBeadType', value)}
                            >

                                {data_List.rootBeadTypes &&
                                    data_List.rootBeadTypes
                                        // .sort((a, b) => a.diameter.localeCompare(b.diameter)) // Sort by category
                                        .map((item, i) => (
                                            <Option key={i} value={item.root_bead_type}> {/* Use item.category as value */}
                                                {item.root_bead_type}
                                            </Option>
                                        ))
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col lg={5}>
                        <Form.Item name="externalEquip" label="External Equip">
                            <Select className='maintenance-select' placeholder={'Select category'} size="large" style={{ marginTop: '-5px' }}
                                showSearch onChange={(value) => handleInputChange('externalEquip', value)}
                                allowClear>

                                {data_List.externalEquipments &&
                                    data_List.externalEquipments
                                        // .sort((a, b) => a.diameter.localeCompare(b.diameter)) // Sort by category
                                        .map((item, i) => (
                                            <Option key={i} value={item.external_equipment}> {/* Use item.category as value */}
                                                {item.external_equipment}
                                            </Option>
                                        ))
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col lg={5}>
                        <Form.Item name="weldingProcess" label="Welding Process">
                            <Select className='maintenance-select' placeholder={'Select category'} size="large" style={{ marginTop: '-5px' }}
                                showSearch onChange={(value) => handleInputChange('weldingProcess', value)}
                                allowClear>

                                {data_List.weldingProcesses &&
                                    data_List.weldingProcesses
                                        // .sort((a, b) => a.diameter.localeCompare(b.diameter)) // Sort by category
                                        .map((item, i) => (
                                            <Option key={i} value={item.welding_process}> {/* Use item.category as value */}
                                                {item.welding_process}
                                            </Option>
                                        ))
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col lg={5}>
                        <Form.Item name="powerSupply" label="Power Supply">
                            <Select className='maintenance-select' placeholder={'Select category'} size="large" style={{ marginTop: '-5px' }}
                                showSearch onChange={(value) => handleInputChange('powerSupply', value)}
                                allowClear>

                                {data_List.powerSupplies &&
                                    data_List.powerSupplies
                                        // .sort((a, b) => a.diameter.localeCompare(b.diameter)) // Sort by category
                                        .map((item, i) => (
                                            <Option key={i} value={item.power_supply}> {/* Use item.category as value */}
                                                {item.power_supply}
                                            </Option>
                                        ))
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col lg={5}>
                        <Form.Item name="weldingWireDiameter" label="Welding Wire Diameter">
                            <Input onChange={(e) => handleInputChange('weldingWireDiameter', e.target.value)} size="large" />
                        </Form.Item>
                    </Col>
                    <Col lg={5}>
                        <Form.Item name="weldingWireType" label="Welding Wire Type">
                            <Select className='maintenance-select' placeholder={'Select category'} size="large" style={{ marginTop: '-5px' }}
                                showSearch onChange={(value) => handleInputChange('weldingWireType', value)}
                                allowClear>

                                {data_List.wireTypes &&
                                    data_List.wireTypes
                                        // .sort((a, b) => a.diameter.localeCompare(b.diameter)) // Sort by category
                                        .map((item, i) => (
                                            <Option key={i} value={item.wireType}> {/* Use item.category as value */}
                                                {item.wireType}
                                            </Option>
                                        ))
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col lg={5}>
                        <Form.Item name="terrain" label="Terrain">
                            <Select className='maintenance-select' placeholder={'Select category'} size="large" style={{ marginTop: '-5px' }}
                                showSearch onChange={(value) => handleInputChange('terrain', value)}
                                allowClear>

                                {data_List.terrains &&
                                    data_List.terrains
                                        // .sort((a, b) => a.diameter.localeCompare(b.diameter)) // Sort by category
                                        .map((item, i) => (
                                            <Option key={i} value={item.terrain}> {/* Use item.category as value */}
                                                {item.terrain}
                                            </Option>
                                        ))
                                }
                            </Select>

                        </Form.Item>
                    </Col>
                    <Col lg={5}>
                        <Form.Item name="climate" label="Climate">
                            <Select className='maintenance-select' placeholder={'Select category'} size="large" style={{ marginTop: '-5px' }}
                                showSearch onChange={(value) => handleInputChange('climate', value)}
                                allowClear>

                                {data_List.climates &&
                                    data_List.climates
                                        // .sort((a, b) => a.diameter.localeCompare(b.diameter)) // Sort by category
                                        .map((item, i) => (
                                            <Option key={i} value={item.climate}> {/* Use item.category as value */}
                                                {item.climate}
                                            </Option>
                                        ))
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col lg={24}>
                        <div style={{ marginBottom: '10px' }}>
                            <div className='heatp-bottom-border'>
                                &nbsp;
                            </div>
                        </div>
                    </Col>
                    <Col lg={5}>
                        <Form.Item name="prodRate" label="Prod Rate">
                            <Input onChange={(e) => handleInputChange('prodRate', e.target.value)} size="large" type='number' />
                        </Form.Item>
                    </Col>
                    <Col lg={5}>
                        <Form.Item name="repairRate" label="Repair Rate">
                            <Input onChange={(e) => handleInputChange('repairRate', e.target.value)} size="large" type='number' />
                        </Form.Item>
                    </Col>
                    <Col lg={5}>
                        <Form.Item name="numberOfRepairs" label="Number of Repairs">
                            <Input onChange={(e) => handleInputChange('numberOfRepairs', e.target.value)} size="large" type='number' />
                        </Form.Item>
                    </Col>
                    <Col lg={24}>
                        <div style={{ marginBottom: '10px' }}>
                            <div className='heatp-bottom-border'>
                                &nbsp;
                            </div>
                        </div>
                    </Col>
                    <Col lg={5}>
                        <Form.Item name="inspectionType" label="Inspection Type">
                            <Select className='maintenance-select' placeholder={'Select category'} style={{ marginTop: '-5px' }}
                                showSearch size="large"
                                onChange={(value) => handleInputChange('inspectionType', value)}
                                allowClear>

                                {data_List.inspectionTypes &&
                                    data_List.inspectionTypes
                                        // .sort((a, b) => a.diameter.localeCompare(b.diameter)) // Sort by category
                                        .map((item, i) => (
                                            <Option key={i} value={item.inspection_type}> {/* Use item.category as value */}
                                                {item.inspection_type}
                                            </Option>
                                        ))
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col lg={5}>
                        <Form.Item name="inspectionCompany" label="Inspection Company">
                            <Input onChange={(e) => handleInputChange('inspectionCompany', e.target.value)} size="large" />
                        </Form.Item>
                    </Col>
                    <Col lg={5}>
                        <Form.Item name="comments" label="Comments">

                            <Input onChange={(e) => handleInputChange('comments', e.target.value)} size="large" />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
};

export default ProjectModal;
