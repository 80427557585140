/* eslint-disable linebreak-style */
/* eslint-disable react/jsx-props-no-spreading */
import { Layout, notification } from 'antd';
import get from 'lodash/get';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  Redirect,
  Route,
  Router,
  Switch
} from 'react-router-dom';
import Sidebar from '../layout/sidebar';
import LoginPage from '../pages/Login/Login';
import NotFoundPage from '../pages/NotFound';
import { authenticateCurrentUser } from '../services/auth';
import history from '../services/history';
import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';
import routes from './routes';
import './style.scss';
// import { Hub } from 'aws-amplify';
import { Hub } from '@aws-amplify/core';
import ForgotPassword from '../pages/Login/ForgotPassword';
import ResetPassword from '../pages/Login/ResetPassword';
import SignUp from '../pages/Login/SignUp';



const AppContainer = (isLogin) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  Hub.listen(
    'auth',
    // eslint-disable-next-line no-unused-vars
    async ({ payload: { event, data } }) => {
      switch (event) {
        case 'cognitoHostedUI': // set user data and get that data below
          break;
        case 'signIn':
          setIsAuthenticated(true);
          break;
        case 'signOut':
          setIsAuthenticated(false);
          break;
        case 'cognitoHostedUI_failure':
          break;
        default:
          break;
      }
    },
    []
  );
  //const { writeFileSync } = require("fs");
  /** Handling Reloadin or Refreshing page (from browser) */
  // useEffect(() => {
  //   const pageAccessedByReload = (
  //     (window.performance.PerformanceNavigationTiming && window.performance.PerformanceNavigationTiming.type === 1) ||
  //     window.performance
  //       .getEntriesByType('navigation')
  //       .map((nav) => nav.type)
  //       .includes('reload')
  //   );

  //   console.log(pageAccessedByReload);
  //   if (pageAccessedByReload) {
  //       history.push('/');
  //   }
  // }, [])

  useEffect(() => {

    const element = document.getElementById('body-wrapper');
    const theme = sessionStorage.getItem('theme');
    if (theme === 'True') {
      element.classList.add('light-theme');
    } else {
      element.classList.remove('light-theme');
    }

    authenticateCurrentUser()
      .then((data) => {
        setIsAuthenticated(true);
      })
      .catch(() => {
        setIsAuthenticated(false);
        localStorage.clear();
        sessionStorage.clear();
      });
  }, []);


  // const path = "C:\Users\SXT0518B\Documents/smartLyncuser.text";
  // const userName_hist = {"UserName" :"sellaraja"}
  // //var  fs  = require("fs");

  // useEffect(()=>{
  //   if(isAuthenticated)
  //   {

  //     try{
  //       console.log(window.location)
  //     //fs.writeFile(path,userName_hist)
  //     console.log("Data Succsessfully saved");
  //     }
  //     catch(error)
  //     {
  //       console.log("An error has occurred ", error)
  //     }
  //   }

  // },[isAuthenticated]);
  const clickeLockedOut = sessionStorage.getItem('ClickedLogOut')
  return (
    <Router history={history} >
      <Switch>

        {/* {console.log(isAuthenticated)}
        {console.log(window.location.pathname)} */}
        {
          sessionStorage.getItem('selectedFeilOperation') === 'true' && window.location.pathname == "/SmartLync-SignOutPage" ?
            <div>{setIsAuthenticated(false)}{localStorage.clear()}{sessionStorage.clear()}
              {
                notification.success({
                  duration: 3,
                  message: 'Successfully Logged Out',
                  description: '',
                })
              }
              <PublicRoute exact path="/login" isLogin={false} component={LoginPage} />
            </div>

            :

            <Switch>
              {console.log(isAuthenticated)}
              <PublicRoute exact path="/login" isLogin={(clickeLockedOut && clickeLockedOut === 'true') ? false : isAuthenticated} component={LoginPage} />
              <PublicRoute exact path="/signup" isLogin={isAuthenticated} component={SignUp} />
              <PublicRoute path="/forgot-password" isLogin={isAuthenticated} component={ForgotPassword} />
              <PublicRoute path="/reset-password" isLogin={isAuthenticated} component={ResetPassword} />
              {/* <PublicRoute path="/SmartLync-SignOutPage" isLogin={isAuthenticated ? false :true} component={SignOutPage} /> */}
              <Route path="/notFound" component={NotFoundPage} />
              <Layout style={{ maxHeight: '100vh', overflowY: 'auto' }}>
                <Sidebar />
                <Layout.Content className=" pt-3 px-4" >
                  <Switch>
                    {routes.map((route) => (
                      <PrivateRoute
                        key={route.path}
                        path={route.path}
                        exact={route.exact}
                        isLogin={isAuthenticated}
                        component={((props) => (
                          <route.layout {...props}>
                            <route.component {...props} isAuthenticated={isAuthenticated} />
                          </route.layout>
                        ))}
                      />
                    ))}
                    <PrivateRoute path="*" component={() => <Redirect to="/notFound" />} />

                  </Switch>
                </Layout.Content>
              </Layout>
            </Switch>



        }
      </Switch>
    </Router>
  );
};

const mapStateToProps = ({ auth }) => {
  const isLogin = get(auth, 'isLogin', false);
  return {
    isLogin,
  };
};

export default connect(mapStateToProps)(AppContainer);
// export default AppContainer;
