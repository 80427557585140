import {
  put, call, all, takeLatest,
} from 'redux-saga/effects';
import { notification } from 'antd';
import get from 'lodash/get';
import { fetchRawCrcPmListSuccess,fetchRawCrcPmListFailure, fetchRawCmCrcListSuccess, fetchRawCmCrcListFailure } from './action';
import { FETCH_RAW_CRC_PM_LIST, FETCH_RAW_CM_CRC_LIST } from './types';
import axiosConfig from './axiosConfig';

/** Get all raw crc pm list */
function* fetchRawCrcPmList({ tableName, pageNumber, pageSize }) {
  try {
    let url = `/GeProcesstList?Table_Name=${tableName}`;
    let queryParams = [];

    if (pageNumber) {
      queryParams.push(`pageNumber=${pageNumber}`);
    }

    if (pageSize) {
      queryParams.push(`pageSize=${pageSize}`);
    }

    // Add query parameters only if they exist
    if (queryParams.length > 0) {
      url += `&${queryParams.join('&')}`;
    }

    const res = yield axiosConfig.get(url);
    const response = get(res, ['data', 'data'], {});

    if (res.status === 200) {
      yield put(fetchRawCrcPmListSuccess({ response }));
    } else {
      notification.error({
        message: 'Something went wrong.',
      });
    }
  } catch (error) {
    yield put(fetchRawCrcPmListFailure());
    notification.destroy();
    notification.error({
      message: 'A problem occurred while fetching the raw crc pm list.',
      description: `${error}`,
    });
  }
}

/** Get all raw crc pm list */
function* fetchRawCmCrcList({ tableName, pageNumber, pageSize }) {
  try {
    let url = `/GeProcesstList?Table_Name=${tableName}`;
    let queryParams = [];

    if (pageNumber) {
      queryParams.push(`pageNumber=${pageNumber}`);
    }

    if (pageSize) {
      queryParams.push(`pageSize=${pageSize}`);
    }

    // Add query parameters only if they exist
    if (queryParams.length > 0) {
      url += `&${queryParams.join('&')}`;
    }

    const res = yield axiosConfig.get(url);
    const response = get(res, ['data', 'data'], {});

    if (res.status === 200) {
      console.log("response", response);
      yield put(fetchRawCmCrcListSuccess({ response }));
    } else {
      notification.error({
        message: 'Something went wrong.',
      });
    }
  } catch (error) {
    yield put(fetchRawCmCrcListFailure());
    notification.destroy();
    notification.error({
      message: 'A problem occurred while fetching the raw crc pm list.',
      description: `${error}`,
    });
  }
}


function* projectMarginWatcher() {
  yield takeLatest(FETCH_RAW_CRC_PM_LIST, fetchRawCrcPmList);
  yield takeLatest(FETCH_RAW_CM_CRC_LIST, fetchRawCmCrcList);
}

function* projectMarginSagas() {
  yield all([
    call(projectMarginWatcher),
  ]);
}

export default projectMarginSagas;