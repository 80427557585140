/* eslint-disable linebreak-style */
/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
/* eslint-disable prefer-const */
/* eslint-disable comma-spacing */
/* eslint-disable quotes */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import 'antd/dist/antd.dark.css';
import {
  Table, Tabs, notification,
} from 'antd';
import { useHistory, useLocation } from 'react-router-dom';
import UerManagementPage from '../../components/UserManagement/UserManagement';

import { authenticateCurrentUser } from '../../services/auth';

const { TabPane } = Tabs;

const AdminPage = () => {
  const history = useHistory();
  const [group, setGroup] = useState(false);
  const [currentSection, setCurrentSection] = useState('type');
  const [currentTab, setCurrentTab] = useState('1');

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const tabValue = queryParams.get('tabValue');

  useEffect(() => {
    if (tabValue)
     setCurrentTab(tabValue);
  }, [tabValue]);

  useEffect(() => {
    authenticateCurrentUser()
      .then((data) => {
   
        if (data.signInUserSession.accessToken.payload["cognito:groups"] && data.signInUserSession.accessToken.payload["cognito:groups"].includes('crc-admin')) {
          setGroup(true);
        } else {
          notification.info({
            message: 'User created successfully. You are not added to any group. Please contact Vinay.Baburao@sbdinc.com.'
          });
          setGroup(false);
        }
      })
      .catch((error) => {
        setGroup(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChange = (key) => {
    if (key === "3") { setCurrentSection('type'); }
    history.replace({
      pathname: window.location.pathname,
      search: `?tabValue=${key}`
    });
    setCurrentTab(key);
  };

  return (
    <div className="h-100 d-flex flex-column">
      {
        group ? (
          <div className="flex-fill overflow-auto">
            <Tabs
              className="custom-tab h-100"
              onChange={onChange}
              activeKey={currentTab}
              defaultActiveKey="1"
            >  
              <TabPane tab="User Management" key="1" forceRender>
                <UerManagementPage />
              </TabPane>
             
            </Tabs>
          </div>
        ) : <Table />
      }

    </div>
  );
};

export default AdminPage;
