import get from 'lodash/get';
import { FETCH_RAW_CRC_PM_LIST, FETCH_RAW_CRC_PM_LIST_SUCCESS, FETCH_RAW_CRC_PM_LIST_FAILURE, FETCH_RAW_CM_CRC_LIST, FETCH_RAW_CM_CRC_LIST_SUCCESS, FETCH_RAW_CM_CRC_LIST_FAILURE } from './types';

const initialState = {
    isRawCrcPmListLoading: false,
    isRawCmCrcListLoading: false,
    rawCrcPmList: [],
    rawCmCrcList: [],
};

const projectMarginReducer = (state = initialState, action) => {
    switch (action.type) {
        //Raw crc pm 
        case FETCH_RAW_CRC_PM_LIST: {
            return {
                ...state,
                isRawCrcPmListLoading: true
            }
        }

        case FETCH_RAW_CRC_PM_LIST_SUCCESS: {
            const resData = get(action, 'response', []);
            return {
                ...state,
                rawCrcPmList: resData,
                isRawCrcPmListLoading: false
            }
        }

        case FETCH_RAW_CRC_PM_LIST_FAILURE: {
            return {
                ...state,
                isRawCrcPmListLoading: false
            }
        }

        //Raw  cm crc
        case FETCH_RAW_CM_CRC_LIST: {
            return {
                ...state,
                isRawCmCrcListLoading: true
            }
        }

        case FETCH_RAW_CM_CRC_LIST_SUCCESS: {
            const resData = get(action, 'response', []);
            console.log("resData",resData)
            return {
                ...state,
                rawCmCrcList: resData,
                isRawCmCrcListLoading: false
            }
        }

        case FETCH_RAW_CM_CRC_LIST_FAILURE: {
            return {
                ...state,
                isRawCmCrcListLoading: false
            }
        }

        //

        default: {
            return state;
        }
    }
}

export default projectMarginReducer;