/* eslint-disable linebreak-style */
/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
/* eslint-disable prefer-const */
/* eslint-disable comma-spacing */
/* eslint-disable quotes */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import 'antd/dist/antd.dark.css';
import { Table, Tabs, notification } from 'antd';
import { useHistory } from 'react-router-dom';
import PmYoyTab from './PmYoyTab';

const { TabPane } = Tabs;

const CardTabs = () => {
    const history = useHistory();
    const [group, setGroup] = useState(false);
    const [currentSection, setCurrentSection] = useState('1');

    const onChange = (key) => {
        setCurrentSection(key);
    };

    return (
        <div className="h-100 d-flex flex-column">
            <div className="flex-fill">
                        <PmYoyTab />
                {/* <Tabs
                    className="custom-tab h-100"
                    onChange={onChange}
                >
                    <TabPane tab="PM_YoY" key="1">
                    </TabPane>
                    <TabPane tab="Product Line" key="2">
                        <PmYoyTab />
                    </TabPane>
                    <TabPane tab="PL_YoY" key="3">
                        <PmYoyTab />
                    </TabPane>
                    <TabPane tab="Commercial" key="4">
                        <PmYoyTab />
                    </TabPane>
                    <TabPane tab="Project Review" key="5">
                        <PmYoyTab />
                    </TabPane>
                </Tabs> */}
            </div>
        </div>
    );
};

export default CardTabs