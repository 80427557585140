/* eslint-disable linebreak-style */

import { ArrowLeftOutlined, DeleteOutlined, EditOutlined, EyeOutlined, FilePdfOutlined } from '@ant-design/icons';
import { Button, DatePicker, Form, Input, Modal, Space, Table, Tooltip, Typography } from 'antd';
import { get } from 'lodash';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import history from '../services/history.js';

import moment from 'moment';
import MasterUploadForm from '../components/Spare Parts generator/MasterUploadForm.js';
import ProjectDetails from '../components/Spare Parts generator/ProjectDetails.js';
import SaparepartsUpdateForm from '../components/Spare Parts generator/SaparepartsUpdateForm.js';
import { deleteSparePartsAction, fetchSparePartsList } from '../redux/Spareparts/action.js';
import { authenticateCurrentUser } from '../services/auth.js';

const { Title, Text } = Typography;


const SparePartsgenerator = (props) => {


    const {
        sparePartsData,
        isSpartPartsLoading,
        sparePartsListCount,
        onFetchSparePartsData,
        onDeleteSpareparts,
        isDeleteLoading

    } = props
    const data = [
        {
            key: '1',
            project_Name: 'Project A',
            project_Number: 'P123',
            noOfPipelines: 1,
            Project_type: 'Onshore',
            File_Creation_Date: '2024-01-15',
            Updated_Date: '2024-02-20',
            // Add other properties as needed
        },
        {
            key: '2',
            project_Name: 'Project B',
            project_Number: 'P456',
            noOfPipelines: 2,
            Project_type: 'Offshore',
            File_Creation_Date: '2024-02-10',
            Updated_Date: '2024-02-25',
            // Add other properties as needed
        },
        // Add more objects as needed
    ];
    const columns_feild = [

        {
            title: 'Project Name',
            dataIndex: 'projectName',
            key: 'projectName',
            align: 'center',
            // width: '150px',
            //sorter: (a, b) =>  (a.License_File_Name.localeCompare(b.License_File_Name)),
            render: (text) => <a style={{ paddingLeft: '0px' }}>{text}</a>,
        },
        {
            title: 'Project Number',
            dataIndex: 'projectNumber',
            key: 'projectNumber',
            //width: '220px',
            //sorter: (a, b) => a.Unique_Id.localeCompare(b.Unique_Id),
            render: (text) => <a>{text ===' '? '-':text}</a>,
            align: 'center',
        },
        {
            title: 'No. of Pipelines',
            dataIndex: 'noOfPipelines',
            key: 'noOfPipelines',
            align: 'center',
            //sorter: (a, b) => a.Type_Of_Device.localeCompare(b.Type_Of_Device)
        },
        {
            title: 'Project Type',
            dataIndex: 'projectType',
            key: 'projectType',
            align: 'center',
            //sorter: (a, b) =>  (a.Board_Type.localeCompare(b.Board_Type))
        },
        {
            title: 'Total Cost',
            dataIndex: 'totalCost',
            key: 'totalCost',
            align: 'center',
            render: (text) => <a>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(text)}</a>
        },


        {
            title: 'Created Date',
            dataIndex: 'creationDate',
            key: 'creationDate',
            align: 'center',
            render: (text) => {
                return (
                    <div>{text ? moment(text).format('DD MMM YYYY') : '-'}</div>
                );
            },
            // sorter: (a, b) => moment(a.File_Creation_Date).valueOf() - moment(b.File_Creation_Date).valueOf()
        },
        {
            title: 'Updated Date',
            dataIndex: 'updatedDate',
            key: 'updatedDate',
            align: 'center',
            render: (text) => {
                return (
                    <div>{text ? moment(text).format('DD MMM YYYY') : '-'}</div>
                );
            },
            // sorter: (a, b) => a.Expiration_Date - b.Expiration_Date,
            //sorter: (a, b) => moment(a.Expiration_Date).valueOf() - moment(b.Expiration_Date).valueOf()
        },

        {
            title: 'Report',
            dataIndex: 'report',
            key: 'report',
            align: 'center',
            render: (text, record) => {
                return (
                    <>
                        <Tooltip title="Download  file" >

                            <Button href={record.reportUrl} className='p-0 border-0' size='small' target='_blank'>
                                <FilePdfOutlined className='pdfIconRed' />
                            </Button>

                        </Tooltip>
                    </>
                )
            }
        },
        {
            title: 'Edit / Delete',
            dataIndex: 'edit_delete',
            key: 'edit_delete',
            align: 'center',
            render: (text, record) => {
                return (

                    <div>

                        <Tooltip title="View Parts" >
                            <span className="icon-18 mr-2 cursor-pointer edit-hover" style={{ marginRight: '20px', }} >
                                <EyeOutlined style={{ fontSize: '18px' }}
                                    onClick={() => onReadParts(record)}
                                />
                            </span>
                        </Tooltip>
                        <Tooltip title="Update">
                            <span className="icon-18 mr-2 cursor-pointer edit-hover">
                                <EditOutlined style={{ fontSize: '18px' }} onClick={() => onUpdateClick(record)} />
                            </span>
                        </Tooltip>
                        <Tooltip title="Delete">
                            <span className="icon-18 mr-2 cursor-pointer edit-hover" style={{ marginLeft: '20px' }}>
                                <DeleteOutlined style={{ fontSize: '18px' }} onClick={() => showDeleteModal(record)} />
                            </span>
                        </Tooltip>
                    </div>)
            },

        },

    ];




    const [isEdit, setIsEdit] = useState(false);
    const [editRecord, seteditRecord] = useState('');
    const [noofPipelines, setNoofPipelines] = useState([]);
    const [count, setCount] = useState(0);
    const [dataSource, setDataSource] = useState([{}]);
    const [projectDetailForm] = Form.useForm()
    const [showGenerateModel, setShowGenerateModel] = useState(false)
    const [showPipelineModel, setShowPipelineModel] = useState(false)
    const [showMasterModel, setShowMasterModel] = useState(false)
    const [pipelineData, setPipelineData] = useState([]);
    const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
    const [selectedData, setSelectedData] = useState('');
    const [currentPageNumber, setCurrentPageNumber] = useState(1);
    const [currentPagesize, setCurrentPagesize] = useState(10);

    const [isRead, setIsRead] = useState(false);
    //const groups = localStorage.getItem('group');

    const [userName, setUserName] = useState('');
    const [userGroup, setUserGroup] = useState([]);
    /** get user Access */
    useEffect(() => {
        authenticateCurrentUser()
            .then(async (data) => {
                if (data.signInUserSession.accessToken.payload["cognito:groups"]) {
              
                let response = data.signInUserSession.accessToken.payload["cognito:groups"] //await getA(data.username)
                //const userAccess = response.map(item => item?.GroupName)
                console.log(response)
                //setUserName(data.username);
                setUserGroup(response);
              }
            })
            .catch((error) => {
                console.log('error', error);
            });
    }, [history]);
    //const [searchText, setSearchText] = useState();
    const [projectDetails, setProjectDetails] = useState({
        'ProjectName': 'Project A',
        'ProjectNumber': 'P123',
        'ProjectType': 'Onshore',
        'NoofPipelines': '1',
    });

    useEffect(() => {

        var obj = {
            pageSize: currentPagesize,
            pageNumber: currentPageNumber,
        }

        onFetchSparePartsData(obj);
        //console.log(sparePartsData)
    }, []);

    const onUpdateClick = (record) => {
   
        if (record) {
            let editData = {
                projectName: record.projectName,
                projectNumber: record.projectNumber,
                projectType: record.projectType,
                noOfPipelines: Number(record.noOfPipeline),
                totalCost: record.totalCost
            };
            projectDetailForm.setFieldsValue(editData)

            seteditRecord(record)
            setIsEdit(true)
            setShowGenerateModel(true)
        }

    }

    /** Display Confirmation while deleting */
  const showDeleteModal = (record) => {
    setIsDeleteModalVisible(true);
    setSelectedData(record)
  };
  /** Deleting Spare parts data on confirm */
  const handleDeleteOk = async () => {
    await onDeleteSpareparts({ data: selectedData })

  }
    /** Cancelling the deletion of spare parts data */
    const handleDeleteCancel = () => {
        setIsDeleteModalVisible(false);
        setSelectedData('');
      }
        /** Close modal & delete values after confirmation equipment details */
  useEffect(() => {
    if (!isDeleteLoading) {
      setIsDeleteModalVisible(false);
      setSelectedData('');
    }
  }, [isDeleteLoading]);
    

    const onReadParts = async (record) => {
  
        if (record && record.pipelines_data) {


            let editData = {
                projectName: record.projectName,
                projectNumber: record.projectNumber,
                projectType: record.projectType,
                noOfPipelines: Number(record.noOfPipelines),
                totalCost: record.totalCost,
                creationDate: moment(record.creationDate).format('YYYY-MM-DD').toString(),
                updatedDate: moment(record.updatedDate).format('YYYY-MM-DD').toString()
            };
            projectDetailForm.setFieldsValue(editData)
            setProjectDetails(editData);
            const data = record.pipelines_data
            const updatedArray = data.map(item => ({
                col1: item.pipeSize,
                col2: item.wallThickness,
                col3: item.noOfWelds,
                col4: item.noOfStations
            }));

            setPipelineData(updatedArray)

            seteditRecord(record)
            setIsEdit(true)
            setShowGenerateModel(false)
            setIsRead(true)


        }

    }

    useEffect(() => {
        if (!showPipelineModel) {

            projectDetailForm.resetFields()
            setPipelineData([])
            setIsEdit(false)
            setIsRead(false)
            setShowGenerateModel(false)
        }

    }, [showPipelineModel])

    // useEffect(() => {
    //     if (!isRead) {

    //         projectDetailForm.resetFields()
    //         setPipelineData([])
    //         seteditRecord([])
    //         setIsEdit(false)
    //         setShowGenerateModel(false)
    //     }

    // }, [isRead])
    const handleLicenseVisibe = () => {
        projectDetailForm.resetFields()
        setPipelineData([])
        seteditRecord([])
        setShowGenerateModel(true)
    }
    const onMasterSparepartsVisibe = () => {
        setShowMasterModel(true)

    }
    // const searchInput = (

    //     <div className='searchEquip' style={{ width: '100%' }}>
    //         <Input
    //             placeholder='Search'
    //             onChange={e => setSearchText(e.target.value)}
    //             allowClear
    //         />
    //     </div>

    // );

    // const handleSearchDateSelect = async (date, dateString) => {
    //     if (date)
    //         setSearchText(moment(date).format('YYYY-MM-DD'))
    //     else
    //         setSearchText('')
    // }
    // const searchDateInput = (

    //     <div className='date-picker'>
    //         <DatePicker
    //             allowClear={true}
    //             style={{ height: '28px', border: 'line', width: '100%' }}
    //             format="DD-MM-YYYY"
    //             placeholder='Search'
    //             onChange={(date, dateString) => handleSearchDateSelect(date, dateString)}
    //             bordered={true}
    //         />
    //     </div>

    // );
    // const updatedColumns = columns_feild.map(column => ({
    //     ...column,
    //     title: (
    //         <Space style={{ display: 'flow', alignItems: 'center', width: '100%' }}>

    //             {column.title}
    //             {
    //                 column.dataIndex === 'creationDate' || column.dataIndex === 'updatedDate' ? searchDateInput :
    //                     column.dataIndex !== 'edit_delete' && column.dataIndex !== 'report' && searchInput
    //             }

    //         </Space>
    //     ),
    // }));


    const [searchCriteria, setSearchCriteria] = useState({
        projectName: '',
        projectNumber: '',
        projectType: '',
        noOfPipelines: '',
        creationDate: '',
        updatedDate: ''
    });
    function hasNonEmptyTag(jsonObj) {
        for (const key in jsonObj) {
            if (jsonObj.hasOwnProperty(key)) {
                const value = jsonObj[key];
                //console.log(value)

                if (value.trim() !== '') {
                    return true;

                }
            }
        }
        return false;
    }
    useEffect(() => {
       
        const isvalid = hasNonEmptyTag(searchCriteria)
        let obj = {
            pageSize: isvalid ? 10 : currentPagesize,
            pageNumber: isvalid ? 1 : currentPageNumber,
            searchKey: searchCriteria

        };
        onFetchSparePartsData(obj)
        
    }, [searchCriteria]);
    const handleSearchInputChange = (fieldName, value) => {
        setSearchCriteria(prevSearchCriteria => ({
            ...prevSearchCriteria,
            [fieldName]: value
        }));
    };

    const handleSearchDateSelect = (fieldName, date) => {
        const formattedDate = date ? moment(date).format('YYYY-MM-DD') : '';
        handleSearchInputChange(fieldName, formattedDate);
    };

    const searchInputs = [
        { field: 'projectName', placeholder: 'Search Project Name' },
        { field: 'projectNumber', placeholder: 'Search Project Number' },
        { field: 'projectType', placeholder: 'Search Project Type' },
        { field: 'noOfPipelines', placeholder: 'Search No. of Pipelines' },
        { field: 'totalCost', placeholder: 'Search Total cost' }
    ];

    const updatedColumns = columns_feild.map(column => {
        if (column.dataIndex === 'creationDate' || column.dataIndex === 'updatedDate') {
            return {
                ...column,
                title: (
                    <Space style={{ display: 'flow', alignItems: 'center', }}>
                        {column.title}
                        <div className='date-picker'>
                            <DatePicker
                                allowClear
                                style={{ height: '30px', border: 'line', }}
                                format="DD-MM-YYYY"
                                placeholder='Search'
                                onChange={date => handleSearchDateSelect(column.dataIndex, date)}
                                bordered
                            />
                        </div>
                    </Space>
                ),
            };
        } else if (column.dataIndex !== 'edit_delete' && column.dataIndex !== 'report') {
            return {
                ...column,
                title: (
                    <Space style={{ display: 'flow', alignItems: 'center', }}>
                        {column.title}
                        <div className='searchEquip'>
                            <Input
                                placeholder={searchInputs.find(input => input.field === column.dataIndex)?.placeholder}
                                value={searchCriteria[column.dataIndex]}
                                onChange={e => handleSearchInputChange(column.dataIndex, e.target.value)}
                                allowClear
                            />
                        </div>
                    </Space>
                ),
            };
        } else {
            return column;
        }
    });


    return (
        <div className="d-flex flex-column h-100">

            <div className="flex-auto">
                {!showPipelineModel && !showMasterModel && !isRead &&
                    <div className="flex-auto">
                        <div className="d-flex mb-3 align-items-center tab-content-title">


                            <div className='back_equip' >
                                <Tooltip title={'Back'} >
                                    <ArrowLeftOutlined onClick={(e) => {
                                        history.push('/SmartLync-HomePage');
                                    }} />
                                </Tooltip>
                            </div>

                            <Title className="mb-0 add-new-calibration" level={3} style={{ paddingLeft: '30px' }}>
                                Spare Parts List Generator
                            </Title>

                            <div className="new-calibration">


                                {/* <Text onClick={() => onMasterSparepartsVisibe()} className="ps-3 add-new-calibration" style={{}}>
                                    <span className="icon-22" style={{ scale: '20px' }}>
                                        <svg>
                                            <use xlinkHref="#add" />
                                        </svg>
                                    </span>
                                   Go to Database
                                </Text> */}

                                {userGroup && userGroup.includes('spare-parts-normal-user') && userGroup.includes('spare-parts-admin') &&
                                    <Button shape="round" key="apply" type="primary" onClick={onMasterSparepartsVisibe}>
                                        Go to Database
                                    </Button>
                                }


                                <Text onClick={() => handleLicenseVisibe()} className="ps-3 add-new-calibration" style={{}}>
                                    <span className="icon-22" style={{ scale: '20px' }}>
                                        <svg>
                                            <use xlinkHref="#add" />
                                        </svg>
                                    </span>
                                    Generate New Spares
                                </Text>
                            </div>


                        </div>
                        <div className="custom-line" />


                        <div className='addTeamDetails'>
                            <Table columns={updatedColumns}

                                className="h-100 pipeline-table equip_table equipPaginationTable"
                                scroll={{ y: 'calc(100vh - 200px)' }}
                                rowKey={'projectID'}
                                pagination={
                                    sparePartsListCount > 10 ? {
                                        position: ['bottomRight'],
                                        className: 'fixed-pagination',
                                        showSizeChanger: true,
                                        total: sparePartsListCount,
                                        defaultPageSize: 10,
                                        defaultCurrent: 1,
                                        pageSize:currentPagesize,
                                        onChange: async (pageNumber, pageSize) => {
                                            // console.log(licenseTotalCount, pageNumber, pageSize)
                                            setCurrentPageNumber(pageNumber);
                                            setCurrentPagesize(pageSize);
                                            var obj = {
                                                pageSize: pageSize,
                                                pageNumber: pageNumber,
                                                searchKey: searchCriteria
                                            }
                                            await onFetchSparePartsData(obj)

                                        }
                                    }
                                        : false
                                }


                                dataSource={[...sparePartsData]}
                                loading={isSpartPartsLoading}

                            />

                        </div>
                        {showGenerateModel &&
                            <ProjectDetails
                                setShowGenerateModel={setShowGenerateModel}
                                showGenerateModel={showGenerateModel}
                                setShowPipelineModel={setShowPipelineModel}
                                showPipelineModel={showPipelineModel}
                                setPipelineData={setPipelineData}
                                pipelineData={pipelineData}
                                setProjectDetails={setProjectDetails}
                                projectDetails={projectDetails}
                                isEdit={isEdit}
                                setIsEdit={setIsEdit}
                                editRecord={editRecord}
                                projectDetailForm={projectDetailForm}

                            >

                            </ProjectDetails>

                        }

                    </div>
                }
                {showPipelineModel &&
                    <SaparepartsUpdateForm
                        setShowPipelineModel={setShowPipelineModel}
                        showPipelineModel={showPipelineModel}
                        setPipelineData={setPipelineData}
                        pipelineData={pipelineData}
                        setProjectDetails={setProjectDetails}
                        projectDetails={projectDetails}
                        isEdit={isEdit}
                        setIsEdit={setIsEdit}
                        editRecord={editRecord}
                        isRead={isRead}
                        setIsRead={setIsRead}
                    >
                    </SaparepartsUpdateForm>

                }
                {isRead &&
                    <SaparepartsUpdateForm
                        setShowPipelineModel={setShowPipelineModel}
                        showPipelineModel={showPipelineModel}
                        setPipelineData={setPipelineData}
                        pipelineData={pipelineData}
                        setProjectDetails={setProjectDetails}
                        projectDetails={projectDetails}
                        isEdit={isEdit}
                        setIsEdit={setIsEdit}
                        editRecord={editRecord}
                        isRead={isRead}
                        setIsRead={setIsRead}
                    >
                    </SaparepartsUpdateForm>

                }

                {showMasterModel &&
                    <MasterUploadForm
                        setShowMasterModel={setShowMasterModel}
                        showMasterModel={showGenerateModel}
                    >

                    </MasterUploadForm>
                }

                <Modal
                    width="45vw"
                    className="pipeline-modal"
                    title="Delete Spare part Details"
                    centered
                    visible={isDeleteModalVisible}
                    footer={[
                        <Button key="cancel" onClick={handleDeleteCancel}>
                            Cancel
                        </Button>,
                        <Button key="apply" type="primary" onClick={handleDeleteOk} loading={isDeleteLoading}>
                            {isDeleteLoading ? 'Deleting' : 'Delete'}
                        </Button>
                    ]}
                >
                    <div>
                        <Text style={{ fontSize: '16px' }}>
                            <p>Deleting Spare Parts Details will delete all the corresponding pipe line and related information.</p>
                            Are you sure you want to delete <b style={{ color: "#EF314C" }}>{selectedData && selectedData.projectName }</b> spare part?</Text>
                    </div>
                </Modal>

            </div>
        </div>
    )
};
const mapStateToProps = ({ sparepartsreducer }) => {
    const sparePartsData = get(sparepartsreducer, 'SparePartsList', []);
    const isSpartPartsLoading = get(sparepartsreducer, 'isSpartPartsLoading', false);
    //const isUploadLicsense = get(licenseMangerReducer, 'isUploadLicsense', false);
    const sparePartsListCount = get(sparepartsreducer, 'SparePartsListCount', null);
    const isDeleteLoading = get(sparepartsreducer, 'isDeleteLoading', false);
 
    return {
        sparePartsData,
        isSpartPartsLoading,
        // isUploadLicsense,
        sparePartsListCount,
        isDeleteLoading
    }
}

const mapDispatchToProps = {
    onFetchSparePartsData: fetchSparePartsList,
    onDeleteSpareparts: deleteSparePartsAction
}

export default connect(mapStateToProps, mapDispatchToProps)(SparePartsgenerator);
