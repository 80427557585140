import React, { useState } from 'react';
import { ArrowLeftOutlined, CheckOutlined, CloseOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Form, Input, Row, Col, Tabs, Typography, Card, Select, Divider, Space, Table, Modal, Tooltip } from 'antd';

const { TabPane } = Tabs;
const { Title, Text } = Typography;
const { Option } = Select;

const initialWireData = {
    // Internal
    internalWireType: 'CRC 70S',
    internalWireDiameter: "0.035''",
    internalWireSpoolWeight: '3.25',
    internalWireLbsPerBox: '19.5',
    internalWireLbsPerCrate: '1911',
    internalWireLot: 'XXXXX',
    internalWirePartNumber: 'CRC70S-3.25-035-Z',
    internalWireWastage: '30',
    // External
    externalWireType: 'CRC XXXX',
    externalWireDiameter: "0.040''",
    externalWireSpoolWeight: '33',
    externalWireLbsPerBox: '33',
    externalWireLbsPerCrate: '2376',
    externalWireLot: 'XXXXX',
    externalWirePartNumber: 'CRCXXXX-33-040',
    externalWireWastage: '20',
    // M300
    m300500WireType: 'TBQ',
    mx00WireDiameter: "0.047''",
    mx00WireSpoolWeight: '11.0',
    mx00WireLbsPerBox: '33',
    mx00WireLbsPerCrate: '900',
    mx00WireLot: 'N/A',
    mx00WirePartNumber: '81M-10-1.2',
    mx00WireWastage: '20'
};

const ConfigWeldWire = (props) => {
    const { setIsConfigModalVisible } = props;

    const [wireData, setWireData] = useState(initialWireData);
    const [tempWireData, setTempWireData] = useState(initialWireData);

    //bevel configuration
    const initialData = [
        { key: '1', value: '5 deg K-Prep' },
        { key: '2', value: '10 deg K-prep' },
        { key: '3', value: '5 deg  J-prep' },
        { key: '4', value: 'V-Prep' },
    ];

    const [dataSource, setDataSource] = useState(initialData);
    const [editingRow, setEditingRow] = useState(null);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [form] = Form.useForm();

    const columns = [
        {
            title: 'Value',
            dataIndex: 'value',
            key: 'value',
        },
        {
            title: 'Actions',
            key: 'actions',
            render: (_, record) => (
                <div className='tableIcons'>
                    <Tooltip title='Update' placement='left'>
                        <EditOutlined
                            className='icon-18'
                            style={{ margin: '0 10px', color: '#ef314c' }}
                            onClick={() => handleEdit(record)}
                        />
                    </Tooltip>
                    <Tooltip title='Delete' placement='top'>
                        <DeleteOutlined
                            className='icon-18'
                            style={{ margin: '0 10px', color: '#ef314c' }}
                            onClick={() => handleDelete(record.key)}
                        />
                    </Tooltip>
                </div>
            ),
            align: 'center'
        },
    ];

    const handleDelete = (key) => {
        setDataSource(dataSource.filter(item => item.key !== key));
    };

    const handleEdit = (record) => {
        setEditingRow(record);
        form.setFieldsValue(record);
        setIsModalVisible(true);
    };

    const handleSaveBevel = () => {
        form.validateFields()
            .then(values => {
                if (editingRow) {
                    setDataSource(prevDataSource =>
                        prevDataSource.map(item =>
                            item.key === editingRow.key ? { ...item, ...values } : item
                        )
                    );
                } else {
                    // This is for adding a new row
                    const newKey = (dataSource.length + 1).toString();
                    const newRow = {
                        key: newKey,
                        ...values
                    };
                    setDataSource(prevDataSource => [...prevDataSource, newRow]);
                }
                setEditingRow(null);
                setIsModalVisible(false);
                form.resetFields();
            })
            .catch(error => {
                console.log('Validation Failed:', error);
            });
    };

    const handleAdd = () => {
        setEditingRow(null);
        form.resetFields();
        setIsModalVisible(true);
    };


    //
    const handleInputChange = (key, value) => {
        setTempWireData({ ...tempWireData, [key]: value });
    };

    const handleSave = () => {
        setWireData(tempWireData); // Save the edited data
        console.log('Saved Data:', tempWireData);
    };

    const renderWireSection = (title, keys) => {
        return (
            <div className="card-container">
                <Card title={title} bordered={true}>
                    {keys.map((key) => (
                        <Row key={key} gutter={24} className="wire-info-row">
                            <Col span={13}>
                                <Text>{key.replace(/([A-Z])/g, ' $1').trim()}</Text>
                            </Col>
                            <Col span={11}>
                                <Input
                                    className='Data360-addafter'
                                    value={tempWireData[key]}
                                    addonAfter={
                                        // Add '%' for wastage keys
                                        ['internalWireWastage', 'externalWireWastage', 'mx00WireWastage'].includes(key)
                                            ? '%'
                                            // Add 'lbs' for weight-related keys
                                            : ['internalWireSpoolWeight', 'internalWireLbsPerBox', 'internalWireLbsPerCrate',
                                                'externalWireSpoolWeight', 'externalWireLbsPerBox', 'externalWireLbsPerCrate',
                                                'mx00WireSpoolWeight', 'mx00WireLbsPerBox', 'mx00WireLbsPerCrate'].includes(key)
                                                ? 'lbs'
                                                : null
                                    }
                                    type={['internalWireWastage', 'externalWireWastage', 'mx00WireWastage', 'internalWireSpoolWeight', 'internalWireLbsPerBox', 'internalWireLbsPerCrate',
                                        'externalWireSpoolWeight', 'externalWireLbsPerBox', 'externalWireLbsPerCrate',
                                        'mx00WireSpoolWeight', 'mx00WireLbsPerBox', 'mx00WireLbsPerCrate'].includes(key) ? 'number' : 'text'}
                                    onChange={(e) => handleInputChange(key, e.target.value)}
                                />
                            </Col>
                        </Row>
                    ))}
                </Card>
            </div>

        );
    };

    const renderBavel = () => {
        return (
            <Row key="key" gutter={[16, 16]} className="bevel-design-row">
                <Col span={6} className="bevel-design-key">
                    <Text strong>Bavel Design</Text>
                </Col>
                <Col span={12} className="bevel-design-value">
                    <Form.Item>
                        <Select
                            value="5-deg K-prep"
                            placeholder="Select Bevel Design"
                            dropdownRender={menu => (
                                <>
                                    {menu}
                                    <Divider style={{ margin: '8px 0', borderTop: '1px solid lightgrey' }} />
                                    <Space align="center" style={{ padding: '0 8px 4px' }}>
                                        <Input placeholder="Add Bavel" />
                                        <Typography.Link style={{ whiteSpace: 'nowrap', color: "green" }}>
                                            <CheckOutlined />
                                        </Typography.Link>
                                        <Typography.Link style={{ whiteSpace: 'nowrap', color: "red" }}>
                                            <CloseOutlined />
                                        </Typography.Link>
                                    </Space>
                                </>
                            )}
                        >
                            <Option value="5-deg K-prep">5° K-Prep is our standard.</Option>
                            <Option value="10-deg K-prep">10° K-prep is for light wall pipe</Option>
                            <Option value="5-deg J-prep">5° J-prep is standard J</Option>
                            <Option value="V-Prep">V-Prep is for tie-ins and factory ends.</Option>
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
        );
    }

    return (
        <div>
            <div className="flex-auto">
                <div className="d-flex mb-3 align-items-center tab-content-title">
                    <div className='back_equip'>
                        <ArrowLeftOutlined onClick={() => setIsConfigModalVisible(false)} />
                    </div>
                    <Title className="mb-0 add-new-calibration" level={3} style={{ paddingLeft: '30px' }}>
                        IWM/P6XX & M300/M500 Configuration
                    </Title>
                    {/* <Button type="primary" onClick={handleSave} className="save-btn" style={{ borderRadius: '10px' }}>
            Save
          </Button> */}
                </div>
                <div className="custom-line" />
                <div className="custom-tab h-100" style={{ paddingTop: '10px' }}>
                    <Tabs defaultActiveKey="1">
                        <TabPane tab="Wire Information" key="1">
                            <Row gutter={24}>
                                <Col lg={8}>
                                    {renderWireSection('Internal', [
                                        'internalWireType', 'internalWireDiameter', 'internalWireSpoolWeight',
                                        'internalWireLbsPerBox', 'internalWireLbsPerCrate', 'internalWireLot',
                                        'internalWirePartNumber', 'internalWireWastage'
                                    ])}
                                </Col>
                                <Col lg={8}>
                                    {renderWireSection('External', [
                                        'externalWireType', 'externalWireDiameter', 'externalWireSpoolWeight',
                                        'externalWireLbsPerBox', 'externalWireLbsPerCrate', 'externalWireLot',
                                        'externalWirePartNumber', 'externalWireWastage'
                                    ])}
                                </Col>
                                <Col lg={8}>
                                    {renderWireSection('M300', [
                                        'm300500WireType', 'mx00WireDiameter', 'mx00WireSpoolWeight',
                                        'mx00WireLbsPerBox', 'mx00WireLbsPerCrate', 'mx00WireLot',
                                        'mx00WirePartNumber', 'mx00WireWastage'
                                    ])}
                                </Col>
                            </Row>
                            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <Button
                                    type="primary"
                                    key={'submit'}
                                    onClick={handleSave}
                                    className="save-btn"
                                    style={{ borderRadius: '30px' }}
                                >
                                    Save
                                </Button>
                            </div>
                        </TabPane>
                        <TabPane tab="Bevel Design" key="2">
                            <Row gutter={24}>
                                <Col span={12}>
                                    <Table
                                        dataSource={dataSource}
                                        columns={columns}
                                        pagination={false}
                                        rowKey="key"
                                    />
                                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                        <Button
                                            type="primary"
                                            key={'submit'}
                                            onClick={handleAdd}
                                            className="save-btn"
                                            style={{ borderRadius: '30px' }}
                                        >
                                            Add New Value
                                        </Button>
                                    </div>
                                </Col>
                            </Row>
                        </TabPane>
                    </Tabs>
                </div>
                <Modal
                    title={editingRow ? 'Edit Value' : 'Add New Value'}
                    visible={isModalVisible}
                    onOk={handleSaveBevel}
                    onCancel={() => {
                        setIsModalVisible(false);
                        setEditingRow(null);
                        form.resetFields();
                    }}
                >
                    <Form form={form} layout="vertical">
                        <Form.Item
                            name="value"
                            label="Value"
                            rules={[{ required: true, message: 'Please input the value!' }]}
                        >
                            <Input placeholder="Enter value" />
                        </Form.Item>
                    </Form>
                </Modal>
            </div>
        </div>
    );
};

export default ConfigWeldWire;
