import React from 'react';
import { Layout } from 'antd';

import ResetPasswordForm from '../../components/ResetPasswordForm';
import Logo from '../../assets/logo.png';

function ResetPassword() {
    return (
        <Layout.Content className="h-100">
            <div className="d-flex h-100 w-100 overflow-hidden">
                <div className="d-fill overflow-auto w-50 login-bg">
                    <div className="pt-4 ps-4 position-relative " >
                        <a href="aside-logo" className="login-logo">
                            <img src={Logo} alt="logo" className="logo_main" />
                        </a>
                    </div>
                </div>
                <div className="d-fill w-50 overflow-auto">
                    <ResetPasswordForm />
                </div>
            </div>
        </Layout.Content>
    );
}

export default ResetPassword;
