import { notification } from 'antd';
import get from 'lodash/get';



import axiosConfig from './axiosConfig';





export const generateData360Pricing = async (data) => {
  try {

   const url = 'https://qsynbrmxrd.execute-api.us-west-2.amazonaws.com/dev/generate_Data360_Pricing'
    
    //const url = 'http://localhost:5000/generate_Data360_Pricing'
    //console.log('Url Data::', data)

    

    const res = await fetch(`${url}`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-type': 'application/json',
        // Authorization: token
      },
      body: JSON.stringify(data),
    });

    notification.destroy();
    //console.log(await res.json())
    if (res.status === 200) {

      const response1 = await res.json();
      
      console.log(response1.pricing_details)
     
      return response1.pricing_details
    }
    if (res.status === 403) {
      notification.error({
        message: 'Unauthorized',
      });
      return null;
    }
    return null;
  } catch (error) {
    return null;
  }
};

export const getConfogListFromDatabse = async () => {
  try {
    let url;


    url = `/Pricing_Gudiance_ConfigList`;

    const res = await axiosConfig.get(url);
    console.log('testType', res)
    if (res.status === 403) {
      notification.error({
        message: 'Unauthorized',
      });
      return null;
    } if (res.status === 200) {
      const data = get(res, ['data', 'data'], {});
      console.log('testType', data)
      return data;
    }
    return null;
  } catch (error) {
    return null;
  }
};

export const AddOrUpdateConfigData = async (data, isNotify = true) => {


  try {
    const res = await axiosConfig.post('/Pricing_Gudiance_Config', data);

    notification.destroy();
    // console.log(yield res.json)
    if (res.status === 200) {
      const responseData = get(res, ['data', 'data'], {});
      if (isNotify)
        notification.success({
          message: 'Updated Successfully.',
          duration: 3,
        });

      return responseData

    }

    else {

      notification.error({
        message: 'Something went wrong.',
      });
      return null

    }


  } catch (error) {

    notification.destroy();
    notification.error({
      message: 'Update Submission Failed.',
      description: `${error}`,
    });
    return null

  }
}