import React, { useEffect, useState } from 'react';
import { Slider } from 'antd';

const TwoSidedSlider = ({ grossOriginalvalue, calculatedSummary, setCalculatedSummary, setIsMarginEdited }) => {

    //let grossOriginalvalue = parseFloat((calculatedSummary.GM * 100).toFixed(2)) ?? 0
    const [range, setRange] = useState([grossOriginalvalue - 20, grossOriginalvalue, grossOriginalvalue + 20]);
    const [calculatedRevenue, setCalculatedRevenue] = useState({
        Left: { Margin: 0, Revenue: 0 },
        Center: { Margin: grossOriginalvalue, Revenue: 0 },
        Right: { Margin: 0, Revenue: 0 },
    });
    //  const defaultRange = [grossOriginalvalue - 20, grossOriginalvalue + 20]
    //console.log(range,defaultRange)
    useEffect(() => {
        // Helper function to check if all values are zero
        const areAllValuesZero = (revenueObj) => {
            return (
                revenueObj.Left.Margin === 0 &&
                revenueObj.Left.Revenue === 0 &&
                revenueObj.Center.Margin === 0 &&
                revenueObj.Center.Revenue === 0 &&
                revenueObj.Right.Margin === 0 &&
                revenueObj.Right.Revenue === 0
            );
        };

        // If CalculatedRevenue exists in calculatedSummary, update it
        if (calculatedSummary.CalculatedRevenue) {

           // console.log('$$$$$',grossOriginalvalue, range, calculatedSummary)
            // Calculate Left and Right Revenue based on current Margins
            const updatedCalculatedRevenue = {
                ...calculatedSummary.CalculatedRevenue,
                Left: {
                    ...calculatedSummary.CalculatedRevenue.Left,
                    Revenue: calculatedSummary.totalCost / (1 - calculatedSummary.CalculatedRevenue.Left.Margin / 100),
                },
                Center: {
                    ...calculatedSummary.CalculatedRevenue.Center,
                    Revenue: calculatedSummary.totalCost / (1 - calculatedSummary.CalculatedRevenue.Center.Margin / 100),
                },
                Right: {
                    ...calculatedSummary.CalculatedRevenue.Right,
                    Revenue: calculatedSummary.totalCost / (1 - calculatedSummary.CalculatedRevenue.Right.Margin / 100),
                },
            };
            calculatedSummary.CalculatedRevenue = updatedCalculatedRevenue
            // Update the calculatedSummary with the new CalculatedRevenue
            // setCalculatedSummary((prev) => ({
            //     ...prev,
            //     CalculatedRevenue: updatedCalculatedRevenue,
            // }));
            setCalculatedRevenue(updatedCalculatedRevenue); // Set the updated revenue in local state as well
        } else {
            // If CalculatedRevenue doesn't exist, calculate initial values based on grossOriginalValue
            const grossOriginalValue = parseFloat((calculatedSummary.GM * 100).toFixed(2)) || 0;

            const initialRevenue = {
                Left: {
                    Margin: grossOriginalValue != 0 ? grossOriginalValue - 20 : 0,
                    Revenue: calculatedSummary.totalCost / (1 - (grossOriginalValue - 20) / 100),
                },
                Center: {
                    Margin: grossOriginalValue,
                    Revenue: calculatedSummary.totalCost / (1 - (grossOriginalValue) / 100),
                },
                Right: {
                    Margin: grossOriginalValue + 20,
                    Revenue: calculatedSummary.totalCost / (1 - (grossOriginalValue + 20) / 100),
                },
            };
            calculatedSummary.CalculatedRevenue = initialRevenue
            // Update calculatedSummary with the new CalculatedRevenue
            // setCalculatedSummary((prev) => ({
            //     ...prev,
            //     CalculatedRevenue: initialRevenue,
            // }));
            setCalculatedRevenue(initialRevenue);  // Set the initial revenue in local state
        }
    }, [calculatedSummary]);



    useEffect(() => {
        // Set the range only when grossOriginalvalue is available

        // console.log(grossOriginalvalue,range,calculatedSummary)
        // if (grossOriginalvalue !== undefined)
        {
            setRange([calculatedRevenue.Left.Margin, calculatedRevenue.Center.Margin, calculatedRevenue.Right.Margin]);
            // handleSliderChange([calculatedRevenue.Left.Margin, calculatedRevenue.Right.Margin])
        }
    }, [calculatedRevenue]);

    const handleSliderChange = (newRange) => {

        setIsMarginEdited(true)
        let [start, center, end] = newRange;

        // Ensure start does not exceed the center, and end does not fall below the center
        if (start > center) start = center;
        if (end < center) end = center;

        setRange([start, center, end]);

        const marginStart = start / 100;
        const marginEnd = end / 100;
        const marginCenter = center / 100;

        const totalCost = calculatedSummary.totalCost;
        const totalRevenueStart = totalCost / (1 - marginStart);
        const totalRevenueEnd = totalCost / (1 - marginEnd);
        const totalRevenueCenter = totalCost / (1 - marginCenter);
        grossOriginalvalue = marginCenter

        const updatedRevenue = {
            ...calculatedRevenue,
            Left: { Margin: start, Revenue: totalRevenueStart },
            Right: { Margin: end, Revenue: totalRevenueEnd },
            Center: { Margin: center, Revenue: totalRevenueCenter },
        };

        //console.log(center, totalRevenueCenter)

        setCalculatedRevenue(updatedRevenue);
        calculatedSummary.CalculatedRevenue = updatedRevenue
        // setCalculatedSummary((prev) => ({
        //     ...prev,
        //     CalculatedRevenue: updatedRevenue,
        // }));
    };

    const formatCurrency = (value) => {
        return value ? `$${parseFloat(value).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}` : '';
    };


    const marks = {
        0: '0%',
        [range[0]]: {
            label: (
                <div>
                    <strong>{`${range[0].toFixed(1)}%`}</strong>
                    <div style={{ marginTop: '-65px', color: '#ef314c' }}>
                        <strong>{formatCurrency(calculatedRevenue.Left.Revenue ?? 0)}</strong>
                    </div>
                </div>
            ),
        },
        [range[1]]: {
            label: (
                <div>
                    <strong>{`${range[1].toFixed(1)}%`}</strong>
                    <div style={{ marginTop: '-65px', color: '#ef314c' }}>
                        <strong>{formatCurrency(calculatedRevenue.Center.Revenue ?? 0)}</strong>
                    </div>
                </div>
            ),
        },
        [range[2]]: {
            label: (
                <div>
                    <strong>{`${range[2].toFixed(1)}%`}</strong>
                    <div style={{ marginTop: '-65px', color: '#ef314c' }}>
                        <strong>{formatCurrency(calculatedRevenue.Right.Revenue ?? 0)}</strong>
                    </div>
                </div>
            ),
        },
        100: {
            // style: { color: '#f50' },
            label: '100%'
        },
    };

    const style = {

        marginInlineStart: 70,

    };

    return (
        <div style={{ margin: '30px' }}>
            <Slider className='marginSlider'
                tooltipVisible={false}
                range
                min={0}
                max={100}
                step={0.5}
                marks={marks}
                value={range}
                //defaultValue={defaultRange}
                onChange={handleSliderChange}
                dotStyle={{ borderWidth: '2px', width: '10px', height: '10px', borderColor: 'Gray', backgroundColor: 'Gray' }}
                trackStyle={[{ backgroundColor: '#ef314c', height: '6px' }, { backgroundColor: '#ef314c', height: '6px' }]}
                handleStyle={[

                    { borderColor: '#ef314c', backgroundColor: '#ef314c', borderWidth: '2px', width: '16px', height: '16px' },
                    { borderColor: '#ef314c', backgroundColor: '#ef314c', borderWidth: '2px', width: '16px', height: '16px' },
                    { borderColor: '#ef314c', backgroundColor: '#ef314c', borderWidth: '2px', width: '16px', height: '16px' },
                ]}
                railStyle={{ backgroundColor: '#d9d9d9', height: '6px' }}
            />
        </div>
    );
};

export default TwoSidedSlider;
