import { ADD_CALIBRATION, 
         ADD_CALIBRATION_FAILURE, 
         ADD_CALIBRATION_SUCCESS, 
         FETCH_CALIBRATION, 
         FETCH_CALIBRATION_SUCCESS, 
         FETCH_CALIBRATION_FAILURE,
         SHARE_CALIBRATION,
         SHARE_CALIBRATION_SUCCESS,
         SHARE_CALIBRATION_FAILURE,
         UPLOAD_CALIBRATION_FILE,
         UPLOAD_CALIBRATION_FILE_SUCCESS,
         UPLOAD_CALIBRATION_FILE_FAILURE,
         UPDATE_CALIBRATION,
         UPDATE_CALIBRATION_SUCCESS,
         UPDATE_CALIBRATION_FAILURE
        } from './types';

const fetchCalibration = (payload) => ({
  type: FETCH_CALIBRATION,
  ...payload
});

const fetchCalibrationSuccess = (payload) => ({
  type: FETCH_CALIBRATION_SUCCESS,
  ...payload
});

const fetchCalibrationFailure = () => ({
  type: FETCH_CALIBRATION_FAILURE
});

const addCalibration = (payload) => ({
  type: ADD_CALIBRATION,
  ...payload
});

const addCalibrationSuccess = (payload) => ({
  type: ADD_CALIBRATION_SUCCESS,
  ...payload
});

const addCalibrationFailure = () => ({
  type: ADD_CALIBRATION_FAILURE
});

const shareCalibration = (payload) => ({
  type: SHARE_CALIBRATION,
  ...payload
});

const shareCalibrationSuccess = (payload) => ({
  type: SHARE_CALIBRATION_SUCCESS,
  ...payload
});

const shareCalibrationFailure = () => ({
  type: SHARE_CALIBRATION_FAILURE
});

const uploadCalibrationFile = (payload) => ({
  type: UPLOAD_CALIBRATION_FILE, ...payload
});

const uploadCalibrationFileSuccess = (payload) => ({
  type: UPLOAD_CALIBRATION_FILE_SUCCESS, ...payload,
});

const uploadCalibrationFileFailure = () => ({
  type: UPLOAD_CALIBRATION_FILE_FAILURE,
});

const updateCalibration = (payload) => ({
  type: UPDATE_CALIBRATION,
  ...payload
});
const updateCalibrationSuccess = (payload) => ({
  type: UPDATE_CALIBRATION_SUCCESS,
  ...payload
});
const updateCalibrationFailure = () => ({
  type: UPDATE_CALIBRATION_FAILURE
});

export {
  fetchCalibration,
  fetchCalibrationSuccess,
  fetchCalibrationFailure,
  addCalibration,
  addCalibrationSuccess,
  addCalibrationFailure,
  shareCalibration,
  shareCalibrationSuccess,
  shareCalibrationFailure,
  uploadCalibrationFile,
  uploadCalibrationFileSuccess,
  uploadCalibrationFileFailure,
  updateCalibration,
  updateCalibrationSuccess,
  updateCalibrationFailure
}