import React, { useState, useRef, useMemo, useEffect, useCallback } from 'react';
import { Button, Select, Pagination, Spin } from 'antd';
import 'ag-grid-enterprise';
import { LicenseManager } from 'ag-grid-enterprise';
import { AgGridReact } from 'ag-grid-react';
import * as XLSX from 'xlsx';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import 'ag-grid-community/styles/ag-theme-balham.css';
import 'ag-grid-community/styles/ag-theme-material.css';
import { fetchRawCrcPmList } from '../../../redux/projectMargin/action';
import get from 'lodash/get';
import debounce from 'lodash/debounce';
import { connect } from 'react-redux';
//ag grid enterprise
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import { ModuleRegistry } from "@ag-grid-community/core";
import { ColumnsToolPanelModule } from "@ag-grid-enterprise/column-tool-panel";
import { FiltersToolPanelModule } from "@ag-grid-enterprise/filter-tool-panel";
import { MenuModule } from "@ag-grid-enterprise/menu";
import { SetFilterModule } from "@ag-grid-enterprise/set-filter";
import { StatusBarModule } from "@ag-grid-enterprise/status-bar";
import axiosConfig from './axiosConfig';
ModuleRegistry.registerModules([
    ClientSideRowModelModule,
    ColumnsToolPanelModule,
    FiltersToolPanelModule,
    MenuModule,
    SetFilterModule,
    StatusBarModule
]);

LicenseManager.setLicenseKey(process.env.REACT_APP_AG_GRID_ENTERPRISE_LICENSE)

const { Option } = Select;

const CrcPmTable = (props) => {

    const { onFetchRawCrcPmList, rawCrcPmList, isRawCrcPmListLoading } = props;

    const [theme, setTheme] = useState('ag-theme-material'); // Default theme
    const [rowData, setRowData] = useState([]);
    const [pageSize, setPageSize] = useState(5000);
    const [pageNumber, setPageNumber] = useState(1);
    const [totalRecords, setTotalRecords] = useState(0);
    const [filterModel, setFilterModel] = useState({});

    // Debounced function to handle API calls
    const debouncedSearch = useCallback(
        debounce((filterModel) => {
            const filters = {};

            // Convert AG Grid filter model to API parameters
            Object.entries(filterModel).forEach(([field, filter]) => {
                if (filter.filter) {
                    filters[field] = filter.filter;
                }
            });

            // Call API with search parameters
            onFetchRawCrcPmList({
                tableName: 'CRC_PM_FBL3N',
                pageNumber: pageNumber,
                pageSize: pageSize,
                // filters: filters
            });
        }, 500),
        [pageNumber, pageSize]
    );

    const CustomLoadingOverlay = () => {
        return (
            <div
                style={{
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    background: 'rgba(255, 255, 255, 0.8)'
                }}
            >
                <Spin size="large" tip="Loading data..." />
            </div>
        );
    };

    const [columnDefs, setColumnDefs] = useState([
        { field: 'ID', headerName: 'ID', editable: false, filter: 'agNumberColumnFilter', rowDrag: true, },
        { field: 'year', headerName: 'Year', editable: true, filter: 'agTextColumnFilter'},
        { field: 'period', headerName: 'Period', editable: true, filter: 'agTextColumnFilter', },
        { field: 'docDate', headerName: 'Doc Date', editable: true, filter: 'agDateColumnFilter',minWidth: 190, },
        {
            field: 'coCode', headerName: 'CoCode', editable: true, filter: 'agSetColumnFilter', filterParams: {
                applyMiniFilterWhileTyping: true,
            },
        },
        { field: 'profitCtr', headerName: 'Profit Ctr', editable: true, filter: 'agSetColumnFilter', },
        { field: 'costCtr', headerName: 'Cost Ctr', editable: true, filter: 'agSetColumnFilter', },
        { field: 'trPrt', headerName: 'Tr.Prt', editable: true, filter: 'agSetColumnFilter', },
        { field: 'account', headerName: 'Account', editable: true, filter: 'agSetColumnFilter', },
        { field: 'gLAcctLongText', headerName: 'G/L Acct Long Text', editable: true, filter: 'agSetColumnFilter', },
        { field: 'documentNo', headerName: 'DocumentNo', editable: true, filter: 'agSetColumnFilter', },
        { field: 'salesOrd', headerName: 'Sales ord.', editable: true, filter: 'agSetColumnFilter', },
        { field: 'order', headerName: 'Order', editable: true, filter: 'agSetColumnFilter', },
        { field: 'material', headerName: 'Material', editable: true, filter: 'agSetColumnFilter', },
        { field: 'purDoc', headerName: 'Pur. Doc.', editable: true, filter: 'agSetColumnFilter', },
        { field: 'vendCustNo', headerName: 'Vend/Cust', editable: true, filter: 'agSetColumnFilter', },
        { field: 'wbsElement', headerName: 'WBS element', editable: true, filter: 'agSetColumnFilter', },
        { field: 'userName', headerName: 'User name', editable: true, filter: 'agSetColumnFilter', },
        { field: 'amountInDocCurr', headerName: 'Amount in doc. curr.', editable: true, filter: 'agNumberColumnFilter', },
        { field: 'curr', headerName: 'TC', editable: true, filter: 'agSetColumnFilter', },
        { field: 'amountInLocalCurr', headerName: 'Amount in local cur.', editable: true, filter: 'agNumberColumnFilter', },
        { field: 'lCurr', headerName: 'LC', editable: true, filter: 'agSetColumnFilter', },
        { field: 'reference', headerName: 'Reference', editable: true, filter: 'agSetColumnFilter', },
        { field: 'assignment', headerName: 'Assignment', editable: true, filter: 'agSetColumnFilter', },
        { field: 'documentHeaderText', headerName: 'Document Header Text', editable: true, filter: 'agSetColumnFilter', },
        // { field: 'Custom', headerName: 'Custom', editable: true, filter: 'agTextColumnFilter', },
        { field: 'text', headerName: 'Text', editable: true, filter: 'agSetColumnFilter', },
        { field: 'Proj_Master', headerName: 'Proj Master', editable: true, filter: 'agSetColumnFilter', },
        { field: 'FXReference', headerName: 'FX Reference', editable: true, filter: 'agSetColumnFilter', },
        { field: 'AmountUSD', headerName: 'Amount USD', editable: true, filter: 'agNumberColumnFilter', },
        { field: 'Year_Period', headerName: 'Year-Period', editable: true, filter: 'agSetColumnFilter', }
    ]);

    useEffect(() => {
        onFetchRawCrcPmList({ tableName: 'CRC_PM_FBL3N', pageNumber: pageNumber, pageSize: pageSize });
    }, [])

    const fetchAllData = async (totalPages) => {
        const tableName = 'CRC_PM_FBL3N';
        let allData = [];

        try {
            for (let pageNumber = 2; pageNumber <= totalPages; pageNumber++) {
                let url = `/GeProcesstList?Table_Name=${tableName}`;
                let queryParams = [];

                if (pageNumber) {
                    queryParams.push(`pageNumber=${pageNumber}`);
                }
                if (pageSize) {
                    queryParams.push(`pageSize=${pageSize}`);
                }
                // Append query parameters to URL if any exist
                if (queryParams.length > 0) {
                    url += `&${queryParams.join('&')}`;
                }
                // Fetch data using the constructed URL
                const res = await axiosConfig.get(url);
                const response = get(res, ['data', 'data'], []); // Ensure response is an array

                // Concatenate the fetched data with existing data
                // allData = allData.concat(response);
                setRowData(prevData => [...(prevData || []), ...response]); // Merge new data with existing state
            }

            // Update rowData with all fetched data
            // setRowData(prevData => [...(prevData || []), ...allData]); // Merge new data with existing state

        } catch (error) {
            console.error('Error fetching additional data:', error);
        }
    };

    // Check for changes in rawCrcPmList and fetch more data if needed
    useEffect(() => {
        if (rawCrcPmList.length > 0) {
            setRowData(rawCrcPmList);
            const totalCount = rawCrcPmList[0]?.totalCount || 0; // Get total count from raw data
            const totalPage = Math.ceil(totalCount / pageSize); // Calculate total pages
            // fetchAllData(totalPage); // Fetch additional data
        }
    }, [rawCrcPmList]); // Added pageSize as a dependency



    const gridRef = useRef();

    const handleFileUpload = (e) => {
        const file = e.target.files[0];
        const reader = new FileReader();
        reader.onload = (event) => {
            const binaryStr = event.target.result;
            const workbook = XLSX.read(binaryStr, { type: 'binary' });
            const sheetName = workbook.SheetNames[0];
            const sheet = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], { header: 1 });

            const columns = sheet[0].map((header, index) => ({
                field: header,
                editable: true,
                filter: true,
                enableValue: header !== 'name' && header !== 'id',
                rowDrag: index === 0 ? true : false
            }));

            const rows = sheet.slice(1).map((row) =>
                row.reduce((acc, value, index) => {
                    acc[sheet[0][index]] = value;
                    return acc;
                }, {})
            );

            setColumnDefs(columns);
            setRowData(rows);
        };
        reader.readAsBinaryString(file);
    };

    const onRowDragEnd = (event) => {
        const { node, overNode } = event;
        if (!overNode) return;

        const draggedRow = node.data;
        const fromIndex = node.rowIndex;
        const toIndex = overNode.rowIndex;

        if (fromIndex === toIndex) return;

        const newRowData = [...rowData];
        newRowData.splice(fromIndex, 1);
        newRowData.splice(toIndex, 0, draggedRow);

        setRowData(newRowData);
    };

    const gridStyle = useMemo(() => ({ height: "98%", width: "100%" }), []);

    const defaultColDef = useMemo(() => {
        return {
            flex: 1,
            minWidth: 160,
            floatingFilter: true,
            resizable: true,
        };
    }, []);

    const rowSelection = useMemo(() => {
        return { mode: "multiRow" };
    }, []);

    const loadingOverlayComponent = useMemo(() => CustomLoadingOverlay, []);
    const loadingOverlayComponentParams = useMemo(() => ({
        loadingMessage: 'One moment please...',
    }), []);


    // Handle filter changes
    const onFilterChanged = useCallback((params) => {
        const filterModel = params.api.getFilterModel();
        setFilterModel(filterModel);
        // debouncedSearch(filterModel);
    }, []);

    const onGridReady = useCallback((params) => {
        if (isRawCrcPmListLoading) {
            params.api.showLoadingOverlay();
        } else {
            params.api.hideOverlay();
        }
    }, [isRawCrcPmListLoading]);

    // Update loading state
    useEffect(() => {
        if (gridRef.current && gridRef.current.api) {
            if (isRawCrcPmListLoading) {
                gridRef.current.api.showLoadingOverlay();
            } else {
                gridRef.current.api.hideOverlay();
            }
        }
    }, [isRawCrcPmListLoading]);

    const [statusBar] = useState({
        statusPanels: [
            {
                statusPanel: 'agTotalRowCountComponent',
                align: 'left'
            },
            {
                statusPanel: 'agSelectedRowCountComponent',
                align: 'left'
            },
            {
                statusPanel: 'agFilteredRowCountComponent',
                align: 'left'
            }
        ]
    });
    


    return (
        <div style={{ height: 'calc(100vh - 109px)', overflow: 'auto', position: 'relative' }}>
            <div className={`ag-grid ${theme} custom-grid-theme`} style={gridStyle}>
                <AgGridReact
                    ref={gridRef}
                    rowData={rowData}
                    columnDefs={columnDefs}
                    rowDragManaged={true}
                    onRowDragEnd={onRowDragEnd}
                    defaultColDef={defaultColDef}
                    rowSelection={rowSelection}
                    onGridReady={onGridReady}
                    loadingOverlayComponent={loadingOverlayComponent}
                    loadingOverlayComponentParams={loadingOverlayComponentParams}
                    // pagination={true}
                    // paginationPageSize={pageSize}
                    // onPaginationChanged={(params) => {
                    //     if (params.newData && params.api) {
                    //         setPageNumber(params.api.paginationGetCurrentPage() + 1);  // Sync current page
                    //     }
                    // }}
                    // onFilterChanged={onFilterChanged}
                    sideBar={{
                        toolPanels: [
                            { id: 'columns', labelDefault: 'Columns', toolPanel: 'agColumnsToolPanel' },
                            { id: 'filters', labelDefault: 'Filters', toolPanel: 'agFiltersToolPanel' },
                        ],
                        defaultToolPanel: 'columns',
                    }}

                    statusBar={statusBar}
                    animateRows={true}
                />
            </div>
            {/* <div className='raw-pagination' style={{
                marginTop: '0px',
                display: 'flex',
                justifyContent: 'end',
                padding: '10px',
                backgroundColor: 'transparent',
                borderTop: '1px solid #f0f0f0'
            }}>
                <Pagination
                    current={pageNumber}
                    total={totalRecords}
                    pageSize={pageSize}
                    onChange={handlePageChange}
                    showSizeChanger={false}
                    showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
                />
            </div> */}
        </div>
    );
};

const mapStateToProps = ({ projectMarginReducer }) => {
    const isRawCrcPmListLoading = get(projectMarginReducer, 'isRawCrcPmListLoading', false);
    const rawCrcPmList = get(projectMarginReducer, 'rawCrcPmList', []);

    return {
        isRawCrcPmListLoading,
        rawCrcPmList,
    }
}

const mapDispatchToProps = {
    onFetchRawCrcPmList: fetchRawCrcPmList,
}

export default connect(mapStateToProps, mapDispatchToProps)(CrcPmTable);