// src/components/ProjectModal.js
import React, { useEffect, useState } from 'react';
import { Modal, Form, Input, Select, Checkbox, Button, Row, Col, Radio, notification, DatePicker, Divider, Space, Typography } from 'antd';


import moment from 'moment';
import { EditFilled, CheckOutlined, CloseOutlined } from '@ant-design/icons';
//import { Contractor_List, countries_List, diameters, PipeDiaRate, Project_List, Representative_List, Spread_Config_List, Pipe_Grade_List } from './Database';
import { AddOrUpdateMasterData, AddOrUpdateProject, getCategoryListFromDatabse, getDiameterListFromDatabse, getListFromDatabase } from '../../redux/Pricing/saga';
const { Option } = Select;

const ProjectModal = (props) => {

    const {

        isModalVisible,
        onClose,
        setIsModalVisible,
        projectDetailForm,
        isEdit,
        setIsEdit,
        editRecord,
        setShowPricingPage,
        userName,
        setProjectDetails,
        categoryListdata,
        setCategoryListdata,
        diameterList,
        setDiameterList,
        equipmentType,
        setEquipmentType,
        UpdateDiameterList,
        handleComboboxChange
    } = props;
    // const [form] = Form.useForm();
    //const [formData, setFormData] = useState({});
    const statuslist = ['In Progress', 'Lost', 'Won']
    const sourcetype = ['Primary', 'Secondary']
    const [addLoding, setAddLaoding] = useState(false)
    const [data_List, setData_List] = useState({})
    const [startedYear, setStartedYear] = useState(moment('YYYY'))
    const [newItem, setNewItem] = useState('');
    const [pipeDiaRateList, setPipeDiaRateList] = useState([])
    const [spreadConfigList, setSpreadConfigList] = useState([])
    const [isSummaryDataAvailble, setSsSummaryDataAvailble] = useState(false)
    const [ProjectsLoading, setProjectsLoading] = useState(false)
    const [CommonInputslist, setCommonInputslist] = useState(

        {
            "C.I.F": 15000,
            "Tech Cost": 95.06,
            "Tech Months": 12,
            "Wire Margin": 0.42,
            "Spares Margin": 0.42,
            "Tech Constant": 30.4,
            "Number of Techs": 2,
            "Techinican Rate": 1300,
            "Wire Cost per Weld": 23.42,
            "Spare Cost per Weld": 17,
            "Number of Tech Months": 2
        }
    )
    const [formData, setFormData] = useState({
        "Proposal_Number": "",
        "Project_Name": "",
        "Project_Type": "Onshore",
        "Contractor": "",
        "Location": "",
        "Status": '',
        "Length_in_Kms": 0,
        "Pipe_OD": '',
        "Representative": "",
        "No_of_Joints": 0,
        "Pipe_Length": 40,
        "Pipe_Grade": "",
        "Wall_thickness_in_mm": 0,
        "Daily_Production_Rate": 0,
        "EstimatedStart": "",
        "Spread_Config": "",
        "Spreads": '',
        "Customer": "",
        "Project_diameter_Rate": 0,
        "Equipment_Type": "IWM",
        "C.I.F": 15000,
        "Number_of_Techs": 2,
        "Number_of_Tech_Months": 2,
        "Techinican_Rate": 1300,
        "Spare_Cost_per_Weld": 17.00,
        "Wire_Cost_per_Weld": 23.42,
        "Spares_Margin": 0.42,
        "Wire_Margin": 0.42,
        "Tech_Constant": 30.4,
        "Tech_Months": 12,
        "Tech_Cost": 95.06,
        "comments": "",
        'Source_Type': 'Primary',
        "Created_By": "",
        "Created_Date": '',
        "Updated_Date": "",
        "Spread_Configuration":
        {
            "Name": "Mainline",
            "Factor": 1
        },
        "Power_Supplies": {
            "Miller XMT 350": "False",
            "Lincoln": "False",
            "Fronius": "False"
        },
        "Equipment_Selection": {
            "PFM / COUMTERBORE": 0,
            "HPU": 0,
            "IWM": 0,
            "IWM Major Spares": 0,
            "CBUC": 0,
            "CBUC Spare Copper Sets": 0,
            "CBUC Major Spares": 0,
            "R/R 40FT": 0,
            "R/R 80FT": 0,
            "P-600 Stations": 0,
            "P-600 Major Spares": 0,
            "P-600 Spare Bugs": 0,
            "M-300C Stations": 0,
            "M-300C Major Spares": 0,
            "M-300C Spare Bugs": 0,
            "Project Suppt. Cost": 0,
            "A/BAND": 0,
            "P/BAND": 0,
            "WHS Container": 0,
            "Spares & Tools": 0,
            "Project WPQ Cost": 0,
            "Cold Weather Pkg": 0,
            "P-625 Stations": 0,
            "P-625 Major Spares": 0,
            "P-625 Spare Bugs": 0,
            "Welding Shelters": 0,
            "Welding Shelters Tie-In": 0,
            "M-500 Station": 0,
            "M-500 Spare Bugs": 0,
            "M-500 Major Spare": 0,
            'Miller XMT 350 Power Supply': 0,
            'Fronius Power Supply': 0,
            'Lincoln V350 Pro Power Supply': 0
        },
        "Units": {
            "Length_in_Kms": "KM",
            'Wall_thickness_in_mm': "mm"
        },
        'Others' :{}


    });

    const tableNames = {

        Project_Name: 'Project_Name',
        Contractor: 'Contractor',
        Countries_List: 'country',
        Representative_List: 'Respresentative',
        Spread_Config_List: 'Spread_Config',
        Pipe_Grade_List: 'Pipe_Grade',
        Spreads_List:'Spreads'
        // Size_Parts: 'Diameter',
    }
    // const convertArray = (data) => {
    //     // Transform the data
    //     const flattenedArray = Object.entries(PipeDiaRate[0]).flatMap(([projectType, diameters]) =>
    //         Object.entries(diameters).map(([diameter, discountFactor]) => ({
    //             Project_Type: projectType,
    //             Diameter: diameter,
    //             Discount_Factor: discountFactor
    //         }))
    //     );

    //     console.log(flattenedArray)

    // }
    // useEffect(() => {
    //     if (categoryListdata) {
    //         const updatedCategoryListdata = { ...categoryListdata }; // create a shallow copy
    
    //         if (filterCategoryList.Project_Name) {
    //             updatedCategoryListdata.Project_Name = [...filterCategoryList.Project_Name];
    //         }
    //         if (filterCategoryList.Spreads) {
    //             updatedCategoryListdata.Spreads_List = [...filterCategoryList.Spreads];
    //         }
    //         if (filterCategoryList.Contractor) {
    //             updatedCategoryListdata.Contractor = [...filterCategoryList.Contractor];
    //         }
           
    
    //         setCategoryListdata(updatedCategoryListdata); // set the updated object
    //     }
    // }, [filterCategoryList]);


    useEffect(() => {

        if (isEdit && editRecord) {
            // console.log('isedit', editRecord)
            setFormData(editRecord)
        }
        {
            (async () => {

                const pipeRate = await getListFromDatabase('PipeDiaRate')

                setPipeDiaRateList(pipeRate)
                const Spread_Config_List = await getListFromDatabase('Spread_Config_List')
                console.log(Spread_Config_List)
                setSpreadConfigList(Spread_Config_List)
                //  console.log(category)
                const dia = await getDiameterListFromDatabse(formData.Equipment_Type);
                setDiameterList(dia.Size_Parts)
                //  console.log(dia)
                const response1 = await getListFromDatabase('CommonInputs')
                console.log('CommonInputs', response1)
                setCommonInputslist(response1[0].CommonInputs)
                // console.log('pipeRate', pipeRate)
            })();
        }


    }, [])


    const getPipeDiaRateValue = (dataArray, projectType, diameter) => {
        const discountFactors = dataArray
            .filter(item => item.Diameter === diameter)
            .map(item => item[projectType]);

        // Since filter might return multiple results, you can choose the first result if needed
        const discountFactor = discountFactors.length > 0 ? Number(discountFactors[0]) : 0;
        return discountFactor
    }

    const getSpreadFactorValue = (dataArray, name) => {
        const factor = dataArray
            .filter(item => item.Spread_Config === name)
            .map(item => item['Spread_Factor']);

        // Since filter might return multiple results, you can choose the first result if needed
        const discountFactor = factor.length > 0 ? Number(factor[0]) : 1;
        console.log(dataArray, discountFactor, factor)
        return discountFactor
    }
    const handleOk = async () => {

        if (formData['Project_Name'] == '') {
            notification.warning({
                message: 'Select the Project Name to proceed!'
            });
            return;
        }
        if (formData['Spreads'] == '') {
            notification.warning({
                message: 'Select the Spreads to proceed!'
            });
            return;
        }
        if (formData['Contractor'] == '') {
            notification.warning({
                message: 'Select the Contractor to proceed!'
            });
            return;
        }

        if (formData['Proposal_Number'] == '') {
            notification.warning({
                message: 'Enter the Quote Number to proceed!'
            });
            return;
        }
        if (formData['No_of_Joints'] == 0) {
            notification.warning({
                message: 'Enter No. of Welds to proceed!'
            });
            return;
        }
        if (formData['Pipe_OD'] == '') {
            notification.warning({
                message: 'Select Diameter to proceed!'
            });
            return;
        }

        if (formData['Spread_Config'] == '') {
            notification.warning({
                message: 'Select Spread Configuration  to proceed!'
            });
            return;
        }


        if (formData['Length_in_Kms'] == 0) {
            notification.warning({
                message: 'Enter Length to proceed!'
            });
            return;
        }
        if (isEdit) {
            formData['ID'] = editRecord.ID
            formData['Updated_Date'] = moment().format('YYYY-MM-DD').toString()
        }
        else {

            formData['Created_Date'] = moment().format('YYYY-MM-DD').toString()
            formData['Updated_Date'] = moment().format('YYYY-MM-DD').toString()
        }
        formData['Created_By'] = userName ?? ''
        formData['isEdit'] = isEdit

        const dia = formData['Pipe_OD']
        if (dia && dia != 0 && pipeDiaRateList.length > 0) {
            let pipeRate = pipeDiaRateList
            if (pipeDiaRateList.length == 0) {
                pipeRate = await getListFromDatabase('PipeDiaRate')
                setPipeDiaRateList(pipeRate)
            }
            //formData['Pipe_OD'] = `${dia} inch`
            const Project_diameter_Rate = getPipeDiaRateValue(pipeRate, formData['Project_Type'], formData['Pipe_OD'])
            //pipeDiaRateList[0][formData['Project_Type']][formData['Pipe_OD']]
            console.log('Project_diameter_Rate', Project_diameter_Rate)
            formData['Project_diameter_Rate'] = Project_diameter_Rate ?? 0
        }

        const factor1 = getSpreadFactorValue(spreadConfigList, formData['Spread_Config'])

        const spreadConfig =
        {
            Name: formData['Spread_Config'],
            Factor: factor1
        }

        Object.keys(CommonInputslist).map((key) => {
            const newKey = key == 'C.I.F' ? key : key.replace(/ /g, '_');
            formData[newKey] = Number(CommonInputslist[key])

        })
        if (CommonInputslist)
            formData['C_I_F'] = CommonInputslist['C.I.F']
        // else
        if (formData['C_I_F'] == 0)
            formData['C_I_F'] = 15000
        formData['C.I.F'] = Number(formData['C_I_F'])
        formData['Spread_Configuration'] = spreadConfig
        console.log('Project data', formData)

        setProjectsLoading(true)
        const res = await AddOrUpdateProject(formData, isEdit, true)
        console.log(res)
        setProjectDetails(res[0])
        setProjectsLoading(false)
        //console.log('Record Item', formData)
        setIsModalVisible(false)
        setShowPricingPage(true)

    };


    const handleCancel = () => {
        projectDetailForm.resetFields();
        setIsEdit(false)

        setFormData({})
        onClose();
        setIsModalVisible(false)

    };
    const onValuesChange = (changedValues, allValues) => {
        // const edited = { ...formData };

        // Object.keys(changedValues).forEach((key) => {
        //     edited[key] = allValues;
        // }); 
        // console.log(allValues)
        // setEditedFields(edited);
        // setIsInputfeildChanged(true)
        //console.log(sortByPropertyAlpha(types,'type'));
        //console.log('Record Item',countries_List)
    };


    const handleInputChange = (name, value) => {
        // if (name === 'trailDate')
        //     value = moment(value).format('YYYY-MM-DD');
        // if(name == 'Project_Name' || name == 'Spreads' || name == 'Contractor')
        //      handleComboboxChange(name, value)

        setFormData((prevData) => ({ ...prevData, [name]: value }));

    };
    const handleInputDateChange = (name, value) => {
        if (name === 'EstimatedStart')
            value = moment(value).format('YYYY-MM-DD');

        // console.log(value)

        setFormData((prevData) => ({ ...prevData, [name]: value }));

    };

    const getCategoryValue1 = (type) => {

        if (formData.hasOwnProperty(type)) { // Check if the category exists in selectedOptions
            return formData[type]; // Return the value for the category
        } else {
            return 'no'; // Return null if the category does not exist
        }
    };
    const handleUnitsChange = (type, value) => {
        //setIsInputfeildChanged(true)
        const category = 'Units'
        setFormData((prevOptions) => {
            const updatedCategory = { ...prevOptions[category] };
            updatedCategory[type] = value;


            return {
                ...prevOptions,
                [category]: updatedCategory,
            };
        });
    }

    const handlePowerSupliesChange = (type, value) => {
        //setIsInputfeildChanged(true)
        const category = 'Power_Supplies'
        setFormData((prevOptions) => {
            const updatedCategory = { ...prevOptions[category] };
            updatedCategory[type] = value;


            return {
                ...prevOptions,
                [category]: updatedCategory,
            };
        });
    }

    const getCategoryUnitsValue1 = (type) => {
        const category = 'Units'
        if (formData.hasOwnProperty(category)) { // Check if the category exists in selectedOptions
            return formData[category][type]; // Return the value for the category
        } else {
            return type == 'length' ? 'KM' : 'no'; // Return null if the category does not exist
        }
    };

    const setAdddnewItem = (event) => {
        setNewItem(event.target.value);
        // console.log(categoryType)
    };
    const addItemToFilterCategoryList = (key,type, newItem) => {
    
        // Check if key and newItem are valid
        if (key && newItem) {
            setCategoryListdata(prevData => {
                // Append the newItem wrapped inside an object with the key derived from tableNames
                return {
                    ...prevData,
                    [key]: [...prevData[key], { [type]: newItem }]
                };
            });
            setNewItem('')
        } else {
            if (!key) {
                console.error(`Key "${key}" is not found in tableNames for type "${type}".`);
            }
            if (!newItem) {
                console.error(`The newItem is empty or invalid.`);
            }
        }
    };

    const addItemToCategoryList = (type, newItem) => {

        const key = tableNames[type];

        if (key) {
            setCategoryListdata(prevData => ({
                ...prevData,
                [type]: [...prevData[type], newItem]
            }));
        } else {
            console.error(`Key "${key}" is not found in categoryListdata.`);
        }
    };

    const addItem = async (type, newItem) => {
       // console.log(type, newItem, categoryListdata)
        if (newItem) {

            const newItem1 = { [tableNames[type]]: newItem }; // Example item
            addItemToCategoryList(type, newItem1);
            const obj = {
                tableName: type,
                data: [{
                    [tableNames[type]]: newItem
                }]
            };
            setNewItem('')
            console.log(obj);
            // Uncomment and implement this function
            await AddOrUpdateMasterData(obj)

            // const category = await getCategoryListFromDatabse();
            // if (category)
            //     setCategoryListdata(category)
            // console.log(category)
        }


    };

    const removeItem = (e) => {
        e.preventDefault();
        setNewItem('');
    }

    const handleQuarterChange = (date, dateString) => {

        setFormData(prevData => ({ ...prevData, EstimatedStart: dateString })); // Update the formData with the formatted value
    };
    return (
        <Modal
            width={1400}
            visible={isModalVisible}
            title="New Welding Log"
            className="pipeline-modal trackrecord-modal Tracker-modal"
            // onOk={handleOk}
            //onCancel={handleCancel}
            footer={[
                <Button key="back" onClick={handleCancel}>
                    Cancel
                </Button>,
                <Button key="submit" type="primary" onClick={async () => await handleOk()} loading={ProjectsLoading}>
                    {isEdit ? 'Update' : 'Submit'}
                </Button>,
            ]}
        >
            <Form form={projectDetailForm}
                name="project_form"
                layout="vertical"
                autoComplete="off"
                getcontainer={false}
                onValuesChange={onValuesChange}
                width={1400}
            >
                <Row gutter={24} >

                    <Col lg={5}>
                        <Form.Item name="Project_Name" label="Project Name" required>

                            <Select className='maintenance-select' placeholder={'Select category'} style={{ marginTop: '-5px' }}
                                onChange={(value) => handleInputChange('Project_Name', value)} size="large"
                                showSearch
                                allowClear
                                dropdownRender={menu => (
                                    <>
                                        {menu}
                                        <Divider style={{ margin: '8px 0', borderTop: '1px solid lightgrey' }} />
                                        <Space align="center" style={{ padding: '0 8px 4px' }}>
                                            <Input placeholder="Add Category" value={newItem} onChange={setAdddnewItem} />
                                            <Typography.Link onClick={(e) => addItem('Project_Name', newItem)} style={{ whiteSpace: 'nowrap', color: "green" }}>
                                                <CheckOutlined />
                                            </Typography.Link>
                                            <Typography.Link onClick={removeItem} style={{ whiteSpace: 'nowrap', color: "red" }}>
                                                <CloseOutlined />
                                            </Typography.Link>
                                        </Space>
                                    </>
                                )}


                            >

                                {categoryListdata.Project_Name &&
                                    categoryListdata.Project_Name
                                        //.sort((a, b) => a.material.localeCompare(b.ProProject_Nameject)) // Sort by category
                                        .map((item, i) => (
                                            <Option key={i} value={item.Project_Name}> {/* Use item.category as value */}
                                                {item.Project_Name}
                                            </Option>
                                        ))
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col lg={5}>
                        <Form.Item name="Spreads" label="Spreads" required>

                            <Select className='maintenance-select' placeholder={'Select category'} style={{ marginTop: '-5px' }}
                                onChange={(value) => handleInputChange('Spreads', value)} size="large"
                                showSearch
                                allowClear
                                dropdownRender={menu => (
                                    <>
                                        {menu}
                                        <Divider style={{ margin: '8px 0', borderTop: '1px solid lightgrey' }} />
                                        <Space align="center" style={{ padding: '0 8px 4px' }}>
                                            <Input placeholder="Add Category" value={newItem} onChange={setAdddnewItem} />
                                            <Typography.Link onClick={(e) => addItem('Spreads_List', newItem)} style={{ whiteSpace: 'nowrap', color: "green" }}>
                                                <CheckOutlined />
                                            </Typography.Link>
                                            <Typography.Link onClick={removeItem} style={{ whiteSpace: 'nowrap', color: "red" }}>
                                                <CloseOutlined />
                                            </Typography.Link>
                                        </Space>
                                    </>
                                )}


                            >

                                {categoryListdata.Spreads_List &&
                                    categoryListdata.Spreads_List
                                        //.sort((a, b) => a.material.localeCompare(b.ProProject_Nameject)) // Sort by category
                                        .map((item, i) => (
                                            <Option key={i} value={item.Spreads}> {/* Use item.category as value */}
                                                {item.Spreads}
                                            </Option>
                                        ))
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col lg={5}>
                        <Form.Item name="Contractor" label="Contractor" required>

                            <Select className='maintenance-select' placeholder={'Select category'} style={{ marginTop: '-5px' }}
                                onChange={(value) => handleInputChange('Contractor', value)} size="large"
                                showSearch
                                allowClear

                                dropdownRender={menu => (
                                    <>
                                        {menu}
                                        <Divider style={{ margin: '8px 0', borderTop: '1px solid lightgrey' }} />
                                        <Space align="center" style={{ padding: '0 8px 4px' }}>
                                            <Input placeholder="Add Category" value={newItem} onChange={setAdddnewItem} />
                                            <Typography.Link onClick={(e) => addItem('Contractor', newItem)} style={{ whiteSpace: 'nowrap', color: "green" }}>
                                                <CheckOutlined />
                                            </Typography.Link>
                                            <Typography.Link onClick={removeItem} style={{ whiteSpace: 'nowrap', color: "red" }}>
                                                <CloseOutlined />
                                            </Typography.Link>
                                        </Space>
                                    </>
                                )}


                            >

                                {categoryListdata.Contractor &&
                                    categoryListdata.Contractor
                                        //.sort((a, b) => a.material.localeCompare(b.Contractor)) // Sort by category
                                        .map((item, i) => (
                                            <Option key={i} value={item.Contractor}> {/* Use item.category as value */}
                                                {item.Contractor}
                                            </Option>
                                        ))
                                }
                            </Select>
                        </Form.Item>
                    </Col>



                    <Col lg={5} >
                        <Form.Item name="Proposal_Number" label="Quote Number" required
                        // rules={[{ required: true, message: 'Please input the project!' }]}
                        >
                            <Input onChange={(e) => handleInputChange('Proposal_Number', e.target.value)} size="large" />
                        </Form.Item>
                    </Col>

                    <Col lg={5}>
                        <Form.Item name="Representative" label="Representative">

                            <Select className='maintenance-select' placeholder={'Select category'} style={{ marginTop: '-5px' }}
                                onChange={(value) => handleInputChange('Representative', value)} size="large"
                                showSearch
                                allowClear

                                dropdownRender={menu => (
                                    <>
                                        {menu}
                                        <Divider style={{ margin: '8px 0', borderTop: '1px solid lightgrey' }} />
                                        <Space align="center" style={{ padding: '0 8px 4px' }}>
                                            <Input placeholder="Add Category" value={newItem} onChange={setAdddnewItem} />
                                            <Typography.Link onClick={(e) => addItem('Representative_List', newItem)} style={{ whiteSpace: 'nowrap', color: "green" }}>
                                                <CheckOutlined />
                                            </Typography.Link>
                                            <Typography.Link onClick={removeItem} style={{ whiteSpace: 'nowrap', color: "red" }}>
                                                <CloseOutlined />
                                            </Typography.Link>
                                        </Space>
                                    </>
                                )}

                            >
                                {categoryListdata.Representative_List &&
                                    categoryListdata.Representative_List
                                        //.sort((a, b) => a.diameter.localeCompare(b.diameter)) // Sort by category
                                        .map((item, i) => (
                                            <Option key={i} value={item.Respresentative}> {/* Use item.category as value */}
                                                {item.Respresentative}
                                            </Option>
                                        ))
                                }

                            </Select>
                        </Form.Item>
                    </Col>








                    <Col lg={5}>
                        <Form.Item name="Location" label="Location">
                            <Select className='maintenance-select' placeholder={'Select category'} style={{ marginTop: '-5px' }}
                                onChange={(value) => handleInputChange('Location', value)} size="large"
                                showSearch
                                allowClear

                                dropdownRender={menu => (
                                    <>
                                        {menu}
                                        <Divider style={{ margin: '8px 0', borderTop: '1px solid lightgrey' }} />
                                        <Space align="center" style={{ padding: '0 8px 4px' }}>
                                            <Input placeholder="Add Category" value={newItem} onChange={setAdddnewItem} />
                                            <Typography.Link onClick={(e) => addItem('Countries_List', newItem)} style={{ whiteSpace: 'nowrap', color: "green" }}>
                                                <CheckOutlined />
                                            </Typography.Link>
                                            <Typography.Link onClick={removeItem} style={{ whiteSpace: 'nowrap', color: "red" }}>
                                                <CloseOutlined />
                                            </Typography.Link>
                                        </Space>
                                    </>
                                )}

                            >
                                {categoryListdata.Countries_List &&
                                    categoryListdata.Countries_List
                                        .sort((a, b) => a.country.localeCompare(b.country)) // Sort by category
                                        .map((item, i) => (
                                            <Option key={i} value={item.country}> {/* Use item.category as value */}
                                                {item.country}
                                            </Option>
                                        ))
                                }

                            </Select>
                        </Form.Item>
                    </Col>




                    <Col lg={5}>
                        <Form.Item name="No_of_Joints" label="Number of Welds" required>
                            <Input onChange={(e) => handleInputChange('No_of_Joints', Number(e.target.value))} min={0} size="large" type='number' />
                        </Form.Item>
                    </Col>
                    <Col lg={5}>
                        <Form.Item name="Pipe_Grade" label="Pipe Grade">
                            <Select className='maintenance-select' placeholder={'Select category'} style={{ marginTop: '-5px' }}
                                showSearch onChange={(value) => handleInputChange('Pipe_Grade', value)} size="large"
                                allowClear

                                dropdownRender={menu => (
                                    <>
                                        {menu}
                                        <Divider style={{ margin: '8px 0', borderTop: '1px solid lightgrey' }} />
                                        <Space align="center" style={{ padding: '0 8px 4px' }}>
                                            <Input placeholder="Add Category" value={newItem} onChange={setAdddnewItem} />
                                            <Typography.Link onClick={(e) => addItem('Pipe_Grade_List', newItem)} style={{ whiteSpace: 'nowrap', color: "green" }}>
                                                <CheckOutlined />
                                            </Typography.Link>
                                            <Typography.Link onClick={removeItem} style={{ whiteSpace: 'nowrap', color: "red" }}>
                                                <CloseOutlined />
                                            </Typography.Link>
                                        </Space>
                                    </>
                                )}

                            >
                                {categoryListdata.Pipe_Grade_List &&
                                    categoryListdata.Pipe_Grade_List
                                        //.sort((a, b) => a.diameter.localeCompare(b.diameter)) // Sort by category
                                        .map((item, i) => (
                                            <Option key={i} value={item.Pipe_Grade}> {/* Use item.category as value */}
                                                {item.Pipe_Grade}
                                            </Option>
                                        ))
                                }

                            </Select>
                        </Form.Item>
                    </Col>

                    <Col lg={5}>
                        <Form.Item name="Pipe_Length" label="Pipe Length (Feet)">
                            <Input defaultValue={40} onChange={(e) => handleInputChange('Pipe_Length', Number(e.target.value))} size="large" type='number' />
                        </Form.Item>
                    </Col>
                    <Col lg={5}>
                        <Form.Item name="Pipe_OD" label="Diameter" required>
                            <Select className='maintenance-select' placeholder={'Select category'} size="large" style={{ marginTop: '-5px' }}
                                showSearch onChange={(value) => handleInputChange('Pipe_OD', value)}
                                allowClear
                                value={formData.Pipe_OD}

                            // dropdownRender={menu => (
                            //     <>
                            //         {menu}
                            //         <Divider style={{ margin: '8px 0', borderTop: '1px solid lightgrey' }} />
                            //         <Space align="center" style={{ padding: '0 8px 4px' }}>
                            //             <Input placeholder="Add Category" value={newItem} onChange={setAdddnewItem} />
                            //             <Typography.Link onClick={(e)=>addItem('diameterList',newItem)} style={{ whiteSpace: 'nowrap', color: "green" }}>
                            //                 <CheckOutlined />
                            //             </Typography.Link>
                            //             <Typography.Link onClick={removeItem} style={{ whiteSpace: 'nowrap', color: "red" }}>
                            //                 <CloseOutlined />
                            //             </Typography.Link>
                            //         </Space>
                            //     </>
                            // )}

                            >

                                {diameterList &&
                                    diameterList
                                        //.sort((a, b) => a.diameter.localeCompare(b.diameter)) // Sort by category
                                        .map((item, i) => (
                                            <Option key={i} value={item.Diameter}> {/* Use item.category as value */}
                                                {item.Diameter}
                                            </Option>
                                        ))
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col lg={5}>
                        <Form.Item name="Daily_Production_Rate" label="Daily Production Rate">
                            <Input onChange={(e) => handleInputChange('Daily_Production_Rate', Number(e.target.value))} min={0} size="large" type='number' />
                        </Form.Item>
                    </Col>

                    <Col lg={5}>
                        <Form.Item name="Spread_Config" label="Spread Configuration" required>
                            <Select className='maintenance-select' placeholder={'Select category'} style={{ marginTop: '-5px' }}
                                showSearch onChange={(value) => handleInputChange('Spread_Config', value)} size="large"
                                allowClear

                            // dropdownRender={menu => (
                            //     <>
                            //         {menu}
                            //         <Divider style={{ margin: '8px 0', borderTop: '1px solid lightgrey' }} />
                            //         <Space align="center" style={{ padding: '0 8px 4px' }}>
                            //             <Input placeholder="Add Category" value={newItem} onChange={setAdddnewItem} />
                            //             <Typography.Link onClick={(e)=>addItem('Spread_Config_List',newItem)} style={{ whiteSpace: 'nowrap', color: "green" }}>
                            //                 <CheckOutlined />
                            //             </Typography.Link>
                            //             <Typography.Link onClick={removeItem} style={{ whiteSpace: 'nowrap', color: "red" }}>
                            //                 <CloseOutlined />
                            //             </Typography.Link>
                            //         </Space>
                            //     </>
                            // )}

                            >
                                {categoryListdata.Spread_Config_List &&
                                    categoryListdata.Spread_Config_List
                                        //.sort((a, b) => a.diameter.localeCompare(b.diameter)) // Sort by category
                                        .map((item, i) => (
                                            <Option key={i} value={item.Spread_Config}> {/* Use item.category as value */}
                                                {item.Spread_Config}
                                            </Option>
                                        ))
                                }

                            </Select>
                        </Form.Item>
                    </Col>

                    <Col lg={5}>
                        <Form.Item name="Length_in_Kms" label="Length" required>

                            <div style={{
                                border: '1px solid #d9d9d9',
                                padding: '5px',
                                borderRadius: '5px',
                                display: 'flex',
                                alignItems: 'center',  // Center vertically
                                marginTop: '-8px'

                            }}>

                                <Input style={{ flex: '0 0 45%' }}
                                    value={formData.Length_in_Kms}
                                    onChange={(e) => handleInputChange('Length_in_Kms', Number(e.target.value))}
                                    size="large"
                                    type='number' />
                                <div className='report-radio' style={{ flex: '0 0 40%', paddingLeft: '5px' }}>
                                    <Radio.Group style={{ display: "flex", }}
                                        value={getCategoryUnitsValue1('Length_in_Kms')}
                                        onChange={(e) => {
                                            handleUnitsChange('Length_in_Kms', e.target.value)

                                        }}
                                    >
                                        <Radio value={'KM'}>KM</Radio>
                                        <Radio value={'Miles'}>Miles</Radio>
                                    </Radio.Group>
                                </div>

                            </div>

                        </Form.Item>
                    </Col>

                    <Col lg={5}>
                        <Form.Item name="Wall_thickness_in_mm" label="Wall Thickness" >
                            <div style={{
                                border: '1px solid #d9d9d9',
                                padding: '5px',
                                borderRadius: '5px',
                                display: 'flex',
                                alignItems: 'center',  // Center vertically
                                marginTop: '-8px'

                            }}>

                                <Input style={{ flex: '0 0 45%' }}
                                    value={formData.Wall_thickness_in_mm}
                                    onChange={(e) => handleInputChange('Wall_thickness_in_mm', Number(e.target.value))}
                                    size="large"
                                    type='number' />
                                <div className='report-radio' style={{ flex: '0 0 40%', paddingLeft: '5px' }}>
                                    <Radio.Group style={{ display: "flex", }}
                                        value={getCategoryUnitsValue1('Wall_thickness_in_mm')}
                                        onChange={(e) => {
                                            handleUnitsChange('Wall_thickness_in_mm', e.target.value)

                                        }}
                                    >
                                        <Radio value={'mm'}>mm</Radio>
                                        <Radio value={'inch'}>inch</Radio>
                                    </Radio.Group>
                                </div>
                            </div>

                        </Form.Item>
                    </Col>
                    <Col lg={5}>
                        <Form.Item name="Project_Type" label=" Project Type" className='report-radio' required>
                            <div>
                                <Radio.Group
                                    value={formData.Project_Type}

                                    onChange={(e) => handleInputChange('Project_Type', e.target.value)}>
                                    <Radio value={"Onshore"} style={{ paddingTop: '5px' }}>Onshore</Radio>
                                    <Radio value={"Offshore"}>Offshore</Radio>
                                </Radio.Group>
                            </div>

                        </Form.Item>
                    </Col>



                    <Col lg={5}>
                        <Form.Item name="EstimatedStart" label="Estimated Start">
                            <div className='date-picker'>
                                <DatePicker
                                    // picker="year" // Picking by year, but we'll interpret it as quarters
                                    style={{ border: 'line', width: '100%' }}
                                    value={formData.EstimatedStart ? moment(formData.EstimatedStart, 'YYYY-[Q]Q') : null}
                                    onChange={handleQuarterChange}
                                    picker={"quarter"}
                                />
                            </div>
                        </Form.Item>
                    </Col>
                    <Col lg={5}>
                        <Form.Item name="Status" label="Status">
                            <Select className='maintenance-select' placeholder={'Select Status'} style={{ marginTop: '-5px' }}
                                showSearch onChange={(value) => handleInputChange('Status', value)} size="large"
                                allowClear
                            >
                                {statuslist &&
                                    statuslist
                                        //.sort((a, b) => a.diameter.localeCompare(b.diameter)) // Sort by category
                                        .map((item, i) => (
                                            <Option key={i} value={item}> {/* Use item.category as value */}
                                                {item}
                                            </Option>
                                        ))
                                }

                            </Select>
                        </Form.Item>
                    </Col>
                    <Col lg={4}>
                        <Form.Item name="Source_Type" label="Proposal Type" className='report-radio'>

                            <Select className='maintenance-select' placeholder={'Select Source'} style={{ marginTop: '-5px' }}
                                showSearch onChange={(value) => handleInputChange('Source_Type', value)} size="large"
                                allowClear
                            >
                                {sourcetype &&
                                    sourcetype
                                        //.sort((a, b) => a.diameter.localeCompare(b.diameter)) // Sort by category
                                        .map((item, i) => (
                                            <Option key={i} value={item}> {/* Use item.category as value */}
                                                {item}
                                            </Option>
                                        ))
                                }

                            </Select>

                        </Form.Item>
                    </Col>
                    <Col lg={4}>
                        <Form.Item name="Equipment_Type" label="Equipment Type" className='report-radio' required>
                            <div>                                <Radio.Group
                                value={formData.Equipment_Type}
                                onChange={async (e) => {
                                    await handleInputChange('Pipe_OD', '')
                                    const editData = {
                                        Pipe_OD: '',
                                    };

                                    projectDetailForm.setFieldsValue(editData)
                                    handleInputChange('Equipment_Type', e.target.value);
                                    setEquipmentType(e.target.value);
                                    await UpdateDiameterList(e.target.value)

                                }}>
                                <Radio value={"IWM"} style={{ paddingTop: '5px' }}>IWM</Radio>
                                <Radio value={"CBUC"}>CBUC</Radio>
                            </Radio.Group>
                            </div>

                        </Form.Item>
                    </Col>


                    <Col lg={6}>
                        <Form.Item name="Power_Supplies" label="Power Supplies" className='report-radio'>
                            <div>

                                <Checkbox value={"Miller XMT 350"}
                                    onChange={(e) => {
                                        handlePowerSupliesChange('Miller XMT 350', e.target.checked ? 'True' : 'False');
                                    }} style={{ paddingTop: '5px' }}
                                    checked={formData.Power_Supplies['Miller XMT 350'] == 'True' ? true : false}
                                >Miller XMT 350</Checkbox>
                                <Checkbox value={"Lincoln"}

                                    checked={formData.Power_Supplies['Lincoln'] == 'True' ? true : false}
                                    onChange={(e) => {

                                        handlePowerSupliesChange('Lincoln', e.target.checked ? 'True' : 'False');

                                    }}>Lincoln</Checkbox>
                                <Checkbox value={"Fronius"} style={{ paddingTop: '5px' }}
                                    checked={formData.Power_Supplies['Fronius'] == 'True' ? true : false}
                                    onChange={(e) => {

                                        handlePowerSupliesChange('Fronius', e.target.checked ? 'True' : 'False');

                                    }}>Fronius</Checkbox>

                            </div>

                        </Form.Item>
                    </Col>


                </Row>
            </Form>
        </Modal>
    );
};

export default ProjectModal;
