import React, { useEffect, useState } from 'react';
import { Table, Button, Layout, Input, text, Row, Col, Tooltip, Slider } from 'antd';
import { FilePdfOutlined } from '@ant-design/icons';
import { Content, Footer } from 'antd/lib/layout/layout';
import RevenueChart from './RevenueChart';
import TwoSidedSlider from './Sidelsider';
const EquipmentTable = (props) => {
    const { summary, calculatedWeldPrice, calculatedSummary, setCalculatedSummary, ReportUrl, setIsMarginEdited } = props;

    const [grossMargin1, setGrossMargin] = useState(parseFloat((summary['Margin']['Total'] * 100).toFixed(2)));
    const [totalRevenue, setTotalRevenue] = useState(summary['Revenue']['Total Revenue']); // Retrieve total revenue
    
    let grossOriginalvalue = parseFloat((summary['Margin']['Total'] * 100).toFixed(2)) ?? 0
    useEffect(() => {
        setGrossMargin(parseFloat((summary['Margin']['Total'] * 100).toFixed(2)) ?? 0)
       
    }, [])
    // Prepare the data for the table
    const tableData = [
        {
            key: '1',
            item: 'Rent',
            perWeldTotal: calculatedWeldPrice['Rent'],
            totalRevenue: summary['Revenue']['Rent'],
            totalCost: summary['Cost']['Rent'],
            GM: summary['Margin']['Rent'] * 100,

        },
        {
            key: '2',
            item: 'Mob',
            perWeldTotal: calculatedWeldPrice['Mob'],
            totalRevenue: summary['Revenue']['Mob'],
            totalCost: summary['Cost']['Mob'],
            GM: summary['Margin']['Mob'] * 100,
        },

        {
            key: '3',
            item: 'Spares',
            perWeldTotal: calculatedWeldPrice['Spares'],
            totalRevenue: summary['Revenue']['Spares'],
            totalCost: summary['Cost']['Spares'],
            GM: summary['Margin']['Spares'] * 100,
        },
        {
            key: '4',
            item: 'Wire',
            perWeldTotal: calculatedWeldPrice['Wire'],
            totalRevenue: summary['Revenue']['Wire'],
            totalCost: summary['Cost']['Wire'],
            GM: summary['Margin']['Wire'] * 100,
        },

        {
            key: '5',
            item: 'Total',
            perWeldTotal: calculatedWeldPrice['Total'],
            totalRevenue: summary['Revenue']['Total Revenue'],
            totalCost: summary['Cost']['Total Cost'],
            GM: summary['Margin']['Total'] * 100,
        },
        // {
        //     key: '6',
        //     item: 'Tech Rates',
        //     perWeldTotal: calculatedWeldPrice['Tech Rates'],
        //     totalRevenue:summary['Revenue']['Tech Rates'],
        //     totalCost: summary['Cost']['Tech Rates'],
        //     GM: summary['Margin']['Tech Rates'] *100,

        // },
        // {
        //     key: '7',
        //     item: 'Grand Total',
        //     perWeldTotal: '',
        //     totalRevenue: summary['Revenue']['Grand Total'],
        //     totalCost: summary['Cost']['Grand Total'],
        //     GM: summary['Margin']['Grand Total'] *100,
        // },
        // {
        //     key: '7',
        //     item: 'GM',
        //     perWeldTotal: '',
        //     totalRevenue: summary['Revenue']['Grand Total'],
        //     totalCost: summary['Cost']['Grand Total'],
        //     GM: summary['Margin']['Mob'],
        // },
    ];
    const formatCurrency = (value) => {
        if(value=='')
            return '-'
        return value ? `$${parseFloat(value).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}` : '';
    };

    //sconst [tableData, setTableData] = useState(initialData);
    // Define the columns for the table
    const columns = [
        {
            title: 'Item',
            dataIndex: 'item',
            key: 'item',
            align: 'center',
            render: (text, record) => {
                return record.item === 'Total' ? <strong>{text}</strong> : text;
            }
        },
        {
            title: 'Per Weld Total',
            dataIndex: 'perWeldTotal',
            key: 'perWeldTotal',
            align: 'center',
            render: (text, record) => {
                return record.item === 'Total' ? <strong style={{fontSize:'18px'}}>{formatCurrency(text)}</strong> : formatCurrency(text);
            },
        },
        {
            title: 'Total Revenue',
            dataIndex: 'totalRevenue',
            key: 'totalRevenue',
            align: 'center',
            render: (text, record) => {
                return record.item === 'Total' ? <strong style={{fontSize:'18px'}}>{formatCurrency(text)}</strong> : formatCurrency(text);
            },
        },
        {
            title: 'Total Cost',
            dataIndex: 'totalCost',
            key: 'totalCost',
            align: 'center',
            render: (text, record) => {
                return record.item === 'Total' ? <strong style={{fontSize:'18px'}}>{formatCurrency(text)}</strong> : formatCurrency(text);
            },
        },
        {
            title: 'GM %',
            dataIndex: 'GM',
            key: 'GM',
            align: 'center',
            render: (text, record) => {
                return record.item === 'Total' ? <strong style={{fontSize:'18px'}}>{`${text =='' || text =='NaN'?0:text.toFixed(1)}%`}</strong> : `${text==''|| text =='NaN'?0:text.toFixed(1)}%`;
            },
        },
    ];
    const equipmentList = [
        'Gross Margin',
        'Gross Margin1',
        'Total Revenue',

    ];
    const handleInputChange = (value) => {
        setIsMarginEdited(true)
        // Convert input to a number and handle it as a percentage
        const marginValue = Number(value) / 100; // Convert the value to decimal (e.g., 20% becomes 0.20)
        console.log('Gross Margin:', grossMargin1)
        // Update the grossMargin state
        setGrossMargin(value);

        // Calculate Total Revenue using the updated gross margin
        const totalCost = calculatedSummary.totalCost; // Get total cost from the summary
        const updatedTotalRevenue = totalCost / (1 - marginValue); // Calculate total revenue

        // Update the calculatedSummary with the new total revenue
        //calculatedSummary['Revenue']['Total Revenue'] = updatedTotalRevenue;
        calculatedSummary.totalRevenue = updatedTotalRevenue
        console.log(`Gross Margin: ${marginValue}, Updated Total Revenue: ${updatedTotalRevenue}`);
        setCalculatedSummary(calculatedSummary)

    };
    const DownloadUrl = () => {
        if (ReportUrl) {

            const link = document.createElement('a');
            link.href = ReportUrl;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }

    // const marks = {
    //     0: '0%',
    //     // [grossOriginalvalue-5] :grossOriginalvalue-20,
    //     [grossOriginalvalue]: grossOriginalvalue,
    //     // [grossOriginalvalue+5] :grossOriginalvalue+20,
    //     100: {
    //         style: {
    //             color: '#f50',
    //         },
    //         label: <strong>100%</strong>,
    //     },
    // };


    const marks = {
        0: '0°C',
        26: '26°C',
        37: '37°C',
        100: {
            style: {
                color: '#f50',
            },
            label: <strong>100°C</strong>,
        },
    };

    const revenuChart1 = () => {
        const totalCost = summary['Cost']['Total Cost']; // Retrieve total cost
        const grossMargin = summary['Margin']['Total'];
        const totalRevenue = summary['Revenue']['Total Revenue']; // Retrieve total revenue

        return (
            <div>
                <Row gutter={[24, 24]}>
                    {/* Column for the chart */}
                    <Col span={14}> {/* Adjust span to control the chart's width */}
                        <RevenueChart
                            totalCost={totalCost}
                            grossMargin={grossMargin}
                            totalRevenue={totalRevenue}
                        />
                    </Col>

                    {/* Column for equipment list inputs */}
                    <Col span={10} > {/* Adjust span to control the inputs' width */}

                    </Col>
                </Row >
            </div >
        );
    };
    return (
        <div>
 
        
        {/* Wrapper with border and margin */}
        <div
            style={{
                border: "1px solid #e0e0e0", // Light border for visual separation
                borderRadius: "8px", // Rounded corners
                backgroundColor: "transparent", // Light background color
                padding: "0px", // Padding inside the border for spacing
                marginBottom: "10px", // Margin at the bottom for spacing between components
                boxSizing: "border-box", // Ensure padding and border are included in width calculation
            }}
        >
            {/* Table Component */}
            <Table style={{paddingBottom:'0px'}}
                className="h-100 pipeline-table equip_table equipPaginationTable"
                dataSource={tableData}
                columns={columns}
                pagination={false} // Disable pagination if all rows should be visible
                // scroll={{ y: 'calc(60vh - 250px)' }}
            />
        </div>
        
        {/* Wrapper for TwoSidedSlider with border and margin */}
        <div
            style={{
                border: "1px solid #e0e0e0", // Border around TwoSidedSlider
                borderRadius: "8px", // Rounded corners
                backgroundColor: "transparent", // Background color
                padding: "10px", // Padding inside the border
                boxSizing: "border-box", // Ensures consistent width and height
            }}
        >
            {/* TwoSidedSlider Component */}
            <TwoSidedSlider
                grossOriginalvalue={grossOriginalvalue}
                calculatedSummary={calculatedSummary}
                setCalculatedSummary={setCalculatedSummary}
                setIsMarginEdited={setIsMarginEdited}
            />
        </div>
        
        </div>

    );
}
export default EquipmentTable
