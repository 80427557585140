

import get from 'lodash/get';
import { FETCHPRICINGLIST, FETCHPRICINGLIST_FAILURE, FETCHPRICINGLIST_SUCCESS, FETCH_PRICING_LIST, FETCH_PRICING_LIST_SUCCESS, FETCH_PRICING_LIST_FAILURE } from './types';

//import { fetchLicenseListSuccess } from './generatorLicense';
const initialState = {
  isGenerateLoading: false,
  isDeleteLoading: false,
  isFetchLoading:false,
  isPricingListLoading:false

};
const PricingListReducer = (state = initialState, action) => {
 
  switch (action.type) {

  
    case FETCHPRICINGLIST: {
      return {
        ...state,
        isFetchLoading: true,
      };
    }
    case FETCHPRICINGLIST_SUCCESS: {
      const resData = get(action, 'responseData', []);
      return {
        ...state,
        PricingList: resData,
        PricingListCount: resData.length > 0 ? resData[0].totalCount : 0,
        PricingListTotalCount: resData.length > 0 ? resData[0].totalListCount : 0,
        isFetchLoading: false
      }
    }
    case FETCHPRICINGLIST_FAILURE: {
      return {
        ...state,
        isFetchLoading: false
      }
    }

    case FETCH_PRICING_LIST: {
      return {
        ...state,
        isPricingListLoading: true,
      };
    }
    case FETCH_PRICING_LIST_SUCCESS: {
      const resData = get(action, 'responseData', []);
      return {
        ...state,
        PricingListData: resData,
        PricingListDataCount: resData.length > 0 ? resData[0].totalCount : 0,
        PricingListDataTotalCount: resData.length > 0 ? resData[0].totalListCount : 0,
        isPricingListLoading: false
      }
    }
    case FETCH_PRICING_LIST_FAILURE: {
      return {
        ...state,
        isPricingListLoading: false
      }
    }

  
    default: {
      return state;
    }
  }
};

export default PricingListReducer;