/* eslint-disable no-const-assign */
/* eslint-disable consistent-return */
/* eslint-disable no-else-return */
/* eslint-disable dot-notation */
/* eslint-disable linebreak-style */
/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
/* eslint-disable prefer-const */
/* eslint-disable comma-spacing */
/* eslint-disable quotes */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import 'antd/dist/antd.dark.css';
import {
  Button, Table, Form, Modal, Spin, notification, Tooltip, Typography, Input
} from 'antd';
import {
  DeleteOutlined, EditOutlined
} from '@ant-design/icons';
//import { addEmail,removeEmail,updateEmail } from '../services/emailConfig';
import { getUsers } from '../../services/teamMembers';
import {
  saveUsers, removeUser, resetUserPassword, sendUserEmail, updateAccess
} from '../../services/userManagement';
import ResetPasswordModal from './ResetPasswordModal';
import AddUserModal from './AddUserModal';
const { Title, Text } = Typography;
//import { resetPasswordSubmit } from '../../services/auth';

const UserManagementPage = () => {
  const [userModalVisible, setUserModalVisible] = useState(false);
  const [userModalLoading, setUserModalLoading] = useState(false);
  const [resetPasswordModalVisible, setResetPasswordModalVisible] = useState(false);
  const [resetPasswordModalLoading, setResetPasswordModalLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [userData, setUserData] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const [disableSendEmail, setDisableSendEmail] = useState(false);
  const [teamModalLoading, setTeamModalLoading] = useState(false);
  const [createUserLoader, setCreateUserLoader] = useState(false);
  const [totalUsers, setTotalUsers] = useState(0);
  const [userAccessData, setUserAccessData] = useState([]);
  const [searchKeyExist, setSearchKeyExist] = useState('');

  const { Column, ColumnGroup } = Table;

  const [userForm] = Form.useForm();
  const [resetPasswordForm] = Form.useForm();

  const getUsersList = async () => {
    setShowLoader(true);
    const response = await getUsers();
    if (response && response.Users.length) {
      // eslint-disable-next-line consistent-return
      let usersForProject = response.Users.map((obj) => {
        const details = { user_id: obj.Username };
        obj.Attributes.forEach((element) => {
          if (element.Name === 'name') {
            details.name = element.Value;
          } else if (element.Name === 'email') {
            details.email = element.Value;
          }
        });
        details.userStatus = obj.UserStatus;
        details.userAccess = obj.Group;
        if (details.name && details.email && details.userStatus) {
          return details;
        }
      });
      usersForProject = usersForProject.filter((el) => el);
      usersForProject.sort((a, b) => a.name.localeCompare(b.name));
      setUserData(usersForProject);
      setTotalUsers(usersForProject.length);
      setShowLoader(false);
    } else {
      notification.error({
        message: 'Something went wrong.',
      });
      setShowLoader(false);
    }
  };

  useEffect(() => {
    getUsersList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const onUserManagment = async (event) => {
    if (event.target.value) {
      setSearchKeyExist(event.target.value);
      // const pageToken = pageTokens[pageTokens.length - 1];
      getUsersList('', '', event.target.value)
    } else {
      setSearchKeyExist('');
      getUsersList();
    }
  }
  const showResetPasswordModal = () => {
    setResetPasswordModalVisible(true);
  };

  const handleDeletion = (record) => {
    Modal.confirm({
      title: 'Are you sure you want to remove this user?',
      className: 'custom-text-clr',
      async onOk() {
        const userDetail = { email: record.email, name: record.name };
        return removeUser(userDetail).then((res) => {
          if (res) {
            notification.success({
              message: 'User deleted successfully',
            });
            getUsersList();
          } else {
            notification.error({
              message: 'Something went wrong.',
            });
          }
          return true;
        }).catch(() => notification.error({
          message: 'Something went wrong.',
        }));
      },
      onCancel() { },
    });
  };

  const saveUser = async (email, name, password, userAccess) => {
    const data = {
      name,
      email,
      password,
      userAccess
    };
    setUserModalLoading(false);
    const response = await saveUsers(data);
    if (response === 200) {
      setDisableSendEmail(true);
      notification.success({
        message: 'User created successfully',
        duration: 1.2
      });
      getUsersList();
    } else if (response === 502) {
      notification.warning({
        message: 'User with the given email already exists.',
        duration: 1.2
      });
    } else {
      notification.error({
        message: 'Something went wrong.',
      });
    }
    setCreateUserLoader(false);
  };

  const sendEmail = async (email, userName, password) => {
    const data = {
      userName,
      email,
      password
    };
    const response = await sendUserEmail(data);
    if (response) {
      notification.success({
        message: 'Email sent successfully',
      });
      userForm.resetFields();
      setUserAccessData([]);
      setCreateUserLoader(false);
      setDisableSendEmail(false);
      setUserModalVisible(false);
    } else {
      notification.error({
        message: 'Something went wrong.',
      });
    }
  };

  const resetPassword = async (password, email) => {
    const data = {
      email,
      password
    };
    setResetPasswordModalVisible(false);
    resetPasswordForm.resetFields();
    setResetPasswordModalLoading(false);
    const response = await resetUserPassword(data);
    if (response) {
      notification.success({
        message: 'Password has been reset successfully',
      });
    } else {
      notification.success({
        message: 'Something went wrong',
      });
    }
  };

  const showAddEditUserModal = (record, edit) => {
    let userDt = {};
    if (record !== '') {
      const fullName = record.name.split(" ");
      const fName = fullName[0];
      if (fullName.length === 2) {
        const lName = fullName[1];
        userDt = {
          email: record.email,
          firstName: fName,
          lastName: lName,
          access: record.userAccess
        };
      } else {
        userDt = {
          email: record.email,
          firstName: fName,
          access: record.userAccess
        };
      }
      userForm.setFieldsValue(userDt);
      setUserAccessData(record.userAccess);
    } else {
      setUserAccessData([]);
      userForm.resetFields();
    }

    setIsEdit(edit);

    setUserModalVisible(true);
  };

  const updateUserAccess = async (email, name, userAccess) => {
    const data = {
      name,
      email,
      userAccess
    };
    setUserModalLoading(false);
   
    const response = await updateAccess(data);
    if (response === 200) {
      notification.success({
        message: 'User access updated successfully',
        duration: 1.2
      });
      setUserModalVisible(false);
      setCreateUserLoader(false);
      getUsersList();
    } else {
      notification.error({
        message: 'Something went wrong.',
      });
    }
  };

  return (
    <>
      <div className="h-100 overflow-y-hidden overflow-x-auto">
        <div className="d-flex align-items-center">
          <Title className="mb-0" level={4}>
            List of Users
          </Title>
          <div className='searchUserManagement'>
            <Input
              placeholder='Search By Email'
              onChange={onUserManagment}
              value={searchKeyExist}
              allowClear
            />
          </div>
         

          <div className="text-right new-calibration">
            <Button
              key="submit"
              size="medium"
              shape="round"
              type="primary"
              style={{ 'margin-right': '10px' }}
              disabled={showLoader}
              onClick={() => showAddEditUserModal('', false)}
            >
              Create User
            </Button>
            <Button
              key="submit"
              size="medium"
              shape="round"
              type="primary"
              style={{ 'margin-right': '10px' }}
              disabled={showLoader}
              onClick={() => showResetPasswordModal()}
            >
              Reset Password
            </Button>
          </div>
          </div>
        <Table
          //scroll={{ x: totalUsers.length > 0 ? 1000 : 0, y: '100%' }}
          scroll={{ x: totalUsers.length > 0 ? 1000 : 0, y: 'calc(100vh - 255px)' }}
          pagination={false}
          dataSource={userData && userData}
          className="table-with-button group-table-title"
          loading={{
            indicator: <Spin />,
            spinning: showLoader
          }}
        >
          <ColumnGroup title="">
            <Column title="Name" dataIndex="name" key="name" align="left" width="300px" />
          </ColumnGroup>
          <ColumnGroup title="">
            <Column title="Email" dataIndex="email" key="email" align="left" width="410px" />
          </ColumnGroup>
          <ColumnGroup title="">
            <Column title="Confirmation status" dataIndex="userStatus" key="userStatus" align="left" width="300px" />
          </ColumnGroup>
          <ColumnGroup>
            <Column
              title=""
              render={(record) => {
                if (record) {
                  return (
                    <div>
                      <Tooltip
                        arrowPointAtCenter
                        placement="topRight"
                        title="Edit User Access"
                      >
                        <EditOutlined  className='heatIcon'  style={{ marginRight: '30px', fontSize: '16px' }} onClick={() => showAddEditUserModal(record, true)} />
                      </Tooltip>
                      <Tooltip
                        arrowPointAtCenter
                        placement="topLeft"
                        title="Delete User"
                      >
                        <DeleteOutlined className='heatIcon' style={{ marginRight: '30px', fontSize: '16px' }} onClick={() => handleDeletion(record)} />
                      </Tooltip>
                    </div>
                  );
                  // eslint-disable-next-line brace-style
                }
                // eslint-disable-next-line no-else-return
                else {
                  return <div>-</div>;
                }
              }}
              align="right"
            />
          </ColumnGroup>

        </Table>

        {userModalVisible
          ? (
            <AddUserModal
              setUserModalVisible={setUserModalVisible}
              setUserModalLoading={setUserModalLoading}
              userModalVisible={userModalVisible}
              userModalLoading={userModalLoading}
              userForm={userForm}
              isEdit={isEdit}
              setIsEdit={setIsEdit}
              onSave={saveUser}
              setCreateUserLoader={setCreateUserLoader}
              createUserLoader={createUserLoader}
              disableSendEmail={disableSendEmail}
              OnSendEmail={sendEmail}
              setDisableSendEmail={(value) => setDisableSendEmail(value)}
              OnUpdateUserAccess={updateUserAccess}
              userAccessData={userAccessData}
              setUserAccessData={setUserAccessData}
            />
          )
          : null}
        {
          resetPasswordModalVisible
            ? (
              <ResetPasswordModal
                setResetPasswordModalVisible={setResetPasswordModalVisible}
                resetPasswordModalVisible={resetPasswordModalVisible}
                setResetPasswordModalLoading={(value) => setResetPasswordModalLoading(value)}
                resetPasswordModalLoading={resetPasswordModalLoading}
                resetPasswordForm={resetPasswordForm}
                teamModalLoading={teamModalLoading}
                setTeamModalLoading={setTeamModalLoading}
                onResetPassword={resetPassword}
              />
            ) : null
        }
      </div>
    </>

  );
};

export default UserManagementPage;
