import React, { useEffect } from 'react';
import { Table } from 'antd';

const DetailCalculationForm = ({ summaryData, setExcludedKeys }) => {
    const detailCalculation = summaryData?.['Detail Calculation'] || {};

    const formatValue = (value) => {
        return value ? `${parseFloat(value).toFixed(2)}` : '';
    };

    const Detail_Calculation = [
        "RENT PER WELD",
        "RENT PER WELD discounted",
        "RENT PER WELD min",
        "MIN. MONTHLY RATE",
        "MIN. NO. WELDS / MONTH",
        "STANDBY RATE (A/60.8)",
        "MOB/DEMOB FEE(lumpsum)",
        "MOB/DEMOB FEE(per weld)",
        "CONTINGENCY FEE (C*60)",
        "C.I.F.",
        "Min cost to Rent",
        "Total Cost",
        "Total Monthly Rental",
        'Discount'
    ];

    const GetformatValue = (key) => {
        if ("MOB/DEMOB FEE(lumpsum)" === key) return "MOB/DEMOB FEE (lumpsum)";
        if ("MOB/DEMOB FEE(per weld)" === key) return "MOB/DEMOB FEE (per weld)";
        return key;
    };

    // Prepare data for the Table component
    const dataSource = Detail_Calculation.map((key, index) => ({
        key: index,
        label: key,
        value: detailCalculation[GetformatValue(key)] ? formatValue(detailCalculation[GetformatValue(key)]) : 0,
    }));


    const filteredDataSource = dataSource.filter(item =>
        item.label !== 'Discount' && item.label !== 'MOB/DEMOB FEE(per weld)' && item.label !== 'Min cost to Rent' && item.label !== 'Total Cost' && item.label !== 'Total Monthly Rental'
    );

    useEffect(() => {
        const updatedItems = dataSource.map(item => {
            if (item.label === 'Discount') {
                return {
                    ...item,
                    value: item.value * 100
                };
            }
            return item; 
        });
    
        const excludedItems = updatedItems.filter(item =>
            item.label === 'Discount' ||
            item.label === 'Min cost to Rent' ||
            item.label === 'Total Cost' ||
            item.label === 'Total Monthly Rental'
        );
    
        setExcludedKeys(excludedItems);
    }, [summaryData]);
    
    const formatCurrency = (value) => {
        return value 
            ? `$${Number(value).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}` 
            : '';
    };
    //console.log("dataSource", dataSource)
    // Define columns for the Table component
    const columns = [
        {
            title: '',
            dataIndex: 'label',
            key: 'label',
            width: '20%',
        },
        {
            title: '',
            dataIndex: 'value',
            key: 'value',
            width: '10%',
            render: (text, record) => (formatCurrency(text)),
        },
        {
            title: '',
            dataIndex: 'value',
            key: 'value',
            width: '10%',
            render: (text, record) => {
                if (record.label == 'RENT PER WELD discounted') {
                    return dataSource.map(item => {
                        if (item.label == "Discount") {
                            return `${item.value * 100}%`;

                        }
                    });
                } else if (record.label == 'MOB/DEMOB FEE(lumpsum)') {
                    return dataSource.map(item => {
                        if (item.label == "MOB/DEMOB FEE(per weld)") {
                            return `${formatCurrency(item.value)}`;

                        }
                    });
                } else {
                    return '-';
                }
            },
        },
    ];

    return (
        <>
            {/* <div style={{ display: 'flex', flexWrap: 'wrap', marginBottom: '16px' }}>
                {excludedItems.map(item => (
                    <div key={item.label} style={{ marginRight: '16px' }}>
                        <strong>{item.label}:</strong> ${item.value}
                    </div>
                ))}
            </div> */}

            <Table style={{width:'90%' ,marginLeft:'25px'}}
                className='pricing-equipment-table'
                dataSource={filteredDataSource}
                columns={columns}
                pagination={false}
                //   bordered
                size="small"
            />
        </>
    );
};

export default DetailCalculationForm;
