/* eslint-disable no-param-reassign */
/* eslint-disable react/no-unescaped-entities */
import { ArrowLeftOutlined, CloudUploadOutlined, DeleteOutlined, EditOutlined, EyeOutlined, FilterOutlined } from '@ant-design/icons';
import {
    Button, Form,
    Input,
    Modal,
    Space,
    Table,
    Tabs,
    Tooltip,
    Typography,
    Upload, notification, DatePicker, Drawer, Select, Layout
} from 'antd';
import { get } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import ExcelJS from 'exceljs';
import * as XLSX from 'xlsx';

import history from '../../services/history';
import ProjectModal from './ProjectModal';
import MasterModal from './MasterModal';
import { fetchRecordList } from '../../redux/Trackrecord/action';
import MasteForm from './MasteForm';
import { AddOrUpdateProject, DeleteTrackRecordDetails, GeneratetrackRecordReport, GetRecordListExcel, getFilterCategoryListFromDatabse } from '../../redux/Trackrecord/saga';
import { authenticateCurrentUser } from '../../services/auth';
import { Content, Footer } from 'antd/lib/layout/layout';



const { Option } = Select;
const { Title, Text } = Typography;
const { TabPane } = Tabs;
//const { TabPane } = Tabs;
//const { Column } = Table;
//const { Search } = Input;

//const { Option } = Select;
const TrackRecordPage = (props) => {

    const {
        onRecordList,
        projectList,
        isLoading,
        listCount,
        listTotalCount
    } = props;

    const dataSource = [
        {
            "project": "sella",
            "projectName": "rajas",
            "contractor": "gfg",
            "owningCo": "gfg",
            "location": "AMERICAN SAMOA",
            "yearStarted": "gfdg",
            "material": "22% Cr",
            "lengthKM": "fgf",
            "lengthMiles": "fgg",
            "numberOfWelds": "334",
            "diameter": 10,
            "wt1": "bgb",
            "wt2": "fgb",
            "wt3": "bbv",
            "wt4": "bvbv",
            "rentalContract": "yes",
            "serviceContract": "yes",
            "saleContract": "yes",
            "type": "Onshore",
            "position": "1G",
            "numberOfSpreads": "bvc",
            "workmanship": "bcvb",
            "eca": "bvcbv",
            "craMaterials": "vbcb",
            "specification": "bvcb",
            "numberOfStations": "bvcbcv",
            "rootBeadType": "Manual STT",
            "externalEquip": "P260",
            "weldingProcess": "FCAW",
            "powerSupply": "DC400",
            "weldingWireDiameter": "vbv",
            "weldingWireType": "DC400",
            "terrain": "Normal",
            "climate": "Monsoon",
            "prodRate": "g45",
            "repairRate": "ghg",
            "numberOfRepairs": "hgfh",
            "inspectionType": "X-Ray",
            "inspectionCompany": "ghg",
            "comments": "comments "
        }

    ]
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isMasterModalVisible, setIsMasterModalVisible] = useState(false);
    const [currentPageNumber, setCurrentPageNumber] = useState(1);
    const [currentPagesize, setCurrentPagesize] = useState(10);
    const [isEdit, setIsEdit] = useState(false);
    const [editRecord, seteditRecord] = useState('');
    const [projectDetailForm] = Form.useForm()
    const [searchCriteria, setSearchCriteria] = useState({});
    const [selectdTypeFilter, setSelectdFilterType] = useState({});
    const [searchFilterType, setSearchFilterType] = useState({});
    const [categoryList, setCategoryList] = useState({});
    const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
    const [selectedData, setSelectedData] = useState('');
    const [isDeleteLoading, setIsDeleteLoading] = useState(false)
    const [isDrawerVisible, setIsDrawerVisible] = useState(false);
    const [generateReportLoading, setGenerateReportLoading] = useState(false);
    const [projectNameCategory, setProjectNameCategory] = useState([]);
    const [exportLoading, setExportLoading] = useState(false);
    const [uploadLoading, setUploadLoading] = useState(false)
    const [bulkUplaodModal, setBulkUplaodModal] = useState(false);
    const [fileList, setFileList] = useState([]);
    const [disableFileUpload, setDisableFileUpload] = useState(false);
    const [filterCategoryList, setFilterCategoryList] = useState({
        'projectname': []
    });

    const [prevPageNumber, setPrevPageNumber] = useState(1);
    const [prevPagesize, setPrevPagesize] = useState(10);
    const [searchType, setSearchType] = useState({});

    const [activeKey, setActiveKey] = useState('1')
    const [activeKeyLine, setActiveKeyLine] = useState('1')
    const factor = 0.621371;
    const renderText = (text) => {
        if (!text) {
            return <div>-</div>;
        }

        const originalValue = Number(text);
        const convertedValue = (originalValue * factor).toFixed(3); // Rounded to 3 decimal places

        return <div>{`${text} / ${convertedValue}`}</div>;
    };
    const columns = [
        {
            title: 'Year',
            dataIndex: 'yearStarted',
            key: 'yearStarted',
            align: 'center',
        },
        // {
        //     title: 'Project',
        //     dataIndex: 'project',
        //     key: 'project',
        //     align: 'center',
        // },
        {
            title: 'Project Name',
            dataIndex: 'projectName',
            key: 'projectName',
            align: 'left',
            width: '18%'
        },
        {
            title: 'Contractor',
            dataIndex: 'contractor',
            key: 'contractor',
            align: 'left',
            width: '10%'
        },
        // {
        //     title: 'Owning Co',
        //     dataIndex: 'owningCo',
        //     key: 'owningCo',
        //     align: 'center',
        // },
        {
            title: 'Location',
            dataIndex: 'location',
            key: 'location',
            align: 'left',
            width: '10%'
        },

        {
            title: 'Material',
            dataIndex: 'material',
            key: 'material',
            align: 'center',
        },
        {
            title: 'Length (KM/Miles)',
            dataIndex: 'lengthKM',
            key: 'lengthKM',
            align: 'center',
            width: '10%',
            render: (text) => renderText(text)
        },
        {
            title: 'Equipment',
            dataIndex: 'externalEquip',
            key: 'externalEquip',
            align: 'center',
            width: '8%'
        },
        {
            title: 'Welds',
            dataIndex: 'numberOfWelds',
            key: 'numberOfWelds',
            align: 'center',
        },
        {
            title: 'Diameter',
            dataIndex: 'diameter',
            key: 'diameter',
            align: 'center',
        },
        {
            title: 'WT 1',
            dataIndex: 'wt1',
            key: 'wt1',
            align: 'center',
        },
        {
            title: 'WT 2',
            dataIndex: 'wt2',
            key: 'wt2',
            align: 'center',
        },
        {
            title: 'WT 3',
            dataIndex: 'wt3',
            key: 'wt3',
            align: 'center',
        },
        // {
        //     title: 'WT 4',
        //     dataIndex: 'wt4',
        //     key: 'wt4',
        //     align: 'center',
        // },
        // {
        //     title: 'Rental Contract',
        //     dataIndex: 'rentalContract',
        //     key: 'rentalContract',
        //     align: 'center',
        // },
        // {
        //     title: 'Service Contract',
        //     dataIndex: 'serviceContract',
        //     key: 'serviceContract',
        //     align: 'center',
        // },
        // {
        //     title: 'Sale Contract',
        //     dataIndex: 'saleContract',
        //     key: 'saleContract',
        //     align: 'center',
        // },
        // {
        //     title: 'Type',
        //     dataIndex: 'type',
        //     key: 'type',
        //     align: 'center',
        // },
        // {
        //     title: 'Position',
        //     dataIndex: 'position',
        //     key: 'position',
        //     align: 'center',
        // },
        // {
        //     title: 'Number of Spreads',
        //     dataIndex: 'numberOfSpreads',
        //     key: 'numberOfSpreads',
        //     align: 'center',
        // },
        // {
        //     title: 'Workmanship',
        //     dataIndex: 'workmanship',
        //     key: 'workmanship',
        //     align: 'center',
        // },
        // {
        //     title: 'ECA',
        //     dataIndex: 'eca',
        //     key: 'eca',
        //     align: 'center',
        // },
        // {
        //     title: 'CRA Materials',
        //     dataIndex: 'craMaterials',
        //     key: 'craMaterials',
        //     align: 'center',
        // },
        // {
        //     title: 'Specification',
        //     dataIndex: 'specification',
        //     key: 'specification',
        //     align: 'center',
        // },
        // {
        //     title: 'Number of Stations',
        //     dataIndex: 'numberOfStations',
        //     key: 'numberOfStations',
        //     align: 'center',
        // },
        // {
        //     title: 'Root Bead Type',
        //     dataIndex: 'rootBeadType',
        //     key: 'rootBeadType',
        //     align: 'center',
        // },
        // {
        //     title: 'External Equip',
        //     dataIndex: 'externalEquip',
        //     key: 'externalEquip',
        //     align: 'center',
        // },
        // {
        //     title: 'Welding Process',
        //     dataIndex: 'weldingProcess',
        //     key: 'weldingProcess',
        //     align: 'center',
        // },
        // {
        //     title: 'Power Supply',
        //     dataIndex: 'powerSupply',
        //     key: 'powerSupply',
        //     align: 'center',
        // },
        // {
        //     title: 'Welding Wire Diameter',
        //     dataIndex: 'weldingWireDiameter',
        //     key: 'weldingWireDiameter',
        //     align: 'center',
        // },
        // {
        //     title: 'Welding Wire Type',
        //     dataIndex: 'weldingWireType',
        //     key: 'weldingWireType',
        //     align: 'center',
        // },
        // {
        //     title: 'Terrain',
        //     dataIndex: 'terrain',
        //     key: 'terrain',
        //     align: 'center',
        // },
        // {
        //     title: 'Climate',
        //     dataIndex: 'climate',
        //     key: 'climate',
        //     align: 'center',
        // },
        // {
        //     title: 'Prod Rate',
        //     dataIndex: 'prodRate',
        //     key: 'prodRate',
        //     align: 'center',
        // },
        // {
        //     title: 'Repair Rate',
        //     dataIndex: 'repairRate',
        //     key: 'repairRate',
        //     align: 'center',
        // },
        // {
        //     title: 'Number of Repairs',
        //     dataIndex: 'numberOfRepairs',
        //     key: 'numberOfRepairs',
        //     align: 'center',
        // },
        // {
        //     title: 'Inspection Type',
        //     dataIndex: 'inspectionType',
        //     key: 'inspectionType',
        //     align: 'center',
        // },
        // {
        //     title: 'Inspection Company',
        //     dataIndex: 'inspectionCompany',
        //     key: 'inspectionCompany',
        //     align: 'center',
        // },
        // {
        //     title: 'Comments',
        //     dataIndex: 'comments',
        //     key: 'comments',
        //     align: 'center',
        // },
        {
            title: 'Edit / Delete',
            dataIndex: 'edit_delete',
            key: 'edit_delete',
            align: 'center',
            render: (text, record) => {
                return (

                    <div>
                        {/* 
                        <Tooltip title="View Parts" >
                            <span className="icon-18 mr-2 cursor-pointer edit-hover" style={{ marginRight: '20px', }} >
                                <EyeOutlined style={{ fontSize: '18px' }}
                                    onClick={() => onReadParts(record)}
                                />
                            </span>
                        </Tooltip> */}
                        <Tooltip title="Update">
                            <span className="icon-18 mr-2 cursor-pointer edit-hover">
                                <EditOutlined style={{ fontSize: '18px' }} onClick={() => onUpdateClick(record)} />
                            </span>
                        </Tooltip>
                        <Tooltip title="Delete">
                            <span className="icon-18 mr-2 cursor-pointer edit-hover" style={{ marginLeft: '20px' }}>
                                <DeleteOutlined style={{ fontSize: '18px' }}
                                    onClick={() => showDeleteModal(record)}
                                />
                            </span>
                        </Tooltip>
                    </div>)
            },

        },
    ];
    const [userName, setUserName] = useState('');
    const [userGroup, setUserGroup] = useState([]);
    const [selectedTap, setSelectedTap] = useState('Welding');
    const [tapsList, setTaplist] = useState(['Welding', 'Coating', 'Competitor']);
    /** get user Access */
    useEffect(() => {
        authenticateCurrentUser()
            .then(async (data) => {
                if (data.signInUserSession.accessToken.payload["cognito:groups"]) {

                    let response = data.signInUserSession.accessToken.payload["cognito:groups"] //await getA(data.username)
                    //const userAccess = response.map(item => item?.GroupName)
                    //console.log(response)
                    const userEmail = JSON.parse(localStorage.getItem('user')).email
                    setUserName(userEmail);
                    setUserGroup(response);
                }
            })
            .catch((error) => {
                console.log('error', error);
            });
    }, [history]);
    useEffect(() => {
        if (!uploadLoading && bulkUplaodModal)
            setBulkUplaodModal(false)

    }, [uploadLoading])
    const keys = [

        'project',
        "projectName",
        "contractor",
        "owningCo",
        "location",
        "yearStarted",
        "material",
        "lengthKM",
        "lengthMiles",
        "numberOfWelds",
        "diameter",
        "wt1",
        "wt2",
        "wt3",
        "wt4",
        "rentalContract",
        "serviceContract",
        "saleContract",
        "saleContractInfo",
        "type",
        "position",
        "numberOfSpreads",
        "workmanship",
        "eca",
        "craMaterials",
        "specification",
        "numberOfStations",
        "rootBeadType",
        "externalEquip",
        "weldingProcess",
        "powerSupply",
        "weldingWireDiameter",
        "weldingWireType",
        "terrain",
        "climate",
        "prodRate",
        "repairRate",
        "numberOfRepairs",
        "inspectionType",
        "inspectionCompany",
        "comments"
    ];
    const filtermaping = [
        {
            title: 'Year',
            dataIndex: 'yearStarted',
            key: 'yearStarted',
            align: 'center',
        },
        {
            title: 'Project',
            dataIndex: 'project',
            key: 'project',
            align: 'center',
        },
        {
            title: 'Project Name',
            dataIndex: 'projectName',
            key: 'projectName',
            align: 'left',
            width: '20%'
        },
        {
            title: 'Contractor',
            dataIndex: 'contractor',
            key: 'contractor',
            align: 'left',
            width: '10%'
        },
        // {
        //     title: 'Owning Co',
        //     dataIndex: 'owningCo',
        //     key: 'owningCo',
        //     align: 'center',
        // },
        {
            title: 'Location',
            dataIndex: 'location',
            key: 'location',
            align: 'left',
            width: '10%'
        },

        {
            title: 'Material',
            dataIndex: 'material',
            key: 'material',
            align: 'center',
        },
        {
            title: 'Length',
            dataIndex: 'lengthKM',
            key: 'lengthKM',
            align: 'center',
        },
        {
            title: 'Equipment',
            dataIndex: 'externalEquip',
            key: 'externalEquip',
            align: 'center',
            width: '10%'
        },
        {
            title: 'Welds',
            dataIndex: 'numberOfWelds',
            key: 'numberOfWelds',
            align: 'center',
        },
        {
            title: 'Diameter',
            dataIndex: 'diameter',
            key: 'diameter',
            align: 'center',
        },
        {
            title: 'WT 1',
            dataIndex: 'wt1',
            key: 'wt1',
            align: 'center',
        },
        {
            title: 'WT 2',
            dataIndex: 'wt2',
            key: 'wt2',
            align: 'center',
        },
        {
            title: 'WT 3',
            dataIndex: 'wt3',
            key: 'wt3',
            align: 'center',
        },
        {
            title: 'WT 4',
            dataIndex: 'wt4',
            key: 'wt4',
            align: 'center',
        },
        {
            title: 'Rental Contract',
            dataIndex: 'rentalContract',
            key: 'rentalContract',
            align: 'center',
        },
        {
            title: 'Service Contract',
            dataIndex: 'serviceContract',
            key: 'serviceContract',
            align: 'center',
        },
        {
            title: 'Sale Contract',
            dataIndex: 'saleContract',
            key: 'saleContract',
            align: 'center',
        },
        {
            title: 'Sale Contract',
            dataIndex: 'saleContractInfo',
            key: 'saleContractInfo',
            align: 'center',
        },
        {
            title: 'Type',
            dataIndex: 'type',
            key: 'type',
            align: 'center',
        },
        {
            title: 'Position',
            dataIndex: 'position',
            key: 'position',
            align: 'center',
        },
        {
            title: 'Number of Spreads',
            dataIndex: 'numberOfSpreads',
            key: 'numberOfSpreads',
            align: 'center',
        },
        {
            title: 'Workmanship',
            dataIndex: 'workmanship',
            key: 'workmanship',
            align: 'center',
        },
        {
            title: 'ECA',
            dataIndex: 'eca',
            key: 'eca',
            align: 'center',
        },
        {
            title: 'CRA Materials',
            dataIndex: 'craMaterials',
            key: 'craMaterials',
            align: 'center',
        },
        {
            title: 'Specification',
            dataIndex: 'specification',
            key: 'specification',
            align: 'center',
        },
        {
            title: 'Number of Stations',
            dataIndex: 'numberOfStations',
            key: 'numberOfStations',
            align: 'center',
        },
        {
            title: 'Root Bead Type',
            dataIndex: 'rootBeadType',
            key: 'rootBeadType',
            align: 'center',
        },
        {
            title: 'External Equip',
            dataIndex: 'externalEquip',
            key: 'externalEquip',
            align: 'center',
        },
        {
            title: 'Welding Process',
            dataIndex: 'weldingProcess',
            key: 'weldingProcess',
            align: 'center',
        },
        {
            title: 'Power Supply',
            dataIndex: 'powerSupply',
            key: 'powerSupply',
            align: 'center',
        },
        {
            title: 'Welding Wire Diameter',
            dataIndex: 'weldingWireDiameter',
            key: 'weldingWireDiameter',
            align: 'center',
        },
        {
            title: 'Welding Wire Type',
            dataIndex: 'weldingWireType',
            key: 'weldingWireType',
            align: 'center',
        },
        {
            title: 'Terrain',
            dataIndex: 'terrain',
            key: 'terrain',
            align: 'center',
        },
        {
            title: 'Climate',
            dataIndex: 'climate',
            key: 'climate',
            align: 'center',
        },
        {
            title: 'Prod Rate',
            dataIndex: 'prodRate',
            key: 'prodRate',
            align: 'center',
        },
        {
            title: 'Repair Rate',
            dataIndex: 'repairRate',
            key: 'repairRate',
            align: 'center',
        },
        {
            title: 'Number of Repairs',
            dataIndex: 'numberOfRepairs',
            key: 'numberOfRepairs',
            align: 'center',
        },
        {
            title: 'Inspection Type',
            dataIndex: 'inspectionType',
            key: 'inspectionType',
            align: 'center',
        },
        {
            title: 'Inspection Company',
            dataIndex: 'inspectionCompany',
            key: 'inspectionCompany',
            align: 'center',
        },
        {
            title: 'Comments',
            dataIndex: 'comments',
            key: 'comments',
            align: 'center',
        },
    ]
    const onUpdateClick = (record) => {

        if (record.yearStarted) {
            record.yearStarted = moment(record.trailDate).format('YYYY');
            //console.log(record.yearStarted)
        }
        projectDetailForm.setFieldsValue(record)
        //console.log(record)
        seteditRecord(record)
        setIsEdit(true)
        setIsModalVisible(true)
    }

    useEffect(() => {
        // var obj = {
        //     pageSize: currentPagesize,
        //     pageNumber: currentPageNumber,

        // }
        // onRecordList(obj);
    }, [])

    function hasNonEmptyTag(jsonObj) {
        for (const key in jsonObj) {
            if (jsonObj.hasOwnProperty(key)) {
                const value = jsonObj[key];


                if (Array.isArray(value) && value.length > 0) {
                    return true;
                } else if (typeof value === 'string' && value.trim() !== '') {
                    return true;
                }
            }
        }
        return false;
    }
    useEffect(() => {

        // var obj = {
        //     pageSize: currentPagesize,
        //     pageNumber: 1,
        //     searchKey: searchCriteria
        // }
        // setCurrent(1)
        // onProjectList(obj)


        // console.log(obj);

        (async () => {
            const isvalid = hasNonEmptyTag(searchCriteria)
            let obj = {
                pageSize: isvalid ? currentPagesize : prevPagesize,
                pageNumber: isvalid ? 1 : prevPageNumber,
                searchKey: searchCriteria
            };
            // setSelectedMaterial([])
            if (isvalid) {
                setPrevPagesize(currentPagesize)
                setPrevPageNumber(currentPageNumber)
            }
            setCurrentPageNumber(obj.pageNumber)
            await onRecordList(obj);
        })();


    }, [searchCriteria]);

    // useEffect(() => {
    //     var obj = {
    //         pageSize: currentPagesize,
    //         pageNumber: currentPageNumber,
    //         searchKey: searchCriteria

    //     }
    //     if (!isModalVisible)
    //         onRecordList(obj);
    // }, [isModalVisible])

    const onfetchList = async () => {
        var obj = {
            pageSize: currentPagesize,
            pageNumber: currentPageNumber,
            searchKey: searchCriteria

        }
        await onRecordList(obj);
    }
    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
        setIsEdit(false)
    };

    const showMasterModal = () => {
        setIsMasterModalVisible(true);
    };

    const GenerateReport = async () => {
        setGenerateReportLoading(true)

        let obj = {

            searchKey: searchCriteria
        };
        //console.log(obj);


        const response = await GeneratetrackRecordReport(obj)
        if (response) {

            const link = document.createElement('a');
            link.href = response.PDFFileLink;
            link.download = 'report.pdf';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            setGenerateReportLoading(false)
        }
    };


    const handleMasterCancel = () => {
        isMasterModalVisible(false);
    };
    /** Display Confirmation while deleting */
    const showDeleteModal = (record) => {
        setIsDeleteModalVisible(true);
        setSelectedData(record)
    };
    /** Deleting Spare parts data on confirm */
    const handleDeleteOk = async () => {
        // console.log(selectedData)
        setIsDeleteLoading(true)
        await DeleteTrackRecordDetails(selectedData)
        setIsDeleteLoading(false)
        setIsDeleteModalVisible(false);
        setSelectedData('');

        setIsDeleteLoading(false)
        var obj = {
            pageSize: currentPagesize,
            pageNumber: currentPageNumber,

        }
        await onRecordList(obj);

    }
    /** Cancelling the deletion of spare parts data */
    const handleDeleteCancel = () => {
        setIsDeleteModalVisible(false);
        setSelectedData('');
    }
    const onClearFilters = async () => {

        filtermaping.map(async column => {
            if (column.dataIndex !== 'edit_delete') {
                if (selectdTypeFilter[column.dataIndex] && selectdTypeFilter[column.dataIndex]?.length > 0)
                    await handleComboboxChange(column.dataIndex, []);
            }
        });


    }
    const showDrawer = () => {
        setIsDrawerVisible(true);
    };

    const closeDrawer = () => {
        setIsDrawerVisible(false);
    };

    const handleSearchInputChange = (fieldName, value) => {
        setSearchCriteria(prevSearchCriteria => ({
            ...prevSearchCriteria,
            [fieldName]: value
        }));
    }
    const handleTypeSearchInputChange = (fieldName, value) => {
        setSearchType(prevSearchCriteria => ({
            ...prevSearchCriteria,
            [fieldName]: value
        }));
    }
    const handleComboboxChange = async (fieldName, value) => {

        setSearchFilterType(prevSearchtype => ({
            ...prevSearchtype,
            [fieldName]: ''
        }));
        setSelectdFilterType(prevSelectedtype => ({
            ...prevSelectedtype,
            [fieldName]: value
        }));


        await handleTypeSearchInputChange(fieldName, value)
        await handleSearchInputChange(fieldName, value)
    };



    const handleSearchComboboxChange = async (category, value) => {


        {
            //await handleSearchInputChange('Material', value)
            //setSelectedMaterial([value])
            setSearchFilterType(prevSearchtype => ({
                ...prevSearchtype,
                [category]: value
            }));
            //await handleTypeSearchInputChange('Material', value)
            await handleTypeSearchInputChange(category, value)
            await handleSearchInputChange(category, value)
            // handleSearchFilterInputChange('Material', value)
        }

    };

    const UpdateFilterCategoryList = async (fieldName, value) => {
        setFilterCategoryList(prevcategory => ({
            ...prevcategory,
            [fieldName]: value
        }));
    }

    // const UpdateFilterCategory = async () => {

    //     filtermaping.map(async column => {
    //         if (column.dataIndex !== 'edit_delete') {
    //             if (!selectdTypeFilter[column.dataIndex] || selectdTypeFilter[column.dataIndex]?.length == 0)
    //                 await UpdateFilterCategoryList(column.dataIndex, await getCategoryList(column.dataIndex, searchType));
    //         }
    //     });

    // }
    const UpdateFilterCategory = async () => {
        const batchSize = 5; // Number of concurrent requests
        for (let i = 0; i < filtermaping.length; i += batchSize) {
            const batch = filtermaping.slice(i, i + batchSize).map(async column => {
                if (column.dataIndex !== 'edit_delete') {
                    if (!selectdTypeFilter[column.dataIndex] || selectdTypeFilter[column.dataIndex]?.length === 0) {
                        const categoryList = await getCategoryList(column.dataIndex, searchType[column.dataIndex] || '');
                        await UpdateFilterCategoryList(column.dataIndex, categoryList);
                    }
                }
            });
            await Promise.all(batch); // Wait for the batch to finish before starting the next one
        }
    };

    useEffect(() => {
        (async () => {
            console.log(filterCategoryList)
            await UpdateFilterCategory();
        })();
    }, [searchType]);

    const getCategoryList = async (searchKeyExist, searchkey) => {
        try {
            const object = {
                header: searchKeyExist,
                searchKey: searchkey
            }
            //  console.log('Search key', object)
            //return;
            const response = await getFilterCategoryListFromDatabse(object);
         console.log('Search key', response)
            if (response) {

                return response;
            }
        } catch (error) {
            notification.error({
                message: 'Something went wrong. ' + searchKeyExist,
            });
            return []
        }
    };
    const tagRender = (props) => {
        return null
        // const { label, value, closable, onClose } = props;
        // return (
        //     <span onMouseDown={(e) => { e.preventDefault(); e.stopPropagation(); }}
        //         style={{
        //             marginTop: 30, padding: '3px 6px', borderRadius: 3,
        //             display: 'inline-block'
        //         }}>
        //         {label}
        //         {closable && <span onClick={onClose} style={{ marginLeft: 3, cursor: 'pointer' }}>x</span>}
        //     </span>
        // );
    };
    const handleDropdownVisibleChange = (open) => {
        if (!open) {

            // setMatrialSearchType('')
            // setProjectNameSearchType('')
            // setTestTypeSearchType('')
            // setTimelineSearchType('')
            // setTemperatureSearchType('')

        }
    };

    const handleDownloadFilterTable = async () => {
        setExportLoading(true)
        //console.log(filterData)
        await createExcelTemplate();

        setExportLoading(false)
    };
    const createExcelTemplate = async () => {

        var obj = {
            searchKey: searchCriteria
        }
        const databaseList = await GetRecordListExcel(obj)
        if (databaseList && databaseList.length > 0) {
            //console.log('Master Databse List', databaseList)
            const workbook = new ExcelJS.Workbook();
            const masterstationSheet = workbook.addWorksheet('Track Record');
            const headerRow = masterstationSheet.getRow(1);
            headerRow.height = 20;

            // const keys = [
            //     'ID',
            //     'project',
            //     "projectName",
            //     "contractor",
            //     "owningCo",
            //     "location",
            //     "yearStarted",
            //     "material",
            //     "lengthKM",
            //     "lengthMiles",
            //     "numberOfWelds",
            //     "diameter",
            //     "wt1",
            //     "wt2",
            //     "wt3",
            //     "wt4",
            //     "rentalContract",
            //     "serviceContract",
            //     "saleContract",
            //     "type",
            //     "position",
            //     "numberOfSpreads",
            //     "workmanship",
            //     "eca",
            //     "craMaterials",
            //     "specification",
            //     "numberOfStations",
            //     "rootBeadType",
            //     "externalEquip",
            //     "weldingProcess",
            //     "powerSupply",
            //     "weldingWireDiameter",
            //     "weldingWireType",
            //     "terrain",
            //     "climate",
            //     "prodRate",
            //     "repairRate",
            //     "numberOfRepairs",
            //     "inspectionType",
            //     "inspectionCompany",
            //     "comments"
            // ];

            masterstationSheet.columns = keys.map(key => ({
                header: key.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase()), // Converts camelCase to Title Case
                key: key,
                width: 20 // Set default width, adjust as necessary
            }));



            headerRow.eachCell((cell) => {
                cell.font = { bold: true };
            });

            (async () => {

                const ws = workbook.getWorksheet('Track Record');

                // Add data to the worksheet
                ws.addRows(databaseList);

                // Convert to buffer
                workbook.xlsx.writeBuffer().then((buffer) => {
                    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                    const url = URL.createObjectURL(blob);
                    const link = document.createElement('a');
                    link.href = url;
                    link.download = 'Track_Record_List.xlsx';
                    link.click();
                });
            })();

        }
    };

    const fileProps = {
        onRemove: (file) => {
            const index = fileList.indexOf(file);
            const newFileList = fileList.slice();
            newFileList.splice(index, 1);
            setFileList(newFileList);
            setDisableFileUpload(false);
        },
        beforeUpload: (file) => {

            setFileList([file]);
            setDisableFileUpload(true);
            return false;
        },
        fileList,
    };
    const showBulkUploadModal = () => {

        setBulkUplaodModal(true);
    }
    const handleCancelUploadFile = () => {
        setBulkUplaodModal(false);
    }
    const handleUploadFile3 = async () => {
        if (fileList.length > 0) {
            const file = fileList[0];
    
            if (file.type === 'application/vnd.ms-excel' || file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
                const reader = new FileReader();
    
                reader.onload = async (event) => {
                    try {
                        const data = new Uint8Array(event.target.result);
                   
                        const workbook = XLSX.read(data, { type: 'array' });
                     
                        const combinedData = [];
                        const headersSet = new Set();
    
                        for (let index = 0; index < workbook.SheetNames.length; index++) {
                            const sheetName = workbook.SheetNames[index];
                            const sheet = workbook.Sheets[sheetName];
                            console.log('Parcing',sheetName)
                            const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });
                            if (jsonData.length === 0 || jsonData[0].length === 0) {
                                notification.error({ message: `Sheet '${sheetName}' is empty or invalid.` });
                                continue;
                            }
    
                            const headers = jsonData[0];
                           // headers.forEach(header => headersSet.add(header.toLowerCase().replace(/ /g, '_')));
    
                             // Create a mapping of headers to keys with spaces and slashes replaced by underscores
                        const keyMapping = headers.reduce((acc, header) => {
                            let key = header
                                .toLowerCase()
                                .replace(/ /g, '_') // Replace spaces with underscores
                                .replace(/\//g, '_'); // Replace slashes with underscores

                            // Remove leading underscore if added due to space at the start
                            if (key.startsWith('_')) {
                                key = key.substring(1);
                            }

                            acc[header] = key;
                            headersSet.add(key);
                            return acc;
                        }, {});
    
                            const formattedData = jsonData.slice(1).map(row => {
                                const rowData = {};
                                headers.forEach((header, index) => {
                                    const mappedKey = keyMapping[header];
                                    const value = row[index];
                                    if (mappedKey !== undefined) {
                                        rowData[mappedKey] = value !== undefined ? value.toString() : ''; // Set value to empty string if undefined
                                    }
                                });
                                rowData['type'] =sheetName;
                                return rowData;
                            });
                           
                            console.log('formattedData',formattedData)
                            combinedData.push(...formattedData);
                        }
                       
                        // Create a new workbook with combined data
                        const combinedHeaders = Array.from(headersSet);
                        const combinedSheetData = [combinedHeaders].concat(combinedData.map(row => {
                            return combinedHeaders.reduce((acc, header) => {
                                acc[header] = row[header] || ''; // Use empty string if value is undefined
                                return acc;
                            }, {});
                        }));
                      
                        console.log(combinedSheetData)
                        // const newWorkbook = XLSX.utils.book_new();
                        // const newSheet = XLSX.utils.aoa_to_sheet(combinedSheetData);
                        // XLSX.utils.book_append_sheet(newWorkbook, newSheet, 'CombinedData');
    
                        // // Write the new workbook to a file (this will trigger a download)
                        // XLSX.writeFile(newWorkbook, 'CombinedData.xlsx');
    
                    } catch (error) {
                        notification.error({ message: 'Error parsing Excel file. Please select a valid file.' });
                    }
                };
    
                reader.readAsArrayBuffer(file);
    
            } else {
                notification.warn({ message: 'Please select a valid Excel file (XLS or XLSX) to upload.' });
            }
        } else {
            notification.warn({ message: 'Please select a file to upload.' });
        }
    };
    
    const handleUploadFile1 = async () => {
        if (fileList.length > 0) {
            const file = fileList[0];

            if (file.type === 'application/vnd.ms-excel' || file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
                const reader = new FileReader();

                reader.onload = async (event) => {
                    try {
                        const data = new Uint8Array(event.target.result);
                        const workbook = XLSX.read(data, { type: 'array' });

                        const sheetName = workbook.SheetNames[0];
                        const sheet = workbook.Sheets[sheetName];

                        const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });
                        if (jsonData[1].length === 0) {
                            notification.error({ message: 'Invalid file. Please select a valid file.' });
                            return;
                            //throw new Error('Invalid file. Please select a valid file.');
                        }
                        //console.log(jsonData)
                        //const headers = jsonData[0];
                        const masterstationSheet = {
                            columns: keys.map(key => ({
                                header: key.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase()), // Converts camelCase to Title Case
                                key: key,
                                width: 20 // Set default width, adjust as necessary
                            }))
                        };

                        // Inverse transformation to create a mapping of headers to keys
                        const keyMapping = masterstationSheet.columns.reduce((acc, column) => {
                            acc[column.header] = column.key;
                            return acc;
                        }, {});

                        const headers = jsonData[0];

                        const allHeadersAvailable = Object.keys(keyMapping)
                            .filter(key => key !== 'ID') // Exclude 'ID' key
                            .every(header => headers.includes(header));
                        console.log(allHeadersAvailable);

                        if (!allHeadersAvailable) {
                            notification.error({ message: 'Invalid header file. Please select a valid file.' });
                            return;
                            //throw new Error('Invalid file. Please select a valid file.');
                        }
                        setUploadLoading(true)
                        const toTitleCaseCountry = str => {
                            return str.toLowerCase().split(' ').map(word => {
                                if (word.includes('.')) {
                                    // Handle abbreviations like U.A.E.
                                    return word.toUpperCase();
                                } else if (word.includes('&')) {
                                    // Handle compound words like "TURKS & CAICOS ISLANDS"
                                    return word;
                                } else {
                                    return word.charAt(0).toUpperCase() + word.slice(1);
                                }
                            }).join(' ');
                        };

                        const toTitleCaseContract = (text) => {
                            return text.split(' ').map(word => {
                                // Preserve casing for words with special characters or digits
                                if (/\d/.test(word) || /[&/-]/.test(word)) {
                                    return word;
                                }
                                // Otherwise, capitalize the first letter and lowercase the rest
                                return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
                            }).join(' ');
                        };
                        const formattedData = jsonData.slice(1).map(row => {
                            const rowData = {};
                            let results = {}
                            headers.forEach((header, index) => {
                                const mappedKey = keyMapping[header]
                                let value = row[index];
                                // if (value !== undefined && value != '')
                                //     value = value.trim()


                                if (mappedKey !== undefined) {
                                    if (header == 'Contractor' && value !== undefined && value != '')
                                        rowData[mappedKey] = toTitleCaseContract(value)
                                    else if (header === 'Location' && value !== undefined && value != '')
                                        rowData[mappedKey] = toTitleCaseCountry(value)
                                    else if (header === 'Rental Contract' && value !== undefined && value != '')
                                        rowData[mappedKey] = toTitleCaseCountry(value)
                                    else if (header === 'Service Contract' && value !== undefined && value != '')
                                        rowData[mappedKey] = toTitleCaseCountry(value)
                                    else if (header === 'Sale Contract' && value !== undefined && value != '') {
                                        rowData[mappedKey] = toTitleCaseCountry(value)
                                    }
                                    else
                                        rowData[mappedKey] = value !== undefined ? value.toString() : ''; // Set value to empty string if undefined
                                }

                            });
                            return rowData;
                        });
                        formattedData.reverse();
                        console.log(formattedData)
                        //setConfirmLoading(true)
                        await AddOrUpdateProject(formattedData, true)
                        // Send the formatted data to your API
                        var obj = {
                            pageSize: 10,
                            pageNumber: 1,

                        }
                        await onRecordList(obj);

                        //setTimeout(UpdateUplaodmastrList, 1000)


                    } catch (error) {
                        notification.error({ message: 'Error parsing Excel file. Please select a valid file.' });
                    }
                };

                reader.readAsArrayBuffer(file);
                setUploadLoading(false)
                setBulkUplaodModal(false);
            } else {
                notification.warn({ message: 'Please select a valid Excel file (XLS or XLSX) to upload.' });
            }
        } else {
            notification.warn({ message: 'Please select a file to upload.' });
        }
    };
    const handleUploadFile = async () => {
        if (fileList.length > 0) {
            const file = fileList[0];

            if (file.type === 'application/vnd.ms-excel' || file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
                const reader = new FileReader();

                reader.onload = async (event) => {
                    try {

                        const data = new Uint8Array(event.target.result);
                        const workbook = XLSX.read(data, { type: 'array' });

                        const sheetName = workbook.SheetNames[0];
                        const sheet = workbook.Sheets[sheetName];

                        const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });
                        if (jsonData[1].length === 0) {
                            notification.error({ message: 'Invalid file. Please select a valid file.' });
                            return;
                            //throw new Error('Invalid file. Please select a valid file.');
                        }
                        //console.log(jsonData)
                        //const headers = jsonData[0];
                        const masterstationSheet = {
                            columns: keys.map(key => ({
                                header: key.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase()), // Converts camelCase to Title Case
                                key: key,
                                width: 20 // Set default width, adjust as necessary
                            }))
                        };

                        // Inverse transformation to create a mapping of headers to keys
                        const keyMapping = masterstationSheet.columns.reduce((acc, column) => {
                            acc[column.header] = column.key;
                            return acc;
                        }, {});

                        const headers = jsonData[0];
                        //console.log(headers)
                        const allHeadersAvailable = Object.keys(keyMapping)
                            .filter(key => key !== 'ID') // Exclude 'ID' key
                            .every(header => headers.includes(header));
                        //console.log(allHeadersAvailable);

                        if (!allHeadersAvailable) {
                            notification.error({ message: 'Invalid header file. Please select a valid file.' });
                            return;
                            //throw new Error('Invalid file. Please select a valid file.');
                        }

                        const formattedData = jsonData.slice(1).map(row => {
                            const rowData = {};
                            let results = {}
                            headers.forEach((header, index) => {
                                const mappedKey = keyMapping[header]
                                let value = row[index];
                                // if (value !== undefined && value != '')
                                //     value = value.trim()


                                if (mappedKey !== undefined) {

                                    rowData[mappedKey] = value !== undefined ? value.toString() : ''; // Set value to empty string if undefined
                                }

                            });
                            return rowData;
                        });
                        formattedData.reverse();
                        //console.log(formattedData)
                        setUploadLoading(true)
                        await AddOrUpdateProject(formattedData, true)
                        // Send the formatted data to your API
                        var obj = {
                            pageSize: 10,
                            pageNumber: 1,

                        }
                        await onRecordList(obj);
                        await UpdateFilterCategory();
                        setUploadLoading(false)
                        //setTimeout(UpdateUplaodmastrList, 1000)
                    } catch (error) {
                        notification.error({ message: 'Error parsing Excel file. Please select a valid file.' });
                    }
                };

                reader.readAsArrayBuffer(file);

                //  setBulkUplaodModal(false);
            } else {
                notification.warn({ message: 'Please select a valid Excel file (XLS or XLSX) to upload.' });
            }
        } else {
            notification.warn({ message: 'Please select a file to upload.' });
        }
    };
    const handleTabChange = (key) => {

        setActiveKeyLine(key);
        setSelectedTap(tapsList[Number(key) - 1])

    };

    const getWeldingDetails = () => {

        return (
            <>
                <div className="flex-auto">
                    {!isMasterModalVisible &&
                        <div className="flex-auto">
                            <div className="d-flex mb-3 align-items-center tab-content-title">


                                {/* <div className='back_equip' >
                                    <Tooltip title={'Back'} >
                                        <ArrowLeftOutlined onClick={(e) => {
                                            history.push('/SmartLync-HomePage');
                                        }} />
                                    </Tooltip>
                                </div>

                                <Title className="mb-0 add-new-calibration" level={3} style={{ paddingLeft: '30px' }}>
                                    Track Record
                                </Title> */}

                                <div className="new-calibration">

                                    <Text className="ps-3 add-new-calibration" style={{ marginTop: '10px', marginRight: '20px' }}>Filters Count: {listCount} / {listTotalCount}</Text>
                                    {/* <Text onClick={() => onMasterSparepartsVisibe()} className="ps-3 add-new-calibration" style={{}}>
                                    <span className="icon-22" style={{ scale: '20px' }}>
                                        <svg>
                                            <use xlinkHref="#add" />
                                        </svg>
                                    </span>
                                   Go to Database
                                </Text> */}
                                    <Text onClick={() => showModal()} className="ps-3 add-new-calibration" style={{ marginRight: '20px' }}>
                                        <span className="icon-22" style={{ scale: '20px' }}>
                                            <svg>
                                                <use xlinkHref="#add" />
                                            </svg>
                                        </span>
                                        Add New Record
                                    </Text>

                                    {
                                        // userGroup && userGroup.includes('spare-parts-normal-user') && userGroup.includes('spare-parts-admin') &&
                                        <Button shape="round" key="apply" type="primary" style={{ marginRight: '20px' }}
                                            onClick={GenerateReport} loading={generateReportLoading}
                                        >
                                            Generate Report
                                        </Button>
                                    }
                                    {
                                        userGroup && userGroup.includes('track-record-admin') &&
                                        <Button shape="round" key="apply" type="primary" style={{ marginRight: '20px' }}
                                            onClick={handleDownloadFilterTable} loading={exportLoading}
                                        >
                                            Export to Excel
                                        </Button>
                                    }
                                    {
                                        userGroup && userGroup.includes('track-record-admin') &&
                                        <Button shape="round" key="apply" type="primary" style={{ marginRight: '20px' }}
                                            onClick={showBulkUploadModal}
                                        >
                                            <CloudUploadOutlined style={{ fontSize: '20px' }}> </CloudUploadOutlined>
                                            Bulk Upload
                                        </Button>
                                    }


                                    {
                                        userGroup && userGroup.includes('track-record-admin') &&
                                        <Button shape="round" key="apply" type="primary" style={{ marginRight: '20px' }}
                                            onClick={showMasterModal}
                                        >
                                            Configuration
                                        </Button>
                                    }
                                    <Button shape="round" key="apply" type="primary" onClick={showDrawer} style={{ marginLeft: '10px' }}>
                                        <FilterOutlined style={{ fontSize: '20px' }}> </FilterOutlined>
                                        Filter
                                    </Button>
                                </div>


                            </div>

                            <div className='addTeamDetails'>
                                <Table columns={columns}

                                    className="h-100 pipeline-table equipPaginationTable"
                                    scroll={{ y: 'calc(100vh - 320px)' }}
                                    // scroll={{ x: '100%', y: '100%' }}
                                    //scroll={{ x: 1000, y: 'calc(100vh - 200px)' }}
                                    rowKey={'ID'}

                                    pagination={
                                        listCount > 10 ? {
                                            position: ['bottomRight'],
                                            showSizeChanger: true,
                                            className: 'fixed-pagination',
                                            total: listCount,
                                            defaultPageSize: 10,
                                            defaultCurrent: 1,
                                            current: currentPageNumber,
                                            pageSize: currentPagesize,
                                            onChange: async (pageNumber, pageSize, sorter) => {
                                                //console.log(listCount, pageNumber, pageSize)
                                                setCurrentPageNumber(pageNumber);
                                                setCurrentPagesize(pageSize);


                                                var obj = {
                                                    pageSize: pageSize,
                                                    pageNumber: pageNumber,
                                                    // searchKey: searchCriteria

                                                }


                                                await onRecordList(obj)

                                            }
                                        }
                                            : false
                                    }
                                    dataSource={[...projectList]}
                                    loading={isLoading}

                                />

                            </div>
                            {isModalVisible &&
                                <ProjectModal
                                    isModalVisible={isModalVisible}
                                    setIsModalVisible={setIsModalVisible}
                                    onClose={handleCancel}
                                    projectDetailForm={projectDetailForm}
                                    isEdit={isEdit}
                                    setIsEdit={setIsEdit}
                                    editRecord={editRecord}
                                    onfetchList={onfetchList}
                                    userName={userName}
                                />
                            }

                        </div>
                    }



                    <Modal
                        width="45vw"
                        className="pipeline-modal"
                        title="Delete Master  Details"
                        centered
                        visible={isDeleteModalVisible}
                        footer={[
                            <Button key="cancel" onClick={handleDeleteCancel}>
                                Cancel
                            </Button>,
                            <Button key="apply" type="primary" onClick={handleDeleteOk} loading={isDeleteLoading}>
                                {isDeleteLoading ? 'Deleting' : 'Delete'}
                            </Button>
                        ]}
                    >
                        <div>
                            <Text style={{ fontSize: '16px' }}>
                                <p>Deleting track record Details will delete all the corresponding and related information.</p>
                                Are you sure you want to delete <b style={{ color: "#EF314C" }}>{selectedData && selectedData.projectName}</b> track record?</Text>
                        </div>
                    </Modal>

                    <Modal
                        className="pipeline-modal"
                        title='Upload Track Record'
                        centered
                        visible={bulkUplaodModal}
                        footer={[
                            <Button key="cancel" onClick={handleCancelUploadFile}>
                                {'Cancel'}
                            </Button>,
                            <Button key="apply" type="primary" onClick={handleUploadFile} loading={uploadLoading}>
                                {'Upload'}
                            </Button>
                        ]}
                    >
                        <div className='uploadFile_admin'>
                            <Upload {...fileProps} maxCount={1} accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" >
                                <Button disabled={disableFileUpload} icon={<CloudUploadOutlined className='icon-22' />}>Select File</Button>
                            </Upload>
                        </div>
                    </Modal>
                </div>
                <Drawer
                    title="Filter Options"
                    placement="right"
                    onClose={closeDrawer}
                    visible={isDrawerVisible}
                    width='30%'
                    className='pipeline-modal Filter-Drawer'
                >

                    <div className="drawer-content">
                        <Layout className="content-scrollable">
                            <Content>
                                {filtermaping.map(column => {
                                    if (column.dataIndex !== 'edit_delete') {
                                        return (
                                            <Space style={{ display: 'flow', alignItems: 'center', }}>
                                                {column.title}
                                                {
                                                    // column.dataIndex === 'yearStarted' ?
                                                    // <div className='maintenance-select1'>                                                   
                                                    //         <DatePicker multiple picker="year" 
                                                    //           //onChange={(value) => handleComboboxChange(column.dataIndex, value)}
                                                    //          // value={formData.yearStarted ? moment(formData.yearStarted, 'YYYY') : null}
                                                    //           onChange={(date, dateString) => handleComboboxChange(column.dataIndex, [dateString])}
                                                    //         />
                                                    //         <div className="selected-tags">
                                                    //             {selectdTypeFilter && selectdTypeFilter[column.dataIndex]?.length == 0 && searchFilterType[column.dataIndex] != '' &&
                                                    //                 <div key={0} className='selected-tag'>
                                                    //                     {searchFilterType[column.dataIndex]}
                                                    //                     <span
                                                    //                         className='remove-tag'
                                                    //                         onClick={() => {


                                                    //                             handleSearchComboboxChange(column.dataIndex, '')
                                                    //                             //setSelectedMaterial(newSelectedMaterials);
                                                    //                         }}
                                                    //                     >
                                                    //                         X
                                                    //                     </span>
                                                    //                 </div>
                                                    //             }
                                                    //             {searchFilterType[column.dataIndex] === '' && selectdTypeFilter[column.dataIndex].map((value, index) => (
                                                    //                 <div key={index} className='selected-tag'>
                                                    //                     {value}
                                                    //                     <span
                                                    //                         className='remove-tag'
                                                    //                         onClick={() => {

                                                    //                             const newSelectedMaterials = selectdTypeFilter[column.dataIndex].filter(item => item !== value);
                                                    //                             //handleMaterialComboboxChange(newSelectedMaterials)
                                                    //                             handleComboboxChange(column.dataIndex, newSelectedMaterials)
                                                    //                             //setSelectedMaterial(newSelectedMaterials);
                                                    //                         }}
                                                    //                     >
                                                    //                         X
                                                    //                     </span>
                                                    //                 </div>
                                                    //             ))}

                                                    //         </div>
                                                    //     </div>

                                                    //     :
                                                    <div className='maintenance-select1'>
                                                        <Select
                                                            className='maintenance-select1'
                                                            placeholder={'Select category'}
                                                            showSearch
                                                            allowClear
                                                            mode="multiple"
                                                            tagRender={tagRender}


                                                            //loading={isUserListLoading}
                                                            style={{
                                                                height: '35px',
                                                                position: 'relative',
                                                                top: '0px',
                                                                width: '100%'
                                                            }}
                                                            //optionFilterProp="children"
                                                            optionFilterProp={false}
                                                            onChange={(value) => handleComboboxChange(column.dataIndex, value)}
                                                            // onFocus={onGetAllUsers}
                                                            onDropdownVisibleChange={handleDropdownVisibleChange}
                                                            onSearch={(value) => handleSearchComboboxChange(column.dataIndex, value)}
                                                            searchValue={searchFilterType[column.dataIndex]}
                                                            //value={matrialSearchType == '' ? selectedMaterial : matrialSearchType}
                                                            value={selectdTypeFilter[column.dataIndex]}

                                                        // disabled={disableTypes}
                                                        >
                                                            {Object.keys(filterCategoryList).length > 0 && filterCategoryList[column.dataIndex] &&
                                                                filterCategoryList[column.dataIndex]
                                                                    //.sort((a, b) => a[column.dataIndex].localeCompare(b[column.dataIndex])) // Sort by category
                                                                    .map((item, i) => (
                                                                        <Option key={i} value={item[column.dataIndex]}> {/* Use item.category as value */}
                                                                            {item[column.dataIndex]}
                                                                        </Option>
                                                                    ))
                                                            }
                                                        </Select>
                                                        <div className="selected-tags">
                                                            {selectdTypeFilter && selectdTypeFilter[column.dataIndex]?.length == 0 && searchFilterType[column.dataIndex] != '' &&
                                                                <div key={0} className='selected-tag'>
                                                                    {searchFilterType[column.dataIndex]}
                                                                    <span
                                                                        className='remove-tag'
                                                                        onClick={() => {


                                                                            handleSearchComboboxChange(column.dataIndex, '')
                                                                            //setSelectedMaterial(newSelectedMaterials);
                                                                        }}
                                                                    >
                                                                        X
                                                                    </span>
                                                                </div>
                                                            }
                                                            {searchFilterType[column.dataIndex] === '' && selectdTypeFilter[column.dataIndex].map((value, index) => (
                                                                <div key={index} className='selected-tag'>
                                                                    {value}
                                                                    <span
                                                                        className='remove-tag'
                                                                        onClick={() => {

                                                                            const newSelectedMaterials = selectdTypeFilter[column.dataIndex].filter(item => item !== value);
                                                                            //handleMaterialComboboxChange(newSelectedMaterials)
                                                                            handleComboboxChange(column.dataIndex, newSelectedMaterials)
                                                                            //setSelectedMaterial(newSelectedMaterials);
                                                                        }}
                                                                    >
                                                                        X
                                                                    </span>
                                                                </div>
                                                            ))}

                                                        </div>
                                                    </div>
                                                }
                                            </Space>
                                        );
                                    }
                                    return null;
                                })}
                            </Content>
                        </Layout>
                        <Footer className="fixed-footer">
                            <div className="button-container">
                                <Button type="primary" style={{ marginTop: 6 }} onClick={onClearFilters}>
                                    Clear Filters
                                </Button>
                                <Button type="primary" style={{ marginTop: 6, }} onClick={closeDrawer}>
                                    Submit
                                </Button>
                            </div>
                        </Footer>
                    </div>
                </Drawer>

            </>

        )
    }

    const getRecordTabpanes = () => {
        return (
            <div className="custom-tab h-100" style={{ paddingTop: '10px' }}>
                <Tabs defaultActiveKey='1' activeKey={activeKeyLine} onChange={handleTabChange}  >
                    {
                        <TabPane tab='Welding' key='1'>
                            {getWeldingDetails()}
                        </TabPane>
                    }
                    {
                        <TabPane tab='Coating' key='2'>

                        </TabPane>
                    }
                    {
                        <TabPane tab='Competitor' key='3'>

                        </TabPane>
                    }

                </Tabs>
            </div>
        );
    }

    return (

        <div className="d-flex flex-column h-100">
            {!isMasterModalVisible && <div>
                <div className="flex-auto">
                    <div className="d-flex mb-3 align-items-center tab-content-title">


                        <div className='back_equip' >
                            <Tooltip title={'Back'} >
                                <ArrowLeftOutlined onClick={(e) => {
                                    history.push('/SmartLync-HomePage');
                                }} />
                            </Tooltip>
                        </div>

                        <Title className="mb-0 add-new-calibration" level={3} style={{ paddingLeft: '30px' }}>
                            Track Record
                        </Title>
                    </div>
                </div>

                {getRecordTabpanes()}
            </div>
            }

            {isMasterModalVisible &&
                <MasteForm
                    isMasterModalVisible={isMasterModalVisible}
                    setIsMasterModalVisible={setIsMasterModalVisible}
                    selectedTap={selectedTap}

                />
            }

        </div>
    );
};
const mapStateToProps = ({ RecordListReducer }) => {

    const projectList = get(RecordListReducer, 'RecordList', []);

    const isLoading = get(RecordListReducer, 'isFetchLoading', false);
    const listCount = get(RecordListReducer, 'RecordListCount', null);
    const listTotalCount = get(RecordListReducer, 'RecordListTotalCount', null);

    return {
        projectList,
        isLoading,
        listCount,
        listTotalCount
    }
}

const mapDispatchToProps = {

    onRecordList: fetchRecordList,
}


export default connect(mapStateToProps, mapDispatchToProps)(TrackRecordPage);

