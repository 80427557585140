

import get from 'lodash/get';
import {
  GENERATESPAREPARTS, GENERATESPAREPARTS_SUCCESS, GENERATESPAREPARTS_FAILURE, FETCHSPAREPARTSLIST,
  FETCHSPAREPARTSLIST_SUCCESS, FETCHSPAREPARTSLIST_FAILURE, DELETESPAREPARTS, DELETESPAREPARTS_SUCCESS,
  DELETESPAREPARTS_FAILURE, FETCHSPAREPARTSMASTERLIST, FETCHSPAREPARTSLISTMASTER_SUCCESS, FETCHSPAREPARTSLISTMASTER_FAILURE, UPLOADMASTERSPAREPARTS, UPLOADMASTERSPAREPARTS_SUCCESS, UPLOADMASTERSPAREPARTS_FAILURE, FETCHSPAREPARTSMASTERLISTP, FETCHSPAREPARTSLISTMASTERP_SUCCESS, FETCHSPAREPARTSLISTMASTERP_FAILURE, GENERATEMASTERSPAREPARTS, GENERATEMASTERSPAREPARTS_SUCCESS, GENERATEMASTERSPAREPARTS_FAILURE, DELETESMASTERLIST, DELETESMASTERLIST_SUCCESS, DELETESMASTERLIST_FAILURE
} from './types';
//import { fetchLicenseListSuccess } from './generatorLicense';
const initialState = {
  isSpartPartsLoading: false,
  isGenerateLoading: false,
  isDeleteLoading: false,
  isDatabseMasterListLoading: false,
  isMasterListPageLoading: false,
  isUploadMasterSparts: false,
  isGenerateMasterLoading: false,
  isDeleteMasterLoading: false,
};
const SparePartsReducer = (state = initialState, action) => {
 
  switch (action.type) {

    case GENERATESPAREPARTS: {

      return {
        ...state,
        isGenerateLoading: true
      };
    }
    case GENERATESPAREPARTS_SUCCESS: {
      return {
        ...state,
        isGenerateLoading: false
      }
    }
    case GENERATESPAREPARTS_FAILURE: {
      return {
        ...state,
        isGenerateLoading: false
      }
    }

    case DELETESPAREPARTS: {

      return {
        ...state,
        isDeleteLoading: true
      };
    }
    case DELETESPAREPARTS_SUCCESS: {
      return {
        ...state,
        isDeleteLoading: false
      }
    }
    case DELETESPAREPARTS_FAILURE: {
      return {
        ...state,
        isDeleteLoading: false
      }
    }
    case FETCHSPAREPARTSLIST: {
      // console.log('resducer called')
      return {
        ...state,
        isSpartPartsLoading: true,
      };
    }
    case FETCHSPAREPARTSLIST_SUCCESS: {
      const resData = get(action, 'responseData', []);
      //console.log("resData", resData)
      return {
        ...state,
        SparePartsList: resData,
        SparePartsListCount: resData.length > 0 ? resData[0].totalCount : 0,
        isSpartPartsLoading: false
      }
    }
    case FETCHSPAREPARTSLIST_FAILURE: {
      return {
        ...state,
        isSpartPartsLoading: false
      }
    }

    case FETCHSPAREPARTSMASTERLIST: {
      // console.log('resducer called')
      return {
        ...state,
        isDatabseMasterListLoading: true,
      };
    }
    case FETCHSPAREPARTSLISTMASTER_SUCCESS: {
      const resData = get(action, 'responseData', []);
     // console.log("resData", resData)
      return {
        ...state,
        DatabaseMasterList: resData,
        DatabaseMasterListCount: resData.length > 0 ? resData[0].totalCount : 0,
        isDatabseMasterListLoading: false
      }
    }
    case FETCHSPAREPARTSLISTMASTER_FAILURE: {
      return {
        ...state,
        isDatabseMasterListLoading: false
      }
    }

    case UPLOADMASTERSPAREPARTS: {

      return {
        ...state,
        isUploadMasterSparts: true
      };
    }
    case UPLOADMASTERSPAREPARTS_SUCCESS: {
      return {
        ...state,
        isUploadMasterSparts: false
      }
    }
    case UPLOADMASTERSPAREPARTS_FAILURE: {
      return {
        ...state,
        isUploadMasterSparts: false
      }
    }
    case FETCHSPAREPARTSMASTERLISTP: {
      // console.log('resducer called')
      return {
        ...state,
        isMasterListPageLoading: true,
      };
    }
    case FETCHSPAREPARTSLISTMASTERP_SUCCESS: {
      const resData = get(action, 'responseData', []);
      //console.log("resData", resData)
      return {
        ...state,
        SparePartsMasterListPage: resData,
        SparePartsMasterListPageCount: resData.length > 0 ? resData[0].totalCount : 0,
        isMasterListPageLoading: false
      }
    }
    case FETCHSPAREPARTSLISTMASTERP_FAILURE: {
      return {
        ...state,
        isMasterListPageLoading: false
      }
    }

    case GENERATEMASTERSPAREPARTS: {

      return {
        ...state,
        isGenerateMasterLoading: true
      };
    }
    case GENERATEMASTERSPAREPARTS_SUCCESS: {
      return {
        ...state,
        isGenerateMasterLoading: false
      }
    }
    case GENERATEMASTERSPAREPARTS_FAILURE: {
      return {
        ...state,
        isGenerateMasterLoading: false
      }
    }
    case DELETESMASTERLIST: {

      return {
        ...state,
        isDeleteMasterLoading: true
      };
    }
    case DELETESMASTERLIST_SUCCESS: {
      return {
        ...state,
        isDeleteMasterLoading: false
      }
    }
    case DELETESMASTERLIST_FAILURE: {
      return {
        ...state,
        isDeleteMasterLoading: false
      }
    }

    default: {
      return state;
    }
  }
};

export default SparePartsReducer;