import React, { useState } from 'react';
import { Table } from 'antd';

const PricingTable = (props) => {
    const { currentDetailCalculation,formData } = props;
    
    // Default detailCalculation if no data passed via props
    const initialDetailCalculation = {
        
            "Discount Rate": 1,
            "Hardware Monthly Rental": 800,
            "Hardware Monthly Rental Per Unit": 800,
            "Hardware Selling Price": 8000,
            "Hardware Total Rental": 9600,
            "One time Cost": 1500,
            "Pricing Per Unit": 1000,
            "Recovery Per Unit": 625,
            "Software Rental Price per unit": 950,
            "Total Rental Price": 22500,
            "Total Software Rental Price": 12900,



            
              
                "Hardware Sale Price per unit": 8000,
                "Hardware Selling Price": 8000,
                "License Weightage": 1.25,
                "One time Cost": 1500,
                "Pricing Per Unit": 1000,
                "Recovery Per Unit": 625,
                "Software Sale Price per unit": 14250,
                "Total Hardware Sale Price": 48000,
                "Total Sale Price": 135000,
                "Total Software Sale Price": 85500,
                "Unit Sale Price": 22250
            
    };
    
    //const [currentDetailCalculation, setDetailCalculation] = useState(initialDetailCalculation);
    
    // Helper to format currency
    const formatCurrency = (value) => {
        return value
            ? `$${Number(value).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`
            : '$0.00';
    };

    // Define data source dynamically based on `currentDetailCalculation`
const dataSource = [
    {
        key: 'Hardware',
        label: 'Hardware',
        unitCost: currentDetailCalculation['Hardware Rental Price Per Unit'] || 0,
        totalCost: currentDetailCalculation['Hardware Rental Price Total'] || 0,
        oneTimeCost: currentDetailCalculation['One Time Cost Total'] || 0,
        GrandTotal: currentDetailCalculation['Hardware Rental Price Grand Total'] 
    },
    {
        key: 'Software',
        label: 'Software',
        unitCost: currentDetailCalculation['Software Rental Price per unit'] || 0,
        totalCost: currentDetailCalculation['Software Rental Price Total'] || 0,
        oneTimeCost: currentDetailCalculation['Software One time Cost'] || 0,
        GrandTotal: currentDetailCalculation['Software Rental Price Grand Total'] || 0
    },
    {
        key: 'Total',
        label: 'Total',
        unitCost: currentDetailCalculation['Rental Total Price per Unit'],
        totalCost: currentDetailCalculation['Rental Price Total'] || 0,
        oneTimeCost: (currentDetailCalculation['One Time Cost Total'] || 0),
        GrandTotal: currentDetailCalculation['Rental Price Grand Total'] || 0
    }
];

const dataSource1 = [
    {
        key: 'Hardware',
        label: 'Hardware',
        unitCost: currentDetailCalculation['Hardware Sale Price per unit'] || 0,
        totalCost: currentDetailCalculation['Hardware Sale Price Total'] || 0,
        oneTimeCost: currentDetailCalculation['Hardware One Time Cost'] || 0,
        GrandTotal: currentDetailCalculation['Hardware Sale Price Grand Total'] 
    },
    {
        key: 'Software',
        label: 'Software',
        unitCost: currentDetailCalculation['Software Sale Price per unit'] || 0,
        totalCost: currentDetailCalculation['Software Sale Price Total'] || 0,
        oneTimeCost: currentDetailCalculation['Software One time Cost'] || 0,
        GrandTotal: currentDetailCalculation['Software Sale Price Grand Total'] || 0
    },
    {
        key: 'Total',
        label: 'Total',
        unitCost: currentDetailCalculation['Sale Total Price per Unit'],
        totalCost: currentDetailCalculation['Sale Price Total'] || 0,
        oneTimeCost: (currentDetailCalculation['One Time Cost Total'] || 0),
        GrandTotal: currentDetailCalculation['Sale Price Grand Total'] || 0
    }
];

    // Define columns for the Table component
    const columns = [
        {
            title: 'Items',
            dataIndex: 'label',
            key: 'label',
            width: '20%',
          
        },
        
        {
            title: 'Unit Price',
            dataIndex: 'unitCost',
            key: 'unitCost',
            align: 'center',
            width: '10%',
            render: (value) => formatCurrency(value),
        },
        {
            title: 'Total Price',
            dataIndex: 'totalCost',
            key: 'totalCost',
            align: 'center',
            width: '10%',
            render: (value) => formatCurrency(value),
        },
        {
            title: 'One Time Cost',
            dataIndex: 'oneTimeCost',
            key: 'oneTimeCost',
            align: 'center',
            width: '10%',
            render: (value) => formatCurrency(value),
        },
        {
            title: 'Grand Total',
            dataIndex: 'GrandTotal',
            key: 'GrandTotal',
            align: 'center',
            width: '10%',
            render: (value) => formatCurrency(value),
        },
        
    ];

    return (
        <>
            <Table
                style={{ width: '90%', paddingLeft: '10%', }}
                className='Data360-pricing-table'
                dataSource={formData['Project_details']['License_mode'] == 'Sale' ?dataSource1 :dataSource}
                columns={columns}
                pagination={false}
                size="large"
            />
        </>
    );
};

export default PricingTable;
