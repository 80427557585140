/* eslint-disable no-param-reassign */
/* eslint-disable react/no-unescaped-entities */
import { ArrowLeftOutlined, CloudUploadOutlined, DeleteOutlined, EditOutlined, EyeOutlined, FilterOutlined, RightCircleTwoTone, DownCircleTwoTone } from '@ant-design/icons';
import {
    Button, Form,
    Input,
    Modal,
    Space,
    Table,
    Tabs,
    Tooltip,
    Typography,
    Upload, notification, DatePicker, Drawer, Select, Layout, Row, Col, Card,
    Radio
} from 'antd';
import { get } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import history from '../../services/history';

import PricingForm from './PricingForm';
import PricingTable from './PricingTable';
import { AddOrUpdateConfigData, generateData360Pricing, getConfogListFromDatabse } from '../../redux/Pricing gudiance/saga';
import { authenticateCurrentUser } from '../../services/auth';



const { Option } = Select;
const { Title, Text } = Typography;
const { TabPane } = Tabs;
//const { TabPane } = Tabs;
//const { Column } = Table;
//const { Search } = Input;

//const { Option } = Select;
const Data360Page = (props) => {

    const {

    } = props;


    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isMasterModalVisible, setIsMasterModalVisible] = useState(false);

    const [isRead, setIsRead] = useState(false);
    const [prevPageNumber, setPrevPageNumber] = useState(1);
    const [prevPagesize, setPrevPagesize] = useState(10);
    const [searchType, setSearchType] = useState({});

    const [userName, setUserName] = useState('');
    const [userGroup, setUserGroup] = useState([]);

    const [activeKey, setActiveKey] = useState('1')
    const [activeKeyLine, setActiveKeyLine] = useState('1')
    const [selectedTap, setSelectedTap] = useState('Log');
    const [tapsList, setTaplist] = useState(['Log', 'Analysis']);

    const [showPricingPage, setShowPricingPage] = useState(false);
    const [projectDetails, setProjectDetails] = useState({});
    const [diameterList, setDiameterList] = useState([])
    const [equipmentType, setEquipmentType] = useState('IWM');

    // const [formData, setFormData] = useState({})
    const [Contractor, setContractor] = useState('Rent');
    const [License_Duration, setLicense_Duration] = useState(1);
    const [License_Tier, setLicense_Tier] = useState('Basic');
    const [SaveLoading, setSaveLoading] = useState(false);
    const [showConfiguration, setShowConfiguration] = useState(false);
    const [GenerateLoading, setGenerateLoading] = useState(false)
    const [showPricingTable, setShowPricingTable] = useState(false)
    const [ConfigData, setConfigData] = useState({})
    const [isInputfeildChanged, setIsInputfeildChanged] = useState(false)
    const [formData, setFormData] = useState(
        {
            "Project_details": {
                "No_of_units": 1,
                "License_mode": "Sale",
                "Project_duration": 12,
                "Tier": "Basic",
                "License_duration": "1 Year"
            },
            "Hardware": {
                "Unit_cost": 3000,
                "Utilization": 0.6,
                "Margin": 0.6,
                "Rental_rate": 0.10
            },
            "Application": {
                "Payback_Period": 12,
                "Dev_cost": 300000,
                "Margin": 0.6,
                "Units_per_Year": 40
            },
            "License_weightage": {
                "Basic": 0.5,
                "Standard": 0.75,
                "Premium": 1
            },
            "License_duration_weightage": {
                "1_year": 1.25,
                "2_year": 1.0,
                "3_year": 0.75,
                "5_year": 0.6,
                "Perpetual": 0.5
            },
            "License_duration_discount": {
                "<12": 1,
                "13to24": 0.95,
                "25to36": 0.9,
                "37to60": 0.85,
                "Perpetual": 0.8

            },
            "Others": {
                "Recurring": {
                    "Internet": 50,
                    "AWS": 50,
                    "Maintenance": 100,
                    "Premium": 100
                },
                "One_time": {
                    "Training_Cost": 1500,
                    "Premium": 500
                }

            }

        }
    )
    const [pricingCalculationData, setpricingCalculationData] = useState(null);


    useEffect(() => {


        {
            (async () => {


                const config = await getConfogListFromDatabse()
                console.log('confog', config)
                if(config  && config[0] && config[0].Configuration)
                {
                    setConfigData(config[0])
                    setFormData(config[0].Configuration)
                }
                //setFormData(config.Configuration)
            })();
        }


    }, [])
    const getHardwareDetails = () => {

        return (
            <>
                <div className="flex-auto">

                    <div className="flex-auto">
                        <div className="d-flex mb-3 align-items-center tab-content-title">


                            {/* <div className='back_equip' >
                                    <Tooltip title={'Back'} >
                                        <ArrowLeftOutlined onClick={(e) => {
                                            history.push('/SmartLync-HomePage');
                                        }} />
                                    </Tooltip>
                                </div>

                                <Title className="mb-0 add-new-calibration" level={3} style={{ paddingLeft: '30px' }}>
                                    Track Record
                                </Title> */}

                            <div className="new-calibration">


                                {/* <Text onClick={() => onMasterSparepartsVisibe()} className="ps-3 add-new-calibration" style={{}}>
                                    <span className="icon-22" style={{ scale: '20px' }}>
                                        <svg>
                                            <use xlinkHref="#add" />
                                        </svg>
                                    </span>
                                   Go to Database
                                </Text> */}




                            </div>


                        </div>

                        {PricingForm()}

                    </div>



                </div>


            </>

        )
    }
    /** get user Access */
    useEffect(() => {
        authenticateCurrentUser()
            .then(async (data) => {
                if (data.signInUserSession.accessToken.payload["cognito:groups"]) {

                    let response = data.signInUserSession.accessToken.payload["cognito:groups"] //await getA(data.username)
                    //const userAccess = response.map(item => item?.GroupName)
                    //console.log(response)
                    const userEmail = JSON.parse(localStorage.getItem('user')).email
                    setUserName(userEmail);
                    setUserGroup(response);
                }
            })
            .catch((error) => {
                console.log('error', error);
            });
    }, [history]);
    const handleTabChange = (key) => {

        setActiveKeyLine(key);
        setSelectedTap(tapsList[Number(key) - 1])

    };

    // const handleInputChange = (name, value) => {

    //     setFormData((prevData) => ({ ...prevData, [name]: value }));

    // };

    const onGeneratePricing = async () => {
        setGenerateLoading(true)
        console.log('Data', formData)
        const res = await generateData360Pricing(formData)
        console.log('Res', res)
        if (res)
            setpricingCalculationData(res)

        setShowPricingTable(true)
        setGenerateLoading(false)
    }

    const handleInputChange = (category, type, value) => {
        //setIsInputfeildChanged(true)
        //const category = 'Power_Supplies'
        setShowPricingTable(false)
        setIsInputfeildChanged(true)
        setFormData((prevOptions) => {
            const updatedCategory = { ...prevOptions[category] };
            updatedCategory[type] = value;


            return {
                ...prevOptions,
                [category]: updatedCategory,
            };
        });
    }


    const getCategoryValue1 = (category, type) => {
        if (formData.hasOwnProperty(category)) { // Check if the category exists in selectedOptions
            return formData[category][type]; // Return the value for the category
        }
    };

    const handleInputChange1 = (category, type, value) => {
        const maincategory = 'Others'
        setFormData((prevOptions) => {
            // Spread the previous state to avoid mutations
            return {
                ...prevOptions,  // Spread the outermost object
                [maincategory]: {   // Spread the maincategory
                    ...prevOptions[maincategory],  // Keep other categories intact
                    [category]: {  // Spread the specific category to update only the nested type
                        ...prevOptions[maincategory][category],
                        [type]: value,  // Update the specific type with the new value
                    },
                },
            };
        });
    };


    const getCategoryValue2 = (category, type) => {
        const maincategory = 'Others'
        if (formData.hasOwnProperty(maincategory) && formData[maincategory].hasOwnProperty(category)) {
            return formData[maincategory][category][type]; // Return the value for the category and type
        }
        return ''; // Return an empty string or fallback value if not found
    };
    const OnSave = async () => {
        setSaveLoading(true);
      
         const data = {
            ID:ConfigData.ID,
            Configuration:formData
         }
         console.log(data)
        await AddOrUpdateConfigData(data)
        setIsInputfeildChanged(false)
        setSaveLoading(false);

    }

    const onValuesChange = (changedValues, allValues) => {
      
        setIsInputfeildChanged(true)
    };

    const getPricingTabpanes = () => {
        return (
            <div className="custom-tab h-100" style={{ paddingTop: '0px' }}>
                <Tabs defaultActiveKey='1' activeKey={activeKeyLine} onChange={handleTabChange}  >
                    {
                        <TabPane tab='DATA360' key='1'>

                            <div className="flex-auto">
                                {!showConfiguration && <div>

                                    {userGroup && userGroup.includes('pricing-gudiance-admin') &&
                                        <div className="d-flex mb-2 align-items-center tab-content-title">
                                            <div className="new-calibration">
                                                <Button shape="round" key="apply" type="primary" style={{ marginTop: '00px' }}
                                                    onClick={() => setShowConfiguration(true)} >
                                                    Configuration
                                                </Button>
                                            </div>
                                        </div>
                                    }

                                    <Form
                                        layout="vertical"
                                        autoComplete="off"
                                        getcontainer={false}
                                        style={{ alignItems: 'center' }}
                                    // onFinish={onFinish}
                                    onValuesChange={onValuesChange}
                                    >
                                        {/* Project Inputs Section */}
                                        <div style={{ paddingLeft: '10%' }}>
                                            <Row gutter={24}>
                                                <Col span={6}>
                                                    <Form.Item label="No. of Welding Systems" name="No_of_units" required>
                                                        <div style={{ paddingRight: '30%', width: '80%' }}>
                                                            <Input onChange={(e) => handleInputChange('Project_details', 'No_of_units', Number(e.target.value))}
                                                                value={getCategoryValue1('Project_details', 'No_of_units')}
                                                                min={0} size="large" type='number' style={{ textAlign: 'center', }} />
                                                        </div>
                                                    </Form.Item>
                                                </Col>


                                                <Col span={6}>
                                                    <Form.Item name="License_mode" label="License mode" className='report-radio' required>
                                                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', width: '100%' }}>
                                                            <Radio.Group
                                                                value={Contractor}
                                                                onChange={async (e) => {
                                                                    handleInputChange('Project_details', 'License_mode', e.target.value);
                                                                    setContractor(e.target.value);
                                                                }}
                                                            >
                                                                <Radio value={"Rent"} style={{ marginTop: '5px' }}>Rent</Radio>
                                                                <Radio value={"Sale"} style={{ marginTop: '5px' }}>Sale</Radio>
                                                            </Radio.Group>
                                                        </div>
                                                    </Form.Item>
                                                </Col>
                                                {Contractor == 'Rent' &&
                                                    <Col span={6}>
                                                        <Form.Item label="Project Duration (months)" name="Project_duration" required>
                                                            <div style={{ paddingRight: '30%', width: '80%' }}>
                                                                <Input onChange={(e) => handleInputChange('Project_details', 'Project_duration', Number(e.target.value))}
                                                                    value={getCategoryValue1('Project_details', 'Project_duration')}
                                                                    min={0} size="large" type='number' style={{ textAlign: 'center' }} />
                                                            </div>
                                                        </Form.Item>
                                                    </Col>
                                                }

                                                {Contractor == 'Sale' &&
                                                    <Col span={6}>
                                                        <Form.Item label="License Duration" name="License_Duration" required className='report-radio'>
                                                            <div style={{ paddingRight: '30%', width: '80%' }}>
                                                                <Select placeholder="Select License Duration" className='maintenance-select'
                                                                    size="large" onChange={(value) => handleInputChange('Project_details', 'License_duration', value)}
                                                                    value={getCategoryValue1('Project_details', 'License_duration')}
                                                                >
                                                                    <Option value="1 Year">1 Year</Option>
                                                                    <Option value="2 Year">2 Year</Option>
                                                                    <Option value="3 Year">3 Year</Option>
                                                                    <Option value="5 Year">5 Year</Option>
                                                                    <Option value="Perpetual">Perpetual</Option>
                                                                </Select>
                                                            </div>

                                                        </Form.Item>
                                                    </Col>
                                                }

                                                <Col span={6}>
                                                    <Form.Item label="License Tier" name="License_Tier" className='report-radio' required>
                                                        <div style={{ paddingRight: '30%', width: '80%' }}>
                                                            <Select placeholder="Select License Tier" className='maintenance-select' size="large"
                                                                onChange={(value) => handleInputChange('Project_details', 'Tier', value)}
                                                                value={getCategoryValue1('Project_details', 'Tier')}
                                                            >
                                                                <Option value="Basic">Basic</Option>
                                                                <Option value="Standard">Standard</Option>
                                                                <Option value="Premium">Premium</Option>
                                                            </Select>
                                                        </div>
                                                    </Form.Item>
                                                </Col>



                                            </Row>
                                        </div>
                                    </Form>
                                </div>
                                }
                                {showConfiguration && <div>

                                    <Form
                                        layout="vertical"
                                        autoComplete="off"
                                        getcontainer={false}
                                        style={{ alignItems: 'center' }}
                                    // onFinish={onFinish}
                                    //onValuesChange={handleValueChange}

                                    >
                                        <Row gutter={16}>
                                            <Col span={4}>
                                                <Card title="Hardware" className='Data360_Prcing-card' style={{ height: '95%' }}>
                                                    <Form.Item label="Unit Cost" name="Unit_cost">
                                                        <div style={{ display: 'flex', alignItems: 'center' }}>

                                                            <Input addonBefore={<div style={{ width: '100%', textAlign: 'center' }}>$</div>} className='Data360-addbefore'
                                                                value={getCategoryValue1('Hardware', 'Unit_cost')}
                                                                onChange={(e) => handleInputChange('Hardware', 'Unit_cost', Number(e.target.value))}
                                                                min={0}
                                                                type='number'
                                                                style={{ width: '100%', textAlign: 'center' }} size='large'
                                                            />
                                                        </div>
                                                    </Form.Item>

                                                    <Form.Item label="Utilization" name="Utilization">
                                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                                            <Input addonAfter='%' className='Data360-addafter'
                                                                value={getCategoryValue1('Hardware', 'Utilization') * 100}
                                                                onChange={(e) => handleInputChange('Hardware', 'Utilization', Number(e.target.value) / 100)}
                                                                min={1}
                                                                type='number'
                                                                style={{ width: '100%', textAlign: 'center' }} size='large'
                                                            />

                                                        </div>
                                                    </Form.Item>

                                                    <Form.Item label="Profit Margin" name="HW_Profit_Margin">
                                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                                            <Input addonAfter='%' className='Data360-addafter'
                                                                value={getCategoryValue1('Hardware', 'Margin') * 100}
                                                                onChange={(e) => handleInputChange('Hardware', 'Margin', Number(e.target.value) / 100)}
                                                                min={1}
                                                                type='number'
                                                                style={{ width: '100%', textAlign: 'center' }} size='large'
                                                            />

                                                        </div>
                                                    </Form.Item>

                                                    <Form.Item label="Rental Rate" name="Rental_rate">
                                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                                            <Input addonAfter='%' className='Data360-addafter'
                                                                value={getCategoryValue1('Hardware', 'Rental_rate') * 100}
                                                                onChange={(e) => handleInputChange('Hardware', 'Rental_rate', Number(e.target.value) / 100)}
                                                                min={1}
                                                                type='number'
                                                                style={{ width: '100%', textAlign: 'center' }} size='large'
                                                            />

                                                        </div>
                                                    </Form.Item>
                                                </Card>
                                            </Col>
                                            <Col span={4} offset={1}>
                                                {/* Application Section */}
                                                <Card title="Application" className="Data360_Prcing-card" style={{ height: '95%' }}>
                                                    <Form.Item label="Pay Back Period (months)" name="Payback_Period" >
                                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                                            <Input
                                                                onChange={(e) => handleInputChange('Application', 'Payback_Period', Number(e.target.value))}
                                                                value={getCategoryValue1('Application', 'Payback_Period')}
                                                                min={0}
                                                                type='number'
                                                                style={{ width: '100%', textAlign: 'center' }} size='large'

                                                            />
                                                        </div>
                                                    </Form.Item>

                                                    <Form.Item label="Development Cost" name="Dev_cost">
                                                        <div style={{ display: 'flex', alignItems: 'center' }}>

                                                            <Input addonBefore='$' className='Data360-addbefore'
                                                                onChange={(e) => handleInputChange('Application', 'Dev_cost', Number(e.target.value))}
                                                                value={getCategoryValue1('Application', 'Dev_cost')}
                                                                min={0}
                                                                type='number'
                                                                style={{ width: '100%', textAlign: 'center' }} size='large'
                                                            />
                                                        </div>
                                                    </Form.Item>

                                                    <Form.Item label="Profit Margin" name="SW_Profit_Margin">
                                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                                            <Input addonAfter='%' className='Data360-addafter'
                                                                value={getCategoryValue1('Application', 'Margin') * 100}
                                                                onChange={(e) => handleInputChange('Application', 'Margin', (Number(e.target.value) / 100))}
                                                                min={0}
                                                                type='number'
                                                                style={{ width: '100%', textAlign: 'center' }} size='large'
                                                            />

                                                        </div>
                                                    </Form.Item>

                                                    <Form.Item label="Units per Year" name="Units_per_Year">
                                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                                            <Input
                                                                onChange={(e) => handleInputChange('Application', 'Units_per_Year', Number(e.target.value))}
                                                                value={getCategoryValue1('Application', 'Units_per_Year')}
                                                                min={0}
                                                                type='number'
                                                                style={{ width: '100%', textAlign: 'center' }} size='large'
                                                            />
                                                        </div>
                                                    </Form.Item>
                                                </Card>
                                            </Col>
                                            <Col span={4} offset={1}>
                                                {/* License Weightage */}
                                                <Card title="License Weightage" className="Data360_Prcing-card" style={{ height: '95%' }}>
                                                    <Form.Item label="Basic" name="Basic">
                                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                                            <Input
                                                                onChange={(e) => handleInputChange('License_weightage', 'Basic', Number(e.target.value))}
                                                                value={getCategoryValue1('License_weightage', 'Basic')}
                                                                min={0}
                                                                type='number' size='large'
                                                                style={{ width: '100%', textAlign: 'center' }}
                                                            />
                                                        </div>
                                                    </Form.Item>

                                                    <Form.Item label="Standard" name="Standard">
                                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                                            <Input
                                                                onChange={(e) => handleInputChange('License_weightage', 'Standard', Number(e.target.value))}
                                                                value={getCategoryValue1('License_weightage', 'Standard')}
                                                                min={0}
                                                                type='number' size='large'
                                                                style={{ width: '100%', textAlign: 'center' }}
                                                            />
                                                        </div>
                                                    </Form.Item>

                                                    <Form.Item label="Premium" name="Premium">
                                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                                            <Input
                                                                onChange={(e) => handleInputChange('License_weightage', 'Premium', Number(e.target.value))}
                                                                value={getCategoryValue1('License_weightage', 'Premium')}
                                                                min={0}
                                                                type='number' size='large'
                                                                style={{ width: '100%', textAlign: 'center' }}
                                                            />
                                                        </div>
                                                    </Form.Item>
                                                </Card>

                                            </Col>
                                            {Contractor == 'Sale' &&
                                                <Col span={4} offset={1}>

                                                    <Card title="License Duration Weightage" className="Data360_Prcing-card" style={{ height: '95%' }}>
                                                        <Form layout="vertical">

                                                            <Form.Item label="1 year" name="1_year">
                                                                <div style={{ display: 'flex', alignItems: 'center' }}>

                                                                    <Input
                                                                        onChange={(e) => handleInputChange('License_duration_weightage', '1_year', Number(e.target.value))}
                                                                        value={getCategoryValue1('License_duration_weightage', '1_year')}
                                                                        min={0}
                                                                        type='number'
                                                                        size='large'
                                                                        style={{ width: '90%', textAlign: 'center' }}
                                                                    />
                                                                </div>
                                                            </Form.Item>

                                                            <Form.Item label="2 year" name="2_year">
                                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                    <Input
                                                                        onChange={(e) => handleInputChange('License_duration_weightage', '2_year', Number(e.target.value))}
                                                                        value={getCategoryValue1('License_duration_weightage', '2_year')}
                                                                        min={0}
                                                                        type='number'
                                                                        size='large'
                                                                        style={{ width: '90%', textAlign: 'center' }}
                                                                    />
                                                                </div>
                                                            </Form.Item>

                                                            <Form.Item label="3 year" name="3_year">
                                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                    <Input
                                                                        onChange={(e) => handleInputChange('License_duration_weightage', '3_year', Number(e.target.value))}
                                                                        value={getCategoryValue1('License_duration_weightage', '3_year')}
                                                                        min={0}
                                                                        type='number'
                                                                        size='large'
                                                                        style={{ width: '90%', textAlign: 'center' }}
                                                                    />
                                                                </div>
                                                            </Form.Item>

                                                            <Form.Item label="5 year" name="5_year">
                                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                    <Input
                                                                        onChange={(e) => handleInputChange('License_duration_weightage', '5_year', Number(e.target.value))}
                                                                        value={getCategoryValue1('License_duration_weightage', '5_year')}
                                                                        min={0}
                                                                        type='number'
                                                                        size='large'
                                                                        style={{ width: '90%', textAlign: 'center' }}
                                                                    />
                                                                </div>
                                                            </Form.Item>

                                                            <Form.Item label="Perpetual" name="Perpetual">
                                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                    <Input
                                                                        onChange={(e) => handleInputChange('License_duration_weightage', 'Perpetual', Number(e.target.value))}
                                                                        value={getCategoryValue1('License_duration_weightage', 'Perpetual')}
                                                                        min={0}
                                                                        type='number'
                                                                        size='large'
                                                                        style={{ width: '100%', textAlign: 'center' }}
                                                                    />
                                                                </div>
                                                            </Form.Item>

                                                        </Form>
                                                    </Card>


                                                </Col>
                                            }{Contractor == 'Rent' &&
                                                <Col span={4} offset={1}>

                                                    <Card title="License Duration Discount" className="Data360_Prcing-card" style={{ height: '95%' }}>
                                                        <Form layout="vertical">

                                                            <Form.Item label="<12 Month" name="<12">
                                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                    <Input onChange={(e) => handleInputChange('License_duration_discount', '<12', Number(e.target.value))}
                                                                        value={getCategoryValue1('License_duration_discount', '<12')}
                                                                        style={{ width: '100%', textAlign: 'center' }}
                                                                        min={0}
                                                                        type="number"
                                                                        size="large"
                                                                    />

                                                                </div>

                                                            </Form.Item>

                                                            <Form.Item label="13 to 24 Month" name="2_year">
                                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                    <Input
                                                                        onChange={(e) => handleInputChange('License_duration_discount', '13to24', Number(e.target.value))}
                                                                        value={getCategoryValue1('License_duration_discount', '13to24')}
                                                                        style={{ width: '100%', textAlign: 'center' }}
                                                                        min={0}
                                                                        type="number"
                                                                        size="large"
                                                                    />
                                                                </div>
                                                            </Form.Item>

                                                            <Form.Item label="25 to 36 Month" name="25to36">
                                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                    <Input
                                                                        onChange={(e) => handleInputChange('License_duration_discount', '25to36', Number(e.target.value))}
                                                                        value={getCategoryValue1('License_duration_discount', '25to36')}
                                                                        style={{ width: '100%', textAlign: 'center' }}
                                                                        min={0}
                                                                        type="number"
                                                                        size="large"
                                                                    />
                                                                </div>
                                                            </Form.Item>

                                                            <Form.Item label="37 to 60 Month" name="37to60">
                                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                    <Input
                                                                        onChange={(e) => handleInputChange('License_duration_discount', '37to60', Number(e.target.value))}
                                                                        value={getCategoryValue1('License_duration_discount', '37to60')}
                                                                        style={{ width: '100%', textAlign: 'center' }}
                                                                        min={0}
                                                                        type="number"
                                                                        size="large"
                                                                    />
                                                                </div>
                                                            </Form.Item>


                                                            <Form.Item label="Perpetual" name="Perpetual">
                                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                    <Input
                                                                        onChange={(e) => handleInputChange('License_duration_discount', 'Perpetual', Number(e.target.value))}
                                                                        value={getCategoryValue1('License_duration_discount', 'Perpetual')}
                                                                        style={{ width: '100%', textAlign: 'center' }}
                                                                        min={0}
                                                                        type="number"
                                                                        size="large"
                                                                    />
                                                                </div>
                                                            </Form.Item>

                                                            {/* Add any additional items here */}

                                                        </Form>
                                                    </Card>


                                                </Col>
                                            }
                                            <Col span={4} offset={1}>
                                                {/* Other Costs Section */}
                                                <Card title="Other Costs" className="Data360_Prcing-card" style={{ height: '95%' }}>
                                                    <Form layout="vertical">

                                                        <Form.Item label="Internet Fee" name="Internet_Fee">
                                                            <div style={{ display: 'flex', alignItems: 'center' }}>

                                                                <Input addonBefore='$' className='Data360-addbefore'
                                                                    onChange={(e) => handleInputChange1('Recurring', 'Internet', Number(e.target.value))}
                                                                    value={getCategoryValue2('Recurring', 'Internet')}
                                                                    min={0}
                                                                    type="number"
                                                                    size="large"
                                                                    style={{ width: '100%', textAlign: 'center' }}
                                                                />
                                                            </div>
                                                        </Form.Item>

                                                        <Form.Item label="AWS" name="Cloud_fee">
                                                            <div style={{ display: 'flex', alignItems: 'center' }}>

                                                                <Input addonBefore='$' className='Data360-addbefore'
                                                                    onChange={(e) => handleInputChange1('Recurring', 'AWS', Number(e.target.value))}
                                                                    value={getCategoryValue2('Recurring', 'AWS')}
                                                                    min={0}
                                                                    type="number"
                                                                    size="large"

                                                                    style={{ width: '100%', textAlign: 'center' }}
                                                                />
                                                            </div>
                                                        </Form.Item>

                                                        <Form.Item label="Maintenance & Support" name="Maintenance_Support">
                                                            <div style={{ display: 'flex', alignItems: 'center' }}>

                                                                <Input addonBefore='$' className='Data360-addbefore'
                                                                    onChange={(e) => handleInputChange1('Recurring', 'Maintenance', Number(e.target.value))}
                                                                    value={getCategoryValue2('Recurring', 'Maintenance')}
                                                                    min={0}
                                                                    type="number"
                                                                    size="large"
                                                                    style={{ width: '100%', textAlign: 'center' }}
                                                                />
                                                            </div>
                                                        </Form.Item>

                                                        <Form.Item label="Premium Charges" name="Premium_Charges">
                                                            <div style={{ display: 'flex', alignItems: 'center' }}>

                                                                <Input addonBefore='$' className='Data360-addbefore'
                                                                    onChange={(e) => handleInputChange1('Recurring', 'Premium', Number(e.target.value))}
                                                                    value={getCategoryValue2('Recurring', 'Premium')}
                                                                    min={0}
                                                                    type="number"
                                                                    size="large"
                                                                    style={{ width: '100%', textAlign: 'center' }}
                                                                />
                                                            </div>
                                                        </Form.Item>

                                                        <Form.Item label="One Time Training Cost" name="One_Time_Training_Cost">
                                                            <div style={{ display: 'flex', alignItems: 'center' }}>

                                                                <Input addonBefore='$' className='Data360-addbefore'
                                                                    onChange={(e) => handleInputChange1('One_time', 'Training_Cost', Number(e.target.value))}
                                                                    value={getCategoryValue2('One_time', 'Training_Cost')}
                                                                    min={0}
                                                                    type="number"
                                                                    size="large"
                                                                    style={{ width: '100%', textAlign: 'center' }}
                                                                />
                                                            </div>
                                                        </Form.Item>

                                                        <Form.Item label="One Time Premium" name="One_Time_Premium">
                                                            <div style={{ display: 'flex', alignItems: 'center' }}>

                                                                <Input addonBefore='$' className='Data360-addbefore'
                                                                    onChange={(e) => handleInputChange1('One_time', 'Premium', Number(e.target.value))}
                                                                    value={getCategoryValue2('One_time', 'Premium')}
                                                                    min={0}
                                                                    type="number"
                                                                    size="large"
                                                                    style={{ width: '100%', textAlign: 'center' }}
                                                                />
                                                            </div>
                                                        </Form.Item>


                                                    </Form>
                                                </Card>

                                            </Col>
                                        </Row>


                                        <Form.Item>
                                            {/* <Button type="primary" key="apply" shape="round" style={{ marginTop: '5px'  }}
                                                onClick={() => setShowConfiguration(false)}
                                            >
                                                Save
                                            </Button> */}
                                            <Button
                                                key="apply"
                                                type="primary"
                                                onClick={() => OnSave()}
                                                disabled ={!isInputfeildChanged }
                                                loading={SaveLoading}
                                                style={{
                                                    borderRadius: '30px',
                                                    position: "fixed",
                                                    bottom: "20px",
                                                    right: "30px",
                                                    zIndex: 1000,
                                                }}
                                            >
                                                Save
                                            </Button>

                                        </Form.Item>
                                    </Form>
                                </div>

                                }

                            </div>
                            {!showConfiguration &&

                                <div>

                                    <Button shape="round" key="apply" type="primary" style={{ marginTop: '00px' }}
                                        onClick={() => onGeneratePricing()} loading={GenerateLoading}
                                    >
                                        Generate Pricing
                                    </Button>
                                    {pricingCalculationData && showPricingTable &&
                                        <div>
                                            <div style={{ fontSize: '24px', color: 'green', paddingTop: '30px', fontWeight: 'bold' }}>
                                                {pricingCalculationData.message ?? ''}
                                            </div>

                                            <PricingTable
                                                currentDetailCalculation={pricingCalculationData}
                                                formData={formData}

                                            ></PricingTable>
                                        </div>
                                    }

                                </div>
                            }

                        </TabPane>


                    }
                    {/* {
                        <TabPane tab='Software' key='2'>

                        </TabPane>
                    } */}

                </Tabs>
            </div>
        );
    }



    return (

        <div className="d-flex flex-column h-100">
            {!showPricingPage && !isMasterModalVisible && <div>
                <div className="flex-auto">
                    <div className="d-flex mb-3 align-items-center tab-content-title">


                        <div className='back_equip' >
                            <Tooltip title={'Back'} >
                                <ArrowLeftOutlined onClick={(e) => {
                                    setShowConfiguration(false)
                                    history.push(showConfiguration ? '/Data360Pricing' : '/SmartLync-HomePage');
                                }} />
                            </Tooltip>
                        </div>

                        <Title className="mb-0 add-new-calibration" level={3} style={{ paddingLeft: '30px' }}>
                            {showConfiguration ? 'Configuration' : 'Pricing Gudiance'}
                        </Title>
                    </div>
                </div>

                {getPricingTabpanes()}
            </div>

            }

        </div>
    );
};
const mapStateToProps = ({ }) => {
    // const projectList = get(PricingListReducer, 'PricingList', []);

    // const isLoading = get(PricingListReducer, 'isFetchLoading', false);
    // const listCount = get(PricingListReducer, 'PricingListCount', null);
    // const listTotalCount = get(PricingListReducer, 'PricingListTotalCount', null);

    return {
        // projectList,
        // isLoading,
        // listCount,
        // listTotalCount
    }
}

const mapDispatchToProps = {

    // onPricingList: fetchPricingdList,
}


export default connect(mapStateToProps, mapDispatchToProps)(Data360Page);

