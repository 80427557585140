/* eslint-disable no-param-reassign */
/* eslint-disable react/no-unescaped-entities */
import { Button, Col, Form, Input, InputNumber, Modal, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { AddOrUpdateMasterData } from '../../redux/Pricing/saga';
import { chopershorconvertList } from './Database';

const MasterAddNew = (props) => {
    const {
        showAddNewModal,
        setShowAddNewModal,
        addNewForm,
        isEdit,
        setIsEdit,
        editRecord,
        formData,
        tableName,
        FetchDatalist,
        handleAddCancel
    } = props;

    const [data, setData] = useState({});
    const [isLoading, setisLoading] = useState(false);
    useEffect(() => {
        const newData = {};
        Object.keys(formData).forEach((key) => {
            if (isEdit) {
                newData[key] = formData[key];
            } else if (key !== 'ID') {
                newData[key] = '';
            }
        });
        setData(newData);
    }, [formData, isEdit]);

    const handleCancel = async() => {
       
        addNewForm.resetFields()
        setData({})
        setIsEdit(false);
        setShowAddNewModal(false);
       //await handleAddCancel()
    };

   

    const handleCreate = async () => {
        addNewForm.validateFields()
            .then(async (values) => {
                let item = { ...values };

                if (isEdit) {
                    item.isEdit = isEdit;
                    item.ID = editRecord.ID;
                }
                setisLoading(true)

                const data = {
                    columnName: tableName,
                    tableName: tableName,
                     data: [item]
                }
                console.log('Added Item:', data);
                // Call your action to add or update spare parts
                await AddOrUpdateMasterData(data);
                await FetchDatalist()
                setisLoading(false)
                setIsEdit(false);
                setData({})
                setIsEdit(false);
                addNewForm.resetFields()
                setShowAddNewModal(false);
               
            })
            .catch(errorInfo => {
                console.error('Validation failed:', errorInfo);
            });
    };

    return (
        <Modal
            width="50vw"
            className="pipeline-modal"
            title={isEdit ? "Edit" : "Add"}
            centered
            visible={showAddNewModal}
            bodyStyle={Object.keys(formData).length > 6 ? { height: 500 } : { height: 200 }}
            footer={[
                <div style={{ paddingTop: '10px', paddingRight: '0px' }} key="footer">
                    <Button key="cancel" onClick={()=>handleCancel()}>
                        Cancel
                    </Button>
                    <Button key="apply" type="primary" onClick={()=>handleCreate()} loading={isLoading}>
                        {isEdit ? 'Update' : 'Add'}
                    </Button>
                </div>
            ]}
            onCancel={handleCancel}
        >
            <Form
                form={addNewForm}
                name="basic"
                layout="vertical"
                autoComplete="off"
            >
                <Row gutter={[16, 16]}>
                    {Object.keys(formData).map((key) => {
                        const value = formData[key];
                        if (key === 'created_date' || key === 'Updated_date' || key === 'ID') return null;

                        return (
                            <Col span={8} key={key}>
                                <Form.Item
                                    label={key.replace(/_/g, ' ')}
                                    name={key}
                                    initialValue={data[key]}
                                    rules={[{ required: true, message: `Please input your ${key.replace(/_/g, ' ')}!` }]}
                                >
                                    {Number(value) ? (
                                        <InputNumber min={0} style={{ width: '100%' }} size="large" />
                                    ) : (
                                        <Input size="large" />
                                    )}
                                </Form.Item>
                            </Col>
                        );
                    })}
                </Row>
            </Form>
        </Modal>
    );
};

const mapDispatchToProps = {
    // onAddorUpdateSpareparts: generateMasterSparePartsAction,
};

export default connect(null, mapDispatchToProps)(MasterAddNew);
