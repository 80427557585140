import {
  FETCHRECORDSLIST, FETCHRECORDLIST_SUCCESS, FETCHRECORDLIST_FAILURE
} from './types';



const fetchRecordList = (payload) => ({
  type: FETCHRECORDSLIST,
  ...payload
});
const fetchRecordListSuccess = (payload) => ({
  type: FETCHRECORDLIST_SUCCESS,
  ...payload
});
const fetchRecordListFailure = () => ({
  type: FETCHRECORDLIST_FAILURE
});


export {
  fetchRecordList, fetchRecordListSuccess, fetchRecordListFailure
};