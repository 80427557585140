/* eslint-disable no-param-reassign */
/* eslint-disable react/no-unescaped-entities */
import {
    Card, Checkbox,
    Col,
    Form,
    Row,
} from 'antd';
import React, { useEffect, useState } from 'react';

import { connect } from 'react-redux';


//const { Option } = Select;
const LineUpForm = (props) => {

    const {
        Specification,
        partsData,
        setPartsData,
        activeLine,
        lineUpData,
        isEdit
    } = props;
  

    const [selectedOptions, setSelectedOptions] = useState({
        CBUOPUS: [],
        IWM: [],
    });
    useEffect(()=>{
       
        if(isEdit && lineUpData)
        { const data = lineUpData[activeLine]?.LineUp
           // console.log('lineUpData',data)
            if (isEdit && data) {
                setSelectedOptions({
                    CBUOPUS: data.CBUOPUS ? data.CBUOPUS.split(',') : [],
                    IWM: data.IWM ? data.IWM.split(','): []
                });
            }
        }

    },[lineUpData,activeLine])

    useEffect(() => {
        //console.log(Specification,partsData);
        if (Specification) {

            setPartsData((prevOptions) => {
                return {
                    ...prevOptions,
                    [`Line_${activeLine + 1}`]: {
                        ...prevOptions[`Line_${activeLine + 1}`],
                        LineUpData: selectedOptions,
                    },
                };
            });


            // Update the state or perform any other actions with the updatedProjectDetails
          // console.log(partsData);
        }
    }, [selectedOptions, Specification,activeLine]);

    const handleCheckboxChange = (category, checkedValues) => {
        //console.log(category, checkedValues, selectedOptions);

        setSelectedOptions((prevOptions) => {
            return {
                ...prevOptions,
                [category]: checkedValues,
            };
        });
    };

    const isArrayNotEmpty = (category) => {
        return selectedOptions[category] && Array.isArray(selectedOptions[category]) && selectedOptions[category].length > 0;
    };
    const isArrayContainValue = (category) => {
        return (
            selectedOptions[category] &&
                Array.isArray(selectedOptions[category])
                ? selectedOptions[category]
                : []
        );
    };


    const containerStyle = {
        display: 'grid',
        gridTemplateColumns: 'repeat(5, 1fr)', // Adjust the number based on the desired columns
        gap: '5px',
        margin: '5px',
    };


    return (

        <div className="pipeline-modal">

<div style={{ marginTop: '-10px' }}>
                <Row justify="start">
                    <Col span={4}>
                        <div className="project-details-wrapper text-center">
                            <p className="mb-0" style={{ fontSize: '18px' }}>Specification :</p>

                        </div>
                    </Col>
                    <Col span={4}>
                        <div className="project-details-wrapper text-center">
                            <p className="fw-medium title">Pipe Size(inch)</p>
                            <div className="d-flex edit-session justify-content-center mb-3">
                                <p className="mb-0">{Specification.col1}</p>
                            </div>
                        </div>
                    </Col>
                    <Col span={4}>
                        <div className="project-details-wrapper text-center">
                            <p className="fw-medium title">Wall Thickness(mm)</p>
                            <div className="d-flex edit-session justify-content-center mb-3">
                                <p className="mb-0">{Specification.col2}</p>
                            </div>
                        </div>
                    </Col>
                    <Col span={4}>
                        <div className="project-details-wrapper text-center">
                            <p className="fw-medium title">No. of Welds</p>
                            <div className="d-flex edit-session justify-content-center mb-3">
                                <p className="mb-0">{Specification.col3}</p>
                            </div>
                        </div>
                    </Col>
                    <Col span={4}>
                        <div className="project-details-wrapper text-center">
                            <p className="fw-medium title">No. of Station</p>
                            <div className="d-flex edit-session justify-content-center mb-3">
                                <p className="mb-0">{Specification.col4}</p>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
            <div className="custom-line" style={{ marginTop: '-10px' }} />
            <Form

                name="basic"
                autoComplete="off"
                width='100%'
                style={{ marginTop: '-10px' }}
            >


                <Row gutter={8} >

                    <Col lg={12}>
                        <Card className='custom-card2' title='CBUC OPUS'
                            style={{ background: 'rgba(255, 255, 255, 0.1)', borderColor: (255, 255, 255, 0.1),height: '400px', fontSize: '20px' }}
                        >

                            <Checkbox.Group style={containerStyle} value={isArrayContainValue('CBUOPUS')}
                                onChange={(checkedValues) => handleCheckboxChange('CBUOPUS', checkedValues)} >
                                <Checkbox value='6' style={{ marginLeft: '7px' }}>6</Checkbox>
                                <Checkbox value='8' >8</Checkbox>
                                <Checkbox value='10' >10</Checkbox>
                                <Checkbox value='12' >12</Checkbox>
                                <Checkbox value='14' >14</Checkbox>
                                <Checkbox value='16' >16</Checkbox>
                                <Checkbox value='18' >18</Checkbox>

                                <Checkbox value='20' >20</Checkbox>
                                <Checkbox value='22' >22</Checkbox>
                                <Checkbox value='24'>24</Checkbox>
                                <Checkbox value='26'>26</Checkbox>
                                <Checkbox value='28'>28</Checkbox>
                                <Checkbox value='30' >30</Checkbox>
                                <Checkbox value='32' >32</Checkbox>


                                <Checkbox value='36'>36</Checkbox>

                                <Checkbox value='40'>40 </Checkbox>

                                <Checkbox value='42'>42 </Checkbox>
                                <Checkbox value='44'>44 </Checkbox>
                                <Checkbox value='46'>46 </Checkbox>
                                <Checkbox value='48'>48 </Checkbox>
                                <Checkbox value='56'>56</Checkbox>

                            </Checkbox.Group>


                        </Card>
                    </Col>

                    <Col lg={12}>
                        <Card className='custom-card2' title='IWM'
                            style={{ background: 'rgba(255, 255, 255, 0.1)', borderColor: (255, 255, 255, 0.1),height: '400px', fontSize: '20px' }}
                        >

                            <Checkbox.Group style={containerStyle} value={isArrayContainValue('IWM')}
                                onChange={(checkedValues) => handleCheckboxChange('IWM', checkedValues)} >
                                <Checkbox value='24' style={{ marginLeft: '7px' }}>24</Checkbox>
                                <Checkbox value='26'>26</Checkbox>
                                <Checkbox value='30'>30</Checkbox>
                                <Checkbox value='32'>32</Checkbox>
                                <Checkbox value='34'>34</Checkbox>
                                <Checkbox value='36'>36</Checkbox>
                                <Checkbox value='38'>38</Checkbox>
                                <Checkbox value='40' >40</Checkbox>
                                <Checkbox value='40NH'>40 NH</Checkbox>
                                <Checkbox value='40WH'>40 WH</Checkbox>
                                <Checkbox value='42'>42</Checkbox>
                                <Checkbox value='44'>44</Checkbox>
                                <Checkbox value='46'>46</Checkbox>
                                <Checkbox value='48NH' >48 NH</Checkbox>
                                <Checkbox value='48WH'>48 WH</Checkbox>
                                <Checkbox value='56'>56</Checkbox>

                            </Checkbox.Group>
                        </Card>

                    </Col>


                </Row>
            </Form>

        </div>

    );
};



export default connect(null, null)(LineUpForm);

