import { notification } from 'antd';
import get from 'lodash/get';
import {
  all,
  call,
  put,
  takeLatest,
} from 'redux-saga/effects';
import {
  deleteMasterListFailure,
  deleteMasterListSuccess,
  deleteSparePartsFailure,
  deleteSparePartsSuccess,
  fetchSparePartsListFailure,
  fetchSparePartsListSuccess,
  fetchSparePartsMasterListFailure,
  fetchSparePartsMasterListFailurePage,
  fetchSparePartsMasterListSuccess,
  fetchSparePartsMasterListSuccessPage,
  generateMasterSparePartsFailure,
  generateMasterSparePartsSuccess,
  generateSparePartsFailure,
  generateSparePartsSuccess,
  uploadMsterSparePartsFailure,
  uploadMsterSparePartsSuccess
} from "./action";

import axiosConfig from './axiosConfig';
import {
  DELETESMASTERLIST,
  DELETESPAREPARTS,
  FETCHSPAREPARTSLIST,
  FETCHSPAREPARTSMASTERLIST,
  FETCHSPAREPARTSMASTERLISTP, GENERATEMASTERSPAREPARTS,
  GENERATESPAREPARTS,
  UPLOADMASTERSPAREPARTS
} from './types';
import NotificationEmailsAxiosConfig from '../../services/NotificationEmailsAxiosConfig';

function* generateSpareParts({ data }) {

  let isGeneratedReport = false;
  const user = JSON.parse(localStorage.getItem("user"));
  try {
    let databseurl = `/GetDatabseMasterList`;
    if (databseurl) {


      const res = yield axiosConfig.get(databseurl);
      //console.log(responseData)
      if (res.status === 200) {

        const responseData = get(res, ['data', 'data'], {});
        const pythoninput = {
          User_Input: data,
          Master_Data: responseData,
          User_Name: user ? user.name : ''
        }
        const generateReportUrl = process.env.REACT_APP_SparePartsGenerateReport_URL
        //const generateReportUrl = 'http://localhost:5000/generate-report'   //;

        const resReport = yield fetch(`${generateReportUrl}`, {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-type': 'application/json',
            // Authorization: token
          },
          body: JSON.stringify(pythoninput),
        });

        if (resReport.status == 200) {


          const response1 = yield resReport.json();
          const s3url = response1.report_url.s3Url.replace(/'/g, '"')
          data.ProjectDetails.reportUrl = response1.report_url.s3Url;
          data.ProjectDetails.totalCost = response1.report_url.totalCost;
          isGeneratedReport = true;
        }
      }
    }
    if (!isGeneratedReport) {
      yield put(generateSparePartsFailure())
      notification.error({
        message: 'Report Not Generated.',
      });
      return
    }
    let url = process.env.REACT_APP_SpareParts_URL + 'AddSpareParts';
    //let url = process.env.REACT_APP_SparePartsGenerate__URL;

    if (data.ProjectDetails.isEdit) {
      //console.log('Update Sparet Parts Url :', url)
      url = process.env.REACT_APP_SpareParts_URL + 'UpdateSpareParts';
    }

    //console.log('Url Data::', data)

    const res = yield fetch(`${url}`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-type': 'application/json',
        // Authorization: token
      },
      body: JSON.stringify(data),
    });

    notification.destroy();
    //console.log(res.body)
    if (res.status === 200) {
      notification.success({
        message: data.ProjectDetails.isEdit ? 'Update Successfully' : 'Generated Successfully.',
        duration: 3,
      });

      if (res.status === 200) {
        //const responseData = get(res, ['data', 'data'], {});

        yield put(generateSparePartsSuccess(res.body))
        yield fetchSparePartsList();
        const stage = process.env.REACT_APP_STAGE
        if (stage == 'prod') {
          let sub = "New spares parts generated by " + user.name
          let Title = "Spare parts generation notification"
          if (data.ProjectDetails.isEdit) {
            sub = "Spares parts modified by " + user.name
            Title = "Spare parts updated notification"
          }
          const emailData = {
            TitleContent: Title,
            Subject: sub,

            data: {
              "Project Name": data.ProjectDetails.projectName,
              "Project Number": data.ProjectDetails.projectNumber,
              "Project Type": data.ProjectDetails.projectType,
              "No.Of Pipelines": data.ProjectDetails.noOfPipelines,
            }
          }
          yield NotificationEmailsAxiosConfig.post(`NotifyEmails`, emailData);
        }

      }


    }

    else {
      yield put(generateSparePartsFailure())
      notification.error({
        message: 'Something went wrong.',
      });


    }
  } catch (error) {
    yield put(generateSparePartsFailure())
    notification.destroy();
    notification.error({
      message: 'Generation Submission Failed.',
      description: `${error}`,
    });

  }
}


function* deleteSpareParts({ data }) {

  try {
    let url = process.env.REACT_APP_SpareParts_URL + 'DeleteSpareparts';
    //let url = process.env.REACT_APP_SparePartsDelete__URL;
    //console.log(url)
    const res = yield fetch(`${url}`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-type': 'application/json',
        // Authorization: token
      },
      body: JSON.stringify(data),
    });

    notification.destroy();
    //console.log(res.body)
    if (res.status === 200) {
      notification.success({
        message: 'Deleted Successfully.',
        duration: 3,
      });

      yield fetchSparePartsList();
      yield put(deleteSparePartsSuccess(res.body))

    }

    else {
      yield put(deleteSparePartsFailure())
      notification.error({
        message: 'Something went wrong.',
      });


    }
  } catch (error) {
    notification.destroy();
    yield put(deleteSparePartsFailure())
    notification.error({
      message: 'Delete Submission Failed.',
      description: `${error}`,
    });

  }
}

function* fetchSparePartsList(payload = {}) {

  try {

    let url;
    if (payload && payload.pageNumber && payload.pageSize && JSON.stringify(payload.searchKey)) {

      const encodedSearchKey = encodeURIComponent(JSON.stringify(payload.searchKey));
      url = `/GetSparePartsList?pageNumber=${payload.pageNumber}&pageSize=${payload.pageSize}&searchKey=${encodedSearchKey}`;
    }

    else if (payload && payload.pageNumber && payload.pageSize) {
      url = `/GetSparePartsList?pageNumber=${payload.pageNumber}&pageSize=${payload.pageSize}`
    }
    else if (JSON.stringify(payload.searchKey)) {
      const encodedSearchKey = encodeURIComponent(JSON.stringify(payload.searchKey));
      url = `/GetSparePartsList?searchKey=${encodedSearchKey}`;
    }
    else {
      url = `/GetSparePartsList`
    }



    if (url) {
      // console.log(url)
      const res = yield axiosConfig.get(url);
      //console.log(responseData)
      if (res.status === 200) {



        const responseData = get(res, ['data', 'data'], {});
        //console.log(responseData)
        yield put(fetchSparePartsListSuccess({ responseData }));

      }
    }
    else {
      notification.error({
        message: 'Something went wrong.',
      });
    }

  } catch (error) {
    yield put(fetchSparePartsListFailure());
    notification.destroy();
    notification.error({
      message: 'A problem occured while fetching the spare parts list.',
      description: `${error.error}`,
    });
  }
}

function* fetchSparePartsMasterList(payload = {}) {

  try {

    let url;
    // if (payload && payload.pageNumber && payload.pageSize && payload.searchKey) {
    //   url = `/GetDatabseMasterList?pageNumber=${payload.pageNumber}&pageSize=${payload.pageSize}&searchKey=${payload.searchKey}`;
    // }
    // else if (payload && payload.pageNumber && payload.pageSize) {
    //   url = `/GetDatabseMasterList?pageNumber=${payload.pageNumber}&pageSize=${payload.pageSize}`
    // }
    // else if (payload.searchKey) {
    //   url = `/GetDatabseMasterList?searchKey=${payload.searchKey}`;
    // }
    // else {
    //  url = `/GetDatabseMasterList`
    // }
    url = `/GetDatabseMasterList`;


    if (url) {
      // console.log(url)
      const res = yield axiosConfig.get(url);
      //console.log(responseData)
      if (res.status === 200) {



        const responseData = get(res, ['data', 'data'], {});

        yield put(fetchSparePartsMasterListSuccess({ responseData }));

      }
    }
    else {
      notification.error({
        message: 'Something went wrong.',
      });
    }

  } catch (error) {
    yield put(fetchSparePartsMasterListFailure());
    notification.destroy();
    notification.error({
      message: 'A problem occured while fetching the master spare parts  list.',
      description: `${error.error}`,
    });
  }
}
function* uploadMasterSpareParts({ data }) {

  try {
    let url = process.env.REACT_APP_SpareParts_URL + 'SparePartsMasterListUpload';
    //let url = process.env.REACT_APP_UplaodMasterList__URL;

    const res = yield fetch(`${url}`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-type': 'application/json',
        // Authorization: token
      },
      body: JSON.stringify(data),
    });

    notification.destroy();
    //console.log(res.body)
    if (res.status === 200) {
      notification.success({
        message: 'Uploaded Successfully.',
        duration: 3,
      });

      yield put(uploadMsterSparePartsSuccess(res.body))
      yield fetchSparePartsMasterListPage();
    }

    else {
      yield put(uploadMsterSparePartsFailure())
      notification.error({
        message: 'Something went wrong.',
      });


    }
  } catch (error) {
    yield put(uploadMsterSparePartsFailure())
    notification.destroy();
    notification.error({
      message: 'Upload Submission Failed.',
      description: `${error}`,
    });

  }
}

function* fetchSparePartsMasterListPage(payload = {}) {

  try {

    let url;
    if (payload && payload.pageNumber && payload.pageSize && JSON.stringify(payload.searchKey)) {
      const encodedSearchKey = encodeURIComponent(JSON.stringify(payload.searchKey));
      url = `/GetMasterList?pageNumber=${payload.pageNumber}&pageSize=${payload.pageSize}&searchKey=${encodedSearchKey}`;
    }
    else if (payload && payload.pageNumber && payload.pageSize) {
      url = `/GetMasterList?pageNumber=${payload.pageNumber}&pageSize=${payload.pageSize}`
    }
    else if (JSON.stringify(payload.searchKey)) {
      const encodedSearchKey = encodeURIComponent(JSON.stringify(payload.searchKey));
      url = `/GetMasterList?searchKey=${encodedSearchKey}`;
    }
    else {
      url = `/GetMasterList`
    }


    if (url) {
      // console.log(url)
      const res = yield axiosConfig.get(url);
      //console.log(responseData)
      if (res.status === 200) {

        const responseData = get(res, ['data', 'data'], {});

        yield put(fetchSparePartsMasterListSuccessPage({ responseData }));

      }
    }
    else {
      notification.error({
        message: 'Something went wrong.',
      });
    }

  } catch (error) {
    yield put(fetchSparePartsMasterListFailurePage());
    notification.destroy();
    notification.error({
      message: 'A problem occured while fetching the master spare parts list.',
      description: `${error.error}`,
    });
  }
}


function* GenerateMasterSpareParts({ data }) {

  try {

    let url = process.env.REACT_APP_SpareParts_URL + 'AddUpdateMasterListUpload';
    const res = yield fetch(`${url}`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-type': 'application/json',
        // Authorization: token
      },
      body: JSON.stringify(data),
    });


    notification.destroy();
    //console.log(res.body)
    if (res.status === 200) {
      let msg = 'Added Successfully.'
      if (data.length > 0 && data[0].isEdit) {
        msg = 'Updated Successfully.'
      }

      notification.success({
        message: msg,
        duration: 3,
      });

      yield fetchSparePartsMasterListPage()
      yield put(generateMasterSparePartsSuccess(res.body))

    }

    else {
      yield put(generateMasterSparePartsFailure())
      notification.error({
        message: 'Something went wrong.',
      });


    }
  } catch (error) {
    yield put(generateMasterSparePartsFailure())
    notification.destroy();
    notification.error({
      message: 'Delete Submission Failed.',
      description: `${error}`,
    });

  }
}
function* deleteMasterList({ data }) {

  try {

    let url = process.env.REACT_APP_SpareParts_URL + 'DeleteMasterList';

    const res = yield fetch(`${url}`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-type': 'application/json',
        // Authorization: token
      },
      body: JSON.stringify(data),
    });

    notification.destroy();
    //console.log(res.body)
    if (res.status === 200) {
      notification.success({
        message: 'Deleted Successfully.',
        duration: 3,
      });

      yield fetchSparePartsMasterListPage();
      yield put(deleteMasterListSuccess(res.body))

    }

    else {
      yield put(deleteMasterListFailure())
      notification.error({
        message: 'Something went wrong.',
      });


    }
  } catch (error) {
    yield put(deleteMasterListFailure())
    notification.destroy();
    notification.error({
      message: 'Delete Submission Failed.',
      description: `${error}`,
    });

  }
}

function* SparePartsWatcher() {
  yield takeLatest(GENERATESPAREPARTS, generateSpareParts);
  yield takeLatest(FETCHSPAREPARTSLIST, fetchSparePartsList);
  yield takeLatest(DELETESPAREPARTS, deleteSpareParts);
  yield takeLatest(FETCHSPAREPARTSMASTERLIST, fetchSparePartsMasterList);
  yield takeLatest(UPLOADMASTERSPAREPARTS, uploadMasterSpareParts);
  yield takeLatest(FETCHSPAREPARTSMASTERLISTP, fetchSparePartsMasterListPage);
  yield takeLatest(GENERATEMASTERSPAREPARTS, GenerateMasterSpareParts);
  yield takeLatest(DELETESMASTERLIST, deleteMasterList);

}

function* SparePartsSagas() {
  yield all([
    call(SparePartsWatcher),
  ]);
}

export default SparePartsSagas;


export const getCategoryListFromDatabse = async (columnName) => {
  try {
    let url;

    url = `/GetMasterTypesList?searchKey=${(columnName)}`;
    const res = await axiosConfig.get(url);
    if (res.status === 403) {
      notification.error({
        message: 'Unauthorized',
      });
      return null;
    } if (res.status === 200) {
      const data = get(res, ['data', 'data'], {});

      return data;
    }
    return null;
  } catch (error) {
    return null;
  }
};

