/* eslint-disable no-param-reassign */
/* eslint-disable react/no-unescaped-entities */
import { ArrowLeftOutlined } from '@ant-design/icons';
import {
    Button,
    Col,
    Form,
    Row,
    Tabs,
    Tooltip,
    Typography
} from 'antd';
import { get } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { generateSparePartsAction } from '../../redux/Spareparts/action.js';
import history from '../../services/history.js';
import BevellingForm from './BevellingForm.js';
import LineUpForm from './LineUpForm.js';
import SupportItemsForm from './SupportItemsForm.js';
import WeldingForm from './WeldingForm.js';

const { Title, Text } = Typography;
const { TabPane } = Tabs;

//const { Option } = Select;
const SparepartsUpdateForm = (props) => {

    const {
        OnGenerateSpareParts,
        showPipelineModel,
        setShowPipelineModel,
        pipelineData,
        setPipelineData,
        projectDetails,
        setProjectDetails,
        isEdit,
        setIsEdit,
        editRecord,
        isGenerateLoading,
        isRead,
        setIsRead,
    } = props;
   // const [licenseForm] = Form.useForm()

    const [activeKey, setActiveKey] = useState('1')
    const [activeKeyLine, setActiveKeyLine] = useState('1')
    const [count, setCount] = useState(1)
    //const [showGenerateModel, setShowGenerateModel] = useState(false)
    const [partsData, setPartsData] = useState('');
    const [editPipelineDatas, setEditPipelinesDatas] = useState([])
    const [confirmLoading, setConfirmLoading] = useState(false);
    //const [updateForm] = Form.useForm()
    useEffect(() => {

        if (!pipelineData)
            return;

        const pipeLines = editRecord.pipelines_data
        const pipelineDataObject = pipelineData.reduce((accumulator, row, index) => {
            const lineKey = `Line_${index + 1}`;

            // const pipeline = isEdit ? editRecord.pipelineData[index] : ''
            //console.log('pipeline',index ,pipeLines)
            const lineData = {
                'pipeSize': Number(row.col1),
                'wallThickness': Number(row.col2),
                'noOfWelds': Number(row.col3),
                'noOfStations': Number(row.col4)

            }
            if (isEdit && pipeLines[index]) {
                lineData.pipelineID = pipeLines[index]?.pipelineID;

                const selectedOptionsBevel = {
                    HPU: { CRCE: [], Goriziance: [] },
                    PFM: { CRCE: [], Goriziance: [] },
                    Bevel: { IWM: [], CMT: [], CBUC: [] },
                };

                const editBevelingData = pipeLines[index]?.Beveling;
                // console.log('editBevelingData', editBevelingData);

                if (editBevelingData) {
                    Object.keys(editBevelingData).forEach(key => {
                        const parts = key.split('_');
                        if (parts.length === 2) {
                            const [prefix, suffix] = parts;
                            if (selectedOptionsBevel.hasOwnProperty(prefix) && selectedOptionsBevel[prefix].hasOwnProperty(suffix)) {
                                const values = editBevelingData[key] ? editBevelingData[key].split(',') : [];
                                selectedOptionsBevel[prefix][suffix] = values; // Assign the array of values directly
                            }
                        }
                    });
                    lineData.BevelingData = selectedOptionsBevel;
                }
                const selectedOptionsLineUp = {
                    CBUOPUS: [],
                    IWM: [],
                };

                const lineUpData = pipeLines[index]?.LineUp;
                // console.log('lineUpData', lineUpData);

                if (isEdit && lineUpData) {
                    selectedOptionsLineUp.CBUOPUS = lineUpData.CBUOPUS ? lineUpData.CBUOPUS.split(',') : [];
                    selectedOptionsLineUp.IWM = lineUpData.IWM ? lineUpData.IWM.split(',') : [];

                    lineData.LineUpData = selectedOptionsLineUp;
                }



                const weldingData = pipeLines[index]?.Welding;
                //console.log('weldingData', weldingData);

                if (weldingData) {
                    const weldingDataObj = {
                        m_Series: weldingData.m_Series ? weldingData.m_Series.split(',') : [],
                        P625: {
                            P_625: weldingData.P_625 || "",
                            Torch: weldingData.Torch || "",
                            CMT_Torch: weldingData.CMT_Torch || "",
                            CMT_Material: weldingData.CMT_Material || "",
                            CMT_Wire_Size: weldingData.CMT_Wire_Size || ""
                        }
                    };
                    lineData.WeldingData = weldingDataObj;
                }



                const supportItemData = pipeLines[index]?.SupportItem;
                //console.log('supportItemData', supportItemData);

                if (supportItemData) {
                    const supportDataObj = {
                        projectType: supportItemData.ProjectType ||'None',
                        grinding_Tool_Consumables_Required: supportItemData.Grinding_tool_consumables_required || 'No',
                        PPE: supportItemData.PPE || {
                            Required: "No",
                            Project_Duration: 0,
                            Mainline_Welders: 0,
                            Repair_Welders: 0,
                            Bevel_Machine_Operators: 0,
                            Technicians:0,
                            General_Helper: 0,
                            Air_Fed_Welding_Hood: "No",
                            Non_Air_Fed_Hood: "No",
                            Cool_Vest: "No"
                        }   
                    };
                    lineData.SupportItemData = supportDataObj;
                }
            }
            else {
                // Set empty values when isEdit 
                lineData.BevelingData = { HPU: { CRCE: [], Goriziance: [] }, PFM: { CRCE: [], Goriziance: [] }, Bevel: { IWM: [], CMT: [], CBUC: [] } };
                lineData.LineUpData = { CBUOPUS: [], IWM: [] };
                lineData.WeldingData = { m_Series: [], P625: { P_625: "", Torch: "", CMT_Torch: "", CMT_Material: "", CMT_Wire_Size: "" } };
                lineData.SupportItemData = { projectType: '', grinding_Tool_Consumables_Required: '',
                PPE: {
                    Required: "No",
                    Project_Duration: 0,
                    Mainline_Welders: 0,
                    Repair_Welders: 0,
                    Bevel_Machine_Operators: 0,
                    Technicians:0,
                    General_Helper: 0,
                    Air_Fed_Welding_Hood: "No",
                    Non_Air_Fed_Hood: "No",
                    Cool_Vest: "No"
                }   };
            }


            accumulator[lineKey] = lineData;
            return accumulator;
        }, {});

        if (isEdit) {
            projectDetails.noOfPipelinesList = generatePipelineNames(projectDetails.noOfPipelines)
            projectDetails.isEdit = isEdit;
            projectDetails.projectID = editRecord.projectID;
            projectDetails.totalCost = editRecord.totalCost;
            projectDetails.creationDate= moment(editRecord.creationDate).format('YYYY-MM-DD').toString()
            projectDetails.updatedDate =moment().format('YYYY-MM-DD').toString()
        }
        const data = {
            ProjectDetails: projectDetails,
            ...pipelineDataObject,
        };

        setPartsData(data);
        //console.log('data:', data)

    }, [projectDetails, pipelineData, isRead]);



    useEffect(() => {
        if (isEdit || isRead && editRecord) {

            setEditPipelinesDatas(editRecord.pipelines_data)

        }

    }, [projectDetails, pipelineData, activeKey])

    const generatePipelineNames = (noOfPipelines) => {
        const pipelineNames = [];
        for (let i = 1; i <= noOfPipelines; i++) {
            pipelineNames.push(`Line_${i}`);
        }
        return pipelineNames;
    }
    const handleOk = async (v) => {
        v++;
        if (v <= 4) {
            setCount(v)
            setActiveKey(String(v))
        }
        else {
            let pipeLineCount = Number(activeKeyLine) + 1
            if (pipeLineCount > pipelineData.length)
                pipeLineCount = 1;
            console.log(pipeLineCount)
            setActiveKeyLine(String(pipeLineCount))
            setActiveKey('1')
            setCount(1)
        }
        //console.log(v, activeKey)
    };

    const handleEditMode = async () => {
        setShowPipelineModel(true)
        setIsRead(false)
    };
    const onKeyChange = (key) => {
        setActiveKey(key)
        setCount(Number(key))
    }

    // useEffect(()=>{
    //     console.log(activeKey,activeKeyLine ,pipelineData.length)

    // },[activeKeyLine,activeKey])
    // const handleLicenseVisibe = () => {

    //     setShowGenerateModel(true)
    // }
    const handleTabChange = (key) => {
        setActiveKeyLine(key);

    };
    useEffect(() => {
        // console.log(confirmLoading, isGenerateLoading)
        if (confirmLoading && !isGenerateLoading) {

            setShowPipelineModel(false)
            history.push('/SparePartsGenerator');
        }
    }, [isGenerateLoading])

    const OnGenerateReport = async () => {
        if (!isRead && projectDetails) {

            console.log('Spare Parts List:', partsData)
            setConfirmLoading(true)
            await OnGenerateSpareParts({ data: partsData })

        }
    };


    const getPipelineTabpanes = () => {
        return (
            <div className="custom-tab h-100" style={{ paddingTop: '10px', }}>
                <Tabs activeKey={activeKeyLine} onChange={handleTabChange} defaultActiveKey="1" >
                    {pipelineData.map((row, index) => (
                        <TabPane tab={'Line ' + String(Number(index) + 1)} key={String(Number(index) + 1)} >

                            <div className={isRead ? 'pipeline-modal2 report-checkbox2' : 'pipeline-modal report-checkbox'} style={{ paddingTop: '0px' }}  >
                                <Tabs
                                    className="custom-tab h-100" defaultActiveKey="1" tabPosition={'left'}
                                    activeKey={activeKey}
                                    onChange={onKeyChange}
                                >
                                    <TabPane tab="Bevelling" key="1">
                                        <div style={{ pointerEvents: isRead ? 'none' : 'auto' }} >
                                            <BevellingForm
                                                Specification={row}
                                                partsData={partsData}
                                                setPartsData={setPartsData}
                                                activeLine={index}
                                                isEdit={isEdit}
                                                bevelingData={editPipelineDatas}
                                            ></BevellingForm>
                                        </div>

                                    </TabPane>
                                    <TabPane tab="Line Up" key="2">
                                        <div style={{ pointerEvents: isRead ? 'none' : 'auto' }} >
                                            <LineUpForm
                                                Specification={row}
                                                partsData={partsData}
                                                setPartsData={setPartsData}
                                                activeLine={index}
                                                isEdit={isEdit}
                                                lineUpData={editPipelineDatas}
                                            >
                                            </LineUpForm>
                                        </div>

                                    </TabPane>

                                    <TabPane tab="Welding" key="3">
                                        <div style={{ pointerEvents: isRead ? 'none' : 'auto' }} >
                                            <WeldingForm
                                                Specification={row}
                                                partsData={partsData}
                                                setPartsData={setPartsData}
                                                activeLine={index}
                                                isEdit={isEdit}
                                                weldingData={editPipelineDatas}
                                            >
                                            </WeldingForm>
                                        </div>
                                    </TabPane>
                                    <TabPane tab="Support Items" key="4">
                                        <div style={{ pointerEvents: isRead ? 'none' : 'auto' }} >
                                            <SupportItemsForm
                                                Specification={row}
                                                partsData={partsData}
                                                setPartsData={setPartsData}
                                                activeLine={index}
                                                isEdit={isEdit}
                                                supportItemData={editPipelineDatas}
                                            >
                                            </SupportItemsForm>
                                        </div>
                                    </TabPane>

                                </Tabs>
                            </div>

                        </TabPane>
                    ))}
                </Tabs>


            </div>
        )
    }

    return (

        <div style={{ height: '100%' }}>
            <div>
                <div className="d-flex mb-3 align-items-center">
                    <div className='back_equip' >
                        <Tooltip title={'Back'} >
                            <ArrowLeftOutlined onClick={() => {
                                
                                setIsRead(false)
                            
                                setIsEdit(false)
                                setShowPipelineModel(false)
                                history.push('/SparePartsGenerator');
                            }} />
                        </Tooltip>
                    </div>

                    <Title className="mb-0 add-new-calibration" level={3} style={{ paddingLeft: '30px' }}>
                        Spare Parts List Generator
                    </Title>

                    <div className="new-calibration">


                        {/* <Button size='large' onClick={() => handleLicenseVisibe()} className="ps-3 add-new-calibration" >
                            <PlusCircleOutlined  style={{ fontSize: '20px' }} />
                            Generate New Spares
                </Button> */}


                        {/* <Text onClick={() => handleLicenseVisibe()} className="ps-3 add-new-calibration" style={{ fontSize: '20px' }}>
                        <span className="icon-22" style={{ scale: '20px' }}>
                            <svg>
                                <use xlinkHref="#add" />
                            </svg>
                        </span>
                        Generate New Spares
                    </Text> */}
                    </div>


                </div>
                <div className="custom-line" />
                <Row style={{ paddingTop: '5px' }} gutter={24}>
              
                    <Col span={3}>
                        <div className="project-details-wrapper text-center">
                            <p className="fw-medium title">Project Name</p>
                            <div className="d-flex edit-session justify-content-center mb-3">
                                <p className="mb-0">{projectDetails.projectName}</p>
                            </div>
                        </div>
                    </Col>
                    <Col span={3}>
                        <div className="project-details-wrapper text-center">
                            <p className="fw-medium title">Project No</p>
                            <div className="d-flex edit-session justify-content-center mb-3">
                                <p className="mb-0">{projectDetails.projectNumber ===' '? '-' :projectDetails.projectNumber }</p>
                            </div>
                        </div>
                    </Col>
                    <Col span={3}>
                        <div className="project-details-wrapper text-center">
                            <p className="fw-medium title">No of Pipelines</p>
                            <div className="d-flex edit-session justify-content-center mb-3">
                                <p className="mb-0">{projectDetails.noOfPipelines}</p>
                            </div>
                        </div>
                    </Col>
                    <Col span={3}>
                        <div className="project-details-wrapper text-center">
                            <p className="fw-medium title">Project Type</p>
                            <div className="d-flex edit-session justify-content-center mb-3">
                                <p className="mb-0">{projectDetails.projectType}</p>
                            </div>
                        </div>
                    </Col>
                    <Col span={3}>
                        <div className="project-details-wrapper text-center">
                            <p className="fw-medium title">Total Cost</p>
                            <div className="d-flex edit-session justify-content-center mb-3">
                                <p className="mb-0">{projectDetails.totalCost ? new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(projectDetails.totalCost) : '-'}</p>
                            </div>
                        </div>
                    </Col>
                    <Col span={3}>
                        <div className="project-details-wrapper text-center">
                            <p className="fw-medium title">Created Date</p>
                            <div className="d-flex edit-session justify-content-center mb-3">
                                <p className="mb-0">{moment(projectDetails.creationDate).format('DD MMM YYYY')}</p>
                            </div>
                        </div>
                    </Col>
                    <Col span={3}>
                        <div className="project-details-wrapper text-center">
                            <p className="fw-medium title">Updated Date</p>
                            <div className="d-flex edit-session justify-content-center mb-3">
                                <p className="mb-0">{moment(projectDetails.updatedDate).format('DD MMM YYYY')}</p>
                            </div>
                        </div>
                    </Col>



                </Row>
                <div className="custom-line" style={{ marginTop: '-20px' }} />
                {getPipelineTabpanes()}
                <div style={{ paddingTop: '10px' }} className='Generate-btn'>

                    <Button className="cancel-btn" type="default"
                        onClick={() => {
                            setShowPipelineModel(false)
                            setIsRead(false)
                            history.push('/SparePartsGenerator')
                        }
                        }
                    >
                        Cancel
                    </Button>

                    <Button key="apply" type="primary" className='submit-btn'
                        onClick={() => handleOk(count)}

                    >
                        Next
                    </Button>

                    {isRead &&
                        <Button key="apply" type="default" className='submit-btn'
                            onClick={() => handleEditMode()}

                        >
                            Edit Mode
                        </Button>
                    }
                    {!isRead && Number(activeKeyLine) === pipelineData.length && activeKey == 4 &&
                        <Button key="apply" type="primary" className='submit-btn'
                            onClick={() => {
                                OnGenerateReport()
                                // setShowPipelineModel(false)
                                // history.push('/SparePartsGenerator')
                            }
                            }
                            loading={isGenerateLoading}

                        >
                            {isEdit ? 'Update' : 'Generate'}
                        </Button>
                    }
                </div>
            </div>

        </div>
    );
};

const mapStateToProps = ({ sparepartsreducer }) => {

    const isGenerateLoading = get(sparepartsreducer, 'isGenerateLoading', false);
    return {
        isGenerateLoading
    }
}


const mapDispatchToProps = {
    OnGenerateSpareParts: generateSparePartsAction,

};

export default connect(mapStateToProps, mapDispatchToProps)(SparepartsUpdateForm);

