import { FETCH_RAW_CRC_PM_LIST, FETCH_RAW_CRC_PM_LIST_SUCCESS, FETCH_RAW_CRC_PM_LIST_FAILURE, FETCH_RAW_CM_CRC_LIST, FETCH_RAW_CM_CRC_LIST_SUCCESS, FETCH_RAW_CM_CRC_LIST_FAILURE } from './types';


// RAW CRC PM list
const fetchRawCrcPmList = (payload) => ({
  type: FETCH_RAW_CRC_PM_LIST,
  ...payload
});

const fetchRawCrcPmListSuccess = (payload) => ({
  type: FETCH_RAW_CRC_PM_LIST_SUCCESS,
  ...payload
});

const fetchRawCrcPmListFailure = () => ({
  type: FETCH_RAW_CRC_PM_LIST_FAILURE
});

// RAW CM CRC list
const fetchRawCmCrcList = (payload) => ({
  type: FETCH_RAW_CM_CRC_LIST,
  ...payload
});

const fetchRawCmCrcListSuccess = (payload) => ({
  type: FETCH_RAW_CM_CRC_LIST_SUCCESS,
  ...payload
});

const fetchRawCmCrcListFailure = () => ({
  type: FETCH_RAW_CM_CRC_LIST_FAILURE
});



export {
  fetchRawCrcPmList, fetchRawCrcPmListSuccess, fetchRawCrcPmListFailure,
  fetchRawCmCrcList, fetchRawCmCrcListSuccess, fetchRawCmCrcListFailure,
};