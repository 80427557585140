/* eslint-disable no-param-reassign */
/* eslint-disable react/no-unescaped-entities */
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import {
    Button,
    Col,
    Divider,
    Form,
    Input, InputNumber,
    Modal,
    Row,
    Select,
    Space,
    Typography,
    notification
} from 'antd';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { generateMasterSparePartsAction } from '../../redux/Spareparts/action';
import { getCategoryListFromDatabse } from '../../redux/Spareparts/saga';
const { Option } = Select;
const MasterAddnew = (props) => {

    const {
        showAddNewModal,
        setShowAddNewModal,
        addNewForm,
        onAddorUpdateSpareparts,
        isEdit,
        setIsEdit,
        editRecord,
        seteditRecord

    } = props;

    //const categoryType = ['BEVELLING', 'BEVELLING-IND', 'GRINDING', 'GRINDING-FIX', 'M300 M400 M500 CONS', 'P625 CONS', 'SPARES', 'WELDING TIP', 'WELDING TIP-M', , 'P625 CONS']
    const multiplyItemType = [{ value: 'Y', label: 'Yes' }, { value: 'N', label: 'No' }];
    // const whereUsedType = ['BD4 BEV-CBUC-HW', 'BD3 BEV-CMT', 'BD1 BEV-IWM-HW', 'PFM 16 22', 'PFM 24 26', 'PFM 28 30 32', 'PFM 40NS 42NS', 'PFM 40OS 42OS', 'PFM 48OS', 'PFM 56', 'HPUC - HPU CRC Diesel',
    //     'IWM 24', 'IWM 26 28', 'IWM 38 40WH']
    const handleCancel = () => {
        setIsEdit(false)
        setShowAddNewModal(false);
    };
    const [categoryType, setCategoryType] = useState([]);
    const [whereUsedType, setwhereUsedType] = useState([]);

    const [newItem, setNewItem] = useState('');
    // State to track the selected category
    const [selectedCategory, setSelectedCategory] = useState('');
    const [selectedMultipleItem, setSelectedMultipleItem] = useState('');
    const [selectedWhereUsedType, setSelectedWhereUsedType] = useState([]);
    const [cost, setcost] = useState(0)
    const [qTY500, setQTY500] = useState(0)
    useEffect(()=>{
        if(editRecord)
        {
        setcost(editRecord.cost)
        setQTY500(editRecord.quantity_5000_Welds)
        }
    },[editRecord])
    // Function to handle combobox change
    const handleCategoryComboboxChange = (value) => {

        setSelectedCategory(value);
    };
    const handleMultipleItemComboboxChange = value => {
        setSelectedMultipleItem(value);
    };
    // Function to handle combobox change
    const handleWhereUsedComboboxChange = value => {
        setSelectedWhereUsedType(value);
    };

    // Function to handle custom input
    const handleCustomInput = value => {
        setSelectedWhereUsedType(value);
    };

    const setAdddnewItem = (event) => {
        setNewItem(event.target.value);
        // console.log(categoryType)
    };
    const addItem = () => {
        if (newItem) {
            setCategoryType(prevOptions => [...prevOptions, { category: newItem }]);
            setNewItem('')
          
        }
    };

    const UpdatewereusednewItem = (event) => {
        setNewItem(event.target.value);
        // console.log(categoryType)
    };
    const addWhereUsedItem = () => {
        if (newItem) {
            setwhereUsedType(prevOptions => [...prevOptions, { where_Used: newItem }]);
            setNewItem('')
            // console.log(newItem)
        }
    };

    const getCategoryList = async (searchKeyExist) => {
        try {
            const response = await getCategoryListFromDatabse('category');
            // console.log(response)
            if (response) {
                setCategoryType(response)
                return response.users;
            } else if (response.users.length === 0) {
                setCategoryType([]);
            } else {
                throw new Error('No category found.');
            }
        } catch (error) {
            notification.error({
                message: 'Something went wrong.',
            });
        }

    };
    const getWhereusedList = async (searchKeyExist) => {
        try {
            const response = await getCategoryListFromDatabse('where_Used');
            //console.log(response)
            if (response) {
                setwhereUsedType(response)
                return response.users;
            } else if (response.users.length === 0) {
                setwhereUsedType([]);
            } else {
                throw new Error('No category found.');
            }
        } catch (error) {
            notification.error({
                message: 'Something went wrong.',
            });
        }

    };

    useEffect(() => {
        getCategoryList()
        getWhereusedList()
    }, []);

    const handleCreate = () => {
        addNewForm
            .validateFields()
            .then(async (data) => {
                if (!data.partNumber) {
                    notification.warning({
                        message: 'Enter the part number  to proceed!'
                    });
                }
                else if (!data.category) {
                    notification.warning({
                        message: 'Select  the category  to proceed!'
                    });
                }
                else if (!data.where_Used) {
                    notification.warning({
                        message: 'Select  the where used  to proceed!'
                    });
                }
                else if (!data.multiply_Item) {
                    notification.warning({
                        message: 'Select  the  multiply item  to proceed!'
                    });
                }
                else {
                    const item = {
                        'partNumber': data.partNumber,
                        'description': data.description,
                        'quantity_5000_Welds': qTY500,
                        'category': data.category,
                        'multiply_Item': data.multiply_Item,
                        'cost': cost,
                        'where_Used': data.where_Used,
                        'UOM': data.UOM,
                        'alternate_Parts': data.alternate_Parts,
                    }

                    if (isEdit) {
                        item.isEdit = isEdit;
                        item.ID = editRecord.ID;
                    }
                    //console.log('Added Item:', item)
                    await onAddorUpdateSpareparts({ data: [item] })
                    setIsEdit(false)
                    setShowAddNewModal(false)
                }

            })
            .catch(errorInfo => {
                console.error('Validation failed:', errorInfo);
            });
    };

    const handleBlurQty500 = (value) => {
       
        if (value === '') {
          
            setQTY500(0)
        }
    };


    const handleBlurCost = (value) => {
     
        if (value === '') {
            setcost(0)
        }

    };
    return (

        <div>
            <div>
                <Modal
                    width="50vw"

                    className="pipeline-modal"
                    title="Add Spare Parts"
                    centered
                    visible={showAddNewModal}
                    bodyStyle={{ height: 400 }}

                    footer={[
                        <div style={{ paddingTop: '10px', paddingRight: '0px' }}>
                            <Button key="cancel"
                                onClick={() => handleCancel()}
                            >
                                Cancel
                            </Button>

                            <Button key="apply" type="primary"
                                onClick={() => handleCreate()}

                            >
                                {isEdit ? 'Update' : 'Add'}
                            </Button>

                        </div>
                    ]}
                >
                    <div>
                        <Form
                            form={addNewForm}
                            name="basic"
                            layout="vertical"
                            autoComplete="off"
                            height='200px'

                        >

                            <Row gutter={16}>
                                <Col lg={8}>
                                    <Form.Item
                                        name="partNumber"
                                        label="Part Number"
                                        required

                                    >
                                        <Input placeholder="Part Number" style={{ height: '35px' }} />
                                    </Form.Item>
                                </Col>
                                <Col lg={8}>
                                    <Form.Item
                                        name="description"
                                        label="Description"

                                    >
                                        <Input placeholder="Description" style={{ height: '35px' }} />
                                    </Form.Item>
                                </Col>
                                <Col lg={8}>
                                    <Form.Item
                                        name="quantity_5000_Welds"
                                        label="Qty/5000 Welds"
                                    >


                                        <InputNumber style={{ height: '35px' }}
                                            min={0}
                                            defaultValue={0}
                                            type="number"
                                            onBlur={(value) => handleBlurQty500(value.target.value)}
                                            size="large"
                                            value = {qTY500}
                                            onChange={(v) => {
                                                if (v === null || v === undefined || isNaN(v)) {
                                                    setQTY500(0); // Set default value if input is empty or not a number
                                                } else {
                                                    setQTY500(v);
                                                }
                                            }}
                                        />

                                    </Form.Item>
                                </Col>
                                <Col lg={8}>
                                    <Form.Item
                                        name="category"
                                        label="Category"
                                        required
                                    >

                                        <Select
                                            className='maintenance-select'
                                            placeholder={'Select category'}
                                            //loading={isUserListLoading}
                                            showSearch

                                            //optionFilterProp="children"
                                            optionFilterProp={false}
                                            onChange={(value) => handleCategoryComboboxChange(value)}
                                            // onFocus={onGetAllUsers}
                                            onSearch={(value) => handleCategoryComboboxChange(value)}

                                            value={selectedCategory}

                                            dropdownRender={menu => (
                                                <>
                                                    {menu}
                                                    <Divider style={{ margin: '8px 0', borderTop: '1px solid lightgrey' }} />
                                                    <Space align="center" style={{ padding: '0 8px 4px' }}>
                                                        <Input placeholder="Add Category" value={newItem} onChange={setAdddnewItem} />
                                                        <Typography.Link onClick={addItem} style={{ whiteSpace: 'nowrap', color: "green" }}>
                                                            <CheckOutlined />
                                                        </Typography.Link>
                                                        <Typography.Link onClick={{}} style={{ whiteSpace: 'nowrap', color: "red" }}>
                                                            <CloseOutlined />
                                                        </Typography.Link>
                                                    </Space>
                                                </>
                                            )}

                                        >
                                            {categoryType &&
                                                categoryType
                                                    .sort((a, b) => a.category.localeCompare(b.category)) // Sort by category
                                                    .map((item, i) => (
                                                        <Option key={i} value={item.category}> {/* Use item.category as value */}
                                                            {item.category}
                                                        </Option>
                                                    ))
                                            }
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col lg={8}>
                                    <Form.Item
                                        name="multiply_Item"
                                        label="Multiply Item"
                                        required
                                    >
                                        <Select className='maintenance-select'
                                            onChange={handleMultipleItemComboboxChange}
                                            value={selectedMultipleItem}
                                            placeholder="Select Multiply Item">
                                            {multiplyItemType.map(item => (
                                                <Option key={item.value} value={item.value}>
                                                    {item.label}
                                                </Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col lg={8}>
                                    <Form.Item
                                        name="cost"
                                        label="Cost"
                                    >

                                        <InputNumber style={{ height: '35px' }}

                                            defaultValue={0}
                                            min={0}
                                            type="number"
                                            value={cost}
                                            onBlur={(value) => handleBlurCost(value.target.value)}
                                            size="large"
                                            onChange={(v) => {
                                                if (v === null || v === undefined || isNaN(v)) {
                                                    setcost(0); // Set default value if input is empty or not a number
                                                } else {
                                                    setcost(v);
                                                }
                                               
                                            }}
                                        />


                                    </Form.Item>
                                </Col>
                                <Col lg={8}>
                                    <Form.Item
                                        name="where_Used"
                                        label="Where Used"
                                        required
                                    >
                                        <Select
                                            className='maintenance-select'
                                            placeholder={'Select category'}
                                            //loading={isUserListLoading}
                                            showSearch

                                            //optionFilterProp="children"
                                            optionFilterProp={false}
                                            onChange={(value) => handleWhereUsedComboboxChange(value)}
                                            // onFocus={onGetAllUsers}
                                            onSearch={(value) => handleWhereUsedComboboxChange(value)}

                                            value={selectedWhereUsedType}

                                            dropdownRender={menu => (
                                                <>
                                                    {menu}
                                                    <Divider style={{ margin: '8px 0', borderTop: '1px solid lightgrey' }} />
                                                    <Space align="center" style={{ padding: '0 8px 4px' }}>
                                                        <Input placeholder="Add Category" value={newItem} onChange={UpdatewereusednewItem} />
                                                        <Typography.Link onClick={addWhereUsedItem} style={{ whiteSpace: 'nowrap', color: "green" }}>
                                                            <CheckOutlined />
                                                        </Typography.Link>
                                                        <Typography.Link onClick={{}} style={{ whiteSpace: 'nowrap', color: "red" }}>
                                                            <CloseOutlined />
                                                        </Typography.Link>
                                                    </Space>
                                                </>
                                            )}

                                        >
                                            {whereUsedType &&
                                                whereUsedType
                                                    .sort((a, b) => a.where_Used.localeCompare(b.where_Used)) // Sort by category
                                                    .map((item, i) => (
                                                        <Option key={i} value={item.where_Used}> {/* Use item.category as value */}
                                                            {item.where_Used}
                                                        </Option>
                                                    ))
                                            }
                                        </Select>
                                    </Form.Item>
                                </Col>

                                <Col lg={8}>
                                    <Form.Item
                                        name="UOM"
                                        label="UOM"
                                    >
                                        <Input placeholder="UOM" style={{ height: '35px' }} />
                                    </Form.Item>
                                </Col>
                                <Col lg={8}>
                                    <Form.Item
                                        name="alternate_Parts"
                                        label="Alternate Parts"
                                    >
                                        <Input placeholder="Alternate Parts" style={{ height: '35px' }} />
                                    </Form.Item>
                                </Col>



                            </Row>
                        </Form>
                    </div>


                </Modal >

            </div >
        </div >
    );


};

const mapDispatchToProps = {

    onAddorUpdateSpareparts: generateMasterSparePartsAction,


}

export default connect(null, mapDispatchToProps)(MasterAddnew);

