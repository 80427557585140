

import get from 'lodash/get';
import { FETCHRECORDLIST_FAILURE, FETCHRECORDLIST_SUCCESS, FETCHRECORDSLIST } from './types';

//import { fetchLicenseListSuccess } from './generatorLicense';
const initialState = {
  isGenerateLoading: false,
  isDeleteLoading: false,
  isFetchLoading:false

};
const RecordListReducer = (state = initialState, action) => {
 
  switch (action.type) {

  
    case FETCHRECORDSLIST: {
      // console.log('resducer called')
      return {
        ...state,
        isFetchLoading: true,
      };
    }
    case FETCHRECORDLIST_SUCCESS: {
      const resData = get(action, 'responseData', []);
      //console.log("resData", resData)
      return {
        ...state,
        RecordList: resData,
        RecordListCount: resData.length > 0 ? resData[0].totalCount : 0,
        RecordListTotalCount: resData.length > 0 ? resData[0].totalListCount : 0,
        isFetchLoading: false
      }
    }
    case FETCHRECORDLIST_FAILURE: {
      return {
        ...state,
        isFetchLoading: false
      }
    }

  
    default: {
      return state;
    }
  }
};

export default RecordListReducer;