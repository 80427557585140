import { Table, Collapse, Divider, Spin, Tooltip, Button, Popover, Card } from 'antd';
import { DeleteOutlined, EditOutlined, FilePdfOutlined, EllipsisOutlined, StarFilled, FileExcelOutlined, EyeOutlined } from '@ant-design/icons';
import { ellipsisVertical } from "ionicons/icons";
import { IonIcon } from "@ionic/react";
import React, { useState, useEffect } from 'react';
import { Row, Col, List } from 'antd';

const { Panel } = Collapse;

const CollapsibleTable = ({ projectPricingData, isPricingDataLoading, onUpdateClick, setIsDeleteModalVisible, onReadParts, showDeleteModal }) => {
    const [selectedProjectIndex, setSelectedProjectIndex] = useState(0);
    const [selectedSpreadIndex, setSelectedSpreadIndex] = useState(0);
    const [selectedContractorIndex, setSelectedContractorIndex] = useState(0);
    const [selectedProposals, setSelectedProposals] = useState([]);

    const projectData = projectPricingData.map(project => ({
        ...project,
        Spreads: project.Spreads.map(spread => ({
          ...spread,
          Contractors: spread.Contractors.map(contractor => ({
            ...contractor,
            Proposals: [...contractor.Proposals].sort((a, b) => {
              if (a.Source_Type === "Primary" && b.Source_Type !== "Primary") {
                return -1;
              } else if (a.Source_Type !== "Primary" && b.Source_Type === "Primary") {
                return 1;
              }
              return 0;
            })
          }))
        }))
      }));
      
      
    const handleSelectProject = (projectIndex) => {
        setSelectedProjectIndex(projectIndex);
        setSelectedSpreadIndex(0);
        setSelectedContractorIndex(0);
        updateProposals(projectIndex, 0, 0);
    };

    const handleSelectSpread = (spreadIndex) => {
        setSelectedSpreadIndex(spreadIndex);
        setSelectedContractorIndex(0);
        updateProposals(selectedProjectIndex, spreadIndex, 0);
    };

    const handleSelectContractor = (contractorIndex) => {
        setSelectedContractorIndex(contractorIndex);
        updateProposals(selectedProjectIndex, selectedSpreadIndex, contractorIndex);
    };

    const updateProposals = (projectIndex, spreadIndex, contractorIndex) => {
        setSelectedProposals(
            projectData[projectIndex]?.Spreads[spreadIndex]?.Contractors[contractorIndex]?.Proposals
        );
    };

    useEffect(() => {
        updateProposals(0, 0, 0);
    }, [projectPricingData]);

    //
    const formatCurrency = (value) => {
        return value ? `$${parseFloat(value).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}` : '';
    };

    // proposal table
    const ProposalCard = ({ proposal }) => {
        return (
            <Card className="proposal-card" style={{padding:'5px'}}>
                <div className="card-header">
                    <div className="icons">
                        {(proposal.Source_Type == 'Primary') && <Tooltip title="Primary">
                            <StarFilled className="star-icon" />
                        </Tooltip>}
                        <Tooltip title="Export to Excel">
                            <Button
                                href={proposal.Url}
                                size='small'
                                target='_blank'
                                style={{ border: 'none', top: '-5px' }}
                            >

                                <FileExcelOutlined className="excel-icon" />
                            </Button>
                        </Tooltip>
                    </div>
                    <div className='action-icons'>
                        <Tooltip title="Update">
                            <EditOutlined
                                style={{ marginRight: '10px' }}
                                className="update-icon"
                                onClick={() => onUpdateClick(proposal)}
                            />
                        </Tooltip>
                        <Tooltip title="Delete">
                            <DeleteOutlined className="delete-icon" onClick={() => showDeleteModal(proposal)} />
                        </Tooltip>
                    </div>

                </div>
                <hr />
                <div className="card-content" onClick={() => onReadParts(proposal)}>
                    <div className="info-column">
                        <div className="info-item">
                            <p className="label">Quote No.</p>
                            <p className="value">{proposal.Proposal_Number}</p>
                        </div>
                        <div className="info-item">
                            <p className="label">Representative</p>
                            <p className="value">{proposal['Representative']}</p>
                        </div>
                        <div className="info-item">
                            <p className="label">Status</p>
                            <p className="value">{proposal.Status}</p>
                        </div>
                    </div>
                    <div className="info-column">
                        <div className="info-item">
                            <p className="label">Equipment Type</p>
                            <p className="value">{proposal.Equipment_Type}</p>
                        </div>
                        <div className="info-item">
                            <p className="label">Diameter</p>
                            <p className="value">{proposal['Pipe_OD']}</p>
                        </div>
                        <div className="info-item">
                            <p className="label">Total Revenue</p>
                            <p className="value">{proposal?.Summary?.Summary?.['Revenue']?.['Total Revenue'] ? formatCurrency(proposal?.Summary?.Summary?.['Revenue']?.['Total Revenue']) : '-'}</p>
                        </div>
                    </div>
                    <div className="info-column">
                        <div className="info-item">
                            <p className="label">Welds</p>
                            <p className="value">{proposal.No_of_Joints}</p>
                        </div>
                        <div className="info-item">
                            <p className="label">Length</p>
                            <p className="value">{proposal.Length_in_Kms} KM</p>
                        </div>
                        <div className="info-item">
                            <p className="label">Total Cost</p>
                            <p className="value">{proposal?.Summary?.Summary?.['Cost']?.['Total Cost'] ? formatCurrency(proposal?.Summary?.Summary?.['Cost']?.['Total Cost']) : '-'}</p>
                        </div>

                    </div>
                    <div className="info-column">
                        <div className="info-item">
                            <p className="label">Estimated start</p>
                            <p className="value">{proposal.EstimatedStart}</p>
                        </div>
                        <div className="info-item">
                            <p className="label">Gross Margin</p>
                            <p className="value">{((proposal?.Summary?.Summary?.['Margin']?.['Total']) * 100).toFixed(2)}%</p>
                        </div>

                    </div>
                </div>
            </Card>
        );
    };

    return (
        <div className="collapsible-table">
            {!isPricingDataLoading ?
                <Row gutter={16}>
                    <Col span={5}>
                        <h6 style={{ textAlign: 'left', marginBottom: '5px', color: '#EF314C' }}>
                            Project Name / Spread
                        </h6>
                        <div style={{ height: '95%' }} className='project-header'>

                            <Collapse accordion defaultActiveKey={['0']} expandIconPosition='end'>
                                {projectData?.map((project, projectIndex) => (
                                    <Panel
                                        header={project.Project_Name}
                                        key={projectIndex}
                                        onClick={() => handleSelectProject(projectIndex)}
                                    >
                                        <Collapse accordion defaultActiveKey={['0']} className='spread-header' expandIconPosition='end' collapsible={'disabled'}
                                         style={{ marginTop: '-14px', marginBottom:'-9px' }} 
                                        >
                                            {project?.Spreads?.map((spread, spreadIndex) => (
                                                <Panel
                                                    header={spread.Spreads}
                                                    key={spreadIndex}
                                                    onClick={(event) => {
                                                        event.stopPropagation();
                                                        handleSelectSpread(spreadIndex);
                                                    }}
                                                    showArrow={false}
                                                    className={`spread-panel ${spreadIndex === selectedSpreadIndex ? 'panel-active' : ''}`}
                                                />
                                            ))}
                                        </Collapse>
                                    </Panel>
                                ))}
                            </Collapse>
                        </div>
                    </Col>

                    <Col span={5} >
                        <h6 style={{ textAlign: 'left', marginBottom: '5px', color: '#EF314C' }}>
                            Contractor
                        </h6>
                        <div style={{ height: '100%', overflow: 'auto' }} className='contractor-header'>

                            <Collapse accordion defaultActiveKey={['0']} expandIconPosition='end'>
                                {projectData[selectedProjectIndex]?.Spreads[selectedSpreadIndex]?.Contractors?.map((contractor, contractorIndex) => (
                                    <Panel
                                        header={contractor.Contractor}
                                        key={contractorIndex}
                                        onClick={() => handleSelectContractor(contractorIndex)}
                                    />
                                ))}
                            </Collapse>
                        </div>
                    </Col>

                    <Col span={14} style={{ height: '100%' }}>
                        <h6 style={{ textAlign: 'left', marginBottom: '5px', color: '#EF314C' }}>
                            Proposal
                        </h6>
                        <List
                            grid={{ gutter: 16, column: 1 }}
                            dataSource={selectedProposals}
                            // bordered='none'
                            renderItem={(proposal) => (
                                <List.Item>
                                    <ProposalCard proposal={proposal} onUpdateClick={onUpdateClick} />
                                </List.Item>
                            )}
                            className='proposal-list'
                            style={{ height: 'calc(100vh - 222px)', overflowY: 'auto', overflowX: 'hidden' }}
                        />
                    </Col>
                </Row>
                :
                <Spin spinning={isPricingDataLoading} tip="Loading projects...">.</Spin>
            }
        </div>
    );
};

export default CollapsibleTable;

