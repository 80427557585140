import React, { useState, useEffect } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

const PmYoyTab = () => {
    const [rowData, setRowData] = useState([
        {
            glCategory3: 'Trade',
            channel: 'EMEA',
            channel2: 'COATING',
            '2024_rev': -28039448,
            '2024_cos': 18401466,
            '2024_total': -9637983,
            '2023_rev': -25039448,
            '2023_cos': 16401466,
            '2023_total': -8637982,
        },
        {
            glCategory3: 'Trade',
            channel: 'EMEA',
            channel2: 'EMEA',
            '2024_rev': -45212667,
            '2024_cos': 23707400,
            '2024_total': -21505267,
            '2023_rev': -42212667,
            '2023_cos': 21707400,
            '2023_total': -20505267,
        },
        {
            glCategory3: 'Trade',
            channel: 'EMEA Total',
            channel2: '',
            '2024_rev': -73252115,
            '2024_cos': 42108866,
            '2024_total': -31143250,
            '2023_rev': -67252115,
            '2023_cos': 38108866,
            '2023_total': -29143249,
            isTotal: true
        },
        {
            glCategory3: 'Trade',
            channel: 'NA LANDLINE',
            channel2: 'MICROALLOYING',
            '2024_rev': -6969019,
            '2024_cos': 5450208,
            '2024_total': -1518811,
            '2023_rev': -5969019,
            '2023_cos': 4450208,
            '2023_total': -1518811,
        },
        {
            glCategory3: 'Trade',
            channel: 'NA LANDLINE',
            channel2: 'NA LANDLINE',
            '2024_rev': -26907749,
            '2024_cos': 13155833,
            '2024_total': -13751916,
            '2023_rev': -24907749,
            '2023_cos': 12155833,
            '2023_total': -12751916,
        },
        {
            glCategory3: 'Trade',
            channel: 'NA LANDLINE Total',
            channel2: '',
            '2024_rev': -33876768,
            '2024_cos': 18606040,
            '2024_total': -15270727,
            '2023_rev': -30876768,
            '2023_cos': 16606040,
            '2023_total': -14270728,
            isTotal: true
        },
        {
            glCategory3: 'Trade',
            channel: 'NA OFFSHORE',
            channel2: 'NA OFFSHORE',
            '2024_rev': -33876768,
            '2024_cos': 18606040,
            '2024_total': -15270727,
            '2023_rev': -30876768,
            '2023_cos': 16606040,
            '2023_total': -14270728,
        },
        {
            glCategory3: 'Trade',
            channel: 'NA OFFSHORE Total',
            channel2: '',
            '2024_rev': -33876768,
            '2024_cos': 18606040,
            '2024_total': -15270727,
            '2023_rev': -30876768,
            '2023_cos': 16606040,
            '2023_total': -14270728,
            isTotal:true
        },
        {
            glCategory3: 'Trade Total',
            channel: '',
            channel2: '',
            '2024_rev': '',
            '2024_cos': '',
            '2024_total': '',
            '2023_rev': '',
            '2023_cos': '',
            '2023_total': '',
            isTotal:true
        },
        //
        {
            glCategory3: 'Affiliate',
            channel: 'EMEA',
            channel2: 'COATING',
            '2024_rev': -28039448,
            '2024_cos': 18401466,
            '2024_total': -9637983,
            '2023_rev': -25039448,
            '2023_cos': 16401466,
            '2023_total': -8637982,
        },
        {
            glCategory3: 'Affiliate',
            channel: 'EMEA',
            channel2: 'EMEA',
            '2024_rev': -45212667,
            '2024_cos': 23707400,
            '2024_total': -21505267,
            '2023_rev': -42212667,
            '2023_cos': 21707400,
            '2023_total': -20505267,
        },
        {
            glCategory3: 'Affiliate',
            channel: 'EMEA Total',
            channel2: '',
            '2024_rev': -73252115,
            '2024_cos': 42108866,
            '2024_total': -31143250,
            '2023_rev': -67252115,
            '2023_cos': 38108866,
            '2023_total': -29143249,
            isTotal: true
        },
        {
            glCategory3: 'Affiliate',
            channel: 'NA LANDLINE',
            channel2: 'MICROALLOYING',
            '2024_rev': -6969019,
            '2024_cos': 5450208,
            '2024_total': -1518811,
            '2023_rev': -5969019,
            '2023_cos': 4450208,
            '2023_total': -1518811,
        },
        {
            glCategory3: 'Affiliate',
            channel: 'NA LANDLINE',
            channel2: 'NA LANDLINE',
            '2024_rev': -26907749,
            '2024_cos': 13155833,
            '2024_total': -13751916,
            '2023_rev': -24907749,
            '2023_cos': 12155833,
            '2023_total': -12751916,
        },
        {
            glCategory3: 'Affiliate',
            channel: 'NA LANDLINE Total',
            channel2: '',
            '2024_rev': -33876768,
            '2024_cos': 18606040,
            '2024_total': -15270727,
            '2023_rev': -30876768,
            '2023_cos': 16606040,
            '2023_total': -14270728,
            isTotal: true
        },
        {
            glCategory3: 'Affiliate',
            channel: 'NA OFFSHORE',
            channel2: 'NA OFFSHORE',
            '2024_rev': -33876768,
            '2024_cos': 18606040,
            '2024_total': -15270727,
            '2023_rev': -30876768,
            '2023_cos': 16606040,
            '2023_total': -14270728,
        },
        {
            glCategory3: 'Affiliate',
            channel: 'NA OFFSHORE Total',
            channel2: '',
            '2024_rev': -33876768,
            '2024_cos': 18606040,
            '2024_total': -15270727,
            '2023_rev': -30876768,
            '2023_cos': 16606040,
            '2023_total': -14270728,
            isTotal:true
        },
        {
            glCategory3: 'Affiliate Total',
            channel: '',
            channel2: '',
            '2024_rev': '',
            '2024_cos': '',
            '2024_total': '',
            '2023_rev': '',
            '2023_cos': '',
            '2023_total': '',
            isTotal:true
        },
        {
            glCategory3: 'Grand Total',
            channel: '',
            channel2: '',
            '2024_rev': '',
            '2024_cos': '',
            '2024_total': '',
            '2023_rev': '',
            '2023_cos': '',
            '2023_total': '',
            // isTotal:true
        }
    ]);

    // Function to calculate totals for a specific year
    const calculateYearTotals = (data, year) => {
        // Calculate EMEA Total
        const emeaRows = data.filter(row => row.channel === 'EMEA');
        const emeaTotalRow = data.find(row => row.channel === 'EMEA Total');
        if (emeaTotalRow) {
            emeaTotalRow[`${year}_rev`] = emeaRows.reduce((sum, row) => sum + row[`${year}_rev`], 0);
            emeaTotalRow[`${year}_cos`] = emeaRows.reduce((sum, row) => sum + row[`${year}_cos`], 0);
            emeaTotalRow[`${year}_total`] = emeaTotalRow[`${year}_rev`] + emeaTotalRow[`${year}_cos`];
        }

        // Calculate NA LANDLINE Total
        const naRows = data.filter(row => row.channel === 'NA LANDLINE');
        const naTotalRow = data.find(row => row.channel === 'NA LANDLINE Total');
        if (naTotalRow) {
            naTotalRow[`${year}_rev`] = naRows.reduce((sum, row) => sum + row[`${year}_rev`], 0);
            naTotalRow[`${year}_cos`] = naRows.reduce((sum, row) => sum + row[`${year}_cos`], 0);
            naTotalRow[`${year}_total`] = naTotalRow[`${year}_rev`] + naTotalRow[`${year}_cos`];
        }

        // Calculate Trade Total (sum of all channel totals)
        const tradeTotalRow = data.find(row => row.glCategory3 === 'Trade Total');
        if (tradeTotalRow) {
            const totalRows = data.filter(row => row.channel.includes('Total') && row.channel !== '');
            tradeTotalRow[`${year}_rev`] = totalRows.reduce((sum, row) => sum + row[`${year}_rev`], 0);
            tradeTotalRow[`${year}_cos`] = totalRows.reduce((sum, row) => sum + row[`${year}_cos`], 0);
            tradeTotalRow[`${year}_total`] = tradeTotalRow[`${year}_rev`] + tradeTotalRow[`${year}_cos`];
        }

        // Calculate Trade Total (sum of all channel totals)
        const affiliateTotalRow = data.find(row => row.glCategory3 === 'Affiliate Total');
        if (affiliateTotalRow) {
            const totalRows = data.filter(row => row.channel.includes('Total') && row.channel !== '');
            affiliateTotalRow[`${year}_rev`] = totalRows.reduce((sum, row) => sum + row[`${year}_rev`], 0);
            affiliateTotalRow[`${year}_cos`] = totalRows.reduce((sum, row) => sum + row[`${year}_cos`], 0);
            affiliateTotalRow[`${year}_total`] = affiliateTotalRow[`${year}_rev`] + affiliateTotalRow[`${year}_cos`];
        }

        // Calculate grand Total (sum of all channel totals)
        const grandTotalRow = data.find(row => row.glCategory3 === 'Grand Total');
        if (grandTotalRow) {
            const totalRows = data.filter(row => row.channel.includes('Total') && row.channel !== '');
            grandTotalRow[`${year}_rev`] = totalRows.reduce((sum, row) => sum + row[`${year}_rev`], 0);
            grandTotalRow[`${year}_cos`] = totalRows.reduce((sum, row) => sum + row[`${year}_cos`], 0);
            grandTotalRow[`${year}_total`] = grandTotalRow[`${year}_rev`] + grandTotalRow[`${year}_cos`];
        }
    };

    // Function to update all totals
    const updateTotals = (data) => {
        const newData = [...data];
        calculateYearTotals(newData, '2023');
        calculateYearTotals(newData, '2024');
        return newData;
    };

    // Common cell style function for total rows
    const getTotalRowStyle = params => {
        if (params.data.isTotal) {
            return { backgroundColor: '#f0f0f0', fontWeight: 'bold' };
        }
        return null;
    };

    const [columnDefs] = useState([
        {
            headerName: "Sum of Amount USD",
            children: [
                { 
                    headerName: 'GL Category3', 
                    field: 'glCategory3', 
                    pinned: 'left',
                    cellStyle: getTotalRowStyle
                },
                { 
                    headerName: 'Channel', 
                    field: 'channel', 
                    pinned: 'left',
                    cellStyle: getTotalRowStyle
                },
                { 
                    headerName: 'Channel2', 
                    field: 'channel2', 
                    pinned: 'left',
                    cellStyle: getTotalRowStyle
                },
            ],
        },
        {
            headerName: '2023',
            children: [
                { 
                    headerName: 'REV', 
                    field: '2023_rev', 
                    type: 'numericColumn',
                    editable: params => !params.data.isTotal,
                    cellStyle: getTotalRowStyle
                },
                { 
                    headerName: 'COS', 
                    field: '2023_cos', 
                    type: 'numericColumn',
                    editable: params => !params.data.isTotal,
                    cellStyle: getTotalRowStyle
                },
                { 
                    headerName: 'Total', 
                    field: '2023_total', 
                    type: 'numericColumn',
                    valueGetter: params => params.data['2023_rev'] + params.data['2023_cos'],
                    cellStyle: getTotalRowStyle
                },
            ],
        },
        {
            headerName: '2024',
            children: [
                { 
                    headerName: 'REV', 
                    field: '2024_rev', 
                    type: 'numericColumn',
                    editable: params => !params.data.isTotal,
                    cellStyle: getTotalRowStyle
                },
                { 
                    headerName: 'COS', 
                    field: '2024_cos', 
                    type: 'numericColumn',
                    editable: params => !params.data.isTotal,
                    cellStyle: getTotalRowStyle
                },
                { 
                    headerName: 'Total', 
                    field: '2024_total', 
                    type: 'numericColumn',
                    valueGetter: params => params.data['2024_rev'] + params.data['2024_cos'],
                    cellStyle: getTotalRowStyle
                },
            ],
        }
    ]);

    // Function to handle updates and auto-calculate total
    const onCellValueChanged = (params) => {
        const newData = updateTotals([...rowData]);
        setRowData(newData);
    };

    useEffect(() => {
        // Initial calculation of totals
        const initialData = updateTotals([...rowData]);
        setRowData(initialData);
    }, []);

    const defaultColDef = {
        resizable: true,
        sortable: true,
        filter: true,
        // flex: 2,
        minWidth: 100,
        valueFormatter: params => {
            if (typeof params.value === 'number') {
                return params.value.toLocaleString('en-US', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                });
            }
            return params.value;
        },
        headerClass: 'green-header'
    };

    const gridStyle = `
        .ag-theme-alpine .ag-header-cell,
        .ag-theme-alpine .ag-header-group-cell {
            background-color: #0073BB !important;
            color: white !important;
        }
        
        .ag-theme-alpine .ag-header-cell-resize::after {
            background-color: white !important;
        }
        
        .ag-theme-alpine .ag-header-cell-menu-button:hover {
            color: white !important;
        }
    `;

    return (
        <div>
        <style>{gridStyle}</style>
        <div className="ag-theme-alpine" style={{ height: '800px', width: '100%' }}>
            <AgGridReact
                columnDefs={columnDefs}
                rowData={rowData}
                defaultColDef={defaultColDef}
                onCellValueChanged={onCellValueChanged}
                enableRangeSelection={true}
                enableCharts={true}
            />
        </div>
        </div>
    );
};

export default PmYoyTab;