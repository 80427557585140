/* eslint-disable react/prop-types */
import React from 'react';
import { Redirect, Route } from 'react-router-dom';

function PrivateRoute({
  isLogin, component: Component, path, exact
}) {
  return (
    <Route
      exact={exact}
      path={path}
      render={() => (isLogin ? <Component /> : <Redirect to="/login" />)}
    />
  );
}

export default PrivateRoute;
