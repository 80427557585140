// src/components/ProjectModal.js
import React, { useEffect, useState } from 'react';
import { Modal, Form, Input, Select, Checkbox, Button, Row, Col, Radio, notification, DatePicker, Divider, Space, Typography } from 'antd';


import moment from 'moment';
import { EditFilled, CheckOutlined, CloseOutlined } from '@ant-design/icons';
//import { Contractor_List, countries_List, diameters, PipeDiaRate, Project_List, Representative_List, Spread_Config_List, Pipe_Grade_List } from './Database';
import { AddOrUpdateMasterData, AddOrUpdateProject, getCategoryListFromDatabse, getDiameterListFromDatabse, getListFromDatabase } from '../../redux/Pricing/saga';
const { Option } = Select;

const DrawerPage = (props) => {

    const {
       
       
        projectDetailForm,
        isEdit,
        setIsEdit,
        editRecord,
        setProjectDetails,
        categoryListdata,
        setCategoryListdata,
        formData,
        setFormData,
        setIsProjectdetailChanged
        
    } = props;
    // const [form] = Form.useForm();
    //const [formData, setFormData] = useState({});
    const statuslist = ['In Progress','Lost','Won']
    const sourcetype = ['Primary','Secondary']
    const [addLoding, setAddLaoding] = useState(false)
    const [data_List, setData_List] = useState({})
    const [startedYear, setStartedYear] = useState(moment('YYYY'))
    const [newItem, setNewItem] = useState('');
    const [pipeDiaRateList, setPipeDiaRateList] = useState([])
    const [spreadConfigList, setSpreadConfigList] = useState([])
    
    const [ProjectsLoading,setProjectsLoading] =useState(false)
    const [diameterList, setDiameterList] = useState([])
  
   // const [formData, setFormData] = useState(projectDetails);

    const tableNames = {

        Project_Name: 'Project_Name',
        Contractor: 'Contractor',
        Countries_List: 'country',
        Representative_List: 'Respresentative',
        Spread_Config_List: 'Spread_Config',
        Pipe_Grade_List: 'Pipe_Grade',
        Spreads_List:'Spreads'
        // Size_Parts: 'Diameter',
    }
   

    


    const getPipeDiaRateValue = (dataArray, projectType, diameter) => {
        const discountFactors = dataArray
            .filter(item => item.Diameter === diameter)
            .map(item => item[projectType]);

        // Since filter might return multiple results, you can choose the first result if needed
        const discountFactor = discountFactors.length > 0 ? Number(discountFactors[0]) : 0;
        return discountFactor
    }

    const getSpreadFactorValue = (dataArray, name) => {
        const factor = dataArray
            .filter(item => item.Spread_Config === name)
            .map(item => item['Spreads']);

        // Since filter might return multiple results, you can choose the first result if needed
        const discountFactor = factor.length > 0 ? Number(factor[0]) : 1;
        console.log(dataArray, discountFactor, factor)
        return discountFactor
    }
    // const handleOk = async () => {


    //     if (formData['Proposal_Number'] == '') {
    //         notification.warning({
    //             message: 'Enter the Quote Number  to proceed!'
    //         });
    //         return;
    //     }
    //     if (formData['No_of_Joints'] == 0) {
    //         notification.warning({
    //             message: 'Enter No. of Welds to proceed!'
    //         });
    //         return;
    //     }
    //     if (formData['Pipe_OD'] == '') {
    //         notification.warning({
    //             message: 'Select Diameter to proceed!'
    //         });
    //         return;
    //     }

    //     if (formData['Spread_Config'] == '') {
    //         notification.warning({
    //             message: 'Select Spread Configuration  to proceed!'
    //         });
    //         return;
    //     }


    //     if (formData['Length_in_Kms'] == 0) {
    //         notification.warning({
    //             message: 'Enter Length to proceed!'
    //         });
    //         return;
    //     }
    //     if (isEdit) {
    //         formData['ID'] = editRecord.ID
    //         formData['Updated_Date'] = moment().format('YYYY-MM-DD').toString()
    //     }
    //     else {

    //         formData['Created_Date'] = moment().format('YYYY-MM-DD').toString()
    //         formData['Updated_Date'] = moment().format('YYYY-MM-DD').toString()
    //     }
       
    //     formData['isEdit'] = isEdit

    //     const dia = formData['Pipe_OD']
    //     if (dia && dia != 0 && pipeDiaRateList.length > 0) {
    //         let pipeRate =pipeDiaRateList
    //         if(pipeDiaRateList.length == 0)
    //         {
    //             pipeRate = await getListFromDatabase('PipeDiaRate')
    //            setPipeDiaRateList(pipeRate)
    //         }
    //         //formData['Pipe_OD'] = `${dia} inch`
    //         const Project_diameter_Rate = getPipeDiaRateValue(pipeRate, formData['Project_Type'], formData['Pipe_OD'])
    //         //pipeDiaRateList[0][formData['Project_Type']][formData['Pipe_OD']]
    //         console.log('Project_diameter_Rate', Project_diameter_Rate)
    //         formData['Project_diameter_Rate'] = Project_diameter_Rate ?? 0
    //     }

    //     const factor1 = getSpreadFactorValue(spreadConfigList, formData['Spread_Config'])

    //     const spreadConfig =
    //     {
    //         Name: formData['Spread_Config'],
    //         Factor: factor1
    //     }

    
    //     formData['Spread_Configuration'] = spreadConfig
    //     console.log('Project data', formData)

    //     setProjectsLoading(true)
    //   //  const res = await AddOrUpdateProject(formData, isEdit,true)
    //    // console.log(res)
    //     setProjectDetails(formData)
    //     setProjectsLoading(false)
    

    // };


    // const handleCancel = () => {
    //     projectDetailForm.resetFields();
    //     setIsEdit(false)

    //     setFormData({})
    //     onClose();
    //     setIsModalVisible(false)

    // };
    const onValuesChange = (changedValues, allValues) => {
     
        Object.keys(changedValues).forEach((name) => {
            if (name == 'Spread_Config' || name == 'No_of_Joints' || name === 'Daily_Production_Rate' || name === 'Pipe_OD' || name === 'Power_Supplies' || name == 'Equipment_Type')
                setIsProjectdetailChanged(true)
        }); 
       
    };


    const handleInputChange = (name, value) => {
        


        setFormData((prevData) => ({ ...prevData, [name]: value }));

    };
   
    const handleUnitsChange = (type, value) => {
        //setIsInputfeildChanged(true)
        const category = 'Units'
        setFormData((prevOptions) => {
            const updatedCategory = { ...prevOptions[category] };
            updatedCategory[type] = value;


            return {
                ...prevOptions,
                [category]: updatedCategory,
            };
        });
    }

    const handlePowerSupliesChange = (type, value) => {
        //setIsInputfeildChanged(true)
        const category = 'Power_Supplies'
        setFormData((prevOptions) => {
            const updatedCategory = { ...prevOptions[category] };
            updatedCategory[type] = value;


            return {
                ...prevOptions,
                [category]: updatedCategory,
            };
        });
    }

    const getCategoryUnitsValue1 = (type) => {
        const category = 'Units'
        if (formData.hasOwnProperty(category)) { // Check if the category exists in selectedOptions
            return formData[category][type]; // Return the value for the category
        } else {
            return type == 'length' ? 'KM' : 'no'; // Return null if the category does not exist
        }
    };

    const setAdddnewItem = (event) => {
        setNewItem(event.target.value);
        // console.log(categoryType)
    };

    const addItemToCategoryList = (type, newItem) => {
        const key = tableNames[type];

        if (key) {
            setCategoryListdata(prevData => ({
                ...prevData,
                [type]: [...prevData[type], newItem]
            }));
        } else {
            console.error(`Key "${key}" is not found in categoryListdata.`);
        }
    };

    const addItem = async (type, newItem) => {
       // console.log(type, newItem, categoryListdata)
        if (newItem) {

            const newItem1 = { [tableNames[type]]: newItem }; // Example item
            addItemToCategoryList(type, newItem1);
            const obj = {
                tableName: type,
                data: [{
                    [tableNames[type]]: newItem
                }]
            };
            setNewItem('')
            console.log(obj);
            // Uncomment and implement this function
            await AddOrUpdateMasterData(obj)

        
        }


    };

    const removeItem = (e) => {
        e.preventDefault();
        setNewItem('');
    }

    const handleQuarterChange = (date, dateString) => {

        setFormData(prevData => ({ ...prevData, EstimatedStart: dateString })); // Update the formData with the formatted value
    };
    return (
        <div style={{width:'95%'}}  className="pipeline-modal trackrecord-modal Tracker-modal">
            <Form form={projectDetailForm}
                name="project_form"
                layout="vertical"
                autoComplete="off"
                getcontainer={false}
                onValuesChange={onValuesChange}
                //width={100}
                className="pipeline-modal trackrecord-modal Tracker-modal"
            >
                <Row   gutter={24}>
                <Col lg={12}>
                        <Form.Item name="Project_Name" label="Project Name" required>

                            <Select className='maintenance-select' placeholder={'Select category'} style={{ marginTop: '-5px' }}
                                onChange={(value) => handleInputChange('Project_Name', value)} size="large"
                                showSearch
                                allowClear
                                dropdownRender={menu => (
                                    <>
                                        {menu}
                                        <Divider style={{ margin: '8px 0', borderTop: '1px solid lightgrey' }} />
                                        <Space align="center" style={{ padding: '0 8px 4px' }}>
                                            <Input placeholder="Add Category" value={newItem} onChange={setAdddnewItem} />
                                            <Typography.Link onClick={(e) => addItem('Project_Name', newItem)} style={{ whiteSpace: 'nowrap', color: "green" }}>
                                                <CheckOutlined />
                                            </Typography.Link>
                                            <Typography.Link onClick={removeItem} style={{ whiteSpace: 'nowrap', color: "red" }}>
                                                <CloseOutlined />
                                            </Typography.Link>
                                        </Space>
                                    </>
                                )}


                            >

                                {categoryListdata.Project_Name &&
                                    categoryListdata.Project_Name
                                        //.sort((a, b) => a.material.localeCompare(b.ProProject_Nameject)) // Sort by category
                                        .map((item, i) => (
                                            <Option key={i} value={item.Project_Name}> {/* Use item.category as value */}
                                                {item.Project_Name}
                                            </Option>
                                        ))
                                }
                            </Select>
                        </Form.Item>
                    </Col>


                <Col lg={12}>
                        <Form.Item name="Spreads" label="Spreads" required>

                            <Select className='maintenance-select' placeholder={'Select category'} style={{ marginTop: '-5px' }}
                                onChange={(value) => handleInputChange('Spreads', value)} size="large"
                                showSearch
                                allowClear
                                dropdownRender={menu => (
                                    <>
                                        {menu}
                                        <Divider style={{ margin: '8px 0', borderTop: '1px solid lightgrey' }} />
                                        <Space align="center" style={{ padding: '0 8px 4px' }}>
                                            <Input placeholder="Add Category" value={newItem} onChange={setAdddnewItem} />
                                            <Typography.Link onClick={(e) => addItem('Spreads_List', newItem)} style={{ whiteSpace: 'nowrap', color: "green" }}>
                                                <CheckOutlined />
                                            </Typography.Link>
                                            <Typography.Link onClick={removeItem} style={{ whiteSpace: 'nowrap', color: "red" }}>
                                                <CloseOutlined />
                                            </Typography.Link>
                                        </Space>
                                    </>
                                )}


                            >

                                {categoryListdata.Spreads_List &&
                                    categoryListdata.Spreads_List
                                        //.sort((a, b) => a.material.localeCompare(b.ProProject_Nameject)) // Sort by category
                                        .map((item, i) => (
                                            <Option key={i} value={item.Spreads}> {/* Use item.category as value */}
                                                {item.Spreads}
                                            </Option>
                                        ))
                                }
                            </Select>
                        </Form.Item>
                    </Col>

                    <Col lg={12}>
                        <Form.Item name="Contractor" label="Contractor" required>

                            <Select className='maintenance-select' placeholder={'Select category'} style={{ marginTop: '-5px' }}
                                onChange={(value) => handleInputChange('Contractor', value)} size="large"
                                showSearch
                                allowClear

                                dropdownRender={menu => (
                                    <>
                                        {menu}
                                        <Divider style={{ margin: '8px 0', borderTop: '1px solid lightgrey' }} />
                                        <Space align="center" style={{ padding: '0 8px 4px' }}>
                                            <Input placeholder="Add Category" value={newItem} onChange={setAdddnewItem} />
                                            <Typography.Link onClick={(e) => addItem('Contractor', newItem)} style={{ whiteSpace: 'nowrap', color: "green" }}>
                                                <CheckOutlined />
                                            </Typography.Link>
                                            <Typography.Link onClick={removeItem} style={{ whiteSpace: 'nowrap', color: "red" }}>
                                                <CloseOutlined />
                                            </Typography.Link>
                                        </Space>
                                    </>
                                )}


                            >

                                {categoryListdata.Contractor &&
                                    categoryListdata.Contractor
                                        //.sort((a, b) => a.material.localeCompare(b.Contractor)) // Sort by category
                                        .map((item, i) => (
                                            <Option key={i} value={item.Contractor}> {/* Use item.category as value */}
                                                {item.Contractor}
                                            </Option>
                                        ))
                                }
                            </Select>
                        </Form.Item>
                    </Col>


                    <Col lg={12} >
                        <Form.Item name="Proposal_Number" label="Quote Number" required
                        // rules={[{ required: true, message: 'Please input the project!' }]}
                        >
                            <Input onChange={(e) => handleInputChange('Proposal_Number', e.target.value)} size="large" />
                        </Form.Item>
                    </Col>

                    <Col lg={12}>
                        <Form.Item name="Representative" label="Representative">

                            <Select className='maintenance-select' placeholder={'Select category'} style={{ marginTop: '-5px' }}
                                onChange={(value) => handleInputChange('Representative', value)} size="large"
                                showSearch
                                allowClear

                                dropdownRender={menu => (
                                    <>
                                        {menu}
                                        <Divider style={{ margin: '8px 0', borderTop: '1px solid lightgrey' }} />
                                        <Space align="center" style={{ padding: '0 8px 4px' }}>
                                            <Input placeholder="Add Category" value={newItem} onChange={setAdddnewItem} />
                                            <Typography.Link onClick={(e) => addItem('Representative_List', newItem)} style={{ whiteSpace: 'nowrap', color: "green" }}>
                                                <CheckOutlined />
                                            </Typography.Link>
                                            <Typography.Link onClick={removeItem} style={{ whiteSpace: 'nowrap', color: "red" }}>
                                                <CloseOutlined />
                                            </Typography.Link>
                                        </Space>
                                    </>
                                )}

                            >
                                {categoryListdata.Representative_List &&
                                    categoryListdata.Representative_List
                                        //.sort((a, b) => a.diameter.localeCompare(b.diameter)) // Sort by category
                                        .map((item, i) => (
                                            <Option key={i} value={item.Respresentative}> {/* Use item.category as value */}
                                                {item.Respresentative}
                                            </Option>
                                        ))
                                }

                            </Select>
                        </Form.Item>
                    </Col>



                   



             

                    <Col lg={12}>
                        <Form.Item name="Location" label="Location">
                            <Select className='maintenance-select' placeholder={'Select category'} style={{ marginTop: '-5px' }}
                                onChange={(value) => handleInputChange('Location', value)} size="large"
                                showSearch
                                allowClear

                                dropdownRender={menu => (
                                    <>
                                        {menu}
                                        <Divider style={{ margin: '8px 0', borderTop: '1px solid lightgrey' }} />
                                        <Space align="center" style={{ padding: '0 8px 4px' }}>
                                            <Input placeholder="Add Category" value={newItem} onChange={setAdddnewItem} />
                                            <Typography.Link onClick={(e) => addItem('Countries_List', newItem)} style={{ whiteSpace: 'nowrap', color: "green" }}>
                                                <CheckOutlined />
                                            </Typography.Link>
                                            <Typography.Link onClick={removeItem} style={{ whiteSpace: 'nowrap', color: "red" }}>
                                                <CloseOutlined />
                                            </Typography.Link>
                                        </Space>
                                    </>
                                )}

                            >
                                {categoryListdata.Countries_List &&
                                    categoryListdata.Countries_List
                                        .sort((a, b) => a.country.localeCompare(b.country)) // Sort by category
                                        .map((item, i) => (
                                            <Option key={i} value={item.country}> {/* Use item.category as value */}
                                                {item.country}
                                            </Option>
                                        ))
                                }

                            </Select>
                        </Form.Item>
                    </Col>




                    <Col lg={12}>
                        <Form.Item name="No_of_Joints" label="Number of Welds" required>
                            <Input onChange={(e) => handleInputChange('No_of_Joints', Number(e.target.value))} min={0} size="large" type='number' />
                        </Form.Item>
                    </Col>
                    <Col lg={12}>
                        <Form.Item name="Pipe_Grade" label="Pipe Grade">
                            <Select className='maintenance-select' placeholder={'Select category'} style={{ marginTop: '-5px' }}
                                showSearch onChange={(value) => handleInputChange('Pipe_Grade', value)} size="large"
                                allowClear

                                dropdownRender={menu => (
                                    <>
                                        {menu}
                                        <Divider style={{ margin: '8px 0', borderTop: '1px solid lightgrey' }} />
                                        <Space align="center" style={{ padding: '0 8px 4px' }}>
                                            <Input placeholder="Add Category" value={newItem} onChange={setAdddnewItem} />
                                            <Typography.Link onClick={(e) => addItem('Pipe_Grade_List', newItem)} style={{ whiteSpace: 'nowrap', color: "green" }}>
                                                <CheckOutlined />
                                            </Typography.Link>
                                            <Typography.Link onClick={removeItem} style={{ whiteSpace: 'nowrap', color: "red" }}>
                                                <CloseOutlined />
                                            </Typography.Link>
                                        </Space>
                                    </>
                                )}

                            >
                                {categoryListdata.Pipe_Grade_List &&
                                    categoryListdata.Pipe_Grade_List
                                        //.sort((a, b) => a.diameter.localeCompare(b.diameter)) // Sort by category
                                        .map((item, i) => (
                                            <Option key={i} value={item.Pipe_Grade}> {/* Use item.category as value */}
                                                {item.Pipe_Grade}
                                            </Option>
                                        ))
                                }

                            </Select>
                        </Form.Item>
                    </Col>

                    <Col lg={12}>
                        <Form.Item name="Pipe_Length" label="Pipe Length (Feet)">
                            <Input defaultValue={40} onChange={(e) => handleInputChange('Pipe_Length', Number(e.target.value))} size="large" type='number' />
                        </Form.Item>
                    </Col>
                    <Col lg={12}>
                        <Form.Item name="Pipe_OD" label="Diameter" required>
                            <Select className='maintenance-select' placeholder={'Select category'} size="large" style={{ marginTop: '-5px' }}
                                showSearch onChange={(value) => handleInputChange('Pipe_OD', value)}
                                allowClear
                                value={formData.Pipe_OD}

                            // dropdownRender={menu => (
                            //     <>
                            //         {menu}
                            //         <Divider style={{ margin: '8px 0', borderTop: '1px solid lightgrey' }} />
                            //         <Space align="center" style={{ padding: '0 8px 4px' }}>
                            //             <Input placeholder="Add Category" value={newItem} onChange={setAdddnewItem} />
                            //             <Typography.Link onClick={(e)=>addItem('diameterList',newItem)} style={{ whiteSpace: 'nowrap', color: "green" }}>
                            //                 <CheckOutlined />
                            //             </Typography.Link>
                            //             <Typography.Link onClick={removeItem} style={{ whiteSpace: 'nowrap', color: "red" }}>
                            //                 <CloseOutlined />
                            //             </Typography.Link>
                            //         </Space>
                            //     </>
                            // )}

                            >

                                {diameterList &&
                                    diameterList
                                        //.sort((a, b) => a.diameter.localeCompare(b.diameter)) // Sort by category
                                        .map((item, i) => (
                                            <Option key={i} value={item.Diameter}> {/* Use item.category as value */}
                                                {item.Diameter}
                                            </Option>
                                        ))
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col lg={12}>
                        <Form.Item name="Daily_Production_Rate" label="Daily Production Rate">
                            <Input onChange={(e) => handleInputChange('Daily_Production_Rate', Number(e.target.value))} min={0} size="large" type='number' />
                        </Form.Item>
                    </Col>

                    <Col lg={12}>
                        <Form.Item name="Spread_Config" label="Spread Configuration"  required>
                            <Select className='maintenance-select' placeholder={'Select category'} style={{ marginTop: '-5px' }}
                                showSearch onChange={(value) => handleInputChange('Spread_Config', value)} size="large"
                                allowClear

                            // dropdownRender={menu => (
                            //     <>
                            //         {menu}
                            //         <Divider style={{ margin: '8px 0', borderTop: '1px solid lightgrey' }} />
                            //         <Space align="center" style={{ padding: '0 8px 4px' }}>
                            //             <Input placeholder="Add Category" value={newItem} onChange={setAdddnewItem} />
                            //             <Typography.Link onClick={(e)=>addItem('Spread_Config_List',newItem)} style={{ whiteSpace: 'nowrap', color: "green" }}>
                            //                 <CheckOutlined />
                            //             </Typography.Link>
                            //             <Typography.Link onClick={removeItem} style={{ whiteSpace: 'nowrap', color: "red" }}>
                            //                 <CloseOutlined />
                            //             </Typography.Link>
                            //         </Space>
                            //     </>
                            // )}

                            >
                                {categoryListdata.Spread_Config_List &&
                                    categoryListdata.Spread_Config_List
                                        //.sort((a, b) => a.diameter.localeCompare(b.diameter)) // Sort by category
                                        .map((item, i) => (
                                            <Option key={i} value={item.Spread_Config}> {/* Use item.category as value */}
                                                {item.Spread_Config}
                                            </Option>
                                        ))
                                }

                            </Select>
                        </Form.Item>
                    </Col>
                  
                    <Col lg={12}>
                        <Form.Item name="Length_in_Kms" label="Length" required>

                            <div style={{
                                border: '1px solid #d9d9d9',
                                padding: '5px',
                                borderRadius: '5px',
                                display: 'flex',
                                alignItems: 'center',  // Center vertically
                                marginTop: '-8px'

                            }}>

                                <Input style={{ flex: '0 0 45%' }}
                                    value={formData.Length_in_Kms}
                                    onChange={(e) => handleInputChange('Length_in_Kms', Number(e.target.value))}
                                    size="large"
                                    type='number' />
                                <div className='report-radio' style={{ flex: '0 0 40%', paddingLeft: '5px' }}>
                                    <Radio.Group style={{ display: "flex", }}
                                        value={getCategoryUnitsValue1('Length_in_Kms')}
                                        onChange={(e) => {
                                            handleUnitsChange('Length_in_Kms', e.target.value)

                                        }}
                                    >
                                        <Radio value={'KM'}>KM</Radio>
                                        <Radio value={'Miles'}>Miles</Radio>
                                    </Radio.Group>
                                </div>

                            </div>

                        </Form.Item>
                    </Col>

                    <Col lg={12}>
                        <Form.Item name="Wall_thickness_in_mm" label="Wall Thickness" >
                            <div style={{
                                border: '1px solid #d9d9d9',
                                padding: '5px',
                                borderRadius: '5px',
                                display: 'flex',
                                alignItems: 'center',  // Center vertically
                                marginTop: '-8px'

                            }}>

                                <Input style={{ flex: '0 0 45%' }}
                                    value={formData.Wall_thickness_in_mm}
                                    onChange={(e) => handleInputChange('Wall_thickness_in_mm', Number(e.target.value))}
                                    size="large"
                                    type='number' />
                                <div className='report-radio' style={{ flex: '0 0 40%', paddingLeft: '5px' }}>
                                    <Radio.Group style={{ display: "flex", }}
                                        value={getCategoryUnitsValue1('Wall_thickness_in_mm')}
                                        onChange={(e) => {
                                            handleUnitsChange('Wall_thickness_in_mm', e.target.value)

                                        }}
                                    >
                                        <Radio value={'mm'}>mm</Radio>
                                        <Radio value={'inch'}>inch</Radio>
                                    </Radio.Group>
                                </div>
                            </div>

                        </Form.Item>
                    </Col>
                    <Col lg={12}>
                        <Form.Item name="Project_Type" label=" Project Type" className='report-radio' required>
                            <div>
                                <Radio.Group
                                    value={formData.Project_Type}

                                    onChange={(e) => handleInputChange('Project_Type', e.target.value)}>
                                    <Radio value={"Onshore"} style={{ paddingTop: '5px' }}>Onshore</Radio>
                                    <Radio value={"Offshore"}>Offshore</Radio>
                                </Radio.Group>
                            </div>

                        </Form.Item>
                    </Col>



                    <Col lg={12}>
                        <Form.Item name="EstimatedStart" label="Estimated Start">
                            <div className='date-picker'>
                                <DatePicker
                                    // picker="year" // Picking by year, but we'll interpret it as quarters
                                    style={{ border: 'line', width: '100%' }}
                                    value={formData.EstimatedStart ? moment(formData.EstimatedStart, 'YYYY-[Q]Q') : null}
                                    onChange={handleQuarterChange}
                                    picker={"quarter"}
                                />
                            </div>
                        </Form.Item>
                    </Col>
                    <Col lg={12}>
                        <Form.Item name="Status" label="Status">
                            <Select className='maintenance-select' placeholder={'Select Status'} style={{ marginTop: '-5px' }}
                                showSearch onChange={(value) => handleInputChange('Status', value)} size="large"
                                allowClear
                            >
                                {statuslist &&
                                    statuslist
                                        //.sort((a, b) => a.diameter.localeCompare(b.diameter)) // Sort by category
                                        .map((item, i) => (
                                            <Option key={i} value={item}> {/* Use item.category as value */}
                                                {item}
                                            </Option>
                                        ))
                                }

                            </Select>
                        </Form.Item>
                    </Col>
                    <Col lg={12}>
                        <Form.Item name="Equipment_Type" label="Equipment Type" className='report-radio' required>
                            <div>                                <Radio.Group
                                value={formData.Equipment_Type}
                                onChange={async (e) => {
                                    await handleInputChange('Pipe_OD', '')
                                    const editData = {
                                        Pipe_OD: '',
                                    };
                                
                                    projectDetailForm.setFieldsValue(editData)
                                    handleInputChange('Equipment_Type', e.target.value);
                                    //setEquipmentType(e.target.value);
                                    //await UpdateDiameterList(e.target.value)
                                  
                                }}>
                                <Radio value={"IWM"} style={{ paddingTop: '5px' }}>IWM</Radio>
                                <Radio value={"CBUC"}>CBUC</Radio>
                            </Radio.Group>
                            </div>

                        </Form.Item>
                    </Col>
                    <Col lg={8}>
                        <Form.Item name="Source_Type" label="Proposal Type" className='report-radio'>
                          
                            <Select className='maintenance-select' placeholder={'Select Source'} style={{ marginTop: '-5px' }}
                                showSearch onChange={(value) => handleInputChange('Source_Type', value)} size="large"
                                allowClear
                            >
                                {sourcetype &&
                                    sourcetype
                                        //.sort((a, b) => a.diameter.localeCompare(b.diameter)) // Sort by category
                                        .map((item, i) => (
                                            <Option key={i} value={item}> 
                                                {item}
                                            </Option>
                                        ))
                                }

                            </Select>

                        </Form.Item>
                    </Col>
                   
                    <Col lg={16}>
                        <Form.Item name="Power_Supplies" label="Power Supplies" className='report-radio'>
                            <div>

                                <Checkbox value={"Miller XMT 350"}
                                    onChange={(e) => {
                                        handlePowerSupliesChange('Miller XMT 350', e.target.checked ? 'True' : 'False');
                                    }} style={{ paddingTop: '5px' }}
                                    checked={formData.Power_Supplies['Miller XMT 350'] == 'True' ? true : false}
                                >Miller XMT 350</Checkbox>
                                <Checkbox value={"Lincoln"}

                                    checked={formData.Power_Supplies['Lincoln'] == 'True' ? true : false}
                                    onChange={(e) => {

                                        handlePowerSupliesChange('Lincoln', e.target.checked ? 'True' : 'False');

                                    }}>Lincoln</Checkbox>
                                <Checkbox value={"Fronius"} style={{ paddingTop: '5px' }}
                                    checked={formData.Power_Supplies['Fronius'] == 'True' ? true : false}
                                    onChange={(e) => {

                                        handlePowerSupliesChange('Fronius', e.target.checked ? 'True' : 'False');

                                    }}>Fronius</Checkbox>

                            </div>

                        </Form.Item>
                    </Col>


                </Row>
            </Form>
            </div>
    );
};

export default DrawerPage;
