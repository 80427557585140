import { notification } from 'antd';
import get from 'lodash/get';



import axiosConfig from './axiosConfig';







export const getListFromDatabase = async (tableName) => {
  try {
    let url;
    url = `/GetList?Table_Name=${(tableName)}`;


    const res = await axiosConfig.get(url);
    if (res.status === 403) {
      notification.error({
        message: 'Unauthorized',
      });
      return null;
    } if (res.status === 200) {
      const data = get(res, ['data', 'data'], {});
      //console.log('testType', data)
      return data;
    }
    return [];
  } catch (error) {
    return [];
  }
};

export const AddOrUpdateConfigData = async (data, isNotify = true) => {


  try {
    const res = await axiosConfig.post('/UploadConfig', data);

    notification.destroy();
    // console.log(yield res.json)
    if (res.status === 200) {
      const responseData = get(res, ['data', 'data'], {});
      if (isNotify)
        notification.success({
          message: 'added Successfully.',
          duration: 3,
        });

      return responseData

    }

    else {

      notification.error({
        message: 'Something went wrong.',
      });
      return null

    }


  } catch (error) {

    notification.destroy();
    notification.error({
      message: 'Update Submission Failed.',
      description: `${error}`,
    });
    return null

  }
}

export const DeleteMasterDetails = async (data) => {

  try {
    const resmaster = await axiosConfig.post('/DeleteMaster', data);
    //console.log(resmaster)
    if (resmaster.status === 200) {
      notification.success({
        message: 'Deleted Successfully.',
        duration: 3,
      });
      return true
    }
    else {

      notification.error({
        message: 'Something went wrong.',
      });
      return false
    }


  } catch (error) {

    notification.destroy();
    notification.error({
      message: 'Delete Submission Failed.',
      description: `${error}`,
    });
    return false

  }
}

export const GetRecordListExcel = async (tableName) => {
  try {
    let url;
    url = `/GetRecordListExcel?Table_Name=${(tableName)}`;


    const res = await axiosConfig.get(url);
    if (res.status === 403) {
      notification.error({
        message: 'Unauthorized',
      });
      return null;
    } if (res.status === 200) {
      const data = get(res, ['data', 'data'], {});
      //console.log('testType', data)
      return data;
    }
    return [];
  } catch (error) {
    return [];
  }
};