import { FETCHPRICINGLIST, FETCHPRICINGLIST_FAILURE, FETCHPRICINGLIST_SUCCESS, FETCH_PRICING_LIST, FETCH_PRICING_LIST_SUCCESS, FETCH_PRICING_LIST_FAILURE } from './types';



const fetchPricingdList = (payload) => ({
  type: FETCHPRICINGLIST,
  ...payload
});
const fetchPricingListSuccess = (payload) => ({
  type: FETCHPRICINGLIST_SUCCESS,
  ...payload
});
const fetchPricingListFailure = () => ({
  type: FETCHPRICINGLIST_FAILURE
});

const fetchPricingListData = (payload) => ({
  type: FETCH_PRICING_LIST,
  ...payload
});
const fetchPricingListDataSuccess = (payload) => ({
  type: FETCH_PRICING_LIST_SUCCESS,
  ...payload
});
const fetchPricingListDataFailure = () => ({
  type: FETCH_PRICING_LIST_FAILURE
});


export {
  fetchPricingdList, fetchPricingListSuccess, fetchPricingListFailure, fetchPricingListData, fetchPricingListDataSuccess, fetchPricingListDataFailure
};