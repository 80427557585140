import {GENERATESPAREPARTS,GENERATESPAREPARTS_SUCCESS,GENERATESPAREPARTS_FAILURE, FETCHSPAREPARTSLIST, FETCHSPAREPARTSLIST_SUCCESS, FETCHSPAREPARTSLIST_FAILURE, DELETESPAREPARTS, DELETESPAREPARTS_SUCCESS, DELETESPAREPARTS_FAILURE, FETCHSPAREPARTSMASTERLIST, FETCHSPAREPARTSLISTMASTER_SUCCESS, FETCHSPAREPARTSLISTMASTER_FAILURE, UPLOADMASTERSPAREPARTS, UPLOADMASTERSPAREPARTS_SUCCESS, UPLOADMASTERSPAREPARTS_FAILURE, FETCHSPAREPARTSMASTERLISTP, FETCHSPAREPARTSLISTMASTERP_SUCCESS, FETCHSPAREPARTSLISTMASTERP_FAILURE, GENERATEMASTERSPAREPARTS, GENERATEMASTERSPAREPARTS_SUCCESS, GENERATEMASTERSPAREPARTS_FAILURE, DELETESMASTERLIST, DELETESMASTERLIST_SUCCESS, DELETESMASTERLIST_FAILURE
} from './types';

const generateSparePartsAction = (payload) => ({
    type: GENERATESPAREPARTS, ...payload,
  });
  const generateSparePartsSuccess = (payload) => ({
    type: GENERATESPAREPARTS_SUCCESS,
    ...payload
  });
  const generateSparePartsFailure = () => ({
    type: GENERATESPAREPARTS_FAILURE
  });
  const deleteSparePartsAction = (payload) => ({
    type: DELETESPAREPARTS, ...payload,
  });
  const deleteSparePartsSuccess = (payload) => ({
    type: DELETESPAREPARTS_SUCCESS,
    ...payload
  });
  const deleteSparePartsFailure = () => ({
    type: DELETESPAREPARTS_FAILURE
  });
  const fetchSparePartsList = (payload) => ({
    type: FETCHSPAREPARTSLIST,
    ...payload
  });
  const fetchSparePartsListSuccess = (payload) => ({
    type: FETCHSPAREPARTSLIST_SUCCESS,
    ...payload
  });
  const fetchSparePartsListFailure = () => ({
    type: FETCHSPAREPARTSLIST_FAILURE
  });
  const fetchSparePartsMasterList = (payload) => ({
    type: FETCHSPAREPARTSMASTERLIST,
    ...payload
  });
  const fetchSparePartsMasterListSuccess = (payload) => ({
    type: FETCHSPAREPARTSLISTMASTER_SUCCESS,
    ...payload
  });
  const fetchSparePartsMasterListFailure = () => ({
    type: FETCHSPAREPARTSLISTMASTER_FAILURE
  });
  const uploadMsterSparePartsAction = (payload) => ({
    type: UPLOADMASTERSPAREPARTS, ...payload,
  });
  const uploadMsterSparePartsSuccess = (payload) => ({
    type: UPLOADMASTERSPAREPARTS_SUCCESS,
    ...payload
  });
  const uploadMsterSparePartsFailure = () => ({
    type: UPLOADMASTERSPAREPARTS_FAILURE
  });
  const fetchSparePartsMasterListPage = (payload) => ({
    type: FETCHSPAREPARTSMASTERLISTP,
    ...payload
  });
  const fetchSparePartsMasterListSuccessPage = (payload) => ({
    type: FETCHSPAREPARTSLISTMASTERP_SUCCESS,
    ...payload
  });
  const fetchSparePartsMasterListFailurePage = () => ({
    type: FETCHSPAREPARTSLISTMASTERP_FAILURE
  });

  const generateMasterSparePartsAction = (payload) => ({
    type: GENERATEMASTERSPAREPARTS, ...payload,
  });
  const generateMasterSparePartsSuccess = (payload) => ({
    type: GENERATEMASTERSPAREPARTS_SUCCESS,
    ...payload
  });
  const generateMasterSparePartsFailure = () => ({
    type: GENERATEMASTERSPAREPARTS_FAILURE
  });
  const deleteMasterList = (payload) => ({
    type: DELETESMASTERLIST, ...payload,
  });
  const deleteMasterListSuccess = (payload) => ({
    type: DELETESMASTERLIST_SUCCESS,
    ...payload
  });
  const deleteMasterListFailure = () => ({
    type: DELETESMASTERLIST_FAILURE
  });

  export {
    generateSparePartsAction,generateSparePartsSuccess,generateSparePartsFailure,
    fetchSparePartsList,fetchSparePartsListSuccess,fetchSparePartsListFailure,
    deleteSparePartsAction,deleteSparePartsSuccess,deleteSparePartsFailure,
    fetchSparePartsMasterList,fetchSparePartsMasterListSuccess,fetchSparePartsMasterListFailure,
    uploadMsterSparePartsAction,uploadMsterSparePartsSuccess,uploadMsterSparePartsFailure,
    fetchSparePartsMasterListPage,fetchSparePartsMasterListSuccessPage,fetchSparePartsMasterListFailurePage,
    generateMasterSparePartsAction,generateMasterSparePartsSuccess,generateMasterSparePartsFailure,
    deleteMasterList,deleteMasterListSuccess,deleteMasterListFailure
  };