/* eslint-disable linebreak-style */
/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
/* eslint-disable prefer-const */
/* eslint-disable comma-spacing */
/* eslint-disable quotes */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { ArrowLeftOutlined, RightCircleOutlined } from '@ant-design/icons';
import 'antd/dist/antd.dark.css';
import { Table, Tabs, notification, Tooltip } from 'antd';
import { useHistory } from 'react-router-dom';

const { TabPane } = Tabs;

const Checks2CommercialTabs = () => {
    const history = useHistory();
    const [group, setGroup] = useState(false);
    const [currentSection, setCurrentSection] = useState('1');

    const onChange = (key) => {
        setCurrentSection(key);
    };

    return (
        <div className="h-100 d-flex flex-column">
            <div className='back_equip' style={{ marginBottom: '5px' }}>
                <Tooltip title={'Back'} >
                    <ArrowLeftOutlined onClick={(e) => {
                        history.push('/project-margin');
                    }} />
                </Tooltip>
            </div>
            <div className="flex-fill">
                <Tabs
                    className="custom-tab h-100"
                    onChange={onChange}
                >
                    <TabPane tab="New Projects" key="1">
                        {/* <PmYoyTab /> */}
                    </TabPane>
                    <TabPane tab="New WBS" key="2">
                        {/* <PmYoyTab /> */}
                    </TabPane>
                    <TabPane tab="Channel CoCd" key="3">
                        {/* <PmYoyTab /> */}
                    </TabPane>
                </Tabs>
            </div>
        </div>
    );
};

export default Checks2CommercialTabs