/* eslint-disable no-param-reassign */
/* eslint-disable react/no-unescaped-entities */
import {
    Button,
    Col,
    Form,
    Input, InputNumber,
    Modal,
    Radio,
    Row,
    notification
} from 'antd';
import React, { useEffect, useState } from 'react';

import moment from 'moment';
import { connect } from 'react-redux';
import { PROJECTNAME_PATTERN } from '../../utils/constants';
//const { Option } = Select;
const ProjectDetails = (props) => {

    const {
        setShowGenerateModel,
        showGenerateModel,
        showPipelineModel,
        setShowPipelineModel,
        pipelineData,
        setPipelineData,
        projectDetails,
        setProjectDetails,
        isEdit,
        setIsEdit,
        editRecord,
        projectDetailForm
    } = props;
    // const [projectDetailForm] = Form.useForm()

    //const [activeKey, setActiveKey] = useState('1')
    //const [count, setCount] = useState(1)
    //const [projectDetails, setProjectDetails] = useState(null);
    const [projectName, setProjectName] = useState('');
    const [projecrNumber, setproejctNumber] = useState('');
    const [noOfPipelines, setNoofPipelines] = useState(0);
    const [projectType, setProjectType] = useState('');
    const [isValid, setIsvalid] = useState(false);

    useEffect(() => {
        if (isEdit && editRecord) {
            // console.log(editRecord)
            setProjectName(editRecord.projectName)
            setproejctNumber(editRecord.projectNumber)
            setProjectType(editRecord.projectType)

            OnChangeNoOfPipelines(Number(editRecord.noOfPipelines));
            setNoofPipelines(Number(editRecord.noOfPipelines))
            const data = editRecord.pipelines_data
            if(data)
            {
            const updatedArray = data.map(item => ({
                col1: item.pipeSize,
                col2: item.wallThickness,
                col3: item.noOfWelds,
                col4: item.noOfStations
            }));
            setPipelineData(updatedArray)
        }
        }
        else
            OnChangeNoOfPipelines(1);
    }, [])



    const handleOk = async () => {
        setShowPipelineModel(true)
        setShowGenerateModel(false)
    };

    const handleCancel = async () => {
        projectDetailForm.resetFields();
        setproejctNumber('')
        setproejctNumber('')
        setProjectType('')

        //OnChangeNoOfPipelines(editRecord.noOfPipelines);
        setNoofPipelines(0)
        setPipelineData([]);
        setIsEdit(false)
        setShowGenerateModel(false)
    };

    const handleBlur = (v) => {

        if (v > 10) {

            notification.warning({
                message: 'No.of pipeline should not exceed  more than 10.',
            });

            // Reset the value to 10 if it exceeds the limit
            OnChangeNoOfPipelines(10);
        }

    };

    const OnChangeNoOfPipelines = (value) => {
        // console.log('pipeLine Value', value)
        if (!value)
            return

        if (value > 10) {
            notification.warning({
                message: 'No.of pipeline should not exceed  more than 10.',
            });

            // Reset the value to 10 if it exceeds the limit
            OnChangeNoOfPipelines(10);
        }
        // Create a copy of the current gridData
        let updatedGridData = [...pipelineData];

        if (value > noOfPipelines) {
            // If increasing the number of pipelines, add new items
            for (let index = noOfPipelines + 1; index <= value; index++) {
                const newItem = { col1: 0, col2: 0, col3: 0, col4: 0 };
                updatedGridData.push(newItem);
            }
        } else if (value < noOfPipelines) {
            // If decreasing the number of pipelines, remove items
            updatedGridData = updatedGridData.slice(0, value);
        }

        // Update state variables
        setNoofPipelines(value);
        setPipelineData(updatedGridData);
        //console.log('Pipeline data', pipelineData)
    };


    const handleInputChange = (row, col, value) => {
        //console.log(row, col, value)
        setPipelineData((prevData) => {
            // Create a new array with the updated data
            const newData = prevData.map((rowData, index) => {

                if (index === row) {
                    // If it's the target row, update the specific column
                    return {
                        ...rowData,
                        [col]: value,
                    };
                }
                // Otherwise, keep the row unchanged
                return rowData;
            });

            return newData;
        });
    };

    const ValidatePipelinedata = () => {
        let valid = false;
        if (pipelineData) {
            let validation = pipelineData.map(item => {
                const values = Object.values(item);
                return values.every(value => value !== 0);
            });

            valid = validation.every(valid => valid)
        }

        return valid && PROJECTNAME_PATTERN.test(projectName)
    }

    const GetInputRow = () => {
        const handleBlur = (row, col, value) => {
            // Ensure the value is not empty, set it to 0 if it is
            if (value === '') {
                handleInputChange(row, col, 0);
            }
        };

        return (
            <div>
                {
                    (pipelineData).map((row, index) => (
                        <div style={{ display: 'flex', paddingRight: '0px', paddingLeft: '0px' }} className="pipeline-modal">

                            <div style={{ alignContent: 'center', textAlign: 'center', paddingRight: '0px', paddingTop: '15px' }}>{index + 1}</div>

                            <InputNumber style={{ margin: '5px', alignItems: 'center', T: 'center' }}
                                value={row.col1}
                                changeOnWheel
                                min={0}
                                onBlur={(v) => handleBlur(index, 'col1', v.target.value)}
                                type="number"
                                onChange={(v) => handleInputChange(index, 'col1', v)} />
                            <InputNumber style={{ margin: '5px' }}
                                value={row.col2}
                                min={0}
                                onBlur={(v) => handleBlur(index, 'col2', v.target.value)}
                                type="number"
                                onChange={(v) => handleInputChange(index, 'col2', v)} />
                            <InputNumber style={{ margin: '5px' }}
                                value={row.col3}
                                type="number"
                                min={0}
                                onBlur={(v) => handleBlur(index, 'col3', v.target.value)}
                                onChange={(v) => handleInputChange(index, 'col3', v)} />
                            <InputNumber style={{ margin: '5px' }}
                                value={row.col4}
                                type="number"
                                min={0}
                                onBlur={(v) => handleBlur(index, 'col4', v.target.value)}
                                onChange={(v) => handleInputChange(index, 'col4', v)} />
                        </div>
                    ))
                }
            </div>
        )
    }
    const generatePipelineNames = (noOfPipelines) => {
        const pipelineNames = [];
        for (let i = 1; i <= noOfPipelines; i++) {
            pipelineNames.push(`Line_${i}`);
        }
        return pipelineNames;
    }
    const CreateProject = async () => {
        projectDetailForm
            .validateFields()
            .then(async (data) => {
                if (!data.projectName) {
                    notification.warning({
                        message: 'Enter the  Project Name  to proceed!'
                    });
                }

                else if ( !PROJECTNAME_PATTERN.test(data.projectName)) {
                    notification.warning({
                        message: 'Project name contains invalid characters. Please remove any of the following: < > : " / \\ | ? *'
                    });
                }
                else if (!projectType) {
                    notification.warning({
                        message: 'Enter the  Project Type  to proceed!' 
                    });
                }
                else {
                    const projectData = {
                        'projectName': data.projectName,
                        'projectNumber': data.projectNumber ?? ' ',
                        'projectType': projectType,
                        'noOfPipelines': noOfPipelines,
                        'noOfPipelinesList': generatePipelineNames(noOfPipelines),
                        'creationDate': moment().format('YYYY-MM-DD').toString(),
                        'updatedDate': moment().format('YYYY-MM-DD').toString()

                        //     ...pipelineData.map((row, index) => ({
                        //       [`Line_${index}`]: {
                        //         'PipeSize': row.col1,
                        //         'WallThickness': row.col2,
                        //         'NoofWelds': row.col3,
                        //         'NoofStations': row.col4,
                        //       }
                        //     }))
                    };

                    if (isEdit) {
                        projectData.isEdit = isEdit;
                        projectData.projectID = editRecord.projectID;
                        projectData.totalCost = editRecord.totalCost;
                        projectData.creationDate = moment(editRecord.creationDate).format('YYYY-MM-DD').toString()
                        projectData.updatedDate = moment().format('YYYY-MM-DD').toString()
                    }
                    setProjectDetails(projectData);

                    setShowPipelineModel(true)
                    setShowGenerateModel(false)
                }

            });
    }
    return (

        <div>
            <div>
                <Modal
                    width="50vw"

                    className="pipeline-modal"
                    title="Project Details"
                    centered
                    visible={showGenerateModel}
                    bodyStyle={{ height: 600 }}

                    footer={[
                        <div style={{ paddingTop: '10px', paddingRight: '0px' }}>
                            <Button key="cancel"
                                onClick={() => handleCancel()}
                            >
                                Cancel
                            </Button>

                            <Button key="apply" type="primary"
                                onClick={() => CreateProject()}
                                disabled={!ValidatePipelinedata()}

                            >
                                {isEdit ? 'Update Project' : 'Create Project'}
                            </Button>

                        </div>
                    ]}
                >
                    <div>
                        <Form
                            form={projectDetailForm}
                            name="basic"
                            layout="vertical"
                            autoComplete="off"
                            height='200px'

                        >
                            <Row gutter={16}>
                                <Col lg={6}>
                                    <Form.Item
                                        name="projectName"
                                        label="Project Name"
                                        required
                                        rules={[
                                            {
                                                pattern: true?
                                                     PROJECTNAME_PATTERN:undefined,
                                                     message: 'Project name contains invalid characters. Please remove any of the following: < > : " / \\ | ? *'
                                            },
                                            {
                                                
                                                type: 'string',
                                                min: 1,
                                                max: 50,
                                                message: 'Project Name must be less than 50 characters',
                                              //pattern: PROJECTNAME_PATTERN
                                            }
                                        ]}
                                    >
                                        <Input disabled={isEdit} placeholder="Project Name " style={{ height: '40px' }} onChange={(v)=>{setProjectName(v.target.value)}} />
                                    </Form.Item>
                                    <Form.Item
                                        name="projectNumber"
                                        label="Project Number"


                                    >
                                        <Input disabled={isEdit} placeholder="Project Number" style={{ height: '35px' }} />
                                    </Form.Item>
                                    <Form.Item

                                        label="No. of Pipelines"
                                        required
                                    >
                                        {/* <Select

                                className='client-select'
                                placeholder='Select Category'
                                defaultValue={1}
                                //  allowClear
                                optionFilterProp="children"
                                required
                                options={noofPipelines}
                                onChange={(value) => onChangeOnOfPipelines(value)}
                            >

                            </Select> */}
                                        <InputNumber
                                            changeOnWheel={OnChangeNoOfPipelines}
                                            defaultValue={1}
                                            min={1}
                                            max={10}
                                            value={noOfPipelines}
                                            type="number"
                                            onBlur={(value) => handleBlur(value.target.value)}
                                            size="large"
                                            onChange={(value) => OnChangeNoOfPipelines(value)}
                                        />

                                    </Form.Item>

                                    <Form.Item
                                        name="projectType"
                                        label="Project Type"
                                        required
                                        className='report-radio'
                                    >


                                        <div>
                                            <Radio.Group style={{ display: "inline-flex" }} onChange={(e) => setProjectType(
                                                e.target.value)} value={projectType}>
                                                <Radio value={'Onshore'} >Onshore</Radio>
                                                <Radio value={'Offshore'} >Offshore</Radio>
                                            </Radio.Group>
                                        </div>


                                    </Form.Item>
                                </Col>
                                <Col lg={2}>
                                </Col>
                                <Col lg={16} >
                                    <div style={{ paddingLeft: '0px' }} className="pipeline-modal" >
                                        <div style={{ display: 'flex', justifyContent: 'space-between', paddingRight: '8px', paddingLeft: '8px' }}>

                                            <div style={{ marginLeft: '15px' }}>Pipe Size (inch)</div>
                                            <div style={{ marginLeft: '-0px' }}>Wall Thickness(mm)</div>
                                            <div style={{ marginRight: '20px' }}>No. of Welds</div>
                                            <div style={{ marginRight: '20px' }}>No. of Stations</div>
                                        </div>

                                        {GetInputRow()}
                                    </div>

                                </Col>

                            </Row>
                        </Form>
                    </div>


                </Modal >

            </div>
        </div>
    );


};



export default connect(null, null)(ProjectDetails);

