export const FETCH_CALIBRATION = '@@calibration/fetch-calibration';
export const FETCH_CALIBRATION_SUCCESS = '@@calibration/fetch-calibration-success';
export const FETCH_CALIBRATION_FAILURE = '@@calibration/fetch-calibration-failure';

export const ADD_CALIBRATION = '@@calibration/add-calibration';
export const ADD_CALIBRATION_SUCCESS = '@@calibration/add-calibration-success';
export const ADD_CALIBRATION_FAILURE = '@@calibration/add-calibration-failure';

export const SHARE_CALIBRATION = '@@calibration/share-calibration';
export const SHARE_CALIBRATION_SUCCESS = '@@calibration/share-calibration-success';
export const SHARE_CALIBRATION_FAILURE = '@@calibration/share-calibration-failure';

export const UPLOAD_CALIBRATION_FILE = '@@calibration/upload-calibration-File';
export const UPLOAD_CALIBRATION_FILE_SUCCESS = '@@calibration/upload-calibration-File-success';
export const UPLOAD_CALIBRATION_FILE_FAILURE = '@@calibration/upload-calibration-File-failure';

export const UPDATE_CALIBRATION = '@@calibration/update-calibration';
export const UPDATE_CALIBRATION_SUCCESS = '@@calibration/update-calibration-success';
export const UPDATE_CALIBRATION_FAILURE = '@@calibration/update-calibration-failure';
