export const GENERATESPAREPARTS ="@@generate_spareparts";
export const GENERATESPAREPARTS_SUCCESS = "@@generate_spareparts_Success"
export const GENERATESPAREPARTS_FAILURE = "@@generate_spareparts_failure"

export const FETCHSPAREPARTSLIST = '@@fetch_Spare_Parts_List';
export const FETCHSPAREPARTSLIST_SUCCESS = '@@fetch_Spare_Parts_List_Success';
export const FETCHSPAREPARTSLIST_FAILURE = '@@fetch_Spare_Parts_List_Failure';

export const DELETESPAREPARTS = '@@delete_Spare_Parts';
export const DELETESPAREPARTS_SUCCESS = '@@delete_Spare_Parts_Success';
export const DELETESPAREPARTS_FAILURE = '@@delete_Spare_Parts_Failure';

export const FETCHSPAREPARTSMASTERLIST = '@@fetch_Spare_Parts_Master_List';
export const FETCHSPAREPARTSLISTMASTER_SUCCESS = '@@fetch_Spare_Parts_Master_List_Success';
export const FETCHSPAREPARTSLISTMASTER_FAILURE = '@@fetch_Spare_Parts_Master_List_Failure';

export const UPLOADMASTERSPAREPARTS ="@@uplaod_master_spareparts";
export const  UPLOADMASTERSPAREPARTS_SUCCESS = "@@uplaod_master_spareparts_Success"
export const  UPLOADMASTERSPAREPARTS_FAILURE = "@@uplaod_master_spareparts_failure"

export const FETCHSPAREPARTSMASTERLISTP = '@@fetch_Spare_Parts_Master_ListP';
export const FETCHSPAREPARTSLISTMASTERP_SUCCESS = '@@fetch_Spare_Parts_Master_ListP_Success';
export const FETCHSPAREPARTSLISTMASTERP_FAILURE = '@@fetch_Spare_Parts_Master_ListP_Failure';

export const GENERATEMASTERSPAREPARTS ="@@generate_Master_spareparts";
export const GENERATEMASTERSPAREPARTS_SUCCESS = "@@generate_Master_spareparts_Success"
export const GENERATEMASTERSPAREPARTS_FAILURE = "@@generate_Master_spareparts_failure"

export const DELETESMASTERLIST = '@@delete_Master_List';
export const DELETESMASTERLIST_SUCCESS = '@@delete_Master_List_Success';
export const DELETESMASTERLIST_FAILURE = '@@delete_Master_List_Failure';