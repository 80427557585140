/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import {
    Layout, Button, Form, Input, notification, Typography, Row, Col, Popover, Modal
} from 'antd';
import { connect } from 'react-redux';
import { signUp } from '../../redux/actions/authActions';
import get from 'lodash/get';
import { bool, func } from 'prop-types';
import Logo from '../../assets/logo.png';
import history from '../../services/history'
import { EyeInvisibleOutlined, EyeOutlined, UserAddOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import * as constants from '../../utils/constants';
import { passwordPolicy } from '../../utils/passwordPolicy';
import { resendVerifyLink } from '../../services/auth';

const { Text } = Typography;


const SignUp = ({ onSignUp, showLoader, signedUp }) => {
    const [signUpForm] = Form.useForm();
    const [visible, setVisible] = useState(false);
    const [credential, setCredentials] = useState();

    const [validatingStatus, setValidatingStatus] = useState('');
    const [validateVisible, setValidateVisible] = useState(false);
    const [validatingStatusConfirm, setValidatingStatusConfirm] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [userSignedUp, setUserSignedUp] = useState(false);
    const [isConfirmModal, setIsConfirmModal] = useState(false);
    const [confirmPassword, setConfirmPassword] = useState('');


    useEffect(() => {
        if (userSignedUp) {
            setIsConfirmModal(signedUp);
            setUserSignedUp(false)
        }
    }, [signedUp]);

    const validateCreds = () => {

        signUpForm
            .validateFields()
            .then(async (creds) => {
                console.log(creds);
                if (creds.password === creds.password_confirm) {
                    // console.log(creds);
                await signUp(creds);
                } else {
                    notification.warn({
                        message: 'Password does not match with Confirm Password.',
                    });
                }
            });
    };

    const signUp = async (credentials) => {
        setCredentials(credentials);
        console.log(credentials)
        try {
            setUserSignedUp(true)
            await onSignUp(credentials);
            signUpForm.resetFields();
            setValidatingStatus('');
            setValidatingStatusConfirm('')

        } catch (error) {
            notification.destroy();
            notification.error({
                message: error.message,
            });
        }
    };

    /** Validating Entered Password with password policy */
    const validateToNextPassword = (e) => {
        const value = e.target.value
        if (!value) {
            setValidateVisible(false);
        }
        if (value) {
            setNewPassword(value)
            setValidatingStatus('validating');
            setTimeout(() => {
                if (value.length > 7 && constants.PASSWORD_PATTERN.test(value)) {
                    setValidatingStatus('success');
                    if (confirmPassword.length > 7 && confirmPassword === value) {
                        setValidatingStatusConfirm('success');
                    } else {
                        setValidatingStatusConfirm('warning');
                    }
                    setValidateVisible(false);
                } else {
                    setValidatingStatus('warning');
                    setValidateVisible(true);
                }
            }, 500)
        }
    };

    /** Checking Entered Password and Confirmation password to match */
    const validateToFirstPassword = (e) => {
        const value = e.target.value

        if (value) {
            // console.log(value)
            setValidatingStatusConfirm('validating');
            setConfirmPassword(value);
            setTimeout(() => {
                if (value.length > 7 && value === newPassword) {
                    setValidatingStatusConfirm('success');
                } else {
                    setValidatingStatusConfirm('warning');
                }
            }, 500)
        } else {
            setValidatingStatusConfirm('');
        }
    };

    return (
        <Layout.Content className="h-100">
            <div className="d-flex h-100 w-100 overflow-hidden">
                <div className="d-fill overflow-auto w-50 login-bg">
                    <div className="pt-4 ps-4 position-relative " >
                        <a href="aside-logo" className="login-logo">
                            <img src={Logo} alt="logo" className="logo_main" />
                        </a>
                    </div>
                </div>
                <div className="d-fill w-50 overflow-auto">
                    <div className="login-form signup-form">
                        <UserAddOutlined className='mb-4' style={{ fontSize: '40px', paddingRight: '20px' }} />
                        <h1 className="mb-4 text-center">Create an Account</h1>

                        <Form
                            form={signUpForm}
                            name="normal_login"
                            initialValues={{
                                remember: true,
                            }}
                        >
                            <Row gutter={16}>
                                <Col lg={12}>
                                    <Form.Item
                                        name="firstName"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please enter your first name.',
                                            },
                                        ]}
                                    >
                                        <Input size="large" placeholder="First name" maxLength={25} />
                                    </Form.Item>
                                </Col>
                                <Col lg={12}>
                                    <Form.Item
                                        name="lastName"
                                        rules={[
                                            {
                                                required: false,
                                                message: 'Please enter your last name.',
                                            },
                                        ]}
                                    >

                                        <Input size="large" placeholder="Last name" maxLength={25} />
                                    </Form.Item>
                                </Col>

                            </Row>
                            <Form.Item
                                name="username"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input your email address!',
                                    },
                                    {
                                        pattern: true
                                            ? constants.EMAIL_PATTERN
                                            : undefined,
                                        message: 'Provide a valid email.',
                                    }
                                ]}
                                style={{ color: '#ef314c', fontSize: '14px' }}
                            >
                                <Input size="large" placeholder="Email address" autoComplete="new-email"/>
                            </Form.Item>
                            <Popover placement="bottomRight" title={constants.TITLE} content={passwordPolicy} trigger="focus" visible={validateVisible}>
                                <Form.Item
                                    name="password"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter your password.',
                                        },
                                    ]}
                                    hasFeedback
                                    validateStatus={validatingStatus}
                                >
                                    <Input.Password
                                        size="large"
                                        className="password-icon"
                                        placeholder="Password"
                                        onChange={validateToNextPassword}
                                        autoComplete="new-password"
                                        iconRender={(visibility) => (visibility ? <EyeOutlined style={{ color: 'grey' }} /> : <EyeInvisibleOutlined style={{ color: 'grey' }} />)}
                                    />
                                </Form.Item>
                            </Popover>
                            <Form.Item
                                name="password_confirm"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please confirm your password.',
                                    },
                                ]}
                                hasFeedback
                                validateStatus={validatingStatusConfirm}
                            >
                                <Input.Password
                                    size="large"
                                    className="password-icon"
                                    placeholder="Confirm Password"
                                    onChange={validateToFirstPassword}
                                    iconRender={(visibility) => (visibility ? <EyeOutlined style={{ color: 'grey' }} /> : <EyeInvisibleOutlined style={{ color: 'grey' }} />)}
                                />
                            </Form.Item>
                            <Form.Item>
                                <Button
                                    size="large"
                                    shape="round"
                                    block
                                    htmlType="submit"
                                    className="login-form-button"
                                    onClick={validateCreds}
                                    disabled={showLoader}
                                    loading={showLoader}
                                >
                                    Create Account
                                </Button>
                            </Form.Item>
                            <div className="d-flex pb-4 signup_link">
                                <Text className='login-form-signup_text'>Already have an account?</Text>
                                <Form.Item noStyle>
                                    <Link className="login-form-signup" to="/login">
                                        Sign In &#10148;
                                    </Link>
                                </Form.Item>
                            </div>
                        </Form>
                        <span className='login_copy'>Pioneered by PIH &copy;2022 | <a href='https://www.stanleyblackanddecker.com/terms-use' rel="noreferrer" target='_blank'>Terms & Conditions</a> | <a href='https://www.stanleyblackanddecker.com/privacy-policy' rel="noreferrer" target='_blank'>Privacy Policy</a></span>
                    </div>
                </div>
            </div>
            <Modal
                width="45vw"
                className="pipeline-modal resend-link"
                // title={`Delete Station`}
                centered
                visible={isConfirmModal}
                footer={[
                    <Button key="cancel" onClick={() => {
                        setIsConfirmModal(false);
                        history.push('./login');
                    }}>
                        Okay
                    </Button>
                ]}
            >
                <div>
                    <Text style={{ fontSize: '16px' }}>Confirmation mail sent to your mail. Please verify and login.</Text>
                    <p> <Text>Didn&#39;t receive the mail?
                        <Button
                            size="large"
                            shape="round"
                            className="login-form-button resendLink"
                            onClick={() => resendVerifyLink(credential.username)}
                        >
                            Resend Verify Link
                        </Button>
                    </Text></p>
                </div>
            </Modal>
        </Layout.Content >
    )
};

SignUp.propTypes = {
    onSignUp: func.isRequired,
    showLoader: bool.isRequired
};

const mapStateToProps = ({ auth }) => {
    const showLoader = get(auth, 'isSignUpLoading', false);
    const signedUp = get(auth, 'signedUp', '');
    return {
        showLoader,
        signedUp
    };
};

const mapDispatchToProps = {
    onSignUp: signUp,
};


export default connect(mapStateToProps, mapDispatchToProps)(SignUp);