import React from 'react';
import {
  Line,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  ComposedChart
} from 'recharts';

const RevenueChart = ({ calculatedSummary,totalCost, grossMargin, totalRevenue }) => {
  const cost = totalCost;
  const currentMargin = grossMargin;
  const currentRevenue = totalRevenue;
  // Generate margins array from startMargin to endMargin
  //Define start and end values for margins
  //   const startMargin = 0.40;  // Start at 0%
  //   const endMargin = 0.80; // End at 90%

  //   // Define the number of steps
  //   const numberOfSteps = 50; // For 80 steps between start and end
  // // Generate margins array with rounded values to 2 decimal places
  // const margins = Array.from({ length: numberOfSteps }, (_, i) =>
  //   ((startMargin + (endMargin - startMargin) * (i / (numberOfSteps - 1))))
  //);


  // Generate margins array (0% to 90% margin)
  const margins = Array.from({ length: Math.round(currentMargin * 100) + 5 }, (_, i) => i / 100);

  // Calculate revenue based on margin and cost
  const revenueData = margins.map(margin => ({
    margin: (margin * 100), // margin in percentage
    revenue: (cost / (1 - margin)), // revenue calculation
    revenue1: Math.round(margin * 100) === Math.round(currentMargin * 100) ? currentRevenue : 0,
    isCurrent: Math.round(margin * 100) === Math.round(currentMargin * 100), // boolean to mark the current margin
  }));
  const revenueData1 = margins.map(margin => ({
    margin: (margin * 100), // margin in percentage
    revenue1: Math.round(currentMargin * 100) === Math.round(margin * 100) ? currentMargin * 100 : 0, // revenue calculation

  }));
  //console.log(revenueData.filter(d => d.isCurrent))
  // Single point data for the bar chart (current margin and revenue)
  const barData = [
    {
      margin: currentMargin * 100, // current margin in percentage
      revenue: currentRevenue // current revenue
    }
  ];

  return (
    <div style={{ position: 'relative', overflow: 'hidden' }}>
    <p className="fw-medium title project-details-wrapper text-center"
       style={{ color: '#ef314c', fontSize: 20 }}>
      Selling Price vs Margin
    </p>
  
    {/* ResponsiveContainer to ensure no overflow */}
    <ResponsiveContainer height={240} width="100%">
      <ComposedChart data={revenueData} margin={{ top: 10, right: 10, left: 10, bottom: 15}}>
        {/* Remove inside grid lines */}
        <CartesianGrid strokeDasharray="3 3" strokeOpacity={0} />
  
        {/* X Axis with adjusted label position */}
        <XAxis
          dataKey="margin"
          label={{ value: 'Margin (%)', position: 'insideBottom', offset: -10 }} // Reduce offset
          ticks={[10, 20, 30, 40, 50, 60, 70, 80, 90, 100]} // Percentage values on X axis
          tickFormatter={(tick) => `${tick}%`}
        />
  
        {/* Y Axis with value formatting (M, K, etc.) */}
        <YAxis
          dataKey="revenue"
          tickFormatter={(value) => {
            if (value >= 1000000) return `${(value / 1000000).toFixed(1)}M`;
            if (value >= 1000) return `${(value / 1000).toFixed(1)}K`;
            return value;
          }}
          label={{ value: 'Revenue ($)', angle: -90, position: 'insideLeft', offset: -5,dy: 20  }} // Adjust Y-axis label offset
        />

        {/*Tooltip formatting with comma-separated revenue values*/}
        <Tooltip
            formatter={(value, name, props) => {
              const { payload } = props;
              let margin = payload.margin ? parseFloat(payload.margin).toFixed(2) : ''; // Round margin to 2 decimal places
              const formattedValue = `$${new Intl.NumberFormat().format(value?value.toFixed(2):0)}`; // Format revenue and round to 2 decimal places
              margin = Math.round(currentMargin * 100)== margin ? (currentMargin * 100).toFixed(2) :margin
              // Check if it's the "Current Revenue" bar and handle displaying only when hovering over the bar
              if (name.includes('Current Revenue')) {
                if (value === 0) {
                  return false; // Hide tooltip for Current Revenue when value is 0
                }
                // return [`Current Revenue: ${formattedValue}`]; // Show Current Revenue only when hovering over the bar
              }

              // Show Revenue and Margin normally for the line
              return [
                `Revenue: ${formattedValue}`,
                `Margin: ${margin}%`
              ];
            }}
            labelFormatter={(label) => `Margin: ${label}%`} // Show margin in the tooltip header
          />
  
        {/* Legend */}
        <Legend className='recharts-legend-wrapper' />
  
        {/* Line for revenue */}
        <Line type="monotone" dataKey="revenue" stroke="#8884d8" activeDot={{ r: 8 }} name="Revenue" />
  
        {/* Bar for current revenue at current margin */}
        <Bar
          dataKey="revenue1"
          fill="#ef314c"
          name={`Current Revenue at ${(currentMargin * 100).toFixed(2)}%`}
          barSize={20}
          isAnimationActive={false}
        />
      </ComposedChart>
    </ResponsiveContainer>
  </div>
  

  );
};

export default RevenueChart;
