import { notification } from 'antd';
import get from 'lodash/get';
import {
  all,
  call,
  put,
  takeLatest,
} from 'redux-saga/effects';


import axiosConfig from './axiosConfig';
import { FETCHRECORDSLIST } from './types';
import { fetchRecordListFailure, fetchRecordListSuccess } from './action';
import NotificationEmailsAxiosConfig from '../../services/NotificationEmailsAxiosConfig';

function* fetchRecordList(payload = {}) {
  try {

    let url = '/GetRecordList';
    let queryParams = [];

    // Helper function to check if an object is empty
    const isEmptyObject = (obj) => {
      return obj && Object.keys(obj).length === 0 && obj.constructor === Object;
    };

    if (payload.pageNumber) {
      queryParams.push(`pageNumber=${payload.pageNumber}`);
    }

    if (payload.pageSize) {
      queryParams.push(`pageSize=${payload.pageSize}`);
    }

    if (payload.searchKey && !isEmptyObject(payload.searchKey)) {
      queryParams.push(`searchKey=${encodeURIComponent(JSON.stringify(payload.searchKey))}`);
    }

    if (payload.sort) {
      queryParams.push(`sort=${encodeURIComponent(JSON.stringify(payload.sort))}`);
    }

    if (queryParams.length > 0) {
      url += `?${queryParams.join('&')}`;
    }



    const res = yield axiosConfig.get(url);

    if (res.status === 200) {
      const responseData = get(res, ['data', 'data'], {});
      yield put(fetchRecordListSuccess({ responseData }));
    } else {
      yield put(fetchRecordListFailure());
      notification.error({
        message: 'Something went wrong.',
      });
    }
  } catch (error) {
    yield put(fetchRecordListFailure());
    notification.destroy();
    notification.error({
      message: 'A problem occurred while fetching Record list.',
      description: `${error.message}`,
    });
  }
}




function* RecordListWatcher() {
  //yield takeLatest(GENERATESPAREPARTS, generateRecordlist);
  yield takeLatest(FETCHRECORDSLIST, fetchRecordList);
  // yield takeLatest(DELETESPAREPARTS, deleteRecordList);


}

function* RecordListSagas() {
  yield all([
    call(RecordListWatcher),
  ]);
}

export default RecordListSagas;


// export const getCategoryListFromDatabse = async (columnName) => {
//   try {
//     let url;

//     url = `/GetMasterTypesList?searchKey=${(columnName)}`;
//     const res = await axiosConfig.get(url);
//     if (res.status === 403) {
//       notification.error({
//         message: 'Unauthorized',
//       });
//       return null;
//     } if (res.status === 200) {
//       const data = get(res, ['data', 'data'], {});

//       return data;
//     }
//     return null;
//   } catch (error) {
//     return null;
//   }
// };

export const AddOrUpdateProject = async (data, isbulkUpload = false) => {


  try {

    if (data) {

      const list = {
        data: data,
        isbulkUpload: isbulkUpload

      }
      const res = await axiosConfig.post('/AddOrUpdate', list);

      notification.destroy();
      // console.log(yield res.json)
      if (res.status === 200) {
        const responseData = get(res, ['data', 'data'], {});
        let statusmsg = 'Added Successfully.'
        if (data.isEdit)
          statusmsg = 'Updated Successfully.'


        notification.success({
          message: isbulkUpload ? 'Uploaded Successfully.' : statusmsg,
          duration: 3,
        });
        // await fetchRecordList()
        const stage = process.env.REACT_APP_STAGE
        if (stage == 'prod') {
          if (!isbulkUpload) {
            const user = JSON.parse(localStorage.getItem("user"));
            let sub = "New welding track record generated by " + user.name
            let Title = "Welding track record generation notification"
            if (data.isEdit) {
              sub = "welding track record modified by " + user.name
              Title = "Welding track record updated notification"
            }

            const emailData = {
              TitleContent: Title,
              Subject: sub,

              data: {
                "Year": data.yearStarted,
                "Project Name": data.projectName,
                "Material": data.material,
                "Project type": data.type,
                "Location": data.location,
                "Contractor": data.contractor,
                "No. Of Welds": data.numberOfWelds,
              }
            }
            await NotificationEmailsAxiosConfig.post(`NotifyEmails`, emailData);
          }
        }
        return responseData

      }

      else {

        notification.error({
          message: 'Something went wrong.',
        });
        return null

      }
    }

  } catch (error) {

    notification.destroy();
    notification.error({
      message: 'Update Submission Failed.',
      description: `${error}`,
    });
    return null

  }
}

export const AddOrUpdateMasterData = async (data) => {


  try {
    const res = await axiosConfig.post('/AddOrUpdateMaster', data);

    notification.destroy();
    // console.log(yield res.json)
    if (res.status === 200) {
      const responseData = get(res, ['data', 'data'], {});
      notification.success({
        message: 'Added Successfully.',
        duration: 3,
      });

      return responseData

    }

    else {

      notification.error({
        message: 'Something went wrong.',
      });
      return null

    }


  } catch (error) {

    notification.destroy();
    notification.error({
      message: 'Update Submission Failed.',
      description: `${error}`,
    });
    return null

  }
}

export const getCategoryListFromDatabse = async () => {
  try {
    let url;


    url = `/GetCategoryTypes`;

    const res = await axiosConfig.get(url);
    if (res.status === 403) {
      notification.error({
        message: 'Unauthorized',
      });
      return null;
    } if (res.status === 200) {
      const data = get(res, ['data', 'data'], {});
      //console.log('testType', data)
      return data;
    }
    return null;
  } catch (error) {
    return null;
  }
};

export const getMasterListFromDatabase = async () => {
  try {
    let url;


    url = `/GetMasterList`;

    const res = await axiosConfig.get(url);
    if (res.status === 403) {
      notification.error({
        message: 'Unauthorized',
      });
      return null;
    } if (res.status === 200) {
      const data = get(res, ['data', 'data'], {});
      //console.log('testType', data)
      return data;
    }
    return null;
  } catch (error) {
    return null;
  }
};

export const DeleteMasterDetails = async (data) => {

  try {
    const resmaster = await axiosConfig.post('/DeleteMaster', data);
    //console.log(resmaster)
    if (resmaster.status === 200) {
      notification.success({
        message: 'Deleted Successfully.',
        duration: 3,
      });
      return true
    }
    else {

      notification.error({
        message: 'Something went wrong.',
      });
      return false
    }


  } catch (error) {

    notification.destroy();
    notification.error({
      message: 'Delete Submission Failed.',
      description: `${error}`,
    });
    return false

  }
}
export const DeleteTrackRecordDetails = async (data) => {

  try {
    const resmaster = await axiosConfig.post('/DeleteTrackRecord', data);
    //console.log(resmaster)
    if (resmaster.status === 200) {
      notification.success({
        message: 'Deleted Successfully.',
        duration: 3,
      });
      return true
    }
    else {

      notification.error({
        message: 'Something went wrong.',
      });
      return false
    }


  } catch (error) {

    notification.destroy();
    notification.error({
      message: 'Delete Submission Failed.',
      description: `${error}`,
    });
    return false

  }
}
export const GeneratetrackRecordReport = async (payload) => {
  try {
    let url;


    url = `/GetRecordListAll`;

    //let url = '/GetRecordList';
    let queryParams = [];

    // Helper function to check if an object is empty
    const isEmptyObject = (obj) => {
      return obj && Object.keys(obj).length === 0 && obj.constructor === Object;
    };



    if (payload.searchKey && !isEmptyObject(payload.searchKey)) {
      queryParams.push(`searchKey=${encodeURIComponent(JSON.stringify(payload.searchKey))}`);
    }

    if (payload.sort) {
      queryParams.push(`sort=${encodeURIComponent(JSON.stringify(payload.sort))}`);
    }

    if (queryParams.length > 0) {
      url += `?${queryParams.join('&')}`;
    }

    const res = await axiosConfig.get(url);
    if (res.status === 403) {
      notification.error({
        message: 'Unauthorized',
      });
      return null;
    } if (res.status === 200) {
      const data = get(res, ['data', 'data'], {});
      if (data)
        return await generatePDF(data, process.env.REACT_APP_STAGE);
    }
    return null;
  } catch (error) {
    return null;
  }
};

export const generatePDF = async (data, deployEnv = 'dev') => {
  try {

    const url = ' https://gd7kpl3m62.execute-api.us-west-2.amazonaws.com/dev/generate_track_record_report'

    //const url = 'http://localhost:5000/generate_track_record_report'
    //console.log('Url Data::', data)

    const inputData = {
      inputData: data,
      deployEnv: deployEnv
    }

    const res = await fetch(`${url}`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-type': 'application/json',
        // Authorization: token
      },
      body: JSON.stringify(inputData),
    });

    notification.destroy();
    //console.log(await res.json())
    if (res.status === 200) {

      const response1 = await res.json();
      const url = {
        PDFFileLink: response1.report_url.s3Url,
        projectID: data.ID
      }
      //console.log(url)
      // const resPDf = await axiosConfig.post('/UpdatePDFFile', url);

      // if (resPDf.status === 200) {

      //     const pdfData = get(resPDf, ['data', 'data'], {});
      //     //data.material_database = masterData;
      //     //console.log(pdfData)
      //     return pdfData
      // }
      // else {

      //     notification.error({
      //         message: 'Something went wrong.',
      //     });
      //     return null
      // }
      //console.log(response1)
      return url
    }
    if (res.status === 403) {
      notification.error({
        message: 'Unauthorized',
      });
      return null;
    }
    return null;
  } catch (error) {
    return null;
  }
};

export const getFilterCategoryListFromDatabse = async (payload) => {
  try {
    let url;

    if (payload.searchKey) {

      const encodedSearchKey = encodeURIComponent(JSON.stringify(payload.searchKey));
      url = `/GetFilterCategoryList?header=${(payload.header)}&searchKey=${encodedSearchKey}`;
    }
    else
      url = `/GetFilterCategoryList?header=${(payload.header)}`;

    const res = await axiosConfig.get(url);
    if (res.status === 403) {
      notification.error({
        message: 'Unauthorized',
      });
      return null;
    } if (res.status === 200) {
      const data = get(res, ['data', 'data'], {});
      //console.log('testType', data)
      return data;
    }
    return null;
  } catch (error) {
    return null;
  }
};

export const GetRecordListExcel = async (payload) => {
  try {
    let url;


    url = `/GetRecordListExcel`;

    //let url = '/GetRecordList';
    let queryParams = [];

    // Helper function to check if an object is empty
    const isEmptyObject = (obj) => {
      return obj && Object.keys(obj).length === 0 && obj.constructor === Object;
    };



    if (payload.searchKey && !isEmptyObject(payload.searchKey)) {
      queryParams.push(`searchKey=${encodeURIComponent(JSON.stringify(payload.searchKey))}`);
    }

    if (payload.sort) {
      queryParams.push(`sort=${encodeURIComponent(JSON.stringify(payload.sort))}`);
    }

    if (queryParams.length > 0) {
      url += `?${queryParams.join('&')}`;
    }

    const res = await axiosConfig.get(url);
    if (res.status === 403) {
      notification.error({
        message: 'Unauthorized',
      });
      return null;
    } if (res.status === 200) {
      const data = get(res, ['data', 'data'], {});

      return data
    }
    return null;
  } catch (error) {
    return null;
  }
};