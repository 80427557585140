export const GENERATELICENSE ="@@generatelicense";
export const GENERATELICENSE_SUCCESS = "@@generatelicense_Success"
export const GENERATELICENSE_FAILURE = "@@generatelicense_failure"
export const FETCH_LICENSE_LIST = "@@fetch_licence_List";
export const FETCH_LICENSE_LIST_SUCECESS = "@@fetch_licence_List_Success";
export const FETCH_LICENSE_LIST_FAILURE = "@@fetch_licence_List_Failure";
export const SHARELICENSEINFO ="@@share_License_Info"
export const SHARELICENSEINFO_SUCCESS ="@@share_License_Info_success"
export const SHARELICENSEINFO_FAILURE ="@@share_License_Info_Failure"
export const SHARE_FETCH_LICENSE_LIST = "@@share_fetch_licence_List";
export const SHARE_FETCH_LICENSE_LIST_SUCECESS = "@@share_fetch_licence_List_Success";
export const SHARE_FETCH_LICENSE_LIST_FAILURE = "@@share_fetch_licence_List_Failure";
export const FETCH_GROUP_LICENSE_LIST = "@@fetch_group_licence_List";
export const FETCH_GROUP_LICENSE_LIST_SUCECESS = "@@fetch_group_licence_List_Success";
export const FETCH_GROUP_LICENSE_LIST_FAILURE = "@@fetch_group_licence_List_Failure";

export const FETCH_UNIQUE_ID = "@@fetch_Unique_Id";
export const FETCH_UNIQUE_ID_SUCECESS = "@@fetch_Unique_Id_Success";
export const FETCH_UNIQUE_ID_FAILURE = "@@fetch_Unique_Id_Failure";