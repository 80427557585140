import React, {useState} from 'react';
import { withRouter } from 'react-router-dom';
import {
  object, oneOfType, objectOf, string, symbol, func
} from 'prop-types';
import { Drawer, Button } from 'antd';
import SignoutButton from './SignoutButton';
import 'antd/dist/antd.dark.css';
const HomeLayout = (props) => {
  const { children } = props;
  // const [showDrawer, setDrawer] = useState(false);

  return (
    <div className="h-100">
      {children}

    </div>
  );
};

HomeLayout.propTypes = {
  children: objectOf(oneOfType([object, string, symbol, func])),
};

HomeLayout.defaultProps = {
  children: {},
};

export default withRouter(HomeLayout);
