/* eslint-disable no-param-reassign */
/* eslint-disable react/no-unescaped-entities */
import React, { useEffect, useState } from 'react';
import {
    Button, Form, Select, Modal, Typography, Table, Tooltip
} from 'antd';
import { FilePdfOutlined,EditOutlined } from '@ant-design/icons'

import { connect } from 'react-redux';
import GenerateLicenseForm from '../components/LicenseForm.js';
import moment from 'moment';

const { Title, Text } = Typography;

//const { Option } = Select;
const DownloadForm = (props) => {

    const {
        downloadDataList,
        setShowDownloadModel,
        showDownloadModel
    } = props;
    const [licenseForm] = Form.useForm()
    const [generateLiceseVisible, setGenerateLiceseVisible] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [editRecord, seteditRecord] = useState('');
    const  calculateDifference= (startDateStr, endDateStr)=> {
        const startDate = moment(startDateStr, 'YYYY-MM-DD');
        const endDate = moment(endDateStr, 'YYYY-MM-DD');
  
        const years = endDate.diff(startDate, 'years');
        startDate.add(years, 'years'); // move start date forward by the number of years
    
        const days = endDate.diff(startDate, 'days');
        console.log(years,days)
        let result = '';
        if (years > 0) {
            result += `${years} year${years > 1 ? 's' : ''}`;
        }
        if (days > 0) {
            if (result.length > 0) result += ' ';
            result += `${days} day${days > 1 ? 's' : ''}`;
        }
    
        return result || '-';
    }

    const columns_feild = [

        // {
        //     title: 'Version',
        //     dataIndex: 'version',
        //     key: 'version',
        //     filterSearch: true,
        //     render: (text) => <a style={{ paddingLeft: '2px' }}>{text}</a>,
        // },
    
        // {
        //     title: 'Feature Type',
        //     dataIndex: 'Feature_Type',
        //     key: 'Feature_Type',
        //     filterSearch: true,
        //     render: (text) => <a style={{ paddingLeft: '0px' }}>{text}</a>,
        // },
        
        {
            title: 'User Identifier',
            dataIndex: 'License_File_Name',
            key: 'License_File_Name',
            filterSearch: true,
            width: '150px',
            sorter: (a, b) =>  (a.License_File_Name.localeCompare(b.License_File_Name)),
            render: (text) => <a style={{ paddingLeft: '2px' }}>{text}</a>,
        },
         {
            title: 'Customer Name',
            dataIndex: 'Customer_Name',
            key: 'Customer_Name',
            align: 'center',
            render: (text) => <div style={{  placeContent: 'center' }}>{text ? text : '-'}</div>,
            sorter: (a, b) =>  (a.Customer_Name.localeCompare(b.Customer_Name))
        },
        {
            title: 'Machine Serial Number',
            dataIndex: 'Serial_Number',
            key: 'Serial_Number',
            align: 'center',
            width: '160px',
            render: (text) => <div style={{ placeContent: 'center' }}>{text ? text : '-'}</div>,
            sorter: (a, b) =>  (a.Serial_Number.localeCompare(b.Serial_Number))
        },
        {
            title: 'Location',
            dataIndex: 'Location',
            key: 'Location',
            align: 'center',
            render: (text) => <div style={{  placeContent: 'center' }}>{text ? text : '-'}</div>,
            sorter: (a, b) =>  (a.Customer_Name.localeCompare(b.Customer_Name))
        },
        {
            title: 'Contractor',
            dataIndex: 'Contractor',
            key: 'Contractor',
            
            align: 'center',
            render: (text) => <div style={{ placeContent: 'center' }}>{text ? text : '-'}</div>,
            sorter: (a, b) => (a.Contractor.localeCompare(b.Contractor))
        },
        {
            title: 'Created Date',
            dataIndex: 'File_Creation_Date',
            key: 'File_Creation_Date',
            align: 'center',
            render: (text) => {
                return (
                    <div>{text ? moment(text).format('DD MMM YYYY') : '-'}</div>
                );
            },
            //sorter: (a, b) => moment(a.File_Creation_Date).valueOf() - moment(b.File_Creation_Date).valueOf()
        },
        {
            title: 'Expiration Date',
            dataIndex: 'Expiration_Date',
            key: 'Expiration_Date',
            align: 'center',
            render: (text) => {
                return (
                    <div>{text ? moment(text).format('DD MMM YYYY') : '-'}</div>
                );
            },
            // sorter: (a, b) => a.Expiration_Date - b.Expiration_Date,
            // sorter: (a, b) => moment(a.Expiration_Date).valueOf() - moment(b.Expiration_Date).valueOf()
        },
        {
            title: 'No. of Years',
            dataIndex: 'noOfyears',
            key: 'noOfyears',
            align: 'center',
            render: (text,record) => {
                let validateyears ='-'
                if(!text)
                    validateyears =  calculateDifference(record.File_Creation_Date ,record.Expiration_Date)
                return (
                    <div>{text ? text :validateyears}</div>
                );
            },
            // sorter: (a, b) => a.Expiration_Date - b.Expiration_Date,
            // sorter: (a, b) => moment(a.Expiration_Date).valueOf() - moment(b.Expiration_Date).valueOf()
        },
         {
            title: 'Edit / Download',
            dataIndex: 'File_Link',
            key: 'File_Link',
            render: (text, record) => {
                return (
                    <>
                    
                        <Tooltip title="Update">
                            <span className="icon-18 mr-2 cursor-pointer edit-hover" 
                            // style={{ cursor: 'not-allowed' }}
                            >
                                <EditOutlined style={{ fontSize: '18px' }}
                                    onClick={() => {
                                        showModal(record);
                                    }}
                                />
                            </span>
                        </Tooltip>
                        
                        <Tooltip title="Download license file"  >
                            <span className="icon-24 mr-2 cursor-pointer edit-hover"  >
                                <Button href={record.File_Link} className='p-0 border-0' size='small' target='_blank'>
                                    <FilePdfOutlined  style={{color:'red' ,paddingLeft:'10px', fontSize: '18px'}}/>
                                </Button>
                            </span>
                        </Tooltip>
                  
                        
                    </>
                )
            },
            align: 'center'
        },
    ];

   
    // const columns = [

    //     // {
    //     //     title: 'Version',
    //     //     dataIndex: 'version',
    //     //     key: 'version',
    //     //     filterSearch: true,
    //     //     render: (text) => <a style={{ paddingLeft: '2px' }}>{text}</a>,
    //     // },

    //     {
    //         title: 'User Identifier',
    //         dataIndex: 'License_File_Name',
    //         key: 'License_File_Name',
    //         filterSearch: true,
    //         render: (text) => <a style={{ paddingLeft: '0px' }}>{text}</a>,
    //     },
    //     {
    //         title: 'Created Date',
    //         dataIndex: 'File_Creation_Date',
    //         key: 'File_Creation_Date',
    //         align: 'center',
    //         render: (text) => {
    //             return (
    //                 <div>{text ? moment(text).format('DD MMM YYYY') : '-'}</div>
    //             );
    //         },
    //         //sorter: (a, b) => moment(a.File_Creation_Date).valueOf() - moment(b.File_Creation_Date).valueOf()
    //     },
    //     {
    //         title: 'Expiration Date',
    //         dataIndex: 'Expiration_Date',
    //         key: 'Expiration_Date',
    //         align: 'center',
    //         render: (text) => {
    //             return (
    //                 <div>{text ? moment(text).format('DD MMM YYYY') : '-'}</div>
    //             );
    //         },
    //         // sorter: (a, b) => a.Expiration_Date - b.Expiration_Date,
    //         // sorter: (a, b) => moment(a.Expiration_Date).valueOf() - moment(b.Expiration_Date).valueOf()
    //     },

    //     {
    //         title: 'Link',
    //         dataIndex: 'File_Link',
    //         key: 'File_Link',
    //         align: 'center',
    //         render: (text, record) => {
    //             return (
    //                 <>
    //                     <Tooltip title="Download license file" >
    //                         <span className="icon-24 mr-2 cursor-pointer edit-hover"  >
    //                             <Button href={record.File_Link} className='p-0 border-0' size='small' target='_blank'>
    //                                 <FilePdfOutlined />
    //                             </Button>
    //                         </span>
    //                     </Tooltip>
    //                 </>
    //             )
    //         }
    //     },
    // ];



    const [downloadForm] = Form.useForm()

    const handleOk = async () => {
        setShowDownloadModel(false)
    };
    const showModal = (record) => {
        let editData = null;
    
        if (record != null) {
           
            const name = record.License_File_Name.split('_')
            editData = {
                uniqueId: record.Unique_Id,
                fileName: name[0],

                featureType: record.Feature_Type,
                application_type: record.Type_Of_Device,
                customerName: record.Customer_Name,
                serialNumber: record.Serial_Number,
                board_type: record.Board_Type,
            };
            licenseForm.setFieldsValue(editData)
            setIsEdit(true)
            seteditRecord(record)
            setGenerateLiceseVisible(true)
        }
    }
//  useEffect(()=>{
//     if(showDownloadModel && !generateLiceseVisible )
//     {
//         //setShowDownloadModel(false)
//     }

//  },[generateLiceseVisible])

    return (

        <div>
            <div>
                <Modal
                    width="100vw"

                    className="pipeline-modal"
                    title="Download License"
                    centered
                    visible={showDownloadModel}
                    footer={[
                        <div style={{ paddingTop: '20px', paddingRight: '20px' }}>
                            <Button key="apply" type="primary"
                                onClick={handleOk}

                            >
                                Close
                            </Button>
                        </div>
                    ]}
                >

                    <div className='addTeamDetails'>
                        <Table columns={columns_feild}
                          rowKey={'id'}
                            dataSource={[...downloadDataList]}
                            className="h-100 pipeline-table equip_table equipPaginationTable"
                            scroll={{ y: 'calc(100vh - 400px)' }}
                            pagination={false}
                        />
                    </div>

                  
                </Modal >
                {generateLiceseVisible &&
                <GenerateLicenseForm
                    setGenerateLiceseVisible={setGenerateLiceseVisible}
                    generateLiceseVisible={generateLiceseVisible}
                    record={editRecord}
                    licenseForm={licenseForm}
                    isEdit={isEdit}
                    setIsEdit={setIsEdit}
                    setShowDownloadModel ={setShowDownloadModel}
                />
            }
            </div>
        </div>
    );
};



export default connect(null, null)(DownloadForm);

