/* eslint-disable no-param-reassign */
/* eslint-disable react/no-unescaped-entities */
import { ArrowLeftOutlined, RightCircleOutlined } from '@ant-design/icons';
import { Button, Tabs, Tooltip, Typography, Select, Row, Col, Card } from 'antd';

import { get } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import history from '../../services/history';


const { Title } = Typography;

const ProjectConsoleCards = () => {
    return (
        <div className="d-flex flex-column h-100">
            <div >
                <Row gutter={24} className="detail-rows">
                    {/* <Col span={40}>
                        <Card className='custom-card'
                            onClick={() => {
                                history.push('/tie-out');
                            }}
                            style={{ background: '#ef314c', borderColor: '#ef314c' }}>
                            <div style={{ marginTop: '-10px' }} >
                                <div >
                                    {"Tie Out"}
                                </div>
                                <div style={{ paddingTop: '5px' }}>
                                    <RightCircleOutlined></RightCircleOutlined>
                                </div>
                            </div>
                        </Card>
                    </Col>
                    <Col span={40}>
                        <Card className='custom-card'
                            onClick={() => {
                                history.push('/cm-crc');
                            }}
                            style={{ background: '#ef314c', borderColor: '#ef314c' }}>
                            <div style={{ marginTop: '-10px' }} >
                                <div >
                                    {"Weekly Reporting"}
                                </div>
                                <div style={{ paddingTop: '5px' }}>
                                    <RightCircleOutlined></RightCircleOutlined>
                                </div>
                            </div>
                        </Card>
                    </Col>
                    <Col span={40}>
                        <Card className='custom-card'
                            onClick={() => {
                                history.push('/project-console');
                            }}
                            style={{ background: '#ef314c', borderColor: '#ef314c' }}>
                            <div style={{ marginTop: '-10px' }} >
                                <div >
                                    {"ME Reporting"}
                                </div>
                                <div style={{ paddingTop: '5px' }}>
                                    <RightCircleOutlined></RightCircleOutlined>
                                </div>
                            </div>
                        </Card>
                    </Col> */}
                </Row>
            </div>
        </div>
    );
};

export default ProjectConsoleCards