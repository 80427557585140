/* eslint-disable no-param-reassign */
/* eslint-disable react/no-unescaped-entities */
import { ArrowLeftOutlined, CloudUploadOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import {
    Button, Form,
    Input,
    Modal,
    Space,
    Table,
    Tabs,
    Tooltip,
    Typography,
    Upload, notification, Row, Col
} from 'antd';
import { Content, Footer } from 'antd/lib/layout/layout';
import { get } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import * as XLSX from 'xlsx';
import ExcelJS from 'exceljs';
import history from '../../services/history.js';

import { AddOrUpdateMasterData, DeleteMasterDetails, getListFromDatabase } from '../../redux/Pricing/saga.js';
import Item from 'antd/lib/list/Item.js';
import { pipedia, PipeDiaRate } from './Database.js';
import MasterAddNew from './MasterAddNew.js';
import { number } from 'prop-types';




const { TabPane } = Tabs;
const { Title, Text } = Typography;
//const { TabPane } = Tabs;
//const { Column } = Table;
//const { Search } = Input;

//const { Option } = Select;
const MasteForm = (props) => {

    const {
        setIsMasterModalVisible,
        isMasterModalVisible,
        selectedTap,
        userGroup
    } = props;

    const dataSource = [
        {
            key: '1',
            partNumber: 'C235418',
            description: 'HOLDER,TOOL PIGGY-BACK   5 DEG, 110 OFFS',
            quantity_5000_Welds: 1,
            category: 'BEVELLING',
            multiple_item: 'Yes',
            cost: '23',
            where_Used: 'BD4 BEV-CBUC-HW',
            UOM: 'EA',
            alternate_Parts: 'F2M0044-R',
            // Add other properties as needed
        },
    ]
    const [UpdateModalForm] = Form.useForm()

    const [searchText, setSearchText] = useState({});
    const [searchedColumn, setSearchedColumn] = useState('');

    const [currentPageNumber, setCurrentPageNumber] = useState(1);
    const [currentPagesize, setCurrentPagesize] = useState(10);

    const [masterUploadForm] = Form.useForm()
    const [addNewForm] = Form.useForm()
    const [disableFileUpload, setDisableFileUpload] = useState(false);
    const [fileList, setFileList] = useState([]);
    const [bulkUplaodModal, setBulkUplaodModal] = useState(false);
    const [databaseList, setDatabaseList] = useState([]);
    const [showAddNewModal, setShowAddNewModal] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [editRecord, seteditRecord] = useState('');

    const [confirmLoading, setConfirmLoading] = useState(false);
    const [matrialClassTypes, setMatrialClassTypes] = useState([]);
    const [selectedMatrialClass, setSelectedMatrialClass] = useState('');

    const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
    const [selectedData, setSelectedData] = useState('');
    const [isDeleteLoading, setIsDeleteLoading] = useState(false)
    const [activeKey, setActiveKey] = useState('1')
    const [activeKeyLine, setActiveKeyLine] = useState('1')
    const [searchCriteria, setSearchCriteria] = useState({});
    const [downloadLoading, setDownloadLoading] = useState(false);
    const [uploadLoading, setUploadLoading] = useState(false);

    const [addLoding, setAddLaoding] = useState(false)
    const [loading, setloading] = useState(false);
    const [edittModalVisible, setEdittModalVisible] = useState(false)
    const [isUpdateLoading, setUpdateLoading] = useState(false)
    const [addItem, setAddItem] = useState(11.2);
    const [TableDisplayList, setTableDisplayList] = useState([])
    const [TableIndexList, setTableIndexList] = useState([])
    const [AddUpdateList, setAddUpdateList] = useState({})
    const [data_List, setData_List] = useState([]);
    const [rentalPrice, setRentalPrice] = useState(11.2);
    const [CommonInputUpdating, setCommonInputUpdating] = useState(false)
    const [CommonInputslist, setCommonInputslist] = useState(

        {
            "C.I.F": 15000,
            "Tech Cost": 95.06,
            "Tech Months": 12,
            "Wire Margin": 0.42,
            "Spares Margin": 0.42,
            "Tech Constant": 30.4,
            "Number of Techs": 2,
            "Techinican Rate": 1300,
            "Wire Cost per Weld": 23.42,
            "Spare Cost per Weld": 17,
            "Number of Tech Months": 2
        }

    )
    const [CommonInputsDabase, setCommonInputsDabase] = useState([])
    const tableNames = {
        CopperShoe_List: 'CopperShoe',
        DiscountRate: 'Discount Rate',
        Size_Parts: 'Part List (Size Based)',
        Non_Size_Parts: 'Part List',
        PipeDiaRate: 'Pipe Dia Rate',
        Spread_Config_List: 'Spread Config',
        CommonInputs: 'CommonInputs',
    };
   
    const rentalPriceList = ['CopperShoe_List', 'Size_Parts', 'Non_Size_Parts']
    const generateColumns = (titles, dataIndices, onUpdateClick, showDeleteModal) => {
        // Create an array of columns based on titles and dataIndices
        const columns = titles.map((title, index) => ({
            title: title,
            dataIndex: dataIndices[index],
            key: dataIndices[index],
            align: 'center'
        }));

        // Add the actions column at the end
        columns.push({
            title: 'Actions',
            key: 'action',
            dataIndex: 'action',
            render: (text, record) => {
                return (
                    <div>
                        <Tooltip title="Update">
                            <span className="icon-18 mr-2 cursor-pointer edit-hover">
                                <EditOutlined style={{ fontSize: '18px' }}
                                    onClick={() => onUpdateClick(record)}
                                />
                            </span>
                        </Tooltip>
                        <Tooltip title="Delete">
                            <span className="icon-18 mr-2 cursor-pointer edit-hover" style={{ marginLeft: '20px' }}>
                                <DeleteOutlined style={{ fontSize: '18px' }}
                                    onClick={() => showDeleteModal(record)}
                                />
                            </span>
                        </Tooltip>
                    </div>
                );
            },
            align: 'center'
        });

        return columns;
    };

    // const coulumNames = {
    //     countries_List: 'country',
    //     diameters: 'diameter',
    //     saleContracts: 'sale_contract',
    //     projecttypes: 'type',
    //     rootBeadTypes: 'root_bead_type',
    //     externalEquipments: 'external_equipment',
    //     weldingProcesses: 'welding_process',
    //     powerSupplies: 'power_supply',
    //     wireTypes: 'wireType',
    //     terrains: 'terrain',
    //     climates: 'climate',
    //     positions: 'position',
    //     inspectionTypes: 'inspection_type',
    //     materials: 'material'
    // };

    useEffect(() => {
        (async () => {
            if (data_List)
                setloading(true)
            const response = await getListFromDatabase('CopperShoe_List')
            if (response) {
                setData_List(response);
                console.log('Record Item', response);
                //const keys = Object.keys(response[0]).map(key => key);
                const keys = Object.keys(response[0]).filter(key =>
                    key !== 'created_date' && key !== 'Updated_date' && key !== 'ID'
                );
                setTableIndexList(keys)
                const filteredKeys = Object.keys(response[0]).filter(
                    key => key !== 'created_date' && key !== 'Updated_date' && key !== 'ID'
                );
                console.log(filteredKeys, keys)
                // Extract and transform the keys
                const transformedKeys = keys.map(key => key.replace(/_/g, ' '));
                setTableDisplayList(transformedKeys)


                const filteredObject = Object.fromEntries(
                    Object.entries(response[0]).filter(
                        ([key]) => key !== 'created_date' && key !== 'Updated_date' && key !== 'ID'
                    )
                );
                setAddUpdateList(filteredObject)
                // Set the initial selected material class
                const initialClass = Object.keys(tableNames)[0];
                setSelectedMatrialClass(initialClass);
                setActiveKeyLine(initialClass);
                setRentalPrice(getDefaultRentprice(response[0]))

                setloading(false)
            }
        })();
    }, []);


    const getDefaultRentprice = (item) => {

        const priceBook = parseFloat(item.Rental_Price);
        const extended_Pricebook = parseFloat(item.Extended_Pricebook);
        // Update Extended_Pricebook by applying the percentage
        const rentalPer = (priceBook / extended_Pricebook) * 100;
        console.log('Rent Price %', rentalPer)
        return Number(rentalPer.toFixed(2)) ?? 11.2
    }

    const updatePrices = async (dataArray, percentage) => {
        return dataArray.map(item => {
            // Convert necessary fields to numbers
            const unitCost = parseFloat(item.Unit_Cost);
            const priceBook = parseFloat(item.Price_Book);
            const extended_Pricebook = parseFloat(item.Extended_Pricebook);
            // Update Extended_Pricebook by applying the percentage
            const extendedPriceBook = (extended_Pricebook * (percentage / 100));

            // Update Rental_Price
            // const rentalPrice = unitCost + (unitCost * 0.1); // Example calculation, adjust as needed

            return {
                ...item,
                Unit_Cost: Number(unitCost.toFixed(3)),
                Price_Book: Number(priceBook.toFixed(3)),
                Extended_Pricebook: Number(extended_Pricebook.toFixed(3)),
                Rental_Price: Number(extendedPriceBook.toFixed(3))
            };
        });
    }

    const handleRentelPrizeChanges = async (value) => {

    }


    const handleTabChange = async (key) => {
        setActiveKeyLine(key);
        setSelectedMatrialClass(key);
        console.log(key)
        setloading(true)
        if (key == 'CommonInputs') {
            const response1 = await getListFromDatabase(key)
            console.log('Record Item', response1)
            const sortedKeys = Object.keys(response1[0].CommonInputs).sort();
            setCommonInputsDabase(response1[0])
            setCommonInputslist(response1[0].CommonInputs)
            return;
        }
        const response = await getListFromDatabase(key)
        if (response) {

            console.log('Record Item', response);
            //  const keys = Object.keys(response[0]).map(key => key);
            const keys = Object.keys(response[0]).filter(key =>
                key !== 'Created_date' && key !== 'created_date' && key !== 'Updated_date' && key !== 'ID'
            );
            setTableIndexList(keys)

            const filteredObject = Object.fromEntries(
                Object.entries(response[0]).filter(
                    ([key]) => key !== 'created_date' && key !== 'Updated_date' && key !== 'ID'
                )
            );
            setAddUpdateList(filteredObject)
            console.log(filteredObject)
            // Extract and transform the keys
            const transformedKeys = keys.map(key => key.replace(/_/g, ' '));
            setTableDisplayList(transformedKeys)
            // Set the initial selected material class
            // const initialClass = Object.keys(tableNames)[0];
            // setSelectedMatrialClass(initialClass);
            // setActiveKeyLine(initialClass);
            setData_List(response);

        };
        setloading(false)
    }

    const FetchDatalist = async () => {
        setloading(true)

        if (activeKeyLine == 'CommonInputs') {
            const response1 = await getListFromDatabase(activeKeyLine)
            console.log('Record Item', response1)

            setCommonInputsDabase(response1[0])
            setCommonInputslist(response1[0].CommonInputs)
            return;
        }
        else {
            const response = await getListFromDatabase(activeKeyLine)
            if (response) {
                setData_List(response);
            }
        }
        setloading(false)
    }

    const handleUpdateOk = async () => {

        //if (rentalPrice !== '') 
        {

            console.log('update Array :', rentalPrice)
            setUpdateLoading(true)
            for (let index = 0; index < rentalPriceList.length; index++) {
                const element = rentalPriceList[index];
                const response = await getListFromDatabase(element)
                if (response) {
                    const item = await updatePrices(response, rentalPrice)

                    console.log('update Array :', item)
                    const data = {
                        columnName: element,
                        tableName: element,
                        data: item,
                        isSync: true
                    }
                    await AddOrUpdateMasterData(data);
                    break;
                }
            }
            await FetchDatalist()
            // setRentalPrice('')
            setUpdateLoading(false)
            setEdittModalVisible(false)
        }

    };

    const onUpdateClick = (record) => {
        console.log(record)

        if (record) {
            //UpdateModalForm.resetFields()

            addNewForm.setFieldsValue(record)
            // setAddItem(record[activeKeyLine])
            // console.log(record)

            seteditRecord(record)
            setAddUpdateList(record)
            setIsEdit(true)
            setShowAddNewModal(true)
        }


    }
    useEffect(() => {
        console.log(showAddNewModal)

    }, [showAddNewModal])
    const handleCancel = async () => {

        setRentalPrice('')
        setEdittModalVisible(false)
        UpdateModalForm.resetFields()
    };

    const handleAddCancel = async () => {

        setShowAddNewModal(false)
        // UpdateModalForm.resetFields()
    };

    const handleCommonnInputChange = (name, value) => {
        value = Number(value);
        setCommonInputslist(prevData => ({ ...prevData, [name]: value }));
    };
    const UpdateCommonInput = async () => {
        setCommonInputUpdating(true);
        CommonInputsDabase['CommonInputs'] = CommonInputslist
        const data = {
            columnName: 'CommonInputs',
            tableName: 'CommonInputs',
            data: [CommonInputsDabase]
        }
        console.log('Added Item:', data);
        // Call your action to add or update spare parts
        await AddOrUpdateMasterData(data);
        setCommonInputUpdating(true);
    }

    const getCommonInputs = () => {

        return (
            <Form name="CommoninputForm" autoComplete="off" layout="vertical" style={{ width: '60%' }}>
                <Row gutter={24}>
                    {Object.keys(CommonInputslist).map((key) => (
                        <React.Fragment key={key}>
                            <Col lg={6}>
                                <div style={{ textAlign: 'left', padding: '8px 0' }}>
                                    {key}
                                </div>
                            </Col>

                            <Col lg={6}>
                                <Input
                                    value={CommonInputslist[key]}
                                    size="medium"
                                    onChange={e => handleCommonnInputChange(key, e.target.value)}
                                    style={{ textAlign: 'left' }}
                                />
                            </Col>
                        </React.Fragment>
                    ))}
                </Row>
                <Footer style={{ textAlign: 'center', backgroundColor: 'transparent', paddingBottom: '0px' }}>
                    {/* <Button type="primary" style={{ marginRight: '10px', borderRadius: '30px' }}>
                    Clear
                </Button>
                <Button type="primary" style={{ marginRight: '10px', borderRadius: '30px' }} 
                
                loading={confirmLoading} onClick={() => { OnGenerateReport() }}>
                    Generate Margin
                </Button> */}

                    <Button key="apply" type="primary" style={{ borderRadius: '30px' }} loading={CommonInputUpdating}
                        onClick={() => { UpdateCommonInput() }}

                    >
                        Update
                    </Button>
                </Footer>
            </Form>
        );
    }
    const getPipelineTabpanes = () => {


        return (
            <div className="custom-tab h-100" style={{ paddingTop: '10px' }}>
                <Tabs activeKey={selectedMatrialClass} onChange={handleTabChange} defaultActiveKey={Object.keys(tableNames)[0]}>
                    {Object.keys(tableNames).map((row) => (
                        <TabPane tab={tableNames[row]} key={row}>
                            {row === 'CommonInputs' ? getCommonInputs() :
                                <Row gutter={24}>
                                    <Col span={24}>
                                        <div className='addTeamDetails'>
                                            <Table
                                                className="h-100 pipeline-table equipPaginationTable"
                                                scroll={{ y: 'calc(100vh - 290px)' }}
                                                pagination={false}
                                                dataSource={data_List || []}
                                                columns={generateColumns(TableDisplayList, TableIndexList, onUpdateClick, showDeleteModal)}
                                                loading={loading}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                            }
                        </TabPane>
                    ))}
                </Tabs>
            </div>
        );
    };
    const handleCreateNew = () => {
        addNewForm
            .validateFields()
            .then(async (data) => {


                // if (addItem == '') {
                //     notification.warning({
                //         message: 'Enter the category class to proceed!'
                //     });
                //     return
                // }
                // console.log(addItem)
                // setAddLaoding(true)
                // const obj = {
                //     columnName: coulumNames[activeKeyLine],
                //     tableName: activeKeyLine,
                //     data: {
                //         [coulumNames[activeKeyLine]]: addItem
                //     }
                // };
                // console.log(obj);
                // // Uncomment and implement this function
                // await AddOrUpdateMasterData(obj)
                // setAddLaoding(false)
                // setShowAddNewModal(false);
                // setloading(true)
                // await UpdateMasterList();
                // setloading(false)
            })
            .catch(errorInfo => {
                console.error('Validation failed:', errorInfo);
            });
    };

    /** Display Confirmation while deleting */
    const showDeleteModal = (record) => {
        setIsDeleteModalVisible(true);
        setSelectedData(record)
    };
    /** Deleting Spare parts data on confirm */
    const handleDeleteOk = async () => {
        setIsDeleteLoading(true)
        const obj = {
            columnName: activeKeyLine,
            tableName: activeKeyLine,
            data: selectedData
        };
        console.log(obj);
        await DeleteMasterDetails(obj)
        setIsDeleteLoading(false)
        setIsDeleteModalVisible(false);

        setSelectedData('');
        setloading(true)
        await FetchDatalist();
        setloading(false)

    }

    const UpdateMasterList = async () => {
        // const response = await getMasterListFromDatabase();
        // if (response) {
        //     setData_List(response);
        //     console.log('Record Item', response);

        //     // Set the initial selected material class
        //     //const initialClass = Object.keys(tableNames)[0];
        //     setSelectedMatrialClass(activeKeyLine);
        //     setActiveKeyLine(activeKeyLine);
        // }
    }
    /** Cancelling the deletion of spare parts data */
    const handleDeleteCancel = () => {
        setIsDeleteModalVisible(false);
    }


    const UpdateRentalPrice = () => {
        // setEdittModalVisible(true)

    }
    return (

        <div>
            <div className="d-flex mb-3 align-items-center">
                <div className='back_equip' >
                    <Tooltip title={'Back'} >
                        <ArrowLeftOutlined onClick={() => {

                            setIsMasterModalVisible(false)
                        }} />
                    </Tooltip>
                </div>

                <Title className="mb-0 add-new-calibration" level={3} style={{ paddingLeft: '25px', paddingTop: '5px' }}>
                    {selectedTap} Confguration
                </Title>

                <div className="new-calibration" style={{ display: 'flex', alignItems: 'center' }}>
                    {/* <Button shape="round" key="apply" type="primary" onClick={UpdateRentalPrice} loading={downloadLoading}>
                        Update Rental Price
                    </Button> */}

                    {/* <Text style={{ marginRight: '10px', fontSize: '15px' }}>Rental Price (%)</Text> */}
                    <Text style={{ marginRight: '10px', fontSize: '15px' }}>Rental Price (%)</Text>
                    <Input
                        onChange={(e) => setRentalPrice(Number(e.target.value))}
                        size="large"
                        type="number"
                        style={{ marginRight: '10px', width: '80px' }}
                        value={rentalPrice}
                        disabled = {!userGroup || !userGroup.includes('pricing-master')}
                    />
                    {userGroup && userGroup.includes('pricing-master') &&
                        <Button shape="round" key="apply" type="primary" onClick={() => handleUpdateOk()} loading={isUpdateLoading}>
                            Update
                        </Button>
                    }

                    <Text onClick={() => setShowAddNewModal(true)} className="ps-3 add-new-calibration" >
                        <span className="icon-22" style={{ marginRight: '5px' }}>
                            <svg style={{ width: 'auto', height: 'auto' }}>
                                <use xlinkHref="#add" />
                            </svg>
                        </span>
                        Add New
                    </Text>
                </div>

            </div>
            <div className="custom-line" />
            {getPipelineTabpanes()}

            {
                // showAddNewModal && data_List && data_List.length > 0 &&
                <MasterAddNew
                    showAddNewModal={showAddNewModal}
                    setShowAddNewModal={setShowAddNewModal}
                    addNewForm={addNewForm}
                    isEdit={isEdit}
                    setIsEdit={setIsEdit}
                    editRecord={editRecord}
                    seteditRecord={seteditRecord}
                    formData={AddUpdateList}
                    tableName={activeKeyLine}
                    FetchDatalist={FetchDatalist}
                    handleAddCancel={handleAddCancel}
                >

                </MasterAddNew>
            }
            <Modal width="20vw"
                className="pipeline-modal"
                title='Update'
                centered
                visible={edittModalVisible}
                footer={[
                    <Button key="cancel" onClick={handleCancel}>
                        Cancel
                    </Button>,
                    <Button key="apply" type="primary" onClick={handleUpdateOk} loading={isUpdateLoading}>
                        {isUpdateLoading ? 'Updating' : 'Update'}
                    </Button>
                ]}
            >
                <Form
                    form={UpdateModalForm}
                    name="basic"
                    layout="vertical"
                    autoComplete="off"
                >
                    <Row gutter={16}>
                        <Col lg={20}>
                            <Form.Item
                                name='Rental Price'
                                label='Rental Price (%)'
                            //required
                            >

                                <Input value={rentalPrice}
                                    onChange={(e) => setRentalPrice(Number(e.target.value))}
                                    size="large"
                                    type="number"
                                />
                            </Form.Item>
                        </Col>

                    </Row>
                </Form>

            </Modal>
            {/* <Modal width="30vw"
                className="pipeline-modal"
                title='Add New'
                centered
                visible={showAddNewModal}
                footer={[
                    <Button key="cancel" onClick={handleAddCancel}>
                        Cancel
                    </Button>,
                    <Button key="apply" type="primary" onClick={handleCreateNew} loading={addLoding}>
                        {showAddNewModal ? 'Add' : 'Adding'}
                    </Button>
                ]}
            >
                <Form
                    form={addNewForm}
                    name="basic"
                    layout="vertical"
                    autoComplete="off"
                >
                    <Row gutter={16}>
                        <Col lg={20}>
                            <Form.Item
                            name={coulumNames[activeKeyLine]}
                            label={tableNames[activeKeyLine]}
                            required
                            >
                                <Input style={{ height: '35px' }} onChange={(e) => setAddItem(e.target.value)} />
                            </Form.Item>
                        </Col>

                    </Row>
                </Form>

            </Modal> */}

            <Modal
                width="45vw"
                className="pipeline-modal"
                title="Delete Details"
                centered
                visible={isDeleteModalVisible}
                footer={[
                    <Button key="cancel" onClick={handleDeleteCancel}>
                        Cancel
                    </Button>,
                    <Button key="apply" type="primary" onClick={handleDeleteOk} loading={isDeleteLoading}>
                        {isDeleteLoading ? 'Deleting' : 'Delete'}
                    </Button>
                ]}
            >
                <div>
                    <Text style={{ fontSize: '16px' }}>
                        <p>Deleting all the corresponding and related information.</p>
                        Are you sure you want to delete  ?</Text>
                </div>
            </Modal>

        </div>
    );
};



export default connect(null, null)(MasteForm);

